import { useState } from "react";
import moment from 'moment';
import { productionApproveUpdate, productionRejectionUpdate } from "src/api/ReportsAPI";

const ApprovalModal=({
    selectedStatuses,
    production,
    getProjectProductionData
})=>{
 const remainDuration = production.totalDurations-((production?.approvedDurations||0)+(production?.rejectedDurations||0))
    const [approvedCount, setApprovedCount] = useState(0);
    const handleSubmit = async () => {
        if(approvedCount===0){
          alert('Please Enter Count')
          return
        }
        let data = { }
        if(selectedStatuses === 'Approved'){
            data = {
            approvedDurations: approvedCount
          }
        }
        else
        {
          data = {
            rejectedDurations: approvedCount
          }
        }
        try {
            let productionId = production._id;
            if(selectedStatuses === 'Approved'){
                 await productionApproveUpdate({ productionId, data })
            }
            else{
                await productionRejectionUpdate({ productionId, data })
            }      
            setApprovedCount(0);   
          getProjectProductionData(productionId)
        } catch (error) {
          console.log('error from approved count', error);
        }
      };
      const handleChange = (e) => {
        const value = e.target.value;        
        // Check if the value exceeds the remainDuration
        if (value > remainDuration) {
          alert('Max count is ' + remainDuration);
          // Revert the input field back to the previous valid value (remainDuration or previously approved value)
          e.target.value = remainDuration; // This will visually reset the input value in the field
          setApprovedCount(remainDuration); // Ensure the state also reflects the valid value          
          return;
        }        
        // Otherwise, set the valid input value to the state
        setApprovedCount(value);
      };
   return (
    <div class="modal fade cusmodl" id="statusModal" tabindex="-1" aria-labelledby="exampleModalLabel" style={{display: "none"}} aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header d-flex justify-content-between border-0 pb-0">
                    {/* <h5 class="modal-title" id="exampleModalLabel">{selectedStatuses==="Approved"?"Approval":"Rejection"} Count Max({remainDuration})</h5> */}
                    <div className="d-flex align-items-center">
 <span className=""> <img
    src="/assets/images/users/user1.png"
    alt=""
    className="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden"
  /></span>
  <div className="flex-grow-1">
    <h5 className="text-xl mb-0 fw-bold">{`${production?.userId?.firstName} ${production?.userId?.lastName}`} </h5>
    <span className="text-sm text-secondary-light fw-medium">
    {production?.userId?.designationId.designationName}
    </span>
  </div>
</div> 

                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button>
                </div>
                <div class="modal-body">
                 
                 <div className="d-flex justify-content-between align-content-center border rounded-4">
                 <div className="w-50 p-20">
                  <div className="datemy py-2 d-flex align-items-center gap-2 text-neutral-400"><i className="mdi mdi-calendar-blank text-blue mdi-24px"></i> {moment(production?.date).format("DD MMMM YYYY")}</div>
                  <div className="py-2 text-neutral-900">Task: <span className="text-neutral-400">{production?.taskId?.taskName}</span>  </div>
                  <div className="py-2 text-neutral-900">Module: <span  className="text-neutral-400">{production?.moduleId?.moduleName}</span></div>
                  <div className="py-2 text-neutral-900 text-xl fw-semibold d-flex align-items-center justify-content-between">Total Count <span className="text-sm fw-semibold text-neutral-400 me-4">{production.totalDurations}</span></div>
                  <div className="py-2 text-neutral-900 text-xl fw-semibold d-flex align-items-center justify-content-between">{`${selectedStatuses==="Approved"?"Approval":"Rejection"}`} Count Max <span class={`badge text-sm fw-semibold ${selectedStatuses==="Approved"?"text-success-600 bg-success-100":"text-danger-600 bg-danger-100"}  px-20 py-9 rounded-pill text-white`}>{production.totalDurations-((production?.approvedDurations||0)+(production?.rejectedDurations||0))}</span></div>
                 </div>

                 <div className="w-50 p-20 border-start">
<label>Comment:</label>
<div className="text-sm mt-12">
  {production?.comment}
</div>

</div>
                 </div>

                  <div class="d-flex align-items-center justify-content-between gap-3 pt-24"> 

                    

<div className={`${selectedStatuses==="Approved"?"text-green":"text-red"}  text-md fw-bold d-flex gap-2 align-items-center`}>
{
  selectedStatuses==="Approved"?<i className="mdi mdi-check-circle mdi-24px"></i>:<i className="mdi mdi-close-circle mdi-24px"></i>
}
   
  {`Enter ${selectedStatuses==="Approved"?"Approval":"Rejection"} Count`}
  </div>

<div className="d-flex gap-2 align-items-center">
<input 
                        type="text" 
                        value={approvedCount}
                        placeholder={`Enter ${selectedStatuses==="Approved"?"Approval":"Rejection"} Count`}
                        onChange={handleChange} 
                        class="form-control w-144-px"
                     />
				     <button 
                      class="btn btn-primary-600 radius-8 px-20 py-11"
                      onClick={handleSubmit}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                     >Save</button></div>
</div>
                </div>
            </div>
        </div>
    </div>
   )
}
export default ApprovalModal