import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const taskSearchAPI = function (data) {
    const url = `${config.baseUrl}task/search`;
    return postRequest({
        url,
        data,
    });
};
export const gettaskAPI = function (data) {
    const url = `${config.baseUrl}task/gettask`;
    return postRequest({
        url,
        data,
    });
};

export const taskCreateAPI = function (data) {
    const url = `${config.baseUrl}task/save`;
    return postRequest({
        url,
        data,
    });
};

export const taskUpdateAPI = function ({
    taskId,
    data,
}) {
    const url = `${config.baseUrl}task/update/${taskId}`;
    return putRequest({
        url,
        data,
    });
};
export const assignUserByTask = function (data) {
    const url = `${config.baseUrl}project//assign-user-by-task`;
    return postRequest({
        url,
        data,
    });
};

export const taskStatusUpdateAPI = function ({
    taskId,
    data,
}) {
    const url = `${config.baseUrl}task/updateStatus/${taskId}`;
    return putRequest({
        url,
        data,
    });
};

export const getPendingTask = function (data) {
    const url = `${config.baseUrl}dashboard/getPendingTask`;
    return postRequest({
        url,
        data,
    });
};
export const getPendingProduction = function (data) {
    const url = `${config.baseUrl}dashboard/getPendingProduction`;
    return postRequest({
        url,
        data,
    });
};
export const getAllProduction = function (data) {
    const url = `${config.baseUrl}dashboard/getAllProduction`;
    return postRequest({
        url,
        data,
    });
};