// LagDeductionDetail.js
import React, { useState } from "react";
import PropTypes from "prop-types";

const LagDeductionDetail = ({
    title,
    totalWorkingHours,
    requiredHours,
    exactHours,
    hubstaffTime,
    lagHours,
    lagAmount,
    pfDeduction,
    taxDeduction,
    otherDeduction,
    extraDeduction,
    totalDeduction,
    onInputChange,
    onRadioChange,
    className,
}) => {

      // State to hold the day count and the calculated amount
  const [days, setDays] = useState(0);
  const ratePerDay = 1000; // Set your rate per day here
  const amount = days * ratePerDay;

  // Handle change for days input
  const handleDayChange = (e) => {
    const value = parseInt(e.target.value, 10) || 0; // Parse integer or default to 0
    setDays(value);
  };
    return (
        <div className={`${className}`}>
            <div className="setbdn100">
                <h4 className="card-title">{title}</h4>
                <div className="radius-10 border p-16">
                    <div className="align-items-center d-flex justify-content-between py-8 py-16 border-bottom">
                        <label className="form-label mb-0 text-neutral-500">Total Working Hours</label>
                        <span className="text-md fw-semibold text-neutral-800">{totalWorkingHours}</span>
                    </div>
                    <div className="align-items-center d-flex justify-content-between py-8 py-16 border-bottom">
                        <label className="form-label mb-0 text-neutral-500">Required Hours</label>
                        <span className={`text-md fw-semibold text-neutral-800 ${requiredHours < 0 ? 'text-red' : ''}`}>
                            {requiredHours}
                        </span>
                    </div>
                    <div className="align-items-center d-flex justify-content-between py-8 mt-16">
                        <label className="form-label mb-0">Exact Hours</label>
                        <div className="inpset001">
                            <input
                                type="text"
                                name="exactHours"
                                className="form-control text-end w-160-px ms-auto"
                                placeholder="0"
                                value={exactHours}
                                onChange={(e) => onInputChange("exactHours", e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Hubstaff and Lag Hours */}
                    <div className="align-items-center d-flex justify-content-between py-16 mt-16 border-top">
                        <span className="d-flex align-items-center">
                            <img src="/assets/images/hubstaff.png" className="img-fluid" alt="hubstaff" />
                        </span>
                        <div className="d-flex align-items-center gap-2 border radius-6 py-4 px-16 cursor-pointer" data-bs-toggle="modal" data-bs-target="#HubstaffTimeModal">
                            <span className="text-dblue text-sm fw-semibold">Hubstaff Time</span>
                            {hubstaffTime.hours} <span className="text-green">+ {hubstaffTime.additional}</span>
                        </div>
                        <div className="d-flex align-items-center gap-2 border radius-6 py-4 px-16 cursor-pointer" data-bs-toggle="modal" data-bs-target="#HubstaffLiveDataModal">
                            <i className="mdi mdi-circle text-green"></i>
                            <span className="text-dblue text-sm fw-semibold">Hubstaff Live Data</span>
                        </div>
                    </div>

                    <div className="align-items-center d-flex justify-content-between py-12 border-top">
                        <div className="LagHours">Lag Hours</div>
                        <div className="hours">{lagHours} hours</div>
                        <div className={`Tamont ${lagAmount < 0 ? "text-red" : ""}`}>
                            {lagAmount < 0 ? `-₹ ${Math.abs(lagAmount)}` : `₹ ${lagAmount}`}
                        </div>
                    </div>
                    <div className="align-items-center d-flex justify-content-between pt-8 pb-16 border-bottom mb-16">
      <label className="form-label mb-0">Production Lag</label>
      <div className="d-flex gap-2 align-items-center">
        <div className="input-group w-160-px">
          <span className="input-group-text bg-base">Day</span>
          <input
            type="number"
            className="form-control flex-grow-1 text-end"
            placeholder={0}
            value={days}
            onChange={handleDayChange}
          />
        </div>
        <div className="input-group w-160-px">
          <span className="input-group-text bg-base border-end-0">₹</span>
          <input
            type="text"
            className="form-control flex-grow-1 text-end border-start-0"
            placeholder={0}
            value={amount}
            disabled
          />
        </div>
      </div>
    </div>
                    {/* Deduction Options */}
                    <div className="align-items-center d-flex justify-content-between py-8">
                        <label className="form-label mb-0">Consider for Deduction:</label>
                        <div className="inpset001">
                            <div className="d-flex align-items-center flex-wrap gap-28">
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="considerDeduction"
                                        id="horizontal1"
                                        onChange={() => onRadioChange("considerDeduction", "Yes")}
                                    />
                                    <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="horizontal1">
                                        Yes
                                    </label>
                                </div>
                                <div className="form-check checked-secondary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="considerDeduction"
                                        id="horizontal2"
                                        onChange={() => onRadioChange("considerDeduction", "No")}
                                    />
                                    <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="horizontal2">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div> 

                    {/* Deduction Inputs */}
                    {[
                        { label: "PF Deduction", value: pfDeduction, name: "pfDeduction" },
                        { label: "Tax Deduction", value: taxDeduction, name: "taxDeduction" },
                        { label: "Other Deduction", value: otherDeduction, name: "otherDeduction" },
                        { label: "Extra Deduction", value: extraDeduction, name: "extraDeduction" },
                        { label: "Total Deduction", value: totalDeduction, name: "totalDeduction" },
                    ].map((item, index) => (
                        <div className="align-items-center d-flex justify-content-between py-8" key={index}>
                            <label className="form-label mb-0">{item.label}</label>
                            <div className="inpset001">
                                <input
                                    type="text"
                                    name={item.name}
                                    className="form-control text-end w-160-px ms-auto"
                                    placeholder="0"
                                    value={item.value}
                                    onChange={(e) => onInputChange(item.name, e.target.value)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

// Define prop types
LagDeductionDetail.propTypes = {
    title: PropTypes.string.isRequired,
    totalWorkingHours: PropTypes.number.isRequired,
    requiredHours: PropTypes.number.isRequired,
    exactHours: PropTypes.string.isRequired,
    hubstaffTime: PropTypes.shape({
        hours: PropTypes.number,
        additional: PropTypes.number,
    }).isRequired,
    lagHours: PropTypes.number.isRequired,
    lagAmount: PropTypes.number.isRequired,
    pfDeduction: PropTypes.string.isRequired,
    taxDeduction: PropTypes.string.isRequired,
    otherDeduction: PropTypes.string.isRequired,
    extraDeduction: PropTypes.string.isRequired,
    totalDeduction: PropTypes.string.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onRadioChange: PropTypes.func.isRequired,
};

export default LagDeductionDetail;
