import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import LeaveDetails from './LeaveDetails'; // Import the LeaveDetails component
import LeaveTable from './LeaveTable';
import LagDeductionDetail from './LagDeductionDetail';
import EncashmentDetail from './EncashmentDetail';
import FinalSalary from './FinalSalary';
import GroupCounter from 'src/containers/production-container/GroupCounter';
import AttendanceCalendar from 'src/containers/production-container/AttendanceCalendar';
import SalaryComponent from './SalaryCard';

import EmployeeDetails from './EmployeeDetails';
import config from "src/config";
import { postRequest } from 'src/utils/RequestUtil';
import { salaryIndividualUpdateAction, salarySearchAction, salaryUpsertAction } from './actions/SalaryActions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { costFormat } from 'src/utils/CommonUtils';
import { PER_DAY_WORKING_HOURS } from 'src/constants/CommonConstants';
import ShowDetailsEmployee from 'src/components/Salary/ShowDetailsEmployee';
import HubstaffTime from 'src/components/Salary/HubstaffTime';
import HubstaffLiveData from 'src/components/Salary/HubstaffLiveData';
import CommentMessage from 'src/components/Salary/CommentMessage';
import ReviewEmployeeModalModal from 'src/components/Salary/ReviewEmployeeModal';
import LeaveDetailsPopup from 'src/components/Salary/LeaveDetailsPopup';
import SalarySlip from './SalarySlip';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { SalaryDefaultProps } from './SalaryPropTypes';

const AddSalarySlip = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const {
        search,
    } = useSelector((state) => state.SalaryReducer, shallowEqual);
    const navigate = useNavigate(); 
    // Define the month and year you want to check
    const specificMonth = search?.data.lastMonth; // Change as needed
    const specificYear = search?.data.lastYear; // Change as needed

    useEffect(() => {
        if (!search?.data?.salaryList?.length) {
            // If the salaryList is empty, return early
            return;
        }
        const hasMatchingSalary = search?.data?.salaryList.some(salary =>
            salary.month === specificMonth && salary.year === specificYear
        );

        if (hasMatchingSalary) {
            toast.error("Already created.");
            // Redirect to the /salary route if a match is found
            navigate('/salary');
        }
    }, [search?.data?.salaryList, navigate, specificMonth, specificYear]);

    const salarySearch = () => {
        const payload = {
            where,
        }
        dispatch(salarySearchAction(payload));
    }
    const [where, setWhere] = useState(
        { _id: userId, active: true }
    );
    useEffect(() => {
        salarySearch()
    }, [where])


    // const contentRef = useRef();

    // const handleDownloadPDF = () => {
    //     const content = contentRef.current;
    //     html2canvas(content, { scale: 2 }).then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF('p', 'mm', 'a4');

    //         const pdfWidth = pdf.internal.pageSize.getWidth();
    //         const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    //         pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //         pdf.save('download.pdf');
    //     });
    // };

    const contentRef = useRef(); 

    // Function to handle the PDF generation and download for ClientInvoiceDownload
    const handleDownloadPDF = () => {
        const element = contentRef.current; // Reference to ClientInvoiceDownload content

        // Use html2canvas to capture the content as an image
        html2canvas(element, {
            scale: 5, // Increase the scale for better quality
            useCORS: true, // Enable CORS to handle external images (if any)
            logging: true, // Log the process to console for debugging
            backgroundColor: '#ffffff', // Make sure background is white (can adjust if you need)
        }).then((canvas) => {
            // Convert canvas to image data
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF('P', 'mm', 'a4'); // A4 paper size in mm (landscape mode)

            // Adjust the image size to fit the PDF page
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            const scaleX = pdfWidth / canvasWidth;
            const scaleY = pdfHeight / canvasHeight;
            const scale = Math.min(scaleX, scaleY); // Scale to fit within the page

            const xOffset = (pdfWidth - canvasWidth * scale) / 2; // Center horizontally
            const yOffset = 0; // Set yOffset to 0 to start from the top

            // Add image to PDF with calculated position and scaling
            pdf.addImage(imgData, 'JPEG', xOffset, yOffset, canvasWidth * scale, canvasHeight * scale);

            // Save the PDF with a name
            pdf.save('salary-slip.pdf');
        });
    };
    
    
    const navigation = useNavigate();
    const [netSalary, setNetSalary] = useState(0);
    const [user, setUser] = useState('');
    const [perDaySalary, setPerDaySalary] = useState(0);
    const [initialValues, setInitialValues] = useState( SalaryDefaultProps );

    useEffect(() => {
        setUser({ ...search?.data?.userId, ...search?.data?.personal });
        setInitialValues(search?.data); 
        setPerDaySalary(((search?.data?.userId?.monthlySalary ?? 0) / (search?.data?.totalDays?.totalDays ?? 1)).toFixed(2))
    }, [search]);

    const validationSchema = Yup.object().shape({
        totalLeaves: Yup.number().typeError('Must be a number').integer('Must be an integer').min(0, 'Must be zero or greater'),
        adjustedSickLeaves: Yup.number().oneOf([0, 1, 2, 3], 'Invalid option'),
        adjustedCasualLeaves: Yup.number().oneOf([0, 1, 2, 3], 'Invalid option'),
    });


    const [calculatedLeaves, setCalculatedLeaves] = useState({
        effectiveLeaves: 0,
        adjustedSickLeavesAmount: 0,
        adjustedCasualLeavesAmount: 0,
        totalLeaveDeduction: 0, //effectiveLeaves * perDaySalary,
        totalLagHourDeduction: 0
    });
    useEffect(() => {
        let effLeaves = (Number(initialValues.adjustedSickLeaves) + Number(initialValues.adjustedCasualLeaves)) - initialValues.totalLeaves
        setCalculatedLeaves(prevValues => ({
            ...prevValues,
            effectiveLeaves: effLeaves,
            adjustedSickLeavesAmount: Number(initialValues.adjustedSickLeaves) * perDaySalary,
            adjustedCasualLeavesAmount: Number(initialValues.adjustedCasualLeaves) * perDaySalary,
            totalLeaveDeduction: effLeaves * perDaySalary,
        }));
    }, [initialValues, perDaySalary]);

    // Memoize the calculate function to avoid unnecessary recalculations
    const calculateLeaves = useCallback((values) => {
        const effectiveLeaves = (Number(values.adjustedSickLeaves) + Number(values.adjustedCasualLeaves)) - values.totalLeaves;
        const adjustedSickLeavesAmount = Number(values.adjustedSickLeaves) * perDaySalary;
        const adjustedCasualLeavesAmount = Number(values.adjustedCasualLeaves) * perDaySalary;
        const totalLeaveDeduction = effectiveLeaves * perDaySalary;

        setCalculatedLeaves({
            effectiveLeaves,
            adjustedSickLeavesAmount,
            adjustedCasualLeavesAmount,
            totalLeaveDeduction,
        });
    }, [perDaySalary]);

    const handleFieldChange = (e, handleChange) => {
        const { name, value } = e.target;
        handleChange(e); // Update Formik values
        setInitialValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        })); // Update parent initialValues
    };

    useEffect(() => {
        calculateLeaves(initialValues); // Initial calculation on load or initialValues change
    }, [initialValues, calculateLeaves]);

    useEffect(() => {
        setNetSalary(
            user?.monthlySalary
            - Number(Math.abs(calculatedLeaves?.totalLeaveDeduction))

        )

    }, [user, calculatedLeaves]);

    const onSubmit = (values) => {
        delete values.hubstaff;
        dispatch(salaryUpsertAction(values));
        handleBackClick();
    };
    // State to track the visibility of the details and button label
    const [showDetails, setShowDetails] = useState(false);

    // Toggle function to switch between "Show" and "Hide" states
    const toggleDetails = () => {
        setShowDetails((prev) => !prev);
    };
    const handleBackClick=()=> navigation(`/manage-salary`);

    const reGenerateSalary=()=>{ 
        dispatch(salaryIndividualUpdateAction({userId}));
    }

    return (
        <>
            <div className="card h-100">
                <div className="card-body p-24">

                    <div className="d-flex justify-content-between align-items-center border-bottom pb-16">
                        <div>
                            <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                                <button onClick={handleBackClick}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width={30} height={30}>
                                        <g data-name="20-Arrow Left">
                                            <path fill="#0073ea" d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z" />
                                            <path fill="#0073ea" d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z" />
                                        </g>
                                    </svg>
                                </button>
                                Employee Salary Slip
                            </h2>
                            <ul className="d-flex align-items-center gap-2">
                                <li className="fw-medium">
                                    <a href="/" className="d-flex align-items-center gap-1 hover-text-primary">
                                        <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg" />
                                        Special Access
                                    </a>
                                </li>
                                <li>-</li>
                                <li className="fw-medium">
                                    <a href="/" className="d-flex align-items-center gap-1 hover-text-primary">
                                        HR
                                    </a>
                                </li>
                                <li>-</li>
                                <li className="fw-medium">Salary Slip</li>
                            </ul>
                        </div>
                        <div style={{ position: 'absolute', top: '-10000px', left: '-10000px' }}>
                            <SalarySlip contentRef={contentRef} user={user} initialValues={initialValues}/>
                        </div>

                        <a
                            href="#"
                            className="btn btn-primary text-sm btn-sm px-12 py-4 radius-8 d-flex align-items-center gap-2"
                            onClick={handleDownloadPDF}
                        >
                            Download Salary Slip <i className="mdi mdi-download mdi-18px"></i>
                        </a>
                        <button 
                        type='button'
                        className="btn btn-primary text-sm btn-sm px-12 py-4 radius-8 d-flex align-items-center gap-2" 
                        onClick={reGenerateSalary}>
                            Re Generate Salary 
                       </button>
                    </div> 
                    <EmployeeDetails user={user} />
                    <div className="mt-24">
                        <h4 className="card-title">Production Details</h4>
                        <GroupCounter />
                        <AttendanceCalendar />
                        <div className="d-flex flex-wrap align-items-center gap-4 py-16 border-bottom">
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-green-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Presents</span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-pink-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Holidays</span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-yellow-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Sick Leave</span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-blue-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Casual Leave</span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-zinc-300 rounded-circle" />
                                <span className="text-zinc-300 fw-medium">Week Off</span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-yellow-200 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Half Day</span>
                            </div>
                        </div>

                        <div className='text-end d-flex justify-content-end pt-16'> <button
                            className="text-primary-600 hover-text-primary d-flex align-items-center gap-1 fw-semibold"
                            onClick={toggleDetails}
                        >
                            {showDetails ? 'Hide Details' : 'Show Details'}
                        </button></div>
                        {showDetails && (
                            <div className="showdtalponew">
                                <ShowDetailsEmployee />
                            </div>
                        )}
                    </div>

                    <div className='my-24'>
                        <SalaryComponent salaryList={search?.data.salaryList} totalDays={search?.data?.daysDetails} />
                    </div>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validate={(values) => {
                            calculateLeaves(values); // Trigger calculation on form value change
                        }}
                    >
                        {({ values, handleChange }) => (
                            <Form>
                                <div className="row">
                                    <LeaveDetails
                                        values={values}
                                        employeeDetails={{ ...values, ...calculatedLeaves }}
                                        leaveDetails={ values.leaveDetails}
                                        handleChange={(e) => handleFieldChange(e, handleChange)} className="col-xl-6 col-md-12 mb-24 gy-3"
                                    />
                                    {/* <LeaveTable className="col-xl-6 col-md-12 mb-24 gy-3" /> */}

                                </div>
                                <div className='border-top'>
                                    <div className="row">
                                        <LagDeductionDetail className="col-xl-6 col-md-12 mb-24 gy-3"
                                            lagHourDetails={values.lagHourDetails}
                                            leaveDetails={ values.leaveDetails}
                                            values={values}
                                            employeeDetails={{ ...values, ...calculatedLeaves }}
                                            totalDays={search?.data?.totalDays}
                                            title="Lag Deduction Detail"
                                            totalWorkingHours={0.5}
                                            requiredHours={-800}
                                            exactHours="00"
                                            hubstaffTime={{ hours: values?.lagHourDetails?.exactHours, additional: values?.lagHourDetails?.hubstaffTime-values?.lagHourDetails?.requiredHours }}
                                            lagHours={0}
                                            lagAmount={`0`}
                                            pfDeduction="00"
                                            taxDeduction="00"
                                            otherDeduction="00"
                                            extraDeduction="00"
                                            totalLeaveDeduction="00"
                                            handleChange={(e) => handleFieldChange(e, handleChange)}
                                        />
                                        <div className="col-xl-6 col-md-12 mb-24 gy-3">
                                            <EncashmentDetail 
                                            encashmentDetails={values.encashmentDetails}
                                            leaveDetails={ values.leaveDetails}
                                            values={values}
                                            />
                                            <FinalSalary values={values}/>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <LeaveDetailsPopup />
            <HubstaffTime />
            <HubstaffLiveData />

            <CommentMessage />
            <ReviewEmployeeModalModal />
        </>
    );
};

export default AddSalarySlip;
