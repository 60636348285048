import React, { useState } from "react";

const EncashmentDetail = () => {
    // Define state variables for each input field
    const [leaveEncashmentDay, setLeaveEncashmentDay] = useState(0);
    const [leaveEncashmentAmount, setLeaveEncashmentAmount] = useState(0);
    const [incentivesDay, setIncentivesDay] = useState(0);
    const [incentivesAmount, setIncentivesAmount] = useState(0);
    const [previousMonthDay, setPreviousMonthDay] = useState(0);
    const [previousMonthAmount, setPreviousMonthAmount] = useState(0);
    const [performanceBonus, setPerformanceBonus] = useState(0);
    const [totalDeduction, setTotalDeduction] = useState(400); // Initial value

    return (
        <div className="setbdn100">
            <h4 className="card-title">Encashment Detail</h4>
            <div className="radius-10 border p-16">
                <div className="align-items-center d-flex justify-content-between py-8 mt-16">
                    <label className="form-label mb-0">Leave Encashment</label>
                    <div className="d-flex gap-2 align-items-center">
                        <div className="input-group w-160-px">
                            <span className="input-group-text bg-base">Day</span>
                            <input
                                type="text"
                                className="form-control flex-grow-1 text-end"
                                placeholder="0"
                                value={leaveEncashmentDay}
                                onChange={(e) => setLeaveEncashmentDay(e.target.value)}
                            />
                        </div>
                        <div className="input-group w-160-px">
                            <span className="input-group-text bg-base border-end-0">&#8377;</span>
                            <input
                                type="text"
                                className="form-control flex-grow-1 text-end border-start-0"
                                placeholder="0"
                                value={leaveEncashmentAmount}
                                onChange={(e) => setLeaveEncashmentAmount(e.target.value)}
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Incentives:</label>
                    <div className="d-flex gap-2 align-items-center">
                        <div className="input-group w-160-px">
                            <span className="input-group-text bg-base">Day</span>
                            <input
                                type="text"
                                className="form-control flex-grow-1 text-end"
                                placeholder="0"
                                value={incentivesDay}
                                onChange={(e) => setIncentivesDay(e.target.value)}
                            />
                        </div>
                        <div className="input-group w-160-px">
                            <span className="input-group-text bg-base border-end-0">&#8377;</span>
                            <input
                                type="text"
                                className="form-control flex-grow-1 text-end border-start-0"
                                placeholder="0"
                                value={incentivesAmount}
                                onChange={(e) => setIncentivesAmount(e.target.value)}
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Previous Month</label>
                    <div className="d-flex gap-2 align-items-center">
                        <div className="input-group w-160-px">
                            <span className="input-group-text bg-base">Day</span>
                            <input
                                type="text"
                                className="form-control flex-grow-1 text-end"
                                placeholder="0"
                                value={previousMonthDay}
                                onChange={(e) => setPreviousMonthDay(e.target.value)}
                            />
                        </div>
                        <div className="input-group w-160-px">
                            <span className="input-group-text bg-base border-end-0">&#8377;</span>
                            <input
                                type="text"
                                className="form-control flex-grow-1 text-end border-start-0"
                                placeholder="0"
                                value={previousMonthAmount}
                                onChange={(e) => setPreviousMonthAmount(e.target.value)}
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Performance Bonus:</label>
                    <input
                        type="text"
                        className="form-control text-end w-160-px"
                        placeholder="0"
                        value={performanceBonus}
                        onChange={(e) => setPerformanceBonus(e.target.value)}
                    />
                </div>

                <div className="align-items-center d-flex justify-content-between py-8 py-16 px-16 bg-primary-50 radius-10">
                    <label className="form-label mb-0 text-dblue">Total</label>
                    <span className={`text-xl fw-bold ${totalDeduction < 0 ? "text-red" : ""}`}>
                        {totalDeduction < 0 ? `-₹ ${Math.abs(totalDeduction)}` : `₹ ${totalDeduction}`}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default EncashmentDetail;
