import React from 'react';

const TotalSummary = ({ title, value }) => {
  return (
    <li className="text-dblue">
      {value} <span className="d-block">{title}</span>
    </li>
  );
};

export default TotalSummary;
