import React, { useEffect, useState } from 'react' 
import Select from 'react-select';
import { userSearchAPI } from 'src/api/UserApi';

/**
 * The options array should contain objects.
 * Required keys are "name" and "value" but you can have and use any number of key/value pairs.
 */


const option = [
    { label: 'Swedish', value: 'sv' },
    { label: 'English', value: 'en' },
     
];

const UserMultiSelect = (props) => {
    const [dropdownOptions, setDropdownOptions] = useState(option)
    // useEffect(()=>{
    //     if(options.length > 0){
    //         let option = options.map((item) => ({value:item.subject,id:item._id,label:item.subject}))
    //         setDropdownOptions(option)
    //     }
    // },[options])

    useEffect(() => {
        const getOptions = async () => {
            const {data:{data}} = await userSearchAPI({});
            if(data.length > 0){
                let option = data?.map((item) => ({
                    value:`${item.firstName} ${item.lastName} (${item.employeeID})`,
                    label:`${item.firstName} ${item.lastName} (${item.employeeID})`,
                    id:item._id,                    
                }))
                setDropdownOptions(option)
            }
            // setDropdownOptions(option);
        }
        getOptions();
    },[]) 

    return (
        /* Simple example */
                <Select className="p-0"
                // classNamePrefix="react-select" 
                // value={getValue()}
                options={dropdownOptions}
                isMulti={true}                 
                {...props}
            />
    );
}

export default UserMultiSelect;
