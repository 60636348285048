import React, { useState, useEffect, useRef } from 'react'
import SummariesComponent from './SummariesComponent';
import { calculateDeviation, calculatePercentage, costFormat } from 'src/utils/CommonUtils';
import { useSticky } from 'src/hooks/useSticky';

const PddStatsVertical = (props) => {

  const [data, setData] = useState([]);
  useEffect(() => {
    setData(props?.search)
  }, [props])

  // Calculate totals
  let totalOAD = 0;
  let totalCMD = 0;

  data.forEach((item) => {
    totalOAD += item.totalOad || 0;
    totalCMD += item.totalcmd || 0;
  });

  const verticalData = Object.values(
    data.reduce((acc, { vertical, totalDeliveryCost, totalcmd, totalOad }) => {
      if (!acc[vertical]) {
        acc[vertical] = {
          vertical,
          total: { totalDeliveryCost: 0, totalcmd: 0, totalOad: 0 }
        };
      }

      // Summing up values for each vertical
      acc[vertical].total.totalDeliveryCost += totalDeliveryCost;
      acc[vertical].total.totalcmd += totalcmd;
      acc[vertical].total.totalOad += totalOad;

      return acc;
    }, {})
  );

  const getClassName = (value) => {
    return value.includes('-') ? 'text-red' : 'text-green';
  };

  const trimmedQuery = (typeof props.searchQuery === 'string' ? props.searchQuery : '').trim().toLowerCase();
  const filteredData = trimmedQuery
    ? verticalData.filter((item) =>
      // item.ClientProjectCode.toLowerCase().includes(trimmedQuery) ||
      item.vertical.toLowerCase().includes(trimmedQuery)
    )
    : verticalData; // Return all data if the searchQuery is empty

  const [totalOAD2, totalCMD2, totalDelivery2] = filteredData.reduce(
    ([oadSum, cmdSum, deliverySum], item) => [
      oadSum + (item.total.totalOad || 0),
      cmdSum + (item.total.totalcmd || 0),
      deliverySum + (item.total.totalDeliveryCost || 0)
    ],
    [0, 0, 0]
  );

  const summaries1 = [
    { title: 'Total Domain', value: filteredData.length },
    { title: 'Total OAD', value: costFormat(totalOAD2) },
    { title: 'Total Actual Delivered', value: costFormat(totalDelivery2) },
    { title: 'Total CMD', value: costFormat(totalCMD2) },
  ];

  // const { isSticky, elementRef } = useSticky(); // Use the custom hook

  // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code Start
  return (
    <>
    <div className="TotlUbgset pb-24">
      <SummariesComponent summaries={summaries1} /></div>
      <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
      <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Vertical</th>
            <th>OAD</th>
            <th>%Contribute</th>
            <th>CMD</th>
            <th>%Contribute</th>
            <th>Actual Delivered</th>
            <th>% Delivered</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">No data available.</td>
            </tr>
          ) : (
            filteredData.map((row, index) => {
              const oadContribute = calculatePercentage(row.total.totalOad, totalOAD)
              const cmdContribute = calculatePercentage(row.total.totalcmd, totalCMD)
              const percentDelivered = calculateDeviation(row.total.totalDeliveryCost, row.total?.totalcmd)
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td><strong>{row.vertical}</strong></td>
                  <td>{costFormat(row.total.totalOad)}</td>
                  <td className={`text-end ${getClassName(oadContribute)}`}>{oadContribute}</td>
                  <td>{costFormat(row.total?.totalcmd)}</td>
                  <td className={`text-end ${getClassName(cmdContribute)}`}>{cmdContribute}</td>
                  <td>{costFormat(row.total?.totalDeliveryCost)}</td>
                  <td className={`text-end ${getClassName(percentDelivered)}`}>{percentDelivered}</td>
                </tr>
              )
            }))}
        </tbody>
      </table>
      </div>
    </>
  )
}

export default PddStatsVertical
