export const PERMISSION_MASTER_ACTIONS = {
    PERMISSION_MASTER_FETCH: 'PERMISSION_MASTER',
    PERMISSION_MASTER_FETCH_SUCCESS: 'PERMISSION_MASTER_SUCCESS',
    PERMISSION_MASTER_FETCH_ERROR: 'PERMISSION_MASTER_ERROR',
};

export const permissionMasterFetch = (payload) => ({
    type: PERMISSION_MASTER_ACTIONS.PERMISSION_MASTER_FETCH,
    ...payload,
});

export default {
    PERMISSION_MASTER_ACTIONS,
    permissionMasterFetch,
};
