import React, { useState } from 'react';
import { dateFormat, tenureFormat } from 'src/utils/utils';

// Helper function to generate random color
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Function to calculate contrast color (black or white)
const getContrastColor = (hexColor) => {
  // Remove the # character
  hexColor = hexColor.substring(1);

  // Convert hex to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate brightness using the formula
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // If brightness is less than 128, return white, otherwise return black
  return brightness < 128 ? '#FFFFFF' : '#000000';
};

const EmployeeDetails = ({ user, personal }) => {
  // Function to extract initials (e.g., N from Nandita and S from Sharma)
  const getInitials = (name) => {
    const nameParts = name.split(' ');
    const initials = nameParts.length > 1 ? nameParts[0][0] + nameParts[1][0] : nameParts[0][0];
    return initials.toUpperCase();
  };

  // Generate dynamic background color and contrast text color
  const backgroundColor = getRandomColor();
  const textColor = getContrastColor(backgroundColor);

  return (
    <div className="employee-details my-24 border-bottom">
      <h2 className="card-title">Employee Details</h2>
      <div className="employee-ftdtal d-flex justify-content-between border-bottom">
        <div className="employee-header d-flex align-items-center gap-2">
          <span className="profile-pic">
            {user?.profilePicture ? (
              <img
                src={user?.profilePicture}
                alt={`${user?.firstName} ${user?.lastName}`}
              />
            ) : (
              <div
                className="initials-placeholder"
                style={{
                  backgroundColor: backgroundColor,
                  color: textColor,
                }}
              >
                {getInitials(`${user?.firstName} ${user?.lastName}`)}
              </div>
            )}
          </span>
          <div>
            <h3 className="card-title">
              {`${user?.firstName} ${user?.lastName}`} <span>({user?.employeeID})</span>
            </h3>
            <p className="mb-0">{user?.designationId?.designationName}</p>
            <p className="mb-0">Department: {user?.departmentId?.departmentName}</p>
          </div>
        </div>
        <div className="contact-info text-end">
          <p><span className="fw-bold d-block">Email:</span> {user?.email}</p>
          <p><span className="fw-bold d-block">Mobile:</span> {user?.mobile}</p>
        </div>
      </div>
      <div className="bank-details d-flex justify-content-between align-items-center">
        <div><span className="fw-bold d-block">Account No:</span> {user?.accountno}</div>
        <div><span className="fw-bold d-block">IFSC:</span> {user?.ifsc}</div>
        <div><span className="fw-bold d-block">Bank Name:</span> {user?.bankname}</div>
        <div><span className="fw-bold d-block">Tenure:</span> {tenureFormat(user?.joindate)}</div>
        <div><span className="fw-bold d-block">Date of Joining:</span> {dateFormat(user?.joindate,false)}</div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
