import React from 'react';
import PropTypes from 'prop-types';

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Format day and year
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  // Format month
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const month = months[date.getMonth()];

  return `${day}-${month}-${year}`;
};

// DateFormat component
const DateFormat = ({ dateString }) => {
  const formattedDate = formatDate(dateString);
  return <span>{formattedDate}</span>;
};

// PropTypes for validation
DateFormat.propTypes = {
  dateString: PropTypes.string.isRequired,
};

export default DateFormat;
