export const TASK_ACTIONS = {
    TASK_SEARCH: 'TASK_SEARCH',
    TASK_SEARCH_SUCCESS: 'TASK_SEARCH_SUCCESS',
    TASK_SEARCH_ERROR: 'TASK_SEARCH_ERROR',

    TASK_UPSERT: 'TASK_UPSERT',
    TASK_UPSERT_SUCCESS: 'TASK_UPSERT_SUCCESS',
    TASK_UPSERT_ERROR: 'TASK_UPSERT_ERROR',

    TASK_EDIT: 'TASK_EDIT',

    TASK_RESET: 'TASK_RESET',
};

export const taskResetAction = () => ({
    type: TASK_ACTIONS.TASK_RESET,
});

export const taskSearchAction = (payload) => ({
    type: TASK_ACTIONS.TASK_SEARCH,
    ...payload,
});

export const taskEditAction = (payload) => ({
    type: TASK_ACTIONS.TASK_EDIT,
    ...payload,
});

export const taskUpsertAction = (payload) => ({
    type: TASK_ACTIONS.TASK_UPSERT,
    ...payload,
});

export default {
    TASK_ACTIONS,
    taskSearchAction,
    taskUpsertAction,
    taskEditAction,
};
