export const CLIENT_ACTIONS = {
    CLIENT_SEARCH: 'CLIENT_SEARCH',
    CLIENT_SEARCH_SUCCESS: 'CLIENT_SEARCH_SUCCESS',
    CLIENT_SEARCH_ERROR: 'CLIENT_SEARCH_ERROR',

    PROJECT_ASSIGN_LOAD: 'PROJECT_ASSIGN_LOAD',
    SET_PROJECT_ASSIGN: 'SET_PROJECT_ASSIGN',

    CLIENT_UPSERT: 'CLIENT_UPSERT',
    CLIENT_UPSERT_SUCCESS: 'CLIENT_UPSERT_SUCCESS',
    CLIENT_UPSERT_ERROR: 'CLIENT_UPSERT_ERROR',

    CLIENT_EDIT: 'CLIENT_EDIT',

    CLIENT_RESET: 'CLIENT_RESET',
};

export const clientResetAction = () => ({
    type: CLIENT_ACTIONS.CLIENT_RESET,
});

export const clientSearchAction = (payload) => ({
    type: CLIENT_ACTIONS.CLIENT_SEARCH,
    ...payload,
});
export const ProjectAssigneeSearchAction = (payload) => ({
    type: CLIENT_ACTIONS.CLIENT_SEARCH,
    ...payload,
});

export const clientEditAction = (payload) => ({
    type: CLIENT_ACTIONS.CLIENT_EDIT,
    ...payload,
});

export const clientUpsertAction = (payload) => ({
    type: CLIENT_ACTIONS.CLIENT_UPSERT,
    ...payload,
});

export const projectAssigneeLoadAction = (payload) => ({
    type: CLIENT_ACTIONS.PROJECT_ASSIGN_LOAD,
    ...payload,
});
export const setProjectAssigneeAction = (payload) => ({
    type: CLIENT_ACTIONS.SET_PROJECT_ASSIGN,
    ...payload,
});
export default {
    CLIENT_ACTIONS,
    clientSearchAction,
    clientUpsertAction,
    clientEditAction,
    projectAssigneeLoadAction,
    setProjectAssigneeAction,
};
