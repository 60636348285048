import React, { useState } from 'react';
import SearchInput from 'src/components/SearchInput';
import DatePickerComponent from 'src/components/DatePickerComponent';
import Select2Component from 'src/components/Select2Component';


const FeedbackExcludedDomain = () => {

    // Initial table data
    const [tableExcluded, setTableExcluded] = useState([
        {
            useremail: 'acadecraft.com',
        },
    ]);

    // Function to add a new row
    const handleAddRow = () => {
        const newRow = { useremail: '' }; // Create a new empty row or customize this
        setTableExcluded([...tableExcluded, newRow]); // Add new row to the table
    };

    // Function to remove a row
    const handleRemoveRow = (index) => {
        const newTable = tableExcluded.filter((_, i) => i !== index); // Remove row at the specified index
        setTableExcluded(newTable);
    };
    return (
        <>
            <div>
                <div className="d-flex justify-content-end"><button
                    type="button"
                    className="btn btn-primary text-sm btn-sm px-18 py-10 radius-8 d-flex align-items-center gap-2"
                    onClick={handleAddRow}
                ><i class="mdi mdi-plus"></i>
                    Add
                </button></div>
                <div className="table-responsive table-container mt-24">
                    <table className="table basic-border-table mb-0 table-hover">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Excluded Domains Name</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableExcluded.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <div className="tsknem">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={item.useremail}
                                                onChange={(e) => {
                                                    const newTable = [...tableExcluded];
                                                    newTable[index].useremail = e.target.value;
                                                    setTableExcluded(newTable); // Update the email
                                                }}
                                                placeholder="Enter Domains Name"
                                            />
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-danger-100 remove text-danger-600 radius-8 px-14 py-6 text-sm"
                                            onClick={() => handleRemoveRow(index)}
                                        >
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default FeedbackExcludedDomain