import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const HomeSearchAPI = function (data) {
    const url = `${config.baseUrl}home/search`;
    return postRequest({
        url,
        data,
    });
};

export const HomeCreateAPI = function (data) {
    const url = `${config.baseUrl}home`;
    return postRequest({
        url,
        data,
    });
};
export const HomeContactAPI = function (data) {
    const url = `${config.baseUrl}contact/save`;
    return postRequest({
        url,
        data,
    });
};

export const HomeUpdateAPI = function ({
    homeId,
    data,
}) {
    const url = `${config.baseUrl}home/${homeId}`;
    return putRequest({
        url,
        data,
    });
};
