import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const designationSearchAPI = function (data) {
    const url = `${config.baseUrl}admin/designation/search`;
    return postRequest({
        url,
        data,
    });
};
export const getDesignationAPI = function (data) {
    const url = `${config.baseUrl}user/getDesignation`;
    return postRequest({
        url,
        data,
    });
};

export const designationCreateAPI = function (data) {
    const url = `${config.baseUrl}admin/designation/save`;
    return postRequest({
        url,
        data,
    });
};

export const designationUpdateAPI = function ({
    designationId,
    data,
}) {
    const url = `${config.baseUrl}admin/designation/update/${designationId}`;
    return putRequest({
        url,
        data,
    });
};
