import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const InvoiceButtons = () => {
  const location = useLocation(); // To get the current path
  const [activeButton, setActiveButton] = useState(location.pathname); // Initialize with current path

  // Handle click and update the active state based on the current path
  const handleButtonClick = (path) => {
    setActiveButton(path);
  };

  return (
    <div className="d-flex flex-wrap align-items-center gap-3 btnActiveLight">
      <Link to="/account-invoice">
        <button
          type="button"
          onClick={() => handleButtonClick("/account-invoice")}
          className={`badge text-sm fw-semibold px-16 py-9 radius-4 text-white d-flex align-items-center gap-2 text-primary-light ${
            activeButton === "/account-invoice" ? "active" : ""
          }`}
        >
          Pending Invoice
          <span className="badge bg-neutral-900 text-base radius-5 text-xs">259</span>
        </button>
      </Link>

      <Link to="/invoice-paid">
        <button
          type="button"
          onClick={() => handleButtonClick("/invoice-paid")}
          className={`badge text-sm fw-semibold px-16 py-9 radius-4 text-white d-flex align-items-center gap-2 text-primary-light ${
            activeButton === "/invoice-paid" ? "active" : ""
          }`}
        >
          Paid Invoice
          <span className="badge bg-neutral-900 text-base radius-5 text-xs">105</span>
        </button>
      </Link>

      <Link to="/cancelled-invoice">
        <button
          type="button"
          onClick={() => handleButtonClick("/cancelled-invoice")}
          className={`badge text-sm fw-semibold px-16 py-9 radius-4 text-white d-flex align-items-center gap-2 text-primary-light ${
            activeButton === "/cancelled-invoice" ? "active" : ""
          }`}
        >
          Cancelled Invoice
          <span className="badge bg-neutral-900 text-base radius-5 text-xs">8</span>
        </button>
      </Link>
    </div>
  );
};

export default InvoiceButtons
