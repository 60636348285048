export const PRODUCTION_ACTIONS = {
    PRODUCTION_SEARCH: 'PRODUCTION_SEARCH',
    PRODUCTION_SEARCH_SUCCESS: 'PRODUCTION_SEARCH_SUCCESS',
    PRODUCTION_SEARCH_ERROR: 'PRODUCTION_SEARCH_ERROR',

    PRODUCTION_CURRENT_PRODUCTION: 'PRODUCTION_CURRENT_PRODUCTION',
    
    PRODUCTION_ASSIGNED_PROJECTS_SEARCH: 'PRODUCTION_ASSIGNED_PROJECTS_SEARCH',
    PRODUCTION_TASK_BY_PROJECT_SEARCH: ' PRODUCTION_TASK_BY_PROJECT_SEARCH',
    PRODUCTION_MODULE_BY_TASK_SEARCH: ' PRODUCTION_MODULE_BY_TASK_SEARCH',

    PRODUCTION_ASSIGN_CLIENT: 'PRODUCTION_ASSIGN_CLIENT',
    PRODUCTION_ASSIGN_PROJECT: 'PRODUCTION_ASSIGN_PROJECT',
    PRODUCTION_ASSIGN_SUB_PROJECT: 'PRODUCTION_ASSIGN_SUB_PROJECT',
    PRODUCTION_ASSIGN_TASK: 'PRODUCTION_ASSIGN_TASK',
    PRODUCTION_ASSIGN_MODULE: 'PRODUCTION_ASSIGN_MODULE',

    PRODUCTION_UPSERT: 'PRODUCTION_UPSERT',
    PRODUCTION_UPSERT_SUCCESS: 'PRODUCTION_UPSERT_SUCCESS',
    PRODUCTION_UPSERT_ERROR: 'PRODUCTION_UPSERT_ERROR',

    PRODUCTION_EDIT: 'PRODUCTION_EDIT',

    PRODUCTION_RESET: 'PRODUCTION_RESET',
    CHANGE_DATE: 'CHANGE_DATE',
};

export const productionResetAction = () => ({
    type: PRODUCTION_ACTIONS.PRODUCTION_RESET,
});

export const productionSearchAction = (payload) => ({
    type: PRODUCTION_ACTIONS.PRODUCTION_SEARCH,
    ...payload,
});

export const productionEditAction = (payload) => ({
    type: PRODUCTION_ACTIONS.PRODUCTION_EDIT,
    ...payload,
});

export const productionUpsertAction = (payload) => ({
    type: PRODUCTION_ACTIONS.PRODUCTION_UPSERT,
    ...payload,
});
export const productionProjectAssigneeSearchAction = (payload) => ({
    type: PRODUCTION_ACTIONS.PRODUCTION_ASSIGNED_PROJECTS_SEARCH,
    ...payload,
});
export const productionTaskByProjectSearchAction = (payload) => ({
    type: PRODUCTION_ACTIONS.PRODUCTION_TASK_BY_PROJECT_SEARCH,
    ...payload,
});
export const productionModuleByTaskSearchAction = (payload) => ({
    type: PRODUCTION_ACTIONS.PRODUCTION_MODULE_BY_TASK_SEARCH,
    ...payload,
});



export const productionAssignClientIdAction = (payload) => ({
    type: PRODUCTION_ACTIONS.PRODUCTION_ASSIGN_CLIENT,
    ...payload,
});
export const productionAssignProjectIdAction = (payload) => ({
    type: PRODUCTION_ACTIONS.PRODUCTION_ASSIGN_PROJECT,
    ...payload,
});
export const productionAssignTaskIdAction = (payload) => ({
    type: PRODUCTION_ACTIONS.PRODUCTION_ASSIGN_TASK,
    ...payload,
});
export const productionAssignModuleIdAction = (payload) => ({
    type: PRODUCTION_ACTIONS.PRODUCTION_ASSIGN_MODULE,
    ...payload,
});
export const handleChangeDateAction = (payload) => ({
    type: PRODUCTION_ACTIONS.CHANGE_DATE,
    ...payload,
});
export default {
    PRODUCTION_ACTIONS,
    productionSearchAction,
    productionUpsertAction,
    productionEditAction,
    handleChangeDateAction,
};
