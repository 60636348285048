import { put, call } from 'redux-saga/effects';
import { userCreateAPI, userProfilePicAPI, userSearchAPI, userUpdateAPI, userUploadAPI } from 'src/api/UserApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { USER_ACTIONS } from '../actions/UserActions';
import { toast } from 'react-toastify';

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        
        
        
        
        const {
            data: {
                data,
            },
        } = yield call(userSearchAPI, {
            where,
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: USER_ACTIONS.USER_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: USER_ACTIONS.USER_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({
    _id,
}) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(userSearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {
            
            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }
        delete data[0].password;
        
        yield put({
            type: USER_ACTIONS.USER_UPSERT_SUCCESS,
            currentRecord:data[0],
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(userCreateAPI, payload);  
        if(data?._id && payload.profilePicture){
             yield call(userProfilePicAPI, {
            userId:data?._id,
            data: payload,
            });
        }
           
       
        yield put(headerShowAction({
            data: 'Created',
        })); 
        toast("User Created")
        
        yield put({
            type: USER_ACTIONS.USER_UPSERT_SUCCESS,
            data: {
                ...payload,
                _id: data?._id,
            },
        });
        yield put({
            type: USER_ACTIONS.USER_RESET
        });
        yield put({
            type: USER_ACTIONS.USER_SEARCH
        });
    }
    catch (error) {
        toast.error(error?.message);
        toast.error(error?.response?.data?.message);
        yield put({
            type: USER_ACTIONS.USER_UPSERT_ERROR,
        });
    }
}
function* bulkUpload(payload) {
    try {
        
        
        
        
        
        
        const {finalData} = yield call(userUploadAPI, payload);

        toast(`${finalData.length} User uploaded successfully`)
        
        yield put({
            type: USER_ACTIONS.USER_UPLOAD_SUCCESS,
            data: {
                ...payload,
                
            },
        });
        yield put({
            type: USER_ACTIONS.USER_SEARCH
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: USER_ACTIONS.USER_UPLOAD_ERROR,
        });
    }
}

function* update(userId, payload) {
    try {
        yield call(userUpdateAPI, {
            userId,
            data: payload,
        });

        yield put(headerShowAction({
            data: 'Updated',
        }));
        if(payload.deleted===true){
            toast("User deleted")
        }
        else{
            toast("User account updated")
        } 
        
        yield put({
            type: USER_ACTIONS.USER_SEARCH
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: USER_ACTIONS.USER_UPSERT_ERROR,
        });
    }
}

function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        yield insert(payload);
    }
}

export default [
    takeFirstSagaUtil(USER_ACTIONS.USER_SEARCH, search),
    takeFirstSagaUtil(USER_ACTIONS.USER_UPSERT, upsert),
    takeFirstSagaUtil(USER_ACTIONS.USER_UPLOAD, bulkUpload),
    takeFirstSagaUtil(USER_ACTIONS.USER_EDIT, edit),
];
