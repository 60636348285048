import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const ClientInvoiceDownload = ({ contentRef }) => {
   

    return (

        <div>
            {/* Button to trigger the PDF download */}
            {/* <button onClick={handleDownload}>Download PDF</button> */}


            <div  ref={contentRef}
                id="invoiceContent"
                style={{ width: "100%", maxWidth: 940, margin: "auto", color: "#333", fontFamily: "Inter, sans-serif", backgroundColor: "#fff", padding: 20, borderRadius: 8, lineHeight: "1.5", fontSize: 14 }} >
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');\n        body,p,h3,h2,h1,h4{\n  font-family: Inter, sans-serif;\n  font-optical-sizing: auto; \n  font-style: normal;\n}\n"
                    }}
                />
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 0, borderBottom: "1px solid #ccc" }}>
                    <span style={{ fontSize: "40px", color: "#fff", backgroundColor: "#007bff", padding: "0px 30px 15px 30px", borderRadius: "8px 8px 0px 0", margin: 0, height: "66px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 500 }}>Invoice</span>
                    <span><img src="/assets/images/acadecraft-logo.svg" alt="" /></span>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start", marginBottom: 0, borderBottom: "10px solid #0073EA" }}>
                    <div style={{ flex: 1, padding: 10 }}>
                        <p style={{ margin: 0, fontSize: 13, lineHeight: "1.4", fontWeight: "bold" }}>Acadecraft Private Limited</p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", marginTop: 10 }}>GSTIN: 09AANCA8296LZ2P</p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", display: "flex" }}>Add: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>H-54, Sector 63, Gautam Buddha Nagar, Noida, UP-201301</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>City: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>Noida</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>State: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>Uttar Pradesh</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>State Code: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>09</span></p>
                    </div>
                    <div style={{ flex: 1, padding: 10 }}>
                        <p style={{ margin: 0, fontSize: 12, lineHeight: "1.6", color: "#0073EA" }}>Buyer (Bill to)</p>
                        <p style={{ margin: "2px 0", fontSize: 13, lineHeight: "1.4", fontWeight: "bold" }}>BITS PILANI.</p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", display: "flex", marginTop: 10 }}>Add: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>Birla Institute of Technology &amp; Science, Pilani, Pune centre , Mantri Alpine, 1st floor 102A , S.No.268, Bhunde Vasti, Bavdhan, Pune, Maharashtra 411021</span></p>

                    </div>
                    <div style={{ flex: 1, padding: 10, textAlign: "right" }}>
                        <div style={{ display: "inline-block", textAlign: "left" }}>
                            <p style={{ margin: "2px 0", fontSize: 15, lineHeight: "1.4", fontWeight: "bold", display: "flex" }}><span style={{ display: "inline-block", fontWeight: "normal", paddingRight: "5px" }}>Invoice No:</span> Acade/24-25/337</p>
                            <p style={{ margin: "2px 0", fontSize: 10, lineHeight: "1.6", marginTop: 20, color: "#7B7E8B", display: "flex" }}>Invoice Date: <span style={{ fontWeight: "bold", color: "#323338", paddingLeft: "5px" }}>04 Sep, 2024</span></p>
                            <p style={{ margin: "2px 0", fontSize: 10, lineHeight: "1.6", color: "#7B7E8B", display: "flex" }}>Purchase Order: <span style={{ fontWeight: "bold", color: "#323338", paddingLeft: "5px" }}>Nill</span></p>
                        </div>
                    </div>
                </div>

                <table style={{ width: "100%", borderCollapse: "collapse", marginTop: 15, fontSize: 12, lineHeight: "1.5" }}>
                    <thead>
                        <tr style={{ backgroundColor: "#fff", color: "#7B7E8B", fontWeight: "normal", verticalAlign: "middle" }}>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle", width: "50px" }}>Sr. No.</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Description of Goods/Services</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Unit of Measurement</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Unit Price</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Total Units</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Total Value</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle", width: "50px" }}>1</td>
                            <td style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>DTP of SLM (MCA) A</td>
                            <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>Per Page</td>
                            <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>40</td>
                            <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>4314</td>
                            <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle",textAlign: "right" }}>$172560</td>
                        </tr>

                        <tr>
                            <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle", width: "50px" }}>2</td>
                            <td style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>DTP of SLM (MCA) B</td>
                            <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>Per Page</td>
                            <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>35</td>
                            <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>358</td>
                            <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle",textAlign: "right" }}>$172560</td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={{ border: "1px solid #ddd", padding: 8, textAlign: "end", lineHeight: "1.6", fontSize: 12, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                Total
                            </td>
                            <td colSpan={4} style={{ border: "1px solid #ddd", padding: 8, textAlign: "end", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle",textAlign: "right" }}>
                                $172560
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", verticalAlign: "middle" }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Rounding off
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}>:</span>
                                                $172560
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Total Value (in Fig)
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 14, color: "#323338",fontWeight: "bold", verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}>:</span>
                                                USD,172,5.60/-
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Total Value (in words)
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}>:</span>
                                                One Lakh Seventy Two Thousand Five Hundred and Sixty Only
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 8, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Amount of tax subject to reverse charges
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td colSpan={4} style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }} />
                        </tr>

                        <tr>
                            <td colSpan={2} style={{ border: "1px solid #ddd", verticalAlign: "middle" }}>
                                <table style={{ width: "100%", borderCollapse: "collapse", verticalAlign: "middle" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", borderTop: 0, borderLeft: 0, padding: "0 8px 7px 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Account Holder Name</td>
                                            <td style={{ border: "1px solid #ddd", borderTop: 0, padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>Acadecraft Private Limited</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Name of Bank</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>ICICI BANK LTD</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Account No</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>071901527809</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Account Type</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>SAVING</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>IFSC Code</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>ICIC0000719</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Swift Code</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>ICICINBBCTS</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, borderBottom: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Bank Address</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, borderBottom: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>Sector 18 Noida</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td colSpan={4} style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                <p style={{ lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold" }}>For Acadecraft Private Limited</p>
                                <p><img src="/assets/images/authirisedsignatory1.svg" /></p>
                                <p style={{ lineHeight: "1.6", fontSize: 10, marginBottom: "0", color: "#323338", fontWeight: "normal", verticalAlign: "middle" }}>Authorised Signatory</p>
                            </td>
                        </tr>

                    </tbody>
                </table>
                <div style={{ marginTop: 10, fontSize: 12, lineHeight: "1.6" }}>
                    <p style={{ margin: "2px 0", textAlign: "left", lineHeight: "1.6", fontSize: 8, color: "#7B7E8B", fontWeight: "normal", display: "flex" }}>
                        <span style={{ fontWeight: "bold" }}>Note:</span> This invoice was system
                        generated, signature are not required
                    </p>
                    <p style={{ margin: "2px 0", textAlign: "left", lineHeight: "1.6", fontSize: 8, color: "#7B7E8B", fontWeight: "normal", display: "flex" }} >
                        <span style={{ fontWeight: "bold" }}>Declaration:</span> We declare that
                        this invoice shows the actual price of the goods described and that all
                        particulars are true and correct.
                    </p>
                </div>
            </div>

        </div>
    );
};

export default ClientInvoiceDownload;
