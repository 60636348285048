import { useCallback, useEffect, useState } from "react";
import { useSelector,shallowEqual,useDispatch } from 'react-redux';
import { getIndividualProjectModuleWiseCostAPI } from "src/api/PerfomanceAPI";
import ModuleSummaryPieChart from "src/components/ModuleSummaryPieChart"
import { perfomanceSetModuleWiseWhereAction } from "./actions/PerfomanceActions";

const ProjectModuleWiseCost=({projectId,setModuleId})=>{
    const [projectTasks, setProjectTasks] = useState([]);
    const dispatch = useDispatch();
    const {projectWiseWhere,selectedProject} = useSelector(state=>state.PerfomanceReducer,shallowEqual);
    useEffect(()=>{
      search();
    },[projectWiseWhere,projectId])

    const search = useCallback(async()=>{
        const where = projectId ? {...projectWiseWhere,projectId,requestSrc:"module"} : {...projectWiseWhere,requestSrc:"module"};
        const projectCost  = await getIndividualProjectModuleWiseCostAPI({where});
        setProjectTasks(projectCost.data.data||[])
        dispatch(perfomanceSetModuleWiseWhereAction({where}));
        },[projectWiseWhere,projectId])
   return (
    <>
        <div className="col-md-12">
            <div className="card h-100 p-0">
                <div className="card-body p-24">
                <div className="ms-auto d-flex justify-content-between pb-12 align-items-center inwth">
                    <h4 className="card-title">Module Summary <span className="text-neutral-500">({(selectedProject)?selectedProject:"All"})</span></h4>
                </div>
                <div className="col-md-12">
                    <ModuleSummaryPieChart projectTasks ={projectTasks} setModuleId={setModuleId}/>
                    {/* <div className="text-center">
                        <button 
                        className="btn text-primary-600 border-0 radius-8 pt-20 py-24 pb-8"
                        >
                        Hide
                    </button>
                    </div> */}
                </div>
                </div>
            </div>
            </div>
        
    </>
   )
}
export default ProjectModuleWiseCost