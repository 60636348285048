import React, { useEffect, useRef, useState } from "react"; 
const getInitials = (name) => {
    if (!name) return '';
    const cleanName = name.replace(/\(.*?\)/g, '').trim();
    const words = cleanName.split(' ');
    const firstNameInitial = words[0]?.charAt(0).toUpperCase();
    const lastNameInitial = words[1]?.charAt(0).toUpperCase();
    return `${firstNameInitial}${lastNameInitial || ''}`;
};
const SummaryInvoiceTable = ({onClick}) => {
    const [data, setData] = useState([
        {
            sNo: "01",
            projectCode: "Project 458A",
            clientName: "Nskfdc (Raghu)",
            noOfInv: 5,
            amount: "₹2,50,3,31",
            gst: "₹217",
            tds: "₹217",
            netAmount: "₹2,50,3,31",
            dueAmount: "₹2,50,3,31",
            dueGst: "₹217",
            dueTds: "₹217",
            dueNetAmount: "₹2,50,3,31",
            nonDueAmount: "₹2,50,3,31",
            nonDueGst: "₹217",
            nonDueTds: "₹217",
            nonDueNetAmount: "₹2,50,3,31",
        },
        {
            sNo: "02",
            projectCode: "Project 458A",
            clientName: "Nskfdc (Raghu)",
            noOfInv: 5,
            amount: "₹2,50,3,31",
            gst: "₹217",
            tds: "₹217",
            netAmount: "₹2,50,3,31",
            dueAmount: "₹2,50,3,31",
            dueGst: "₹217",
            dueTds: "₹217",
            dueNetAmount: "₹2,50,3,31",
            nonDueAmount: "₹2,50,3,31",
            nonDueGst: "₹217",
            nonDueTds: "₹217",
            nonDueNetAmount: "₹2,50,3,31",
        },
        {
            sNo: "03",
            projectCode: "Project 458A",
            clientName: "Nskfdc (Raghu)",
            noOfInv: 5,
            amount: "₹2,50,3,31",
            gst: "₹217",
            tds: "₹217",
            netAmount: "₹2,50,3,31",
            dueAmount: "₹2,50,3,31",
            dueGst: "₹217",
            dueTds: "₹217",
            dueNetAmount: "₹2,50,3,31",
            nonDueAmount: "₹2,50,3,31",
            nonDueGst: "₹217",
            nonDueTds: "₹217",
            nonDueNetAmount: "₹2,50,3,31",
        },
        {
            sNo: "04",
            projectCode: "Project 458A",
            clientName: "Nskfdc (Raghu)",
            noOfInv: 5,
            amount: "₹2,50,3,31",
            gst: "₹217",
            tds: "₹217",
            netAmount: "₹2,50,3,31",
            dueAmount: "₹2,50,3,31",
            dueGst: "₹217",
            dueTds: "₹217",
            dueNetAmount: "₹2,50,3,31",
            nonDueAmount: "₹2,50,3,31",
            nonDueGst: "₹217",
            nonDueTds: "₹217",
            nonDueNetAmount: "₹2,50,3,31",
        },
        // other data rows
    ]);

    const [selectedRows, setSelectedRows] = useState([]);

    const handleSelectRow = (sNo) => {
        if (selectedRows.includes(sNo)) {
            setSelectedRows(selectedRows.filter((row) => row !== sNo));
        } else {
            setSelectedRows([...selectedRows, sNo]);
        }
    };

    const handleSelectAll = () => {
        if (selectedRows.length === data.length) {
            setSelectedRows([]); // Unselect all
        } else {
            setSelectedRows(data.map((row) => row.sNo)); // Select all
        }
    };


    const [selectedOption, setSelectedOption] = useState(null);
    const [isDestashowVisible, setIsDestashowVisible] = useState(false);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsDestashowVisible(true); // Show destashow when an option is clicked
    };

    const handleClose = () => {
        setIsDestashowVisible(false); // Hide destashow when close or check is clicked
    };

      // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code End

    return (
        <>
           

                <button className="d-flex align-items-center gap-2 btn rounded-pill text-primary-light radius-8 pe-20 text-xl py-11" onClick={onClick}><i className="mdi mdi-arrow-left mdi-24px"></i> Invoice summary</button>
                <div className="d-flex align-items-center justify-content-between gap-4 mt-16">
                    <div className="d-flex align-items-center justify-content-between gap-4"> 

                        <div className="dropdown">
                            <button
                                className="btn btn-primary-600 bg-primary-50 border-primary-50 text-primary-600 hover-text-primary not-active px-18 py-9 text-sm dropdown-toggle toggle-icon"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Invoice Age
                            </button>
                            <ul className="dropdown-menu border">
                                <li>
                                    <a
                                        className="dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 d-flex align-items-center gap-2"
                                        href="javascript:void(0)"
                                        onClick={() => handleOptionClick("moreThan")}
                                    >
                                        <span className="w-28-px text-center me-6">
                                            <i className="mdi mdi-chevron-right"></i>
                                        </span>
                                        More than
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 d-flex align-items-center gap-2"
                                        href="javascript:void(0)"
                                        onClick={() => handleOptionClick("between")}
                                    >
                                        <span className="w-28-px text-center me-6">
                                            <i className="mdi mdi-format-horizontal-align-left"></i>
                                            <i className="mdi mdi-format-horizontal-align-right"></i>
                                        </span>
                                        Between
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 d-flex align-items-center gap-2"
                                        href="javascript:void(0)"
                                        onClick={() => handleOptionClick("lessThan")}
                                    >
                                        <span className="w-28-px text-center me-6">
                                            <i className="mdi mdi-chevron-left"></i>
                                        </span>
                                        Less than
                                    </a>
                                </li>
                            </ul>
                        </div>

                        {/* Conditionally render the input fields based on the selected option and visibility */}
                        {isDestashowVisible && (
                            <div className="d-flex align-items-center gap-2 destashow">
                                {selectedOption === "moreThan" && (
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="text" className="border text-center w-44-px form-control" />
                                        <button className="checkDv" onClick={handleClose}>
                                            <i className="mdi mdi-check mdi-24px"></i>
                                        </button>
                                        <button className="closeDv" onClick={handleClose}>
                                            <i className="mdi mdi-close mdi-24px"></i>
                                        </button>
                                    </div>
                                )}

                                {selectedOption === "between" && (
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="text" className="border text-center w-44-px  form-control" />
                                        <span>To</span>
                                        <input type="text" className="border text-center w-44-px  form-control" />
                                        <button className="checkDv" onClick={handleClose}>
                                            <i className="mdi mdi-check mdi-24px"></i>
                                        </button>
                                        <button className="closeDv" onClick={handleClose}>
                                            <i className="mdi mdi-close mdi-24px"></i>
                                        </button>
                                    </div>
                                )}

                                {selectedOption === "lessThan" && (
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="text" className="border text-center w-44-px  form-control" />
                                        <button className="checkDv" onClick={handleClose}>
                                            <i className="mdi mdi-check mdi-24px"></i>
                                        </button>
                                        <button className="closeDv" onClick={handleClose}>
                                            <i className="mdi mdi-close mdi-24px"></i>
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="FilterBtn d-flex gap-2">
                            <button
                                type="button"
                                className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#SummaryInvoiceFilter"
                                aria-controls="SummaryInvoiceFilter" 
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="2em"
                                    height="1.5em"
                                    viewBox="0 0 24 24"
                                >
                                    <path fill="currentColor" d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z" />
                                </svg>{" "}
                                Filter
                            </button>
                        </div>

                    </div>
                    <div className="d-flex align-items-center gap-4">
                        <div className="d-flex align-items-center gap-2 text-xl fw-semibold"><span className="text-neutral-400 fw-normal">AverageTime</span> 28 Days</div>
                        <div className="d-flex align-items-center gap-2 text-xl fw-semibold"><span className="text-neutral-400 fw-normal">Net Amount</span> ₹2,50,3.31</div>
                    </div>
                </div>

                <div className="d-flex justify-content-between align-items-center pt-24 pb-16">
                    <h3 className="card-title">Pending Invoice Details</h3>
                    <button
                        type="button"
                        className="btn btn-neutral-100 text-primary-light px-16 py-8 radius-6 fw-medium text-sm d-flex align-items-center gap-2"
                    >
                        Export
                        <img src="assets/images/left-sidebar/xls-icon.svg" className="img-fluid" />
                    </button>


                </div>
                <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
                <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                    <thead>
                        <tr>
                            <th rowSpan={2}>
                                <input className="form-check-input"
                                    type="checkbox"
                                    onChange={handleSelectAll}
                                    checked={selectedRows.length === data.length}
                                    style={{ display: "inline-block", width: "24px", height: "24px" }} // Inline style for checkbox visibility
                                />
                            </th>
                            <th rowSpan={2}>S.No.</th>
                            <th colSpan={1} rowSpan={2}>Project Details</th>
                            <th colSpan={1} rowSpan={2}>Client Name</th>
                            <th colSpan={1} rowSpan={2}>No. of Inv.</th>
                            <th className="text-center" colSpan={4}>Total</th>
                            <th className="text-center" colSpan={4}>Due</th>
                            <th className="text-center" colSpan={4}>Non-Due</th>
                        </tr>
                        <tr>
                            <th>Amount</th>
                            <th>GST</th>
                            <th>TDS</th>
                            <th>Net Amount</th>
                            <th>Amount</th>
                            <th>GST</th>
                            <th>TDS</th>
                            <th>Net Amount</th>
                            <th>Amount</th>
                            <th>GST</th>
                            <th>TDS</th>
                            <th>Net Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => (
                            <tr key={row.sNo}>
                                <td>
                                    <input className="form-check-input"
                                        type="checkbox"
                                        checked={selectedRows.includes(row.sNo)}
                                        onChange={() => handleSelectRow(row.sNo)}
                                        style={{ display: "inline-block", width: "24px", height: "24px" }} // Ensure visibility
                                    />
                                </td>
                                <td>{row.sNo}</td>
                                <td>{row.projectCode}</td>
                                {/* <td>{row.clientName}</td> */}
                                <td><div className="user-cell">
                                                        <span className="user-initials">{getInitials(row.clientName)}</span> {row.clientName}
                                                    </div></td>
                                <td className="text-center">{row.noOfInv}</td>
                                <td className="text-end">{row.amount}</td>
                                <td className="text-end">{row.gst}</td>
                                <td className="text-end">{row.tds}</td>
                                <td className="text-end">{row.netAmount}</td>
                                <td className="text-end text-dblue">{row.dueAmount}</td>
                                <td className="text-end">{row.dueGst}</td>
                                <td className="text-end">{row.dueTds}</td>
                                <td className="text-end text-green">{row.dueNetAmount}</td>
                                <td className="text-end">{row.nonDueAmount}</td>
                                <td className="text-end">{row.nonDueGst}</td>
                                <td className="text-end">{row.nonDueTds}</td>
                                <td className="text-end text-green">{row.nonDueNetAmount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table> 
                </div>
            
        </>
    );
};

export default SummaryInvoiceTable