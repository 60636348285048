import config from 'src/config';
import { postRequest } from 'src/utils/RequestUtil';

export const getAllPermissionsAPI = function (data) {
    const url = `${config.baseUrl}permissions/master`;
    return postRequest({
        url,
        data,
    });
};

export default {
    getAllPermissionsAPI,
};
