// FilterPopup.js
import React, { useEffect, useState } from 'react';

const FilterPopup = ({ onFilterChange, isFilterReset }) => {
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  

  const handleCheckboxChange = (status) => {
    setSelectedStatuses((prev) =>
      prev.includes(status)
        ? prev.filter((item) => item !== status)
        : [...prev, status]
    );
  };

  const handleSave = () => {
    onFilterChange(selectedStatuses); // Pass selected statuses back to parent
  };
useEffect(() => {
  if (isFilterReset) {
    setSelectedStatuses([]);
  }
},[isFilterReset])
  return (
    <div
      className="offcanvas offcanvas-end"
      id="filterRightPopup"
      aria-labelledby="filterRightLayout"
    >
      <div className="offcanvas-header border-bottom">
        <h5 className="offcanvas-title" id="filterRightLayout">
          Filter
        </h5>
        <button
          type="button"
          className="btn-close d-none"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div className="offcanvas-body">
        <div className="assignPopoHeight">
          <form className="AllFilter_Select">
            {/* <input
              className="form-check-input"
              type="checkbox"
              
            />
            <label className="allFilter" htmlFor="allFilter">
              All Filter 
            </label> */}
            <div className="d-flex justify-content-between align-items-center"><label className="C_radioBtn mb-0">
            All Filter
    <input type="checkbox" id="allFilter"
              checked={selectedStatuses.length === 3}
              onChange={() => {
                if (selectedStatuses.length === 3) {
                  setSelectedStatuses([]);
                } else {
                  setSelectedStatuses(['Pending', 'Approved', 'Rejected']);
                }
              }}/>
    <span className="checkmark" />
  </label> <span>{selectedStatuses.length} Selected</span></div>
          </form>

          <div className="selectSec">
          <label className="form-label fw-semibold text-primary-light text-sm mb-8">Status</label>
            {/* <div className="form-group AllFilter_Select pt-0">
              <input
                className="form-check-input radio"
                type="checkbox"
                
              />
              <label htmlFor="pending">Pending</label>
            </div> */}
            <label className="C_radioBtn">
            Pending
    <input type="checkbox" id="pending"
                checked={selectedStatuses.includes('Pending')}
                onChange={() => handleCheckboxChange('Pending')}/>
    <span className="checkmark" />
  </label> 

  <label className="C_radioBtn">
  Approved
    <input type="checkbox" id="approved"
                checked={selectedStatuses.includes('Approved')}
                onChange={() => handleCheckboxChange('Approved')}/>
    <span className="checkmark" />
  </label> 

  <label className="C_radioBtn">
  Rejected
    <input type="checkbox"  id="rejected"
                checked={selectedStatuses.includes('Rejected')}
                onChange={() => handleCheckboxChange('Rejected')}/>
    <span className="checkmark" />
  </label>
 
            {/* <div className="form-group AllFilter_Select pt-0">
              <input
                className="form-check-input radio"
                type="checkbox"
                
              />
              <label htmlFor="approved">Approved</label>
            </div> */}
            {/* <div className="form-group AllFilter_Select pt-0">
              <input
                className="form-check-input radio"
                type="checkbox"
               
              />
              <label htmlFor="rejected">Rejected</label>
            </div> */}
          </div>
        </div> 
      </div>
      <div className="offcanvas-footer px-16">
          <button
            type="button"
            className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            onClick={handleSave}
          >
            Save Filter
          </button>
        </div>
    </div>
  );
};

export default FilterPopup;
