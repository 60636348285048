import { useCallback, useEffect, useState } from "react";
import moment from "moment";

const MilestoneModal = () => {
  // Sample users data
  const users = [
    {
      
      name: "Nidhi Sharma",
      img: "",
      date: "09 Aug 2024",
      amount: "+900",
      statusClass: "text-success-800",
    },
    {
     
      name: "Abhishek Kumar Shukla",
      img: "",
      date: "09 Aug 2024",
      amount: "-300",
      statusClass: "text-danger-700",
    },
    {
       
      name: "Sibanarayana Jena",
      img: "",
      date: "09 Aug 2024",
      amount: "+200",
      statusClass: "text-success-800",
    },
    {
    
      name: "Aakash Saxena",
      img: "assets/images/users/user1.png",
      date: "09 Aug 2024",
      amount: "-1500",
      statusClass: "text-danger-700",
    },
    {
     
      name: "Anupriya",
      img: "",
      date: "09 Aug 2024",
      amount: "+900",
      statusClass: "text-success-800",
    },
  ];

  // Function to get initials from name
  const getInitials = (name) => {
    const nameParts = name.split(" ");
    const initials = nameParts[0][0] + (nameParts[1] ? nameParts[1][0] : "");
    return initials.toUpperCase();
  };

  // Function to generate a dynamic background color based on name
  const getBackgroundColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 60%, 70%)`;
    return color;
  };

  return (
    <>
      <div
        className="modal fade cusmodl"
        id="MilestoneModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between pb-0 border-0">
              <h6 className="mb-2 fw-bold text-lg mb-0 d-flex align-items-center gap-2">Milestone 1 <span class="badge text-sm fw-semibold text-primary-600 bg-primary-100 px-20 py-6 rounded-pill text-white">1-10 days</span></h6>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                ✗
              </button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table bordered-table mb-0 custm2nth">
                  <thead className="sticky-header">
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Updated by </th>
                      <th scope="col">Date & Time </th>
                      <th scope="col" className="text-end">Updated Target </th> 
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td> {/* Sr. No. */}
                        <td>
                          <div className="d-flex align-items-center">
                            <span
                              className="UsIMgName"
                              style={{
                                backgroundColor: getBackgroundColor(user.name),
                              }}
                            >
                              {user.img ? (
                                <img
                                  src={user.img}
                                  alt={user.name}
                                  className="flex-shrink-0 radius-8"
                                />
                              ) : (
                                <span className="initials">
                                  {getInitials(user.name)}
                                </span>
                              )}
                            </span>
                            <span className="text-md text-secondary-light fw-semibold flex-grow-1">
                              {user.name}
                            </span>
                          </div>
                        </td>
                        <td>{moment(user.date, "DD MMM YYYY").format("DD/MM/YYYY")}</td>
                        
                        <td className="text-end">
                          <span
                            className={`${user.statusClass} px-24 py-4 rounded-pill fw-medium text-lg fw-bold`}
                          >
                            {user.amount}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MilestoneModal;
