import { useState, useEffect, useRef } from 'react';

export const useSticky = () => {
  const [isSticky, setIsSticky] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the element has reached the top
      const elementPosition = elementRef.current.getBoundingClientRect();
      if (elementPosition.top <= 0) {
        setIsSticky(true);  // Sticky condition
      } else {
        setIsSticky(false); // Remove sticky condition
      }
    };

    // Add event listener for scrolling
    window.addEventListener('scroll', handleScroll);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { isSticky, elementRef };
};
