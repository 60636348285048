import React, { useState } from "react";

const LeaveTable = ({className}) => {
  // Initialize leave data in state
  const [leaveData, setLeaveData] = useState([
    { month: "January", CL: 0, SL: 1, CL_Remaining: 0, SL_Remaining: 1 },
    { month: "February", CL: 0, SL: 1, CL_Remaining: 0, SL_Remaining: 0 },
    { month: "March", CL: 0, SL: 1, CL_Remaining: 1, SL_Remaining: 1 },
    { month: "April", CL: 0, SL: 1, CL_Remaining: 1, SL_Remaining: 1 },
    { month: "May", CL: 0, SL: 1, CL_Remaining: 1, SL_Remaining: 1 },
    { month: "June", CL: 0, SL: 1, CL_Remaining: 1, SL_Remaining: 1 },
    { month: "July", CL: 0, SL: 1, CL_Remaining: 1, SL_Remaining: 1 },
    { month: "August", CL: 0, SL: 1, CL_Remaining: 1, SL_Remaining: 1 },
    { month: "September", CL: 0, SL: 1, CL_Remaining: 1, SL_Remaining: 1 },
    { month: "October", CL: 0, SL: 1, CL_Remaining: 1, SL_Remaining: 1 },
    { month: "November", CL: 0, SL: 1, CL_Remaining: 1, SL_Remaining: 1 },
    { month: "December", CL: 0, SL: 1, CL_Remaining: 0, SL_Remaining: 1 },
  ]);

  // Calculate totals for each column
  const totals = leaveData.reduce(
    (acc, data) => ({
      CL: acc.CL + data.CL,
      SL: acc.SL + data.SL,
      CL_Remaining: acc.CL_Remaining + data.CL_Remaining,
      SL_Remaining: acc.SL_Remaining + data.SL_Remaining,
    }),
    { CL: 0, SL: 0, CL_Remaining: 0, SL_Remaining: 0 }
  );

   
  

  return (
    <div className={`${className}`}>
     <div className="d-flex justify-content-between align-items-center">
     <h3 className="text-green text-lg">Approved Leaves</h3>
     <h3 className="text-violet-600 text-lg">Adjusted Leaves</h3>
     </div>
     <div className="table-responsive">
      <table className="table bordered-table mb-0 bdplspading">
        <thead>
          <tr>
            <th className="text-start">Month</th>
            <th className="text-center">CL</th>
            <th className="text-center">SL</th>
            <th className="text-center">CL (REM.)</th>
            <th className="text-center">SL (REM.)</th> 
          </tr>
        </thead>
        <tbody>
          {leaveData.map((data, index) => (
            <tr key={index}>
              <td className="text-start fw-semibold">{data.month}</td>
              <td className="text-center"><span className={`${data.CL ? "bg-danger-600" : "bg-neutral-400"} badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle text-white mx-auto`}>{data.CL}</span></td>
              <td className="text-center"><span className={`${data.SL ? "bg-danger-600" : "bg-neutral-400"} badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle text-white mx-auto`}>{data.SL}</span></td>
              <td className="text-center"><span className={`${data.CL_Remaining ? "bg-primary-600" : "bg-neutral-400"} badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle text-white mx-auto`}>{data.CL_Remaining}</span></td>
              <td className="text-center"><span className={`${data.SL_Remaining ? "bg-primary-600" : "bg-neutral-400"} badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle text-white mx-auto`}>{data.SL_Remaining}</span></td> 
            </tr>
          ))}
          {/* Total row */} 
        </tbody>
        <tfoot>
          <tr>
            <td className="text-start fw-bold text-xl ps-16">Total</td>
            <td className="text-center fw-bold text-md">{totals.CL}</td>
            <td className="text-center fw-bold text-md">{totals.SL}</td>
            <td className="text-center fw-bold text-md">{totals.CL_Remaining}(3)</td>
            <td className="text-center fw-bold text-md">{totals.SL_Remaining}(3)</td> 
          </tr>
          </tfoot>
      </table> 
      </div>
    </div>
  );
};

export default LeaveTable;
