import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const departmentSearchAPI = function (data) {
    const url = `${config.baseUrl}admin/department/search`;
    return postRequest({
        url,
        data,
    });
};
export const getDepartmentAPI = function (data) {
    const url = `${config.baseUrl}user/getDepartment`;
    return postRequest({
        url,
        data,
    });
};
export const getRoleAPI = function (data) {
    const url = `${config.baseUrl}user/getRole`;
    return postRequest({
        url,
        data,
    });
};
export const departmentCreateAPI = function (data) {
    const url = `${config.baseUrl}admin/department/save`;
    return postRequest({
        url,
        data,
    });
};

export const departmentUpdateAPI = function ({
    departmentId,
    data,
}) {
    const url = `${config.baseUrl}admin/department/update/${departmentId}`;
    return putRequest({
        url,
        data,
    });
};
