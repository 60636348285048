import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useParams } from 'react-router-dom';
import TopTabs from "../TopTabs"
import AddClientDelivery from "./AddClientDelevery"
import ClientProductionList from "./ClientProductionList"
import ClientDeliveryFilter from "./FIlter"
import ManageColum from "./ManageColoum"
import { useCallback, useEffect, useRef, useState } from "react"
import { clientProductionSearchAction } from "./actions/ClientProductionActions"
import ClientCommentModal from "./ClientCommentModal"
import DatePickerComponent from "src/components/DatePickerComponent"
import BackProjectButton from "src/components/BackProjectButton";


const ClientDeliveryWiseContainer = () => {
  const { projectId } = useParams();
  const {currentRecord} = useSelector((state) => state.ProjectReducer,shallowEqual)
  const projectCode = (currentRecord?.RefClientId?.ClientProjectCode)?`${currentRecord?.RefClientId?.ClientProjectCode} ${currentRecord?.ProjectSubCode}`:''
  const { search: clientProductionSearch } = useSelector((state) => state.ClientProductionReducer, shallowEqual);
  const { _id: userId } = useSelector(state => state.auth?.user, shallowEqual);
  const { selectedMonth, selectedYear } = useSelector(state => state.ProductionReducer, shallowEqual);
  const [isFlatPikerReset, setIsFlatPikerReset] = useState(false);
  const dispatch = useDispatch();
  const [where, setWhere] = useState({
    // userId,
    projectId,
    // $expr: {
    //   $and: [
    //     { $eq: [{ $year: "$date" }, selectedYear] },
    //     { $eq: [{ $month: "$date" }, selectedMonth] }
    //   ]
    // }
  });
  const totalAmount = clientProductionSearch.data.reduce((sum, transaction) => {
    return sum + transaction.cast || 0;
  }, 0);


  useEffect(() => {
    search(where);
  }, [
    dispatch,
    where
  ]);
  const search = useCallback(() => {
    dispatch(clientProductionSearchAction({
      where: where
    }));
  }, [dispatch, where])

  const handleChangeDate = (date) => {
    // console.log("date",,);
    const year = date.getFullYear();  // Example year
    const month = date.getMonth() + 1;  // Since month is 0-indexed in JavaScript, add 1
    setWhere({
      ...where,
      $expr: {
        $and: [
          { $eq: [{ $year: "$date" }, year] },
          { $eq: [{ $month: "$date" }, month] }
        ]
      }
    })
  }
  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setIsFlatPikerReset(false)
      const [startDate, endDate] = selectedDates.map(date => {
        // Ensure that the date is treated as UTC and not auto-adjusted
        const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        return utcDate.toISOString().split("T")[0]; // Get date part only (YYYY-MM-DD)
      });
      setWhere({...where, ts: { $gte: startDate, $lte: endDate } })
    }
   
  }
  const handleSearchInputChange = (e) => {
    const searchKey = e.target.value;
  }
  const resetFilter = () => {
    // document.getElementById('search').value = '';
    setIsFlatPikerReset(true)
    // setSearchTerm('');
    setWhere({
      // $expr: {
      //   $and: [
      //     { $eq: [{ $year: "$date" }, selectedYear] },
      //     { $eq: [{ $month: "$date" }, selectedMonth] }
      //   ]
      // }
    });
    // setFilterDefaultProps(defaultClientFilterProps);
  }

  // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code End

  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
              <BackProjectButton/>
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="javascript:void(0)"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Client Delivery </li>
              </ul>
              <TopTabs />
              <div className="d-flex gap-2 align-items-center py-16 postionTop-0">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addClientDelivery"
                  className="btn btn-primary text-sm heightAllBtnSet44 btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2"
                >
                  <iconify-icon
                    icon="ic:baseline-plus"
                    className="icon text-xl line-height-1"
                  ></iconify-icon>
                  Add Client Delivery
                </a>
                {/* Search start */}
                {/* <SearchInput handleSearchInputChange={handleSearchInputChange}/> */}
                {/* Search End */}
                {/* Date Range piker start  */}
                <DatePickerComponent 
                onDateRangeChange={handleDateRangeChange}
                isFlatPikerReset={isFlatPikerReset}
                 />
                 
                {/* Manage Column btn end */}
                <button type="reset"  
                onClick={resetFilter}
                className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                >
                  Reset
                </button>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-stack"
                    viewBox="0 0 16 16"
                  >
                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
                  </svg>{" "}
                  {projectCode}
                </h6>
               
              </div>
              <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
              <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                  <thead>
                    <tr className="dnd-moved">
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th>Created Date </th>
                      <th>Delivery Date </th>
                      <th>Delivery Path </th>
                      <th>Uploaded by </th>
                      {/* <th>Department </th>
                      <th className="text-center">Cadre </th> */}
                      <th className="text-center">Count </th>
                      {/* <th className="text-center">Amount </th> */}
                      <th className="text-center">File </th>
                      <th className="text-center">Comment </th>
                    </tr>
                  </thead>
                  <tbody id="table-body">
                    <ClientProductionList />
                  </tbody>
                  {/* <tfoot>
                    <tr>
                      <td colSpan={2} />
                      <td colSpan={5} className="fw-bold">
                        Total
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">₹{totalAmount}</span> Total Cost
                      </td>
                      <td colSpan={3} className="fw-bold" />
                    </tr>
                  </tfoot> */}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClientDeliveryFilter />
      <AddClientDelivery search={search}/>
      <ManageColum />
      <ClientCommentModal />
    </>
  )
}
export default ClientDeliveryWiseContainer