import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const projectSearchAPI = function (data) {
    const url = `${config.baseUrl}project/search`;
    return postRequest({
        url,
        data,
    });
};
export const getprojectAPI = function (data) {
    const url = `${config.baseUrl}project/getproject`;
    return postRequest({
        url,
        data,
    });
};

export const projectCreateAPI = function (data) {
    const url = `${config.baseUrl}project/save`;
    return postRequest({
        url,
        data,
    });
};

export const projectUpdateAPI = function ({
    projectId,
    data,
}) {
    const url = `${config.baseUrl}project/update/${projectId}`;
    return putRequest({
        url,
        data,
    });
};

export const projectCostAPI = function (data) {
    const url = `${config.baseUrl}project/viewProjectCost`;
    return postRequest({
        url,
        data,
    });
};
export const projectOADSaveAPI = function (data) {
    const url = `${config.baseUrl}project/save-OAD`;
    return postRequest({
        url,
        data,
    });
};
export const projectCMDSaveAPI = function (data) {
    const url = `${config.baseUrl}project/save-CMD`;
    return postRequest({
        url,
        data,
    });
};
export const deliverySaveAPI = function (data) {
    const url = `${config.baseUrl}project/save-vendor-delivery`;
    return postRequest({
        url,
        data,
    });
};
export const getProjectSubCategorySaveAPI = function (categoryId) {
    const url = `${config.baseUrl}project/get-project-sub-category/${categoryId}`;
    return getRequest({
        url
    });
};
export const projectCategorySaveAPI = function (data) {
    const url = `${config.baseUrl}project/save-Project-category`;
    return postRequest({
        url,
        data,
    });
};
export const projectSubCategorySaveAPI = function (data) {
    const url = `${config.baseUrl}project/save-Project-sub-category`;
    return postRequest({
        url,
        data,
    });
};

export const getAllProjectPermissionsAPI = function (data) {
    const url = `${config.baseUrl}project/permission-master`;
    return postRequest({
        url,
        data,
    });
  };
  export const userProjectPermissionsCreateAPI = function (data) {
    const url = `${config.baseUrl}project/upsert-project-permission`;
    return postRequest({
        url,
        data,
    });
};
export const getProjectPermissionsByUserAPI = function (userId,projectId) {
    const url = `${config.baseUrl}project/get-project-permission/${userId}/${projectId}`;
    return getRequest({
        url
    });
  };