import React, { useState } from 'react'
import SearchInput from './SearchInput'
import DatePickerComponent from './DatePickerComponent';
import VendorDeliveryTable from './VendorDeliveryTable';
import VendorDeliveryTable2 from './VendorDeliveryTable2';
import VendorAddClientDelivery from './VendorAddClientDelivery';
import TopTabs from 'src/containers/project-container/View/TopTabs';
import InvoiceTableModal from './InvoiceTableModal';
import ViewInvoiceListModal from './ViewInvoiceListModal';



const tableData = [
  {
    sNo: "01",
    date: "28-Jun-2024",
    uploadedBy: { initials: "KM", name: "Kiran Mazumdar" },
    vendorName: { initials: "KM", name: "Kiran Mazumdar" },
    mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
    category: "Storyline Development",
    perUnitCost: 98,
    totalUnit: 50,
    paidUnit: 100,
    unpaidUnit: 20,
    status: "Invoice Under Process",
    statusClass: "invoice-under-process",
    addForInvoice: "Generate Invoice",
    payment: "₹ 589.00",
  },
  {
    sNo: "02",
    date: "28-Jun-2024",
    uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
    vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
    mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
    category: "Storyline Development",
    perUnitCost: 100,
    totalUnit: 100,
    paidUnit: 100,
    unpaidUnit: 0,
    status: "Fully Paid",
    statusClass: "fully-paid",
    addForInvoice: "Download",
    payment: "₹ 1500.00",
  },
  {
    sNo: "03",
    date: "28-Jun-2024",
    uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
    vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
    mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
    category: "Storyline Development",
    perUnitCost: 100,
    totalUnit: 100,
    paidUnit: 100,
    unpaidUnit: 0,
    status: "Unpaid",
    statusClass: "unpaid",
    addForInvoice: "Download",
    payment: "₹ 1500.00",
  },
  {
    sNo: "04",
    date: "28-Jun-2024",
    uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
    vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
    mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
    category: "Storyline Development",
    perUnitCost: 100,
    totalUnit: 100,
    paidUnit: 100,
    unpaidUnit: 0,
    status: "Partially Paid",
    statusClass: "partially-paid",
    addForInvoice: "Download",
    payment: "₹ 1500.00",
  }, 
  // Add more rows as needed
];


const VendorDelivery = () => {

  const handleSearchInputChange = (e) => {
  };

  const handleDateRangeChange = (selectedDates) => {

  }

  
  
  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <div className="border-bottom  pb-16">
                <h2 className="fw-semibold mb-16 font-24">
                  <button>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                      <g data-name="20-Arrow Left">
                        <path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z" />
                        <path d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z" />
                      </g>
                    </svg>
                  </button>{" "}
                  My Projects
                </h2>
                <ul className="d-flex align-items-center gap-2">
                  <li className="fw-medium">
                    <a
                      href="#"
                      className="d-flex align-items-center gap-1 hover-text-primary"
                    >
                      <iconify-icon
                        icon="solar:home-smile-angle-outline"
                        className="icon text-lg"
                      ></iconify-icon>
                      Special Access
                    </a>
                  </li>
                </ul>
              </div>
              <TopTabs />
              <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-2 align-items-center py-24">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#vendoraddClientDelivery"
                  className="btn btn-primary text-sm heightAllBtnSet44 btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2"
                >
                  <iconify-icon
                    icon="ic:baseline-plus"
                    className="icon text-xl line-height-1"
                  ></iconify-icon>
                  Add Vendor Delivery
                </button>

                <SearchInput handleSearchInputChange={handleSearchInputChange} />
                <DatePickerComponent
                  onDateRangeChange={handleDateRangeChange}
                />

                <div className="FilterBtn d-flex gap-2"><button type="button" className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"></path></svg> Filter</button>
                  {/* <button type="reset" className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2">Reset</button> */}
                </div> 
                
                 </div>
                 <div className="d-flex align-items-center gap-2"><button type="button" className="btn btn-primary-600 radius-8 px-14 py-9 text-sm"  data-bs-toggle="modal" data-bs-target="#combinedinvoiveModal">Generate Combined Invoice</button>
                <button type="button" className="btn btn-neutral-100 text-primary-light px-16 py-8 radius-6 fw-medium text-sm d-flex align-items-center gap-2">Export<img src="/assets/images/left-sidebar/xls-icon.svg" className="img-fluid" /></button></div>
                 </div>

              <div className="TotlU">
                <ul>
                  <li>256 <span className="d-block">Total Unit</span></li>
                  <li className="text-green">158 <span className="d-block">Total Paid Unit</span></li>
                  <li>59 <span className="d-block">Total Unpaid Unit</span></li>
                  <li className="text-dblue">₹269.9K <span className="d-block">Total Paid Amount</span></li>
                  <li>258 <span className="d-block">Under Process</span></li>
                  <li>36 <span className="d-block">Fully Paid</span></li>
                  <li>98 <span className="d-block">Unpaid</span></li>
                  <li>26 <span className="d-block">Partially Paid</span></li>
                </ul> 
              </div>

              <VendorDeliveryTable data={tableData} status="Fully Paid" />
              {/* <VendorDeliveryTable2 data={tableData} status="Fully Paid" /> */}  
            </div>
          </div>
        </div>
      </div>

      <VendorAddClientDelivery />
      <InvoiceTableModal />
      <ViewInvoiceListModal />
    </>
  )
}

export default VendorDelivery
