import React, { useEffect, useRef, useState } from 'react';
import { useSticky } from 'src/hooks/useSticky';
import DatePickerComponent from './DatePickerComponent';

const getInitials = (name) => {
    if (!name) return '';
    const cleanName = name.replace(/\(.*?\)/g, '').trim();
    const words = cleanName.split(' ');
    const firstNameInitial = words[0]?.charAt(0).toUpperCase();
    const lastNameInitial = words[1]?.charAt(0).toUpperCase();
    return `${firstNameInitial}${lastNameInitial || ''}`;
};

const truncateNotes = (notes) => {
    return notes.length > 20 ? `${notes.substring(0, 20)}...` : notes;
};



const InvoiceTable = ({ data, tableTitle, initialColumns, onClick }) => {
    // const { isSticky, elementRef } = useSticky();
    const [tableData, setTableData] = useState(data);
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [draggedColIndex, setDraggedColIndex] = useState(null);
    const [columns, setColumns] = useState(initialColumns);

    // Initialize tooltips when data or columns change
    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
        return () => {
            tooltipList.forEach(tooltip => tooltip.dispose());
        };
    }, [data, columns]); // Reinitialize tooltips when either data or columns change

    // Row Drag Handlers
    const handleDragStartRow = (index) => {
        setDraggedIndex(index);
    };

    const handleDragOverRow = (index, event) => {
        event.preventDefault();
    };

    const handleDropRow = (index) => {
        const updatedData = [...tableData];
        const [draggedItem] = updatedData.splice(draggedIndex, 1);
        updatedData.splice(index, 0, draggedItem);
        setTableData(updatedData);
        setDraggedIndex(null);
    };

    // Column Drag Handlers
    const handleDragStartCol = (index) => {
        setDraggedColIndex(index);
    };

    const handleDragOverCol = (index, event) => {
        event.preventDefault();
    };

    const handleDropCol = (index) => {
        const updatedColumns = [...columns];
        const [draggedColumn] = updatedColumns.splice(draggedColIndex, 1);
        updatedColumns.splice(index, 0, draggedColumn);
        setColumns(updatedColumns);
        setDraggedColIndex(null);
    };

    // Handle Column Visibility
    const handleColumnVisibilityChange = (id) => {
        setColumns((prevColumns) =>
            prevColumns.map((col) =>
                col.id === id ? { ...col, visible: !col.visible } : col
            )
        );
    };

    // Handle View All Checkbox
    const handleViewAllChange = (event) => {
        const isChecked = event.target.checked;
        setColumns((prevColumns) =>
            prevColumns.map((col) => ({ ...col, visible: isChecked }))
        );
    };

    // Handle Apply Changes
    const handleApply = () => {
        console.log('Applied column visibility:', columns);
    };
    const handleDateRangeChange = (selectedDates) => {

    }


    const [selectedRows, setSelectedRows] = useState([]);

    // Handler to toggle select all rows
    const handleSelectAll = () => {
        if (selectedRows.length === tableData.length) {
            // Deselect all rows
            setSelectedRows([]);
        } else {
            // Select all rows (using their unique IDs or indices)
            setSelectedRows(tableData.map((item) => item.sNo)); // Assuming 'sNo' is the unique identifier for rows
        }
    };

    // Handler to toggle selection for individual rows
    const handleSelectRow = (sNo) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(sNo)) {
                // Deselect the row
                return prevSelectedRows.filter((rowId) => rowId !== sNo);
            } else {
                // Select the row
                return [...prevSelectedRows, sNo];
            }
        });
    }; 


     // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code End

    return (
        <div>
            <div className="mb-3 d-flex align-items-center gap-3 bg-white postionTop-0">
                <button type="button" className="btn btn-primary-600 radius-8 px-20 py-9"><i className="mdi mdi-plus"></i> Add</button>
                <button type="button" className="btn btn-primary-600 radius-8 px-20 py-9" onClick={onClick}><i className="mdi mdi-file-document-outline"></i> Summary</button>
                <div className="dropdown">
                    <button className="btn bg-base px-18 py-9 dropdown-toggle toggle-icon border d-flex align-items-center gap-2" type="button" data-bs-toggle="dropdown" aria-expanded="true"><img src="/assets/images/add-column.svg" className="img-fluid" alt="add-column" /> Manage Columns</button>

                    <div className="dropdown-menu border wsetColumn">
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="viewAll"
                                onChange={handleViewAllChange}
                            />
                            <label className="form-check-label" htmlFor="viewAll">
                                View All
                            </label>
                        </div>
                        <div className="mx-hight200px">
                            {columns.map((col) => (
                                <div key={col.id} className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={col.id}
                                        checked={col.visible}
                                        onChange={() => handleColumnVisibilityChange(col.id)}
                                    />
                                    <label className="form-check-label" htmlFor={col.id}>
                                        {col.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <button className="btn btn-primary mt-16 w-100" onClick={handleApply}>
                            Apply
                        </button>
                    </div>
                </div>
                <DatePickerComponent
                  onDateRangeChange={handleDateRangeChange}
                />
            </div>
            {/* Table Rendering */}
            <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
           
                {tableTitle && <h3 className="card-title">{tableTitle}</h3>}
                {tableData.length === 0 ? (
                    <p>No invoices available.</p>
                ) : (  
                    <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                    <thead>
                        <tr>
                            {/* Checkbox for Select All in the header */}
                            <th>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={handleSelectAll}
                                    checked={selectedRows.length === tableData.length}
                                    style={{ display: "inline-block", width: "24px", height: "24px" }} // Inline style for checkbox visibility
                                />
                            </th>
                            {columns.map((col, index) => (
                                col.visible && (
                                    <th
                                        key={col.id}
                                        draggable
                                        onDragStart={() => handleDragStartCol(index)}
                                        onDragOver={(e) => handleDragOverCol(index, e)}
                                        onDrop={() => handleDropCol(index)}
                                    >
                                        {col.label}
                                    </th>
                                )
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                draggable
                                onDragStart={() => handleDragStartRow(rowIndex)}
                                onDragOver={(e) => handleDragOverRow(rowIndex, e)}
                                onDrop={() => handleDropRow(rowIndex)}
                            >
                                {/* Individual row checkboxes */}
                                <td>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={selectedRows.includes(row.sNo)} // Assuming 'sNo' is the unique identifier
                                        onChange={() => handleSelectRow(row.sNo)}
                                        style={{ display: "inline-block", width: "24px", height: "24px" }} // Ensure visibility
                                    />
                                </td>
                                {columns.map((col) => (
                                    col.visible && (
                                        <td key={col.id}>
                                            {col.id === 'srNo' && (rowIndex + 1)}
                                            {col.id === 'invoiceNumber' && row.invoiceNumber}
                                            {col.id === 'customerName' && (
                                                <div className="user-cell">
                                                    <span className="user-initials">{getInitials(row.customerName)}</span> {row.customerName}
                                                </div>
                                            )}
                                            {col.id === 'taxableAmount' && row.taxableAmount}
                                            {col.id === 'gstAmount' && row.gstAmount}
                                            {col.id === 'totalAmount' && row.totalAmount}
                                            {col.id === 'invoiceDate' && row.invoiceDate}
                                            {col.id === 'paymentStatus' && row.paymentStatus}
                                            {col.id === 'paidDate' && row.paidDate}
                                            {col.id === 'cancelledDate' && row.cancelledDate}
                                            {col.id === 'raisedBy' && (
                                                <div className="user-cell">
                                                    <span className="user-initials">{getInitials(row.raisedBy)}</span> {row.raisedBy}
                                                </div>
                                            )}
                                            {col.id === 'dueDays' && row.dueDays}
                                            {col.id === 'rollbacked' && row.rollbacked}
                                            {col.id === 'action' && (
                                                <div className="dropdown">
                                                    <button
                                                        className="btn btn-primary-600 not-active px-18 py-8 dropdown-toggle toggle-icon text-sm"
                                                        type="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        Action
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li><a className="dropdown-item" href="javascript:void(0)"><i className="mdi mdi-pencil-outline"></i> Edit</a></li>
                                                        <li><a className="dropdown-item" href="javascript:void(0)"><i className="mdi mdi-file-pdf-outline"></i> PDF</a></li>
                                                        <li><a className="dropdown-item" href="javascript:void(0)"><i className="mdi mdi-content-copy"></i> Duplicate</a></li>
                                                        <li><a className="dropdown-item" href="javascript:void(0)"><i className="mdi mdi-close-circle-outline"></i> Cancel</a></li>
                                                    </ul>
                                                </div>
                                            )}
                                            {col.id === 'notes' && (
                                                <div
                                                    className="detaset mxwsetnotes"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-custom-class="tooltip-primary"
                                                    data-bs-title={`${row.notes}`}
                                                >
                                                    {truncateNotes(row.notes)}
                                                </div>
                                            )}
                                        </td>
                                    )
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                )}
            </div>
        </div>
    );
};

export default InvoiceTable