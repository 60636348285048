import { useEffect } from "react";
import { generateColor, getContrastYIQ } from "src/utils/utils"
import TaskParticipantsPopup from "./TaskParticipantsPopup";

const TaskParticipantsCircles=(props)=>{



const {task}=props
useEffect(() => {
    // Initialize Bootstrap tooltips for elements with data-bs-toggle="tooltip"
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    // Cleanup tooltips when the component unmounts
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);
   return (
    <>
                  <div className="d-flex align-items-center flex-wrap">
                        {task.assigneeIds.length>0 && (
                          <>
                            {task.assigneeIds.slice(0, 5).map((participant, userIndex) => (  
                              <span
                                key={userIndex}
                                className="img imgsSet me-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-bs-custom-class="tooltip-dark"
                                data-bs-title={`${participant?.firstName} ${participant?.lastName}`}
                              >
                                {participant?.profilePicture ? (
                                  <img
                                    src={participant?.profilePicture}
                                    className="img-fluid"
                                    alt=""
                                  />
                                ) : (
                                  <span
                                    className="initials d-flex justify-content-center align-items-center w-100 h-100"
                                    style={{
                                      backgroundColor: generateColor(`${participant?.firstName}${participant?.lastName}`),
                                      color: getContrastYIQ(generateColor(`${participant?.firstName}${participant?.lastName}`)),
                                    }}
                                  >
                                    {`${participant?.firstName.charAt(0)}${participant?.lastName.charAt(0)}`}
                                  </span>
                                )}
                              </span>     
                            ))}
                              </>
                            )}
                            
                            {task.assigneeIds?.length > 5 && (
                              <>
                              <span
                              // onClick={() => { handleProjectAssigneeDetails(task.assigneeIds) }}
                              className="w-32-px h-32-px rounded-circle object-fit-cover position-relative ms--10px border bg-neutral-100 text-secondary-light text-xs d-inline-flex align-items-center justify-content-center c-pointer" data-bs-toggle="modal" data-bs-target="#taskModual">
                              +{task.assigneeIds?.length - 5}
                              </span>
                              </>
                            )}
            </div>
            <TaskParticipantsPopup assignees={task.assigneeIds}/>
    </>
   )
}
export default TaskParticipantsCircles