import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const feedbackSearchAPI = function (data) {
    const url = `${config.baseUrl}feedback/email/search`;
    return postRequest({
        url,
        data,
    });
};
export const getfeedbackAPI = function (data) {
    const url = `${config.baseUrl}feedback/getfeedback`;
    return postRequest({
        url,
        data,
    });
};

export const feedbackCreateAPI = function (data) {
    const url = `${config.baseUrl}feedback/save`;
    return postRequest({
        url,
        data,
    });
};

export const feedbackUpdateAPI = function ({
    feedbackId,
    data,
}) {
    const url = `${config.baseUrl}feedback/update/${feedbackId}`;
    return putRequest({
        url,
        data,
    });
};

export const feedbackCostAPI = function (data) {
    const url = `${config.baseUrl}feedback/viewfeedbackCost`;
    return postRequest({
        url,
        data,
    });
};
export const feedbackOADSaveAPI = function (data) {
    const url = `${config.baseUrl}feedback/save-OAD`;
    return postRequest({
        url,
        data,
    });
};
export const feedbackCMDSaveAPI = function (data) {
    const url = `${config.baseUrl}feedback/save-CMD`;
    return postRequest({
        url,
        data,
    });
};
export const getfeedbackSubCategorySaveAPI = function (categoryId) {
    const url = `${config.baseUrl}feedback/get-feedback-sub-category/${categoryId}`;
    return getRequest({
        url
    });
};
export const feedbackCategorySaveAPI = function (data) {
    const url = `${config.baseUrl}feedback/save-feedback-category`;
    return postRequest({
        url,
        data,
    });
};
export const feedbackSubCategorySaveAPI = function (data) {
    const url = `${config.baseUrl}feedback/save-feedback-sub-category`;
    return postRequest({
        url,
        data,
    });
};
