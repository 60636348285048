import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const RolePermissionsPropTypes = PropTypes.shape({
    _id: PropTypes.string,
    isRoleType: PropTypes.string,
    roleId: PropTypes.string,
    permissions: PropTypes.array,
});

export const RolePermissionsDefaultProps = {
    isRoleType: 'role',
    roleId: '',
    permissions: [],
};

export const RolePermissionsYupSchema = Yup.object().shape({
    permissions: Yup.array().required('Required').min(1, 'Give at least 1 Permissions'),
});

export default {
    RolePermissionsDefaultProps,
    RolePermissionsPropTypes,
    RolePermissionsYupSchema,
};
