import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

// Utility function to get initials from name
const getInitials = (name) => {
  if (!name) return '';
  const words = name.trim().split(' ');
  const firstNameInitial = words[0]?.charAt(0).toUpperCase();
  const lastNameInitial = words[1]?.charAt(0).toUpperCase();
  return `${firstNameInitial}${lastNameInitial || ''}`;
};

// Utility function to generate color from a string (e.g., client name)
const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, '0')}${((hash >> 16) & 0xff).toString(16).padStart(2, '0')}${((hash >> 8) & 0xff).toString(16).padStart(2, '0')}`;
  return color.slice(0, 7); // Ensure it's a valid hex color
};

const SalaryTable = ({employees}) => {
 
  // States for selected rows and filters
  const [selectedRows, setSelectedRows] = useState([]);
  const [nameFilter, setNameFilter] = useState([]);
  const [deductionFilter, setDeductionFilter] = useState([]);
  const [effectiveDaysFilter, setEffectiveDaysFilter] = useState([]);

  // Unique filter options
  const uniqueNames = [...new Set(employees?.map(employee => `${employee.userId.firstName} ${employee.userId.lastName}`))];
  const uniqueDeductions = [...new Set(employees?.map(employee => employee.lagHourDetails.totalDeduction))];
  const uniqueEffectiveDays = [...new Set(employees?.map(employee => employee.daysDetails.effWorkingDays))];

  // Handle filter changes
  const handleCheckboxChange = (filter, setFilter, value) => {
    if (filter.includes(value)) {
      setFilter(filter.filter(item => item !== value));
    } else {
      setFilter([...filter, value]);
    }
  };

  // Filter the employee data based on selected filters
  const filteredEmployees = employees?.filter(employee => {
    const matchName = nameFilter?.length ? nameFilter?.includes(employee.name) : true;
    // const matchDeduction = deductionFilter?.length ? deductionFilter?.includes(employee.deduction) : true;
    // const matchEffectiveDays = effectiveDaysFilter?.length ? effectiveDaysFilter?.includes(employee.effectiveDays) : true;
    return matchName// && matchDeduction && matchEffectiveDays;
  });

  // Handle select/unselect all rows
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRows(filteredEmployees.map((emp) => emp.id));
    } else {
      setSelectedRows([]);
    }
  };

  // Handle individual row selection
  const handleSelectRow = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code Start

  return (
    <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
        <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
        <thead>
          <tr>
            <th className="align-middle">
              <input className="form-check-input"
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedRows?.length === filteredEmployees?.length && filteredEmployees?.length > 0}
                style={{ width: "24px", height: "24px" }}
              />
            </th>
            <th className="align-middle">S.No.</th>
            <th className="align-middle">Employee ID</th>
            <th className="align-middle">
              <div className="d-flex justify-content-between align-items-center">
                Employees Name
                <div className="dropdown">
                  <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-filter-variant mdi-24px"></i>
                  </button>
                  <ul className="dropdown-menu border  dropdown-menu-end mx-hight200px">
                    {uniqueNames.map((name, index) => (
                      <li key={index} className="dropdown-item">
                        <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={name}
                            checked={nameFilter.includes(name)}
                            onChange={() => handleCheckboxChange(nameFilter, setNameFilter, name)}
                          />
                          <label className="form-check-label">{name}</label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </th>
            <th className="align-middle"><div className="d-flex align-items-center gap-2 justify-content-between">Deduction 
 <div className="dropdown">
                  <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-filter-variant mdi-24px"></i>
                  </button>
                  <ul className="dropdown-menu border  dropdown-menu-end mx-hight200px">
                    {uniqueDeductions.map((deduction, index) => (
                      <li key={index} className="dropdown-item">
                        <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={deduction}
                            checked={deductionFilter.includes(deduction)}
                            onChange={() => handleCheckboxChange(deductionFilter, setDeductionFilter, deduction)}
                          />
                          <label className="form-check-label">{deduction}</label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div></div> </th>
                
            <th className="align-middle"><div className="d-flex align-items-center gap-2 justify-content-between">Effective Days <div className="dropdown">
                  <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-filter-variant mdi-24px"></i>
                  </button>
                  <ul className="dropdown-menu border  dropdown-menu-end mx-hight200px">
                    {uniqueEffectiveDays.map((days, index) => (
                      <li key={index} className="dropdown-item">
                        <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={days}
                            checked={effectiveDaysFilter.includes(days)}
                            onChange={() => handleCheckboxChange(effectiveDaysFilter, setEffectiveDaysFilter, days)}
                          />
                          <label className="form-check-label">{days}</label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div></div></th>
            <th className="align-middle">Salary</th>
            <th className="align-middle">Net Salary</th>
            <th className="align-middle">Status</th>
            <th className="align-middle">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredEmployees?.map((employee, index) => (
            <tr key={employee.userId._id}>
              <td className="align-middle">
                <input
                  type="checkbox" className="form-check-input"
                  checked={selectedRows.includes(employee.userId._id)}
                  onChange={() => handleSelectRow(employee.userId._id)}
                  style={{ width: "24px", height: "24px" }}
                />
              </td>
              <td className="align-middle">{index + 1}</td>
              <td className="align-middle">{employee.userId.employeeID}</td>
              <td className="align-middle">
                <span
                  className="client-initials img imgsSet"
                  style={{
                    backgroundColor: stringToColor(`${employee.userId.firstName} ${employee.userId.lastName}`),
                    color: '#fff',
                    borderRadius: '50%',
                    width: '32px',
                    height: '32px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {getInitials(`${employee.userId.firstName} ${employee.userId.lastName}`)}
                </span>
                {`${employee.userId.firstName} ${employee.userId.lastName}`}
              </td>
              <td className="align-middle">
                <div className="d-flex align-items-center gap-2">
                  <span style={{ color: employee.lagHourDetails.totalDeduction > 0 ? "red" : "green" }}>
                    <i className="mdi mdi-circle"></i>
                  </span> {employee.lagHourDetails.totalDeduction?.toFixed(2)}
                </div>
              </td>
              <td className="align-middle">{employee.daysDetails.effWorkingDays}</td>
              <td className="align-middle">{employee.monthlySalary}</td>
              <td className="align-middle">{employee.netSalary?.toFixed(2)}</td>
              <td className="text-center align-middle">
                <div
                  className={`badge text-sm fw-semibold rounded-pill px-20 py-9 radius-4 ${
                    employee.status === false ? "text-warning-600 bg-warning-100" : ""
                  }`}
                  style={{ color: employee.status === true ? "green" : "orange" }}
                >
                  {employee.status === false ? (
                    <>
                      <span><i className="mdi mdi-clock mdi-16px"></i></span> Pending
                    </>
                  ) : (
                    <span><i className="mdi mdi-check-circle mdi-24px"></i></span>
                  )}
                </div>
              </td>
              <td className="align-middle">
                <Link
                  to={`/add-salary-slip/${employee.userId._id}`}
                  className="btn btn-sm btn-primary-600 radius-8"
                  >
                      View
                  </Link>
                {/* <button className="btn btn-sm btn-primary-600 radius-8">View</button> */}
                </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SalaryTable;
