import React, { useRef, useEffect } from 'react';
import flatpickr from 'flatpickr';

const DatePickerInputPendingInvoive = ({ label, placeholder, value, onChange }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        flatpickr(inputRef.current, {
            enableTime: false,
            dateFormat: "Y/m/d",
            onChange: (selectedDates) => {
                const dateStr = selectedDates[0].toISOString().split('T')[0];
                onChange(dateStr);
            }
        });
    }, [onChange]);

    const openCalendar = () => {
        inputRef.current && inputRef.current._flatpickr.open();
    };

    return (
        <div className="position-relative">
            {/* <label>{label}</label> */}
            <input
                type="text"
                placeholder={placeholder || "MM/DD/YYYY"}
                className="form-control radius-8 bg-base w-160-px"
                ref={inputRef}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
            <span
                className="position-absolute end-0 top-50 translate-middle-y me-12"
                onClick={openCalendar}
                style={{ cursor: 'pointer' }}
            >
                <iconify-icon icon="solar:calendar-linear" className="icon text-lg"></iconify-icon>
            </span>
        </div>
    );
};

export default DatePickerInputPendingInvoive;
