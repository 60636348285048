import React from 'react';
import TotalSummary from '../../../components/TotalSummary'; 
 

const SummariesComponent = ({ summaries }) => {
  return ( 
    <div className="TotlU">
      <ul>
        {summaries.map((summary, index) => (
          <TotalSummary key={index} title={summary.title} value={summary.value} />
        ))}
      </ul>
    </div>
     
  );
};


export default SummariesComponent;
