export const AUTH_ACTIONS = {
    LOGIN: 'LOGIN',
    LOGIN_SUCCESS: 'CONFIRM_OTP_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT: 'LOGOUT',

    FETCH_PERMISSION_SCOPES: 'FETCH_PERMISSION_SCOPES',
    FETCH_PERMISSION_SCOPES_SUCCESS: 'FETCH_PERMISSION_SCOPES_SUCCESS',
    FETCH_PERMISSION_SCOPES_ERROR: 'FETCH_PERMISSION_SCOPES_ERROR',

    FETCH_USER_DETAILS: 'FETCH_USER_DETAILS',
    FETCH_USER_DETAILS_SUCCESS: 'FETCH_USER_DETAILS_SUCCESS',
    FETCH_USER_DETAILS_ERROR: 'FETCH_USER_DETAILS_ERROR',
};

export const confirmCredentialsAction = (payload) => ({
    type: AUTH_ACTIONS.LOGIN,
    ...payload,
});

export const fetchUserScopesAction = (payload) => ({
    type: AUTH_ACTIONS.FETCH_PERMISSION_SCOPES,
    ...payload,
});
export const fetchUserDetailsAction = (payload) => ({
    type: AUTH_ACTIONS.FETCH_USER_DETAILS,
    ...payload,
});

export const logoutAction = () => ({
    type: AUTH_ACTIONS.LOGOUT,
});

export default {
    AUTH_ACTIONS,
    confirmCredentialsAction,
    logoutAction,
};
