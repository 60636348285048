import React from 'react';

const AddforInvoiceModal = ({ handleInvoiceModal, message, isVisible, setVisible }) => {
  // Function to dismiss the modal by updating state
  const dismissModal = () => {
    setVisible(false); // Set modal visibility to false to close it
  };

  // Prevent backdrop from showing by not rendering it conditionally based on isVisible
  return (
    <>
      {isVisible && (
        <div className="modal fade show" id="AddforInvoiceModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
          <div className="modal-dialog modal-sm modal-dialog-scrollable modal-dialog-centered z-9999">
            <div className="modal-content"> 
              <div className="modal-body text-center"> 
                <h6 className="fw-bold text-lg mb-24">{message}</h6> 
                <div className="d-flex gap-4 justify-content-between">
                  <button 
                    type="button" 
                    className="btn btn-primary radius-8 px-20 py-11 w-100" 
                    onClick={() => { handleInvoiceModal(); dismissModal(); }}
                  >
                    Yes
                  </button>
                  <button 
                    type="button" 
                    className="btn radius-8 px-20 py-11 w-100 btn-outline-neutral-900" 
                    onClick={dismissModal}
                  >
                    No
                  </button>
                </div>  
              </div> 
            </div>
          </div>
          <div className="modal-backdrop fade show" onClick={dismissModal}></div>
        </div>
      )}
    </>
  );
};

export default AddforInvoiceModal;
