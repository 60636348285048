import React, { useRef, useEffect, useState } from 'react';
import flatpickr from 'flatpickr';

const SelectSingalDate = () => {
    const dateRef = useRef(null); // Ref for the input field
    const datePicker = useRef(null);  // Store flatpickr instance
    const [selectedDate, setSelectedDate] = useState('');

    useEffect(() => {
        // Initialize flatpickr for the date field and store the instance
        if (dateRef.current) {
            datePicker.current = flatpickr(dateRef.current, {
                enableTime: false,
                dateFormat: "d/m/y", // Use your desired format
                onChange: (selectedDates) => {
                    // Ensure the selected date is set to the state (use local date formatting)
                    const date = selectedDates[0];
                    const day = date.getDate().toString().padStart(2, '0');
                    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // month is zero-indexed
                    const year = date.getFullYear();
                    const formattedDate = `${day}/${month}/${year}`;  // Format the date as d/m/y
                    setSelectedDate(formattedDate);  // Update state with the formatted date
                }
            });
        }

        // Cleanup flatpickr instance on component unmount
        return () => {
            datePicker.current && datePicker.current.destroy();
        };
    }, []);

    const openCalendar = () => {
        datePicker.current && datePicker.current.open();
    };
  return (
    <div>
       <div className="position-relative">
                                        <input 
                                            type="text"
                                            placeholder="DD/MM/YYYY"
                                            className="form-control radius-8 bg-base"
                                            ref={dateRef}
                                            value={selectedDate}  // Ensure the selected date is reflected in the input field
                                            onChange={(e) => setSelectedDate(e.target.value)}  // Update state if manually editing
                                        />
                                        <span
                                            className="position-absolute end-0 top-50 translate-middle-y me-12 text-primary-700"
                                            onClick={openCalendar}  // Open the calendar when clicked
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <iconify-icon icon="solar:calendar-linear" className="icon text-lg"></iconify-icon>
                                        </span>
                                    </div>
    </div>
  )
}

export default SelectSingalDate
