import React from "react";

// Sample Salary Data
const salaryData = [
  { month: "September", year: 2024, amount: "₹31,583.36" },
  { month: "October", year: 2024, amount: "₹31,583.36" },
  { month: "November", year: 2024, amount: "₹31,583.36" },
  { month: "December", year: 2024, amount: "₹31,583.36" },
  { month: "January", year: 2023, amount: "₹31,583.36" },
  { month: "February", year: 2023, amount: "₹31,583.36" },
  { month: "March", year: 2023, amount: "₹31,583.36" },
  { month: "April", year: 2023, amount: "₹31,583.36" },
  { month: "May", year: 2023, amount: "₹31,583.36" },
  { month: "June", year: 2023, amount: "₹31,583.36" },
  { month: "July", year: 2023, amount: "₹31,583.36" },
  { month: "August", year: 2023, amount: "₹31,583.36" }
];

// Salary Detail Data
const salaryDetail = [
  { label: "Total Days", value: 30, color: "bg-primary-600" },
  { label: "Off Saturday", value: 30, color: "bg-lilac-600" },
  { label: "Total Absent", value: 30, color: "bg-danger-600" },
  { label: "Eff. Working Days", value: 30, color: "bg-success-600" },
  { label: "Total Sunday", value: 30, color: "bg-pink-600" },
  { label: "Total Holiday", value: 30, color: "bg-violet-600" },
  { label: "Working Days", value: 30, color: "bg-neutral-500" }
];

const SalaryCard = ({ month, year, amount }) => {
  // Conditional class
  const isHighlighted = month === "September" && year === 2024;
  
  return (
    <div className={`salary-card ${isHighlighted ? "highlight" : ""}`}>
      <h6 className="fw-semibold text-primary-light mb-1 text-lg">{amount}</h6>
      <p>{month}</p>
      <p className="mb-0">{year}</p>
    </div>
  );
};

const SalaryDetailItem = ({ label, value, color }) => (
  <div className="detail-item w-100 border-end text-center d-flex justify-content-between flex-column">
    <span className={`badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle mx-auto text-white mb-4 ${color}`}>
      {value}
    </span> 
    <p className="mb-0">{label}</p>
  </div>
);

const SalaryComponent = () => {
  return (
    <div>
      <h2 className="card-title mb-24">Previous Salary</h2>
      <div className="salary-grid mb-24">
        {salaryData.map((salary, index) => (
          <SalaryCard key={index} {...salary} />
        ))}
      </div>

      <h2 className="card-title mb-24">Salary Detail</h2>
      <div className="salary-detail-grid bg-primary-50 radius-10 p-16">
        {salaryDetail.map((detail, index) => (
          <SalaryDetailItem key={index} {...detail} />
        ))}
      </div>
    </div>
  );
};

export default SalaryComponent;
