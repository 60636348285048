import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const ProductionPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    totalDurations: PropTypes.string,
});

export const ProductionDefaultProps = { 
    totalDurations: '',
    _id: undefined
};

export const ProductionYupSchema = Yup.object().shape({ 
    totalDurations: Yup.number().required("Required").positive().integer(),
    comment: Yup.string()
    .required('Required')
    .min(50, 'Description must be at least 50 characters'),
});

export default {
    ProductionDefaultProps,
    ProductionPropTypes,
    ProductionYupSchema,
};
