// LeaveDetails.js
import React from 'react';

const LeaveDetails = ({ employeeDetails, handleChange, className }) => {
    return (
       
            <div className={`${className}`}>
               <div className="possettop">
               <h4 className="card-title">Leave Details</h4>
                <div className="radius-10 border p-16">
                    <div className="align-items-center d-flex justify-content-between py-8">
                        <label className="form-label mb-0">Total Leaves</label>
                        <div className="inpset001">
                            <input
                                type="text"
                                name="totalLeaves"
                                className="form-control w-160-px ms-auto"
                                placeholder="0"
                                value={employeeDetails.totalLeaves}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="align-items-center d-flex justify-content-between py-8">
                        <label className="form-label mb-0">Adjusted Sick Leaves</label>
                        <div className="inpset001">
                            <select
                                name="adjustedSickLeaves"
                                className="form-select w-160-px"
                                value={employeeDetails.adjustedSickLeaves}
                                onChange={handleChange}
                            >
                                <option>1L</option>
                            </select>
                        </div>
                    </div>
                    <div className="align-items-center d-flex justify-content-between py-8">
                        <label className="form-label mb-0">Adjusted Casual Leaves</label>
                        <div className="inpset001">
                            <select
                                name="adjustedCasualLeaves"
                                className="form-select w-160-px"
                                value={employeeDetails.adjustedCasualLeaves}
                                onChange={handleChange}
                            >
                                <option>1L</option>
                            </select>
                        </div>
                    </div>
                    <div className="align-items-center d-flex justify-content-between py-8 py-16 border-top mt-16">
                        <label className="form-label mb-0 text-neutral-500">Effective Leaves</label>
                        <span className="text-md fw-semibold text-neutral-800">{employeeDetails.effectiveLeaves}</span>
                    </div>
                    <div className="align-items-center d-flex justify-content-between py-8 py-16 border-top">
  <label className="form-label mb-0 text-neutral-500">Adjusted Casual Leaves Amount</label>
  <span className={`text-md fw-semibold text-neutral-800 ${employeeDetails.casualLeavesAmount < 0 ? 'text-red' : ''}`}>
    ₹ {employeeDetails.casualLeavesAmount}
  </span>
</div>
<div className="align-items-center d-flex justify-content-between py-8 py-16 border-top">
  <label className="form-label mb-0 text-neutral-500">Adjusted Sick Leaves Amount</label>
  <span className={`text-md fw-semibold text-neutral-800 ${employeeDetails.sickLeavesAmount < 0 ? 'text-red' : ''}`}>
    ₹ {employeeDetails.sickLeavesAmount}
  </span>
</div>
                    <div className={`align-items-center d-flex justify-content-between py-8 py-16 px-16 bg-primary-50 radius-10 ${employeeDetails.totalDeduction < 0 ? 'text-red bg-danger-100' : ''}`}>
  <label className="form-label mb-0 text-dblue">Total Deduction</label>
  <span className={`text-xl fw-bold ${employeeDetails.totalDeduction < 0 ? 'text-red' : ''}`}>
    ₹ {employeeDetails.totalDeduction}
  </span>
</div>
                    <div className="bunSeeLeave mt-16"><button className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2" data-bs-toggle="modal" data-bs-target="#LeaveDetailsModal">See Leave Details <i className="mdi mdi-eye mdi-18px"></i></button></div>
                </div>
               </div>
            </div> 
    );
};

export default LeaveDetails;
