import React, { useState, useEffect, useRef } from "react";
import MeasurementDropdown from "./MeasurementDropdown";
import VendorSearchClient from "./VendorSearchClient";

const VendorAddClientDelivery = () => {
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  // State variables
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [headingText, setHeadingText] = useState("Add Vendor Delivery");

  // Form field states
  const [number, setNumber] = useState('');
  const [perUnitCost, setPerUnitCost] = useState('');
  const [comment, setComment] = useState('');

  const modalRef = useRef(null);

  // Handler for Next button click
  const handleNextClick = () => {
    setIsNextClicked(true);
    setHeadingText("Review Vendor Delivery");
  };

  // Handler for Submit button click
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
    // Here you can implement your submit logic (e.g., API call)

    // Reset form and state variables
    resetForm();
  };

  // Function to reset the form
  const resetForm = () => {
    setIsNextClicked(false);
    setHeadingText("Add Vendor Delivery");
    setNumber('');
    setPerUnitCost('');
    setComment('');
  };

  // Reset form on modal show
  useEffect(() => {
    const modalElement = modalRef.current;
    const handleModalShow = () => {
      resetForm();
    };

    // Listen for the modal show event
    modalElement.addEventListener('show.bs.modal', handleModalShow);

    // Cleanup event listener on unmount
    return () => {
      modalElement.removeEventListener('show.bs.modal', handleModalShow);
    };
  }, []);

  return (
    <>
      

      <div
        ref={modalRef}
        className="modal fade cusmodl"
        id="vendoraddClientDelivery"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between pb-0 border-0">
              <h6 className="mb-2 fw-bold text-lg mb-0">{headingText}</h6>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button>
            </div>
           
            <div className="modal-body pb-0">
              <form onSubmit={handleSubmit}>
                <div className="">
                  <img src="/assets/images/workadd.png" className="img-fluid" />
                </div>
                {/* Use d-none for elements to hide after next button click */} 
                <div className={`col-md-4 mb-20 ${isNextClicked ? 'd-none' : 'vvan-none'}`}>
                  <label className="form-label fw-semibold text-primary-light text-sm mb-8">Date</label>
                  <div className="position-relative">
                    <input
                      disabled
                      className="form-control radius-8 bg-base"
                      id="editstartDate"
                      type="text"
                      placeholder={formattedDate}
                      value={formattedDate}
                    />
                    <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                      <iconify-icon icon="solar:calendar-linear" className="icon text-lg"></iconify-icon>
                    </span>
                  </div>
                </div>
                <div className={`col-md-12 mb-20 position-relative ${isNextClicked ? 'd-none' : 'vvan-none'}`}>
                  <label className="form-label fw-semibold text-primary-light text-sm mb-8">Vendor</label>
                  <VendorSearchClient />
                </div>
                <div className={`col-md-12 mb-20 d-flex justify-content-between align-items-start gap-2 ${isNextClicked ? 'd-none' : 'vvan-none'}`}>
                  <div>
                    <label className="form-label fw-semibold text-primary-light text-sm mb-8">Unit of Measurement <span className="text-red">*</span></label>
                    <MeasurementDropdown />
                  </div>
                  <div>
                    <label className="form-label fw-semibold text-primary-light text-sm mb-8">Number <span className="text-red">*</span></label>
                    <input 
                      type="number" 
                      className="form-control" 
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label fw-semibold text-primary-light text-sm mb-8">Per Unit Cost <span className="text-red">*</span></label>
                    <div className="icon-field">
                      <span className="icon" style={{ top: 6 }}>
                        &#8377;
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0"
                        value={perUnitCost}
                        onChange={(e) => setPerUnitCost(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="form-label fw-semibold text-primary-light text-sm mb-8">Total Cost</label>
                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      placeholder="0.00"
                    />
                  </div>
                </div>
                <div className={`col-md-12 mb-20 ${isNextClicked ? 'd-none' : 'vvan-none'}`}>
                  <div className="produComment">
                    <label className="form-label fw-semibold text-primary-light text-sm mb-8">Comment</label>
                    <textarea
                      className="form-control"
                      rows={4}
                      cols={50}
                      placeholder="Enter a description..."
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                </div>
                <div className="py-16 psstyky bg-white z-9">
                  {/* Show Next button only if isNextClicked is false */}
                  {!isNextClicked && (
                    <button 
                      type="button" 
                      className="btn btn-primary w-100 mt-16" 
                      onClick={handleNextClick}
                    >
                      Next 
                    </button>
                  )}
                  {/* Show Submit button if isNextClicked is true */}
                  {isNextClicked && (
                    <button 
                      type="submit" data-bs-dismiss="modal" aria-label="Close"
                      className="btn btn-primary w-100 mt-16"
                    >
                      Submit 
                    </button>
                  )}
                </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorAddClientDelivery;
