import React, { useState, useEffect,useMemo, useCallback, useRef } from 'react';
import FilterPopup from './FilterPopup';
import ManageColumn from './ManageColumn';
import UpsertTask from './UpsertTask';
import TaskModuleTab from '../TaskModuleTab';
import { taskSearchAPI, taskStatusUpdateAPI } from 'src/api/TaskApi';
import { useParams } from 'react-router-dom';
import EditTask from './EditTask';
import BackProjectButton from 'src/components/BackProjectButton';
import { ExportReactCSV } from 'src/components/ExportReactCSV';
import SearchInput from 'src/components/SearchInput';
import { shallowEqual, useSelector } from 'react-redux';
import { debounce } from 'lodash'; 
import TaskParticipantsCircles from './TaskParticipantsCircles';

const TaskContainer = () => {
  const {user} = useSelector((state) => state.auth,shallowEqual)
  const { projectId } = useParams();
  const [tasks, setTasks] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [statuses, setStatuses] = useState({});
  const [pendingTaskCount, setPendingTaskCount] = useState(0);
  const [where,setWhere] = useState({projectId:projectId});
  const [isFilterReset, setIsFilterReset] = useState(false);


  const searchTask = async () => {
    const condition = user?.roleDetails?.roleName ==='User'?{...where,taskStatus: 'Approved'}:{...where}
    let body = {
      pageLength: 100,
      where:condition
    };
    try {
      const resp = await taskSearchAPI(body);
      const getPendingStatus = resp.data.data.data.filter((task) => {
        return task.taskStatus === 'Pending';
      });
      setPendingTaskCount(getPendingStatus.length); 
      setTasks(resp.data.data.data);
    } catch (error) {
      console.error('Error fetching task data', error);
    }
  };
  
  useEffect(() => {
    searchTask();
  }, [where]);

  const handleEditButtonClick = (taskId) => {
    setSelectedTaskId(taskId);
  };

  const handleStatusChange = async (taskId, newStatus) => {
    setStatuses(prev => ({ ...prev, [taskId]: newStatus }));
    let body = {
      taskStatus: newStatus
    }
    try {
      await taskStatusUpdateAPI({ taskId, data: body }); 
      searchTask()
    } catch (error) {
      console.log('Error updating status:', error);
    }
    console.log('Updated status for task id:', taskId, 'New status:', newStatus);
  }
  const handleFilterChange = (statuses) => {
    setWhere({...where,taskStatus: { $in: statuses } })
  };
  const handleTextSearch = useCallback(
    debounce((searchKey, setWhere) => {
      const whereClauseKeysPaths = ['taskName', 'unit'];
      const whereWithOrCondition = {
        $or: whereClauseKeysPaths.map((keyPath) => ({ 
          [keyPath]: {
            $regex: searchKey,
            $options: 'i',
          },
        })),
      }; 
      setWhere({...where,...whereWithOrCondition});
    }, 300),
    [where]
  );
  const handleSearchInputChange = (e) => {
    const searchKey = e.target.value;
    handleTextSearch(searchKey, setWhere);
  };

  const ExportInExcel = useMemo(() => { 
    if (tasks?.length===0) {
        return [];
    }
    const results = [];
    tasks?.forEach((user) => { 
                results.push({
                  taskName: user.taskName,
                  taskStatus: user.taskStatus,
                  unit: user.unit,
                  item: user.item,
                  target: user.target,
                });
    });
    return results;
}, [
  tasks
]);

  useEffect(() => {
    // Check if the user has previously clicked "Remind me later" and 5 hours have passed
    const remindMeLaterTime = localStorage.getItem("remindMeLaterTime");
    const currentTime = new Date().getTime();
    if (remindMeLaterTime && currentTime - remindMeLaterTime < 5 * 60 * 60 * 1000) {
      // If less than 5 hours, don't show the modal
      return;
    }

    const myModal = new window.bootstrap.Modal(document.getElementById('requestAccess'), {
      backdrop: 'static',
      keyboard: false,
    });
    if(pendingTaskCount>0 && user?.roleDetails?.roleName ==='Admin')
      myModal.show();
  }, [pendingTaskCount]);
   
  const handleStatusClick = (e, taskId, status) => {
    e.preventDefault(); // Prevent default link action
    handleStatusChange(taskId, status); // Update status  
    // Find the dropdown element
    const dropdownElement = e.target.closest(".dropdown-menu").previousElementSibling;  
    // Check if bootstrap is available in the global scope
    const dropdownInstance = window.bootstrap.Dropdown.getInstance(dropdownElement);
    if (dropdownInstance) {
      dropdownInstance.hide(); // Hide the dropdown
    }
  };
  const resetFilter = () => {
    document.getElementById('search').value = '';
    setWhere({projectId:projectId});
    setIsFilterReset(true);
  }

  
 // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code Start

  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
               <BackProjectButton/>
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                    <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Task Client Details</li>
              </ul>
              <TaskModuleTab />
              <div className="d-inline-flex gap-2 align-items-center py-16 postionTop-0">                
                <SearchInput handleSearchInputChange={handleSearchInputChange}/>
                
                <div className="FilterBtn d-flex gap-2">
                  <button type="button" data-bs-toggle="offcanvas" data-bs-target="#filterRightPopup" aria-controls="filterRightPopup" className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 radius-8 px-20 py-9 d-flex align-items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.5em" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z" />
                    </svg>{" "}
                    Filter
                  </button>
                  <button 
                  type="reset" 
                  onClick={resetFilter}
                  className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    Reset
                </button>
                </div>
                {/* Filter btn end */}
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16  ">                
                <h6 className="text-lg fw-semibold mb-0 text-neutral-900">Task Details</h6>
                <div className="d-flex align-items-center gap-4">
                  {/* {
                    user?.roleDetails?.roleName === 'Admin' &&  (
                      <> */}
                          <ExportReactCSV csvData={ExportInExcel} fileName={"TaskList-" + new Date().toLocaleDateString()} />
                          <a href="" className="btn btn-sm btn-primary-600" data-bs-toggle="offcanvas" data-bs-target="#AddNewTaskPopup" aria-controls="AddNewTaskPopup">
                          <i className="ri-add-line" /> Add Task
                          </a> 
                      {/* </>                    
                  )
                  } */}
                
                </div>
              </div>
              <div className={`table-body-scrollable topctumsetTask ${isScrollable ? 'scrollable' : ''}`}>
              <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                  <thead>
                    <tr className="dnd-moved"> 
                      <th>#</th>
                      <th>Task Name</th>
                      <th>Target</th>
                      <th>Unit</th>
                      <th>Item</th>
                      <th>Assignee</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tasks?.map((task, index) => (
                      <tr key={task.id} className="dnd-moved"> 
                        <td>{index + 1}</td>
                        <td><div className="tsknem">{task.taskName}</div></td>
                        <td className="text-center">{task.target}</td>
                        <td className="text-center">{task.unit}</td>
                        <td className="text-center">{task.item}</td>
                        <td className="text-center"> <TaskParticipantsCircles task={task}/></td>
                        <td className="text-center">
                          {(user?.roleDetails?.roleName === 'Admin' || user?.roleDetails?.roleName === 'Manager') ? (
                            <div className="btn-group">
                              <div
                                className={`badge text-sm fw-semibold ${
                                  (statuses[task?._id] || task?.taskStatus) === "Approved"
                                    ? "bg-success-600"
                                    : (statuses[task?._id] || task?.taskStatus) === "Rejected"
                                    ? "bg-danger-600"
                                    : "bg-warning-600"
                                } px-20 py-9 radius-4 text-white d-flex align-items-center gap-2`}
                              >
                                {statuses[task?._id] || task?.taskStatus}
                              </div>
                              <button
                                type="button"
                                className={`btn ${
                                  (statuses[task?._id] || task?.taskStatus) === "Approved"
                                    ? "bg-success-600"
                                    : (statuses[task?._id] || task?.taskStatus) === "Rejected"
                                    ? "bg-danger-600"
                                    : "bg-warning-600"
                                } dropdown-toggle dropdown-toggle-split rounded-0 border-start px-16 text-white`}
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                data-bs-reference="parent"
                              >
                                <span className="visually-hidden">
                                  {statuses[task?._id] || task?.taskStatus}
                                </span>
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <a
                                    className={`dropdown-item d-flex align-items-center gap-2 ${
                                      (statuses[task?._id] || task?.taskStatus) === "Approved"
                                        ? "bg-success-600 text-white"
                                        : ""
                                    }`}
                                    href="#"
                                    onClick={(e) => handleStatusClick(e, task?._id, "Approved")}
                                  >
                                    Approved
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className={`dropdown-item d-flex align-items-center gap-2 ${
                                      (statuses[task?._id] || task?.taskStatus) === "Pending"
                                        ? "bg-warning-600 text-white"
                                        : ""
                                    }`}
                                    href="#"
                                    onClick={(e) => handleStatusClick(e, task?._id, "Pending")}
                                  >
                                    Pending
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className={`dropdown-item d-flex align-items-center gap-2 ${
                                      (statuses[task?._id] || task?.taskStatus) === "Rejected"
                                        ? "bg-danger-600 text-white"
                                        : ""
                                    }`}
                                    href="#"
                                    onClick={(e) => handleStatusClick(e, task?._id, "Rejected")}
                                  >
                                    Rejected
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            <span>{task?.taskStatus}</span>
                          )}
                        </td>

                        <td className="text-center">
                          <a
                            className="btn btn-sm btn-primary me-2"
                            onClick={() => handleEditButtonClick(task)}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#EditTaskPopup"
                            aria-controls="EditTaskPopup"
                          >
                            Edit
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ManageColumn />
      <FilterPopup onFilterChange={handleFilterChange} isFilterReset={isFilterReset} />
      <UpsertTask projectId={projectId} onTaskSaved={searchTask} />
      <EditTask taskId={selectedTaskId} onTaskSaved={searchTask} />
    </>
  );
};

export default TaskContainer;
