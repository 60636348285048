import React from 'react'

const ReviewEmployeeModalModal = () => {
  return (
    <div>
        <div
  className="modal fade cusmodl ReviewEmployeeModalModal"
  id="ReviewEmployeeModalModal"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  style={{ display: "none" }}
  aria-hidden="true"
>
  <div className="modal-dialog modal-lg modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header d-flex justify-content-between"> 
        <h6 className="modal-title" id="exampleModalLabel">
        Review 
        </h6> 
        <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              ✗ 
        </button>
      </div>
      <div className="modal-body pt-0">
        <div className="">
        <div class="align-items-center d-flex justify-content-between py-8"><label class="form-label mb-0">Accept Activity% </label><div class="inpset001"><input type="text" name="totalLeaves" class="form-control text-end w-160-px ms-auto" placeholder="0%" /></div></div>
        <div class="align-items-center d-flex justify-content-between py-8"><label class="form-label mb-0">Hours </label><div class="inpset001"><input type="text" name="totalLeaves" class="form-control text-end w-160-px ms-auto" placeholder="09:06:35" /></div></div>
        <div className="align-items-center d-flex justify-content-between py-8">
  <label className="form-label mb-0 d-flex align-items-center gap-2 text-dblue"><img src="/assets/images/ignore-hubstaff.svg" className="img-fluid" alt="" /> Ignore Hubstaff </label>
  <div className="inpset001">
    <div className="d-flex align-items-center flex-wrap gap-28">
      <div className="form-check checked-primary d-flex align-items-center gap-2">
        <input
          className="form-check-input"
          type="radio"
          name="considerDeduction"
          id="horizontal1"
        />
        <label
          className="form-check-label line-height-1 fw-medium text-secondary-light"
          htmlFor="horizontal1"
        >
          Yes
        </label>
      </div>
      <div className="form-check checked-secondary d-flex align-items-center gap-2">
        <input
          className="form-check-input"
          type="radio"
          name="considerDeduction"
          id="horizontal2"
        />
        <label
          className="form-check-label line-height-1 fw-medium text-secondary-light"
          htmlFor="horizontal2"
        >
          No
        </label>
      </div>
    </div>
  </div>
</div>

<div className="mt-16">
  <label className="fw-bold mb-2">Comment</label>
  <textarea className="form-control" placeholder="Write comment " defaultValue={""} />
  <button type="submit" className="btn btn-primary-600 w-100 mt-16">
    Submit
  </button>
</div>

<div className="my-24">
<h3 className="card-title d-flex align-items-center gap-2 text-md"><img src="/assets/images/history-icon.svg" className="img-fluid" alt="" /> History</h3>
<div className="py-16 mt-24 border-top">
<div className="d-flex align-items-start txthistryn justify-content-between">
<ul className="gap-4">
<li><span className="d-block fw-semibold text-green">36%</span>
Previous Activity </li>
<li><span className="d-block text-dblue fw-semibold">05:37:52</span>
Previous Hours </li>
<li><span className="d-block fw-semibold text-green">52%</span>
Updated Activity </li>
<li><span className="d-block fw-semibold text-green">09:02:33</span>
Updated Hours</li>  
</ul>
<div className="byupdated">
<span className="d-block fw-bold text-xl">25 SEP 2024</span>

<div className="mt-16">
Updated by
<span className="d-block fw-semibold">Vinay Sharma</span> 
</div> 
</div> 
</div>
<p className="d-flex gap-2 mt-24 mb-0"><span className="fw-semibold">Comment:</span> Adjusted</p>

</div>
<div className="py-16 mt-0 border-top">
<div className="d-flex align-items-start txthistryn">
<ul className="gap-4">
<li><span className="d-block fw-semibold text-green">36%</span>
Previous Activity </li>
<li><span className="d-block text-dblue fw-semibold">05:37:52</span>
Previous Hours </li>
<li><span className="d-block fw-semibold text-green">52%</span>
Updated Activity </li>
<li><span className="d-block fw-semibold text-green">09:02:33</span>
Updated Hours</li>  
</ul>
<div className="byupdated">
<span className="d-block fw-bold text-xl">25 SEP 2024</span>

<div className="mt-16">
Updated by
<span className="d-block fw-semibold">Vinay Sharma</span> 
</div> 
</div> 
</div>
<p className="d-flex gap-2 mt-24 mb-0"><span className="fw-semibold">Comment:</span> Adjusted</p>

</div>
</div>


        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default ReviewEmployeeModalModal
