import React from 'react'
import BackProjectButton from './BackProjectButton'
import TopTabs from 'src/containers/project-container/View/TopTabs'
import SearchInput from './SearchInput'
import DatePickerComponent from './DatePickerComponent'

const FeedbackLog2 = () => {
    const handleDateRangeChange = (selectedDates) => {
   
    }


    const tableDocumentation = [
        {
          line: 'Content Development|Data Visualisation	',
          responsetime: '3 hrs',  
        }, 
        {
          line: 'Content Development|Data Visualisation	',
          responsetime: '3 hrs',  
        }, 
        {
          line: 'Content Development|Data Visualisation	',
          responsetime: '3 hrs',  
        }, 
        {
          line: 'Content Development|Data Visualisation	',
          responsetime: '3 hrs',  
        }, 
    ];

  return (
    <>
    <div className="row">
        <div className="col-xxl-12 col-xl-12">
            <div className="card h-100">
                <div className="card-body p-24">
                    <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                        <BackProjectButton />
                    </h2>
                    <ul className="d-flex align-items-center gap-2">
                        <li className="fw-medium">
                            <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                                <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                                Special Access
                            </a>
                        </li>
                        <li>-</li>
                        <li className="fw-medium">Documentation</li>
                    </ul>

                    <TopTabs />

                    <div className="d-flex gap-2 align-items-center py-16 mt-16">  
                 <SearchInput />
                 <DatePickerComponent  
                 onDateRangeChange={handleDateRangeChange} 
                 />   
              </div>

                <div className="table-responsive1 mt-24">
                    <table className="table basic-border-table mb-0 table-hover pt170">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Mail Subject Line</th>
                                <th>Average Response time (in hrs) </th> 
                                <th className='text-center'>View</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {tableDocumentation.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>  
                                    <td><div className="tsknem">{item.line}</div></td>
                                    <td>{item.responsetime}</td> 
                                    <td className='text-center'><a href="/feedback-log" className="btn btn-primary text-lg px-16 py-4"><i class="mdi mdi-eye"></i></a></td> 
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> 
                    </div>
                    </div>
                    </div>
                    </div>
                   

                    </>
  )
}

export default FeedbackLog2
