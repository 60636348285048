import { shallowEqual, useSelector } from "react-redux";
import {  calculateTotalLagByDateAndSum, sumIdleTimes, sumWorkingTimes } from "src/utils/utils";

const GroupCounter=(props)=>{
  const {groupCounter,search} = useSelector((state)=>state.ProductionReducer,shallowEqual);
  const {HubstaffResponse} = useSelector((state) => state.ProductionReducer,shallowEqual);
    const {totalIdleTime,totalAllowedIdleTime} = sumIdleTimes(HubstaffResponse,0);
    const {totalWorkingTime,totalAllowedWorkingTime} = sumWorkingTimes(HubstaffResponse,0);
    const totalLag = calculateTotalLagByDateAndSum(search?.data);
 
    return(
    <>
    <div className="row row-cols-xxxl-6 row-cols-xl-6 row-cols-lg-6 row-cols-md-3 row-cols-sm-2 row-cols-1 gy-4">
                  <div className="col">
                    <div className="card shadow-none h-100 totproild Idle-border rounded-0">
                      <div className="card-body p-20 textresponSec">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                          <div>
                            <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                              Total Idle
                            </p>
                            <p className="mb-0 totltm text-neutral-900 fw-semibold">
                              {totalIdleTime}{" "}
                              <span className="text-neutral-400 fw-normal">
                                /{totalAllowedIdleTime}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card end */}
                  </div>
                  <div className="col">
                    <div className="card shadow-none h-100 totproild Hours-border rounded-0">
                      <div className="card-body p-20 textresponSec">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                          <div>
                            <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                              Total Working{" "}
                            </p>
                            <p className="mb-0 totltm text-neutral-900 fw-semibold">
                              {totalWorkingTime}{" "}
                              <span className="text-neutral-400 fw-normal">
                                /{totalAllowedWorkingTime}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card end */}
                  </div>
                  <div className="col">
                    <div className="card shadow-none h-100 totproild Accepted-border rounded-0">
                      <div className="card-body p-20 textresponSec">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                          <div>
                            <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                              Accepted
                            </p>
                            <p className="mb-0 totltm text-neutral-900 fw-semibold">
                              {groupCounter.totalAccepted?.total}{" "}                             
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card end */}
                  </div>
                  <div className="col">
                    <div className="card shadow-none h-100 totproild Rejected-border rounded-0">
                      <div className="card-body p-20 textresponSec">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                          <div>
                            <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                              Rejected
                            </p>
                            <p className="mb-0 totltm text-neutral-900 fw-semibold">
                            {groupCounter.totalRejected?.total}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card end */}
                  </div>
                  <div className="col">
                    <div className="card shadow-none h-100 totproild Pending-border rounded-0">
                      <div className="card-body p-20 textresponSec">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                          <div>
                            <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                              Pending
                            </p>
                            <p className="mb-0 totltm text-neutral-900 fw-semibold">
                            {groupCounter.totalPending?.total}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card end */}
                  </div>
                  <div className="col">
                    <div className="card shadow-none h-100 totproild Lag-border rounded-0">
                      <div className="card-body p-20 textresponSec">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                          <div>
                            <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                            Lag
                            </p>
                            <p className="mb-0 totltm text-neutral-900 fw-semibold">
                             <span className={`${totalLag<0?'text-danger-700':'text-success-700'}`}>{totalLag}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card end */}
                  </div>
                </div>
    </>
    )
}

export default GroupCounter