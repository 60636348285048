import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { projectAssigneeLoadAction, setProjectAssigneeAction } from 'src/containers/client-container/actions/ClientActions';
 
export const useAssignPopup = () => {
  const dispatch = useDispatch();

  const handleOpenAssignPopup = useCallback((project, client,source) => {
    const projectParticipantIds = source === 'project' ? project?.participants?.map((participant) => participant.userId._id) : project?.participants?.map((participant) => participant._id);
    dispatch(projectAssigneeLoadAction({
      data: projectParticipantIds,
      projectId: project._id,
      projectCode: client.ClientProjectCode,
      projectSub: project.ProjectSubCode,
    }));
  }, [dispatch]);

  const handleProjectAssigneeDetails = useCallback((data) => {
    const projectParticipants = data?.map((participant) => participant.userId);
    dispatch(setProjectAssigneeAction({data:projectParticipants}));
  }, [dispatch]);
  return { 
    handleOpenAssignPopup,
    handleProjectAssigneeDetails
 };
};