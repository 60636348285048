import React from 'react';

const StatCard = ({ title, value, allowedValue, customClass }) => {
  return (
    <div className="col">
      <div className={`card shadow-none h-100 totproild ${customClass} rounded-0`}>
        <div className="card-body p-20 textresponSec">
          <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
            <div>
              <p className="fw-medium text-neutral-400 mb-1 fw-semibold">{title}</p>
              <p className="mb-0 totltm text-neutral-900 fw-semibold">
                {value}{' '}
                {allowedValue && (
                  <span className="text-neutral-400 fw-normal">/{allowedValue}</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* card end */}
    </div>
  );
};

export default StatCard;
