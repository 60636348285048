import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { ModuleDefaultProps } from 'src/containers/project-container/Module/ModulePropTypes';
import { MODULE_ACTIONS } from '../actions/ModuleActions';

const initialState = () => ({
    currentRecord: { ...ModuleDefaultProps },

    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case MODULE_ACTIONS.MODULE_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case MODULE_ACTIONS.MODULE_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case MODULE_ACTIONS.MODULE_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case MODULE_ACTIONS.MODULE_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case MODULE_ACTIONS.MODULE_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case MODULE_ACTIONS.MODULE_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case MODULE_ACTIONS.MODULE_RESET: {
            return {
                ...state,
                currentRecord: { ...ModuleDefaultProps },
            };
        }

        default:
            return state;
    }
};
