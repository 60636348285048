import React, { useState, useEffect } from 'react'
import TopTabs from '../TopTabs';
import SearchInput from 'src/components/SearchInput';
import DatePickerComponent from 'src/components/DatePickerComponent';
import VendorDeliveryTable from './VendorDeliveryTable';
import VendorAddClientDelivery from './VendorAddClientDelivery';
import { projectOAD_CMD_DetailsAction } from '../../actions/ProjectActions';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import VendorDeliverySavePopup from '../VendorDeliverySavePopup';
import { useParams } from 'react-router-dom';
import { vendorDeliveryProjectSearchAPI } from 'src/api/ReportsAPI';
import BackProjectButton from 'src/components/BackProjectButton';
import { upperCase } from 'lodash';
import { costFormat } from 'src/utils/CommonUtils';
import { dateFormat } from 'src/utils/utils';

const VendorDeliveryContainer = () => {

  const [searchQuery, setSearchQuery] = useState("");
  const { projectId } = useParams();
  const [search, setSearch] = useState(false);
  const [deliveries, setDeliveries] = useState([]);
  const [totalcost, setTotalCost] = useState(0)
  const [totalSearch, setTotalSearch] = useState(0)

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const trimmedQuery = (typeof searchQuery === 'string' ? searchQuery : '').trim().toLowerCase();
  const filteredData = trimmedQuery
    ? deliveries.filter((item) =>
      (item?.description?.toLowerCase() || '').includes(trimmedQuery) ||
      String(item.value).toLowerCase().includes(trimmedQuery) ||
      item.fullName.toLowerCase().includes(trimmedQuery)
    )
    : deliveries; // Return all data if the searchQuery is empty
  const handleDateRangeChange = (selectedDates) => {
  }
  const tableData = [
    {
      sNo: "01",
      date: "28-Jun-2024",
      uploadedBy: { initials: "KM", name: "Kiran Mazumdar" },
      vendorName: { initials: "KM", name: "Kiran Mazumdar" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 98,
      totalUnit: 50,
      paidUnit: 100,
      unpaidUnit: 20,
      status: "Invoice Under Process",
      statusClass: "invoice-under-process",
      addForInvoice: "Generate Invoice",
      payment: "₹ 589.00",
    },
    {
      sNo: "02",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Fully Paid",
      statusClass: "fully-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "03",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Unpaid",
      statusClass: "unpaid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "04",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "05",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "06",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "07",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "08",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "09",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "10",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "11",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "12",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "13",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "14",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "15",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "16",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "17",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "18",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "19",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "20",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "21",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "22",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "23",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "24",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "25",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "26",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "27",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "28",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "29",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "30",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "31",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "32",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    {
      sNo: "33",
      date: "28-Jun-2024",
      uploadedBy: { initials: "HP", name: "Hasmukhbhai Parekh" },
      vendorName: { initials: "HP", name: "Hasmukhbhai Parekh" },
      mappedClientDeliveryPath: "Project 315 (B) >> Elearnings>>Physics",
      category: "Storyline Development",
      perUnitCost: 100,
      totalUnit: 100,
      paidUnit: 100,
      unpaidUnit: 0,
      status: "Partially Paid",
      statusClass: "partially-paid",
      addForInvoice: "Download",
      payment: "₹ 1500.00",
    },
    // Add more rows as needed
  ];

  const dispatch = useDispatch();
  const project = ''
  // Event handler for button
  const handlePlusClick = (type, mode, project) => {
    dispatch(projectOAD_CMD_DetailsAction({ data: { type, mode, project } }));
    setSearch(true)
  };
  // const [individual, setIndividual] = useState([])
  // const [totalDuration, setTotalDuration] = useState()
  // const { currentRecord } = useSelector((state) => state.ProjectReducer, shallowEqual)
  // const projectCode = (currentRecord?.RefClientId?.ClientProjectCode) ? `${currentRecord?.RefClientId?.ClientProjectCode} ${currentRecord.ProjectSubCode}` : ''

  const getProjectVendorDeliveryData = async () => {
    try {
      const resp = await vendorDeliveryProjectSearchAPI({ projectId });
      setDeliveries(resp?.data?.data || [])
      // const filterSkillsData = skillsData.filter(item =>
      //   item?.fullName?.toLowerCase().includes(search.toLowerCase())
      // );
      setTotalCost(resp?.data?.total)
    } catch (error) {
      console.log('error from project vendor delivery', error);
    }
  };

  useEffect(() => {
    getProjectVendorDeliveryData();
    // Update total based on filtered data
    const newTotal = filteredData.reduce((acc, item) => {
      if (item.type === "add") {
        return acc + item.value;
      } else {
        return acc - item.value;
      }
    }, 0); // Start with 0 instead of using a separate `total` variable

    setTotalSearch(newTotal); // Update total state
  }, [projectId, search]);

  useEffect(() => {
    // Update total based on filtered data
    const newTotal = filteredData.reduce((acc, item) => {
      if (item.type === "add") {
        return acc + item.value;
      } else {
        return acc - item.value;
      }
    }, 0); // Start with 0 instead of using a separate `total` variable

    setTotalSearch(newTotal); // Update total state
  }, [searchQuery]);
  // Function to get initials from manager name
  const getInitials = (name) => {
    const nameParts = name.split(' ');
    const initials = nameParts.map(part => part[0]).join('');
    return initials;
  };
  // Function to determine background color based on manager's name
  const getBackgroundColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 70%, 50%)`; // HSL for more variation
    return color;
  };


  // Function to determine text color based on background color (light or dark)
  const getTextColor = (bgColor) => {
    const rgb = bgColor.match(/\d+/g).map(Number);
    const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
    return brightness > 150 ? '#000' : '#fff'; // Light or dark text based on background
  };
  // Initialize tooltips when data or columns change
  const truncateNotes = (notes) => {
    // return notes.length > 15 ? `${notes.substring(0, 15)}...` : notes;
    return notes && notes.length > 15 ? `${notes.substring(0, 15)}...` : notes || '';
  };

  const handleChange = (ee) => {
    setSearch(ee)
    getProjectVendorDeliveryData();
  }

  useEffect(() => {
    // Timeout to ensure tooltips are initialized after rendering
    const timer = setTimeout(() => {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

      // Cleanup on unmount or if the tooltips need to be re-initialized
      return () => {
        tooltipList.forEach(tooltip => tooltip.dispose());
      };
    }, 100); // Delay initialization to ensure DOM is fully updated

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [searchQuery, handleChange]);


  return (
    <>
      <VendorDeliverySavePopup projectSearch={project} projectId={projectId} handleChange={handleChange} />
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <div className="border-bottom  pb-16">
                <h2 className="fw-semibold mb-16 font-24">
                  <BackProjectButton />
                </h2>
                <ul className="d-flex align-items-center gap-2">
                  <li className="fw-medium">
                    <a
                      href="#"
                      className="d-flex align-items-center gap-1 hover-text-primary"
                    >
                      <iconify-icon
                        icon="solar:home-smile-angle-outline"
                        className="icon text-lg"
                      ></iconify-icon>
                      Special Access
                    </a>
                  </li>
                  <li>-</li>
                  <li className="fw-medium">Vendor Delivery </li>
                </ul>
              </div>
              <TopTabs />
              <div className="d-flex gap-2 align-items-center py-24 bg-white postionTop-0">
                <div className="WBtn_fstx">
                  <button className="btn_001 PlusBtn_001" data-bs-toggle="modal" data-bs-target="#uniqueCostModual"
                    onClick={() => handlePlusClick('OAD', 'add', project)}>
                    <i className="mdi mdi-plus" />
                  </button>
                  <div className="oadTxt">
                    Add Vendor Delivery
                  </div>
                  <button className="btn_001 MinBtn_001" data-bs-toggle="modal" data-bs-target="#uniqueCostModual"
                    onClick={() => handlePlusClick('OAD', 'remove', project)}>
                    <i className="mdi mdi-minus" />
                  </button>
                </div>
                {/* <button
                  data-bs-toggle="modal"
                  data-bs-target="#vendoraddClientDelivery"
                  className="btn btn-primary text-sm heightAllBtnSet44 btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2"
                >
                  <iconify-icon
                    icon="ic:baseline-plus"
                    className="icon text-xl line-height-1"
                  ></iconify-icon>
                  Add Vendor Delivery
                </button> */}

                <SearchInput handleSearchInputChange={handleSearchInputChange} />
                {/* <DatePickerComponent
                  onDateRangeChange={handleDateRangeChange}
                />
                <div className="FilterBtn d-flex gap-2"><button type="button" className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"></path></svg> Filter</button>
                </div> */}
              </div>

              <div className="TotlU">
                <ul>
                  <li>

                    {searchQuery ? (
                      <>
                        {costFormat(totalSearch)} / {costFormat(totalcost)}
                      </>
                    ) : (
                      costFormat(totalcost)
                    )}
                    <span className="d-block">Total</span></li>
                  {/* <li className="text-green">158 <span className="d-block">Total Paid Unit</span></li>
                  <li>59 <span className="d-block">Total Unpaid Unit</span></li>
                  <li className="text-dblue">₹269.9K <span className="d-block">Total Paid Amount</span></li>
                  <li>258 <span className="d-block">Under Process</span></li>
                  <li>36 <span className="d-block">Fully Paid</span></li>
                  <li>98 <span className="d-block">Unpaid</span></li>
                  <li>26 <span className="d-block">Partially Paid</span></li> */}
                </ul>

              </div>

              {/* <VendorDeliveryTable data={tableData} status="Fully Paid" /> */}
              <table className="table basic-border-table mb-0 table-hover">
                <thead>
                  <tr>
                    <th className="align-middle">S.No.</th>
                    <th className="align-middle">Date</th>
                    <th className="align-middle">Status</th>
                    <th className="align-middle">Amount</th>
                    <th className="align-middle">Uploaded By</th>
                    <th className="align-middle">Description</th>
                  </tr>
                </thead>
                <tbody>

                  {filteredData.map((row, index) => {
                    const bgColor = getBackgroundColor(row.fullName);
                    const textColor = getTextColor(bgColor);
                    return (
                      <tr key={row._id}>
                        <td>{index + 1}</td>
                        <td>{dateFormat(row.ts, false)}</td>
                        {/* <td className="bg invoice-under-process">
                          <span className="status-badge d-flex align-items-center gap-2 invoice-under-process">
                            <i className="mdi mdi-clock mdi-24px" /> {upperCase(row.type)}
                          </span>
                        </td> */}
                        <td className={`bg ${row.type === 'add' ? 'green' : 'invoice-under-process'}`}>
                          <span className={`status-badge d-flex align-items-center gap-2 ${row.type === 'add' ? 'green' : 'invoice-under-process'}`}>
                            <i className={`mdi mdi-${row.type === 'add' ? 'check' : 'close'} mdi-24px`} /> {upperCase(row.type)}
                          </span>
                        </td>
                        <td>{costFormat(row.value)}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            {row.profilePicture ? (
                              <img
                                src={row.profilePicture}
                                alt={row.fullName}
                                className="initials-image"
                                style={{ width: '35px', height: '35px', borderRadius: '50%', marginRight: '8px' }}
                              />
                            ) : (
                              <span
                                className="initials-circle UsIMgName"
                                style={{ backgroundColor: bgColor, color: textColor }}>
                                {getInitials(row.fullName)}
                              </span>
                            )}
                            {row.fullName}
                          </div>
                        </td>
                        <td>
                          <div className="detaset" data-bs-toggle="tooltip"

                            data-bs-placement="top"

                            data-bs-custom-class="tooltip-primary"

                            data-bs-title={`${row?.description}`}>{truncateNotes(row?.description)}</div>
                        </td>
                      </tr>
                    )
                  }
                  )}
                  {/* <tr>
                    <td>01</td>
                    <td>28-Jun-2024</td>
                    <td>
                      <div className="user-cell">
                        <span className="user-initials">KM</span>
                        <span>Kiran Mazumdar</span>
                      </div>
                    </td>
                    <td>Project 315 (B) &gt;&gt; Elearnings&gt;&gt;Physics</td>
                    <td>Storyline Development</td>
                    <td>98</td>
                    <td>50</td>
                    <td className="bg invoice-under-process">
                      <span className="status-badge d-flex align-items-center gap-2 invoice-under-process">
                        <i className="mdi mdi-clock mdi-24px" /> Invoice Under Process
                      </span>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <VendorAddClientDelivery />
    </>
  )
}
export default VendorDeliveryContainer