import { shallowEqual, useSelector } from "react-redux"

const ClientCommentModal=()=>{
const {currentRecord}=useSelector(state=>state.ClientProductionReducer,shallowEqual)

    return (
<div
  className="modal fade"
  id="ClientCommentModal"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  >
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header d-flex justify-content-between">
        <h5 className="modal-title" id="exampleModalLabel">
          Comment
        </h5>
        <button
          type="button"
          className="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={22}
            fill="currentColor"
            className="bi bi-x-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </button>
      </div>
      <div className="modal-body">
        <p>
          {currentRecord?.comment}
        </p>
      </div>
    </div>
  </div>
  </div>
    )
}

export default ClientCommentModal