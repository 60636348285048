import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const TaskModuleTab = () => {
    const { projectId } = useParams();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('task');

    useEffect(() => {
        // Check the current path and set the active tab accordingly
        if (location.pathname.includes('/task')) {
            setActiveTab('task');
        } else if (location.pathname.includes('/module')) {
            setActiveTab('module');
        }
    }, [location.pathname]); // Run this effect whenever the pathname changes

    return (
        <div className="tabsAllPages">
            <nav className="tabs">
                <ul className="-primary nav my-16 justify-content-start">
                    <li className={`${activeTab === "task" ? 'active' : ''}`}>
                        <Link to={`/projects/task/${projectId}`} onClick={() => setActiveTab('task')}>Task</Link>
                    </li>
                    <li className={`${activeTab === "module" ? 'active' : ''}`}>
                        <Link to={`/projects/module/${projectId}`} onClick={() => setActiveTab('module')}>Module</Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default TaskModuleTab;
