export const FEEDBACK_ACTIONS = {
    FEEDBACK_SEARCH: 'FEEDBACK_SEARCH',
    FEEDBACK_SEARCH_SUCCESS: 'FEEDBACK_SEARCH_SUCCESS',
    FEEDBACK_SEARCH_ERROR: 'FEEDBACK_SEARCH_ERROR',

    FEEDBACK_UPSERT: 'FEEDBACK_UPSERT',
    FEEDBACK_UPSERT_SUCCESS: 'FEEDBACK_UPSERT_SUCCESS',
    FEEDBACK_UPSERT_ERROR: 'FEEDBACK_UPSERT_ERROR',



    FEEDBACK_UPLOAD: 'FEEDBACK_UPLOAD',
    FEEDBACK_UPLOAD_SUCCESS: 'FEEDBACK_UPLOAD_SUCCESS',
    FEEDBACK_UPLOAD_ERROR: 'FEEDBACK_UPLOAD_ERROR',

    FEEDBACK_EDIT: 'FEEDBACK_EDIT',

    FEEDBACK_RESET: 'FEEDBACK_RESET',
};

export const feedbackResetAction = () => ({
    type: FEEDBACK_ACTIONS.FEEDBACK_RESET,
});

export const feedbackSearchAction = (payload) => ({
    type: FEEDBACK_ACTIONS.FEEDBACK_SEARCH,
    ...payload,
});

export const feedbackEditAction = (payload) => ({
    type: FEEDBACK_ACTIONS.FEEDBACK_EDIT,
    ...payload,
});

export const feedbackUpsertAction = (payload) => ({
    type: FEEDBACK_ACTIONS.FEEDBACK_UPSERT,
    ...payload,
});
export const feedbackUploadAction = (payload) => ({
    type: FEEDBACK_ACTIONS.FEEDBACK_UPLOAD,
    ...payload,
});

export default {
    FEEDBACK_ACTIONS,
    feedbackSearchAction,
    feedbackUpsertAction,
    feedbackEditAction,
    feedbackUploadAction,
};
