import { useDispatch } from "react-redux";
import { clientProductionAssignSubCategoryAction } from "../actions/ClientProductionActions";

const SubCategoryTree=({showModal,subCategory})=>{
  console.log('subCategory=>',subCategory)
    // const [isOpen,setIsOpen] = useState(false)
    const dispatch = useDispatch();
    const handleSubCategorySelect = (e,subCategory)=>{
        e.stopPropagation(); // Prevent event propagation
        dispatch(clientProductionAssignSubCategoryAction({
          data:{
            subCategoryId: subCategory._id,
            subCategoryName: subCategory.subCategoryName
          }
        }))
        document.querySelectorAll('.accordion-collapse').forEach((element) => {
          element.classList.remove('show');
      });
    }
    return (
      <>  
        <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-heading001">
                      <button onClick={(e) => handleSubCategorySelect(e,subCategory)} 
                              className="accordion-button collapsed removePlsMin" 
                              type="button" 
                              data-bs-toggle="collapse"
                              data-bs-target={`#flush-collapse001${subCategory._id}`}
                              aria-expanded="false" 
                              aria-controls={`flush-collapse001${subCategory._id}`}
                        >
                      {subCategory.subCategoryName}
                      </button> 
                    </h2>
                    <div 
                        id={`flush-collapse001${subCategory._id}`} 
                        className="accordion-collapse collapse" 
                        aria-labelledby="flush-heading001" 
                        data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">  
                      </div>
                    </div>
                  </div>
      </>
    )
  }
  export default SubCategoryTree