import React from 'react'

// Sample data
const invoiceData = [
    { id: 1, invoiceNo: "02585", date: "28-Jun-2024", qty: 98 },
    { id: 2, invoiceNo: "02585", date: "28-Jun-2024", qty: 98 },
    { id: 3, invoiceNo: "02585", date: "28-Jun-2024", qty: 98 },
    { id: 4, invoiceNo: "02585", date: "28-Jun-2024", qty: 98 },
    { id: 5, invoiceNo: "02585", date: "28-Jun-2024", qty: 98 },
    { id: 6, invoiceNo: "02585", date: "28-Jun-2024", qty: 98 },
    { id: 7, invoiceNo: "02585", date: "28-Jun-2024", qty: 98 },
    { id: 8, invoiceNo: "02585", date: "28-Jun-2024", qty: 98 },
  ];

const ViewInvoiceListModal = () => {
  return (
    <div>
        <div className="modal fade cusmodl LeaveDetailsModal" id="viewInvoiceListModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl modal-dialog-scrollable">
        <div className="modal-content">
      <div className="modal-header d-flex justify-content-between border-0">
        <h6 className='modal-title'>Invoice List</h6>
        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button></div>
      <div className="modal-body py-0"> 
      <div className="table-container1">
      <table className="table basic-border-table mb-0 table-hover">
        <thead>
          <tr>
            <th className="w-32-px">S.No.</th>
            <th>Invoice No.</th>
            <th>Date</th>
            <th className="text-center">Qty.</th>
            <th className="text-center">Download</th>
          </tr>
        </thead>
        <tbody>
          {invoiceData.map((invoice, index) => (
            <tr key={invoice.id}>
              <td className="w-32-px">{String(index + 1).padStart(2, '0')}</td>
              <td>{invoice.invoiceNo}</td>
              <td>{invoice.date}</td>
              <td className="text-center">{invoice.qty}</td>
              <td className="text-center">
                <button className="download-button border px-20 py-9 radius-6">
                  Download <i className="download-icon">⬇️</i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default ViewInvoiceListModal
