import { shallowEqual, useSelector } from "react-redux";
import { generateMonthData } from "src/utils/CommonUtils"
import { getDateFromDate, getDateRange, getDayFromDate } from "src/utils/utils"

const AttendanceCalendar=(props)=>{
  const {selectedMonth, selectedYear,search} = useSelector((state)=>state.ProductionReducer,shallowEqual);
  // Month value should be from 0 to 11
  const year = selectedYear;
  const month = (selectedMonth-1); // August (0-indexed, so 7 represents August)
  const dateWiseProductions = search.data.filter(item => item.date).map(item => new Date(item.date).toISOString().split('T')[0]);
  const dateWiseLeaves = search?.leaves?.flatMap(item => getDateRange(item.startdate, item.endate, item.subject));
  const dateWiseHolidays = search?.holidays;
  const dateWiseHubStaff = search?.HubstaffResponse;
  const monthDays = generateMonthData(year,month,dateWiseProductions,dateWiseLeaves,dateWiseHubStaff)
   return (
    <>
    <div className="row mb-16 mt-40">
      {
        monthDays.map((item, monthIndex) => {
          return (
            <>
                <div className="col" key={monthIndex}>
                    <div className="p-4 bg-card text-card-foreground rounded-lg">
                      <div className="grid grid-cols-7 gap-1 text-center text-sm font-medium">
                        {
                          item.map((day, weekIndexDate) => {
                            return (
                              <div className="text-muted-foreground" key={`date_${weekIndexDate}`}>{getDateFromDate(day.date)}</div>
                            )
                          })
                        }
                        
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                      {
                          item.map((day, weekIndexDay) => {
                            return (
                              <div className="text-muted-foreground" key={`day_${weekIndexDay}`}>{getDayFromDate(day.date)}</div>
                            )
                          })
                        }
                         
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                      {
                          item.map((day, weekIndexAttendance) => {
                            const formattedDayDate = new Date(day.date).toISOString().split('T')[0];
                            const dayOfMonth = new Date(day.date).getDate();
                            const Leave = dateWiseLeaves?.find(leave => leave.date === formattedDayDate);
                            const  holiday= dateWiseHolidays?.find(holiday => holiday.day == dayOfMonth);
                            if (day.label === "P") {  
                              if((Leave && Leave.subject==1))
                                return (
                                  <div className="bg-blue-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>CL</div>
                                )
                                else if((Leave && Leave.subject==2))
                                return (
                                  <div className="bg-yellow-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>SL</div>
                                )
                               else if (Leave && Leave.subject == 4) {
                                return (
                                  <div className="bg-yellow-200 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>HD</div>
                                );
                              }                                
                              return (
                                <div className="bg-green-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>P</div>
                              );
                            }
                            else if(day.label === "W"){
                              if (holiday) {
                                return (
                                  <div className="bg-pink-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>H</div>
                                );
                              }                                
                              return (
                                <div className="bg-zinc-300 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>W</div>
                              )
                            }
                            else if((Leave && Leave.subject==1))
                            return (
                              <div className="bg-blue-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>CL</div>
                            )
                            else if((Leave && Leave.subject==2))
                            return (
                              <div className="bg-yellow-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>SL</div>
                            )
                            else if((Leave && Leave.subject==3))
                            return (
                              <div className="bg-red-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>A</div>
                            ) 
                            else if(day.label === "A")
                            return (
                              <div className="bg-red-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>A</div>
                            )
                            else 
                              if (holiday) {
                                  return (
                                    <div className="bg-pink-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>H</div>
                                  );
                                }                                
                                return (
                                  <div className="bg-zinc-300 text-zinc-500 py-1 rounded"   key={`att_${weekIndexAttendance}`}></div>
                                )
                          })
                        }
                      </div>
                    </div>
                  </div>
            </>
          )
        })
      }
                </div>
    </>
   )
}
export default AttendanceCalendar