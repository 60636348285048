import React from 'react';

// Utility function to get initials
const getInitials = (name) => {
  if (!name) return '';
  const words = name.trim().split(' ');
  const firstNameInitial = words[0]?.charAt(0).toUpperCase();
  const lastNameInitial = words[1]?.charAt(0).toUpperCase();
  return `${firstNameInitial}${lastNameInitial || ''}`;
};

// Utility function to generate color from a string (e.g., client name)
const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, '0')}${((hash >> 16) & 0xff).toString(16).padStart(2, '0')}${((hash >> 8) & 0xff).toString(16).padStart(2, '0')}`;
  return color.slice(0, 7); // Ensure it's a valid hex color
};

const ClientWiseMonths = () => {
  const clientName = "Hasmukhbhai Parekh";
  const projectTitle = "Project P001-A";
  const months = [
    { name: 'JAN 2024', amount: 23591.01 },
    { name: 'MAR 2024', amount: 23591.01 },
    { name: 'SEP 2024', amount: 23591.01 },
    { name: 'NOV 2024', amount: 23591.01 }
  ];

  // Calculate total amount
  const totalAmount = months.reduce((acc, month) => acc + month.amount, 0).toFixed(2);

  return (
    <div>
      <div className="modal fade cusmodl" id="ClientWiseMonths" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex flex-column justify-content-between pb-0 border-0 gap-3">
              <div className="d-flex align-items-center justify-content-between w-100">
                <h6 className="mb-2 fw-bold text-lg mb-0">{projectTitle}</h6>
                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button>
              </div>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="name d-flex align-items-center gap-2">
                  <span
                    className="client-initials me-2 rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      backgroundColor: stringToColor(clientName),
                      width: '40px',
                      height: '40px',
                      color: '#fff',
                      fontWeight: 'bold'
                    }}
                  >
                    {getInitials(clientName)}
                  </span>
                  {clientName}
                </div>
                <div className="TotalAmount text-dblue fw-bold text-lg">₹{totalAmount}</div>
              </div>
            </div>
            <div className="modal-body">
              {months.map((month, index) => (
                <div key={index} className="d-flex justify-content-between align-items-center my-8">
                  <span className="badge text-md fw-semibold border border bg-transparent px-20 py-11 rounded-pill text-secondary-light">
                    {month.name}
                  </span>
                  <span className="badge text-md fw-semibold border-0 bg-transparent px-20 py-9 radius-4 text-secondary-light">
                    ₹{month.amount.toFixed(2)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientWiseMonths;
