import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { userSearchAPI } from "src/api/UserApi";
import Loading from 'src/containers/main-container/Loading';

const Information=()=>{
    const {employeeID,userId} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [user,setUser] = useState({})

    useEffect(()=>{
        search(userId)
    },[userId])
    const search = useCallback(async()=>{
        setIsLoading(false)
       const user = await userSearchAPI({where:{_id:userId},autopopulate:true})
       setUser(user.data.data[0])
       setIsLoading(true)
      },[userId])
   return (
    <> 
      <div className="col-md-12">
        <div className="card h-100 p-0">
            <div className="card-body p-24">
            <div className="ms-16 mb-24 me-16 d-flex gap-5 align-items-center">
        <div className="d-flex justify-content-center align-items-center perfUser">
            <img
                src="/assets/images/avatar/avatar1.png"
                alt=""
                className="border br-white border-width-2-px rounded-circle object-fit-cover"
            />
        </div>
        <div className="mt-24 w-100">
            <h6 className="text-xl mb-16">Details of employee</h6>
            <ul className="gap-0" style={{ display: "grid", gridTemplateColumns: "repeat(2 ,1fr)" }}>
                <li className="d-flex align-items-center gap-1 mb-12">
                    <span className="w-30 text-md fw-semibold text-primary-light">
                        Name
                    </span>
                    <span className="w-70 text-secondary-light fw-medium">
                        : {(user?.firstName || "") + " " + (user?.lastName || "")}
                    </span>
                </li>
                <li className="d-flex align-items-center gap-1 mb-12">
                    <span className="w-30 text-md fw-semibold text-primary-light">
                        {" "}
                        Employee Id
                    </span>
                    <span className="w-70 text-secondary-light fw-medium">
                        : {user?.employeeID}
                    </span>
                </li>
                <li className="d-flex align-items-center gap-1 mb-12">
                    <span className="w-30 text-md fw-semibold text-primary-light">
                        {" "}
                        Designation
                    </span>
                    <span className="w-70 text-secondary-light fw-medium">
                        : {user?.designationId?.designationName}
                    </span>
                </li>
                <li className="d-flex align-items-center gap-1 mb-12">
                    <span className="w-30 text-md fw-semibold text-primary-light">
                        {" "}
                        Department
                    </span>
                    <span className="w-70 text-secondary-light fw-medium">: {user?.departmentId?.departmentName}</span>
                </li>
                <li className="d-flex align-items-center gap-1 mb-12">
                    <span className="w-30 text-md fw-semibold text-primary-light">
                        {" "}
                        Email
                    </span>
                    <span className="w-70 text-secondary-light fw-medium">
                        : {user?.email}
                    </span>
                </li>
                <li className="d-flex align-items-center gap-1 mb-12">
                    <span className="w-30 text-md fw-semibold text-primary-light">
                        {" "}
                        Mobile No.
                    </span>
                    <span className="w-70 text-secondary-light fw-medium">: (+91) {user?.phone}</span>
                </li>
            </ul>
        </div>
    </div>
            </div>
            </div>
            </div>

    </>
   )
}
export default Information