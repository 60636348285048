import { updateCloserDate } from "src/api/ProductionApi";
import { getDeviation } from "src/utils/utils";

const DeviationRow=({row,dateCol,index,search})=>{
    console.log('row',row)
    const totalOAD = row.projectDetails.OADDetails.reduce((total, item) => (item.type=="add")?(total + item.value):(total-item.value), 0);
    const totalCMD = row.projectDetails.CMDDetails.reduce((total, item) => (item.type=="add")?(total + item.value):(total-item.value), 0);
    const actualDelivery = row.durationsByDate.reduce((total, item) =>  total+item.totalCost, 0);
    const Deviation =  getDeviation(actualDelivery,totalCMD)
    const handleCloserChange = async(e) => {
        const value = e.target.value;
        await updateCloserDate({
            CloserDate:value,
            projectId:row.projectDetails._id
        });
        search();
    }
    return (
        <>
          <tr key={row.id}>
              <td>{index + 1}</td>
              <td>
                <ul className="ProjeAttnc d-flex">
                  <li>{`${row.clientDetails.ClientProjectCode}(${row.projectDetails.ProjectSubCode})`}</li>
                </ul>
              </td>
              <td className="text-center">{row.clientDetails.ClientWebsite}</td>
              <td className="text-center">{(row.managerDetails.length>0)?row.managerDetails[0].firstName + " " + row.managerDetails[0].lastName:"NA"}</td>
              <td className="text-center"> {totalOAD.toFixed(2)}</td>
              <td className="text-center">{totalCMD.toFixed(2)}</td>
              <td className="text-center">{actualDelivery.toFixed(2)}</td>
              <td className="text-center">{Deviation}</td>
              <td className="text-center">
                <div className="w-210-px mx-auto">
                    <input
                    key={`index-${index}`}
                    type="date"
                    className="form-control"
                    value={row.projectDetails?.CloserDate 
                        ? new Date(row.projectDetails.CloserDate).toISOString().split('T')[0] 
                        : ''}  // Ensure it's in YYYY-MM-DD format
                    onChange={handleCloserChange}
                    />
                </div>
                </td> 
                {
                   dateCol.map((date,index)=>{
                        const getTotalDuration = row.durationsByDate?.filter((item)=>item.date==date)[0]||{};
                       return(
                           <td  key={index} className="text-center">{getTotalDuration?.totalCost}</td>
                       )
                   })                    
                     
                 }
            </tr>
        </>
    )
}
export default DeviationRow