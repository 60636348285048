import config from 'src/config';
import { deleteAsyncRequest, getRequest, postAsyncRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const clientProductionSearchAPI = function (data) {
    const url = `${config.baseUrl}clientProduction/search`;
    return postRequest({
        url,
        data,
    });
};
export const getclientProductionAPI = function (data) {
    const url = `${config.baseUrl}clientProduction/getclientProduction`;
    return postRequest({
        url,
        data,
    });
};

export const clientProductionCreateAPI = function (data) {
    const url = `${config.baseUrl}clientProduction/save`;
    return postRequest({
        url,
        data,
    });
};

export const clientProductionUpdateAPI = function ({
    clientProductionId,
    data,
}) {
    const url = `${config.baseUrl}clientProduction/update/${clientProductionId}`;
    return putRequest({
        url,
        data,
    });
};

export const clientProductionFIleRemoveAPI = function (
    data
) {
    const url = `${config.baseUrl}file/delete`;
    return deleteAsyncRequest({
        url,
        data,
    });
};
export const userProductionFileAPI = function (data) {
    const fd = new FormData();
  
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        const jsonString = JSON.stringify(data[key]);
        fd.append(key, jsonString);
      } else if (data[key] instanceof FileList) {
        // If it's a FileList, iterate over it to append each file
        Array.from(data[key]).forEach((file, index) => {
          fd.append(`${key}[${index}]`, file); // appending each file
        });
      } else if (data[key] instanceof File) {
        fd.append(key, data[key]); // Directly append the file
      } else {
        fd.append(key, data[key]);
      }
    });
  
    const url = `${config.baseUrl}file/upload`;
    return postAsyncRequest({
      url,
      data: fd,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  export const getProjectForClientDeliveryFromAPI = function (projectId) {
    const url = `${config.baseUrl}project/getProjectForClientDelivery/${projectId}`;
    return getRequest({
        url,
    });
};