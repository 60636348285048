/* eslint-disable react/forbid-prop-types */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { hasPermission } from 'src/utils/CommonUtils';

const ProjectPermissionHOC = ({
    scope,
    scopeMasterList=[],
    children,
    fallback,
    component,
    isFullAccess=false,
}) => {
    const { permissions = [],participants=[] } = useSelector((state) => state.ProjectReducer.currentRecord || {permissions:[],participants:[]},shallowEqual);
    const {user} = useSelector((state) => state.auth,shallowEqual)

    const hasRight = useMemo(() => hasPermission({
        scope,
        scopeList: scopeMasterList.length > 0 ? scopeMasterList : permissions.length > 0 ? permissions[0].permissions : [],
    }), [
        permissions,
        scopeMasterList,
        scope,
    ]);
    const isFullAccessLocal = participants?.some(participent => participent.userId._id === user._id && participent.role === "Manager");

    if (user?.roleDetails?.roleName !== 'Admin' && !hasRight && !isFullAccess && !isFullAccessLocal) {
        return fallback;
    }

    return component || children;
};

ProjectPermissionHOC.propTypes = {
    scope: PropTypes.string.isRequired,
    fallback: PropTypes.object,
    component: PropTypes.any,
    children: PropTypes.any,
};

ProjectPermissionHOC.defaultProps = {
    fallback: null,
    component: null,
    children: null,
};

export default ProjectPermissionHOC;
