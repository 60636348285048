import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const DashboardPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    DashboardName: PropTypes.string,
});

export const DashboardDefaultProps = { 
    DashboardName: '',
    categoryDetails: [],
    _id: undefined
};

export const DashboardYupSchema = Yup.object().shape({ 
    DashboardName: Yup.string().required('Required'),
});

export default {
    DashboardDefaultProps,
    DashboardPropTypes,
    DashboardYupSchema,
};
