import { Link } from 'react-router-dom';
import PermissionHOC from 'src/hoc/PermissionHOC';

const menuItems = [
    {
        title: 'Dashboard',
        icon: (
            <svg
            width={17}
            height={17}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.88469 2.27001C7.88469 1.36749 7.15221 0.63501 6.24969 0.63501H1.89013C0.987607 0.63501 0.255127 1.36749 0.255127 2.27001V6.62957C0.255127 7.53209 0.987607 8.26457 1.89013 8.26457H6.24969C7.15221 8.26457 7.88469 7.53209 7.88469 6.62957V2.27001ZM6.79513 2.27001V6.62957C6.79513 6.93041 6.55053 7.17501 6.24969 7.17501H1.89013C1.58929 7.17501 1.34469 6.93041 1.34469 6.62957V2.27001C1.34469 1.96917 1.58929 1.72457 1.89013 1.72457H6.24969C6.55053 1.72457 6.79513 1.96917 6.79513 2.27001Z"
              fill="#323338"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.88469 10.99C7.88469 10.0875 7.15221 9.35498 6.24969 9.35498H1.89013C0.987607 9.35498 0.255127 10.0875 0.255127 10.99V15.3495C0.255127 16.2521 0.987607 16.9845 1.89013 16.9845H6.24969C7.15221 16.9845 7.88469 16.2521 7.88469 15.3495V10.99ZM6.79513 10.99V15.3495C6.79513 15.6504 6.55053 15.895 6.24969 15.895H1.89013C1.58929 15.895 1.34469 15.6504 1.34469 15.3495V10.99C1.34469 10.6891 1.58929 10.4445 1.89013 10.4445H6.24969C6.55053 10.4445 6.79513 10.6891 6.79513 10.99Z"
              fill="#323338"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.6047 2.27001C16.6047 1.36749 15.8722 0.63501 14.9697 0.63501H10.6101C9.70758 0.63501 8.9751 1.36749 8.9751 2.27001V6.62957C8.9751 7.53209 9.70758 8.26457 10.6101 8.26457H14.9697C15.8722 8.26457 16.6047 7.53209 16.6047 6.62957V2.27001ZM15.5151 2.27001V6.62957C15.5151 6.93041 15.2705 7.17501 14.9697 7.17501H10.6101C10.3093 7.17501 10.0647 6.93041 10.0647 6.62957V2.27001C10.0647 1.96917 10.3093 1.72457 10.6101 1.72457H14.9697C15.2705 1.72457 15.5151 1.96917 15.5151 2.27001Z"
              fill="#323338"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.6047 10.99C16.6047 10.0875 15.8722 9.35498 14.9697 9.35498H10.6101C9.70758 9.35498 8.9751 10.0875 8.9751 10.99V15.3495C8.9751 16.2521 9.70758 16.9845 10.6101 16.9845H14.9697C15.8722 16.9845 16.6047 16.2521 16.6047 15.3495V10.99ZM15.5151 10.99V15.3495C15.5151 15.6504 15.2705 15.895 14.9697 15.895H10.6101C10.3093 15.895 10.0647 15.6504 10.0647 15.3495V10.99C10.0647 10.6891 10.3093 10.4445 10.6101 10.4445H14.9697C15.2705 10.4445 15.5151 10.6891 15.5151 10.99Z"
              fill="#323338"
            />
          </svg>
        ),
        'path':'/',
        scope: 'dashboard',
        submenu: [],
      },
    {
      title: 'Production',
      icon: (
        <svg
        width={19}
        height={19}
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.47072 10.1818H8.82739C9.57356 10.1818 10.1841 9.5713 10.1841 8.82514V8.70982L15.0477 7.8212C15.1698 8.01113 15.3665 8.14002 15.6107 8.14002C15.9838 8.14002 16.2891 7.83477 16.2891 7.46168V4.07001C16.2891 3.32384 15.6786 2.71334 14.9324 2.71334H12.2191V1.35667C12.2191 0.610501 11.6086 0 10.8624 0H5.43571C4.68954 0 4.07904 0.610501 4.07904 1.35667V2.71334H1.3657C0.619535 2.71334 0.0090332 3.32384 0.0090332 4.07001V13.5667C0.0090332 14.3129 0.619535 14.9234 1.3657 14.9234H7.47072C7.8438 14.9234 8.14905 14.6181 8.14905 14.245C8.14905 13.8719 7.8438 13.5667 7.47072 13.5667H1.3657V7.84155L6.11405 8.70982V8.82514C6.11405 9.5713 6.72455 10.1818 7.47072 10.1818ZM7.47072 8.82514V7.46847H8.82739V8.82514H7.47072ZM5.43571 1.36345H10.8624V2.72012H5.43571V1.36345ZM1.3657 4.07679H14.9324V6.47131L10.1705 7.33958C10.1027 6.65446 9.53286 6.11858 8.82739 6.11858H7.47072C6.76525 6.11858 6.19545 6.65446 6.12761 7.33958L1.3657 6.47131V4.07679Z"
          fill="#323338"
        />
        <path
          d="M14.2541 9.50342C11.6357 9.50342 9.50574 11.6334 9.50574 14.2518C9.50574 16.8701 11.6357 19.0001 14.2541 19.0001C16.8725 19.0001 19.0024 16.8701 19.0024 14.2518C19.0024 11.6334 16.8725 9.50342 14.2541 9.50342ZM14.2541 17.6434C12.3819 17.6434 10.8624 16.124 10.8624 14.2518C10.8624 12.3796 12.3819 10.8601 14.2541 10.8601C16.1263 10.8601 17.6458 12.3796 17.6458 14.2518C17.6458 16.124 16.1263 17.6434 14.2541 17.6434Z"
          fill="#323338"
        />
        <path
          d="M15.6107 13.5735H14.9324V12.8951C14.9324 12.522 14.6271 12.2168 14.254 12.2168C13.8809 12.2168 13.5757 12.522 13.5757 12.8951V14.2518C13.5757 14.6249 13.8809 14.9301 14.254 14.9301H15.6107C15.9838 14.9301 16.289 14.6249 16.289 14.2518C16.289 13.8787 15.9838 13.5735 15.6107 13.5735Z"
          fill="#323338"
        />
      </svg>       
      ),
      scope: 'production',
      submenu: [
        { title: 'My Production', path: '/productions',scope: 'production.my_production', },
        { title: 'Salary', path: '/salary',scope: 'production' },
      ],
    },
    {
      title: 'Special Access',
      icon: (
        <svg
        width={19}
        height={13}
        viewBox="0 0 19 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0277 4.32342C17.9266 4.24411 17.8052 4.195 17.6774 4.18174C17.5496 4.16848 17.4207 4.19162 17.3055 4.24848L13.2998 6.19685L10.0025 0.358542C9.94312 0.253699 9.85696 0.166494 9.75284 0.105822C9.64872 0.0451502 9.53036 0.0131836 9.40985 0.0131836C9.28934 0.0131836 9.17099 0.0451502 9.06687 0.105822C8.96275 0.166494 8.87658 0.253699 8.81717 0.358542L5.53354 6.17642L1.55504 4.24848C1.43985 4.19162 1.31094 4.16848 1.18316 4.18174C1.05539 4.195 0.933972 4.24411 0.832915 4.32342C0.732135 4.40244 0.655671 4.50827 0.612292 4.62876C0.568914 4.74926 0.560378 4.87954 0.587665 5.00467L2.20904 12.4099C2.24228 12.5648 2.32849 12.7033 2.45283 12.8015C2.57717 12.8998 2.73187 12.9516 2.89029 12.948H15.943C16.1005 12.9519 16.2545 12.901 16.3787 12.8042C16.5029 12.7073 16.5897 12.5703 16.6243 12.4167L18.2865 5.01148C18.3137 4.88404 18.3038 4.75149 18.2579 4.62953C18.212 4.50758 18.1321 4.40134 18.0277 4.32342ZM15.398 11.5855H3.42166L2.22266 6.08104L5.5131 7.68198C5.66941 7.75809 5.8489 7.77155 6.01481 7.71963C6.18073 7.6677 6.32051 7.55431 6.40554 7.40267L9.43029 2.07529L12.4482 7.42311C12.5333 7.57474 12.673 7.68814 12.839 7.74006C13.0049 7.79199 13.1844 7.77852 13.3407 7.70242L16.6515 6.08785L15.398 11.5855Z"
          fill="#323338"
        />
      </svg>
      ),
      scope: 'special_access',
      submenu: [
        { title: 'My Projects', path: '/projects',scope: 'special_access.project' },
        { title: 'Clients', path: '/clients',scope: '92' },
        { title: 'Vendors', path: '/vendor-list',scope: 'special_access.vendor' },
        { title: 'MTD Delivery', path: '/mtd-delivery',scope: 'special_access.mtd_delivery' },
        { title: 'PDD Stats', path: '/pdd-stats',scope: 'special_access.pdd_stats' },
        { title: 'Delivery Deviation', path: '/delivery-deviation',scope: 'special_access.delivery_deviation' },
      ],
    },
    {
        title: 'Employee Access',
        icon: (
            <svg
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_747_367"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x={0}
              y={0}
              width={22}
              height={22}
            >
              <rect width={22} height={22} fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_747_367)">
              <path
                d="M3.20825 17.8751V15.8373C3.20825 15.3883 3.33017 14.9725 3.574 14.59C3.81784 14.2076 4.14371 13.9136 4.55163 13.7079C5.4576 13.2637 6.37159 12.9304 7.29361 12.7083C8.21562 12.4862 9.1455 12.3751 10.0833 12.3751C10.405 12.3751 10.7268 12.3895 11.0485 12.4184C11.3701 12.4471 11.6918 12.4903 12.0135 12.5479C11.9877 13.3341 12.1467 14.0736 12.4904 14.7665C12.8342 15.4591 13.3228 16.037 13.9562 16.5001V17.8751H3.20825ZM17.2756 20.343L16.053 19.1252V15.3501C15.4555 15.1743 14.9678 14.8384 14.5898 14.3425C14.212 13.8466 14.0231 13.276 14.0231 12.6306C14.0231 11.8444 14.301 11.1734 14.8568 10.6176C15.4126 10.0618 16.0837 9.78391 16.87 9.78391C17.6562 9.78391 18.3258 10.0619 18.8789 10.6178C19.4318 11.174 19.7083 11.8453 19.7083 12.632C19.7083 13.2422 19.537 13.7823 19.1945 14.2524C18.8518 14.7225 18.4148 15.0604 17.8836 15.266L18.8972 16.2796L17.681 17.4988L18.8972 18.7177L17.2756 20.343ZM10.0833 10.8942C9.20096 10.8942 8.4457 10.5801 7.81748 9.95189C7.18911 9.32367 6.87492 8.56841 6.87492 7.68612C6.87492 6.80383 7.18911 6.04849 7.81748 5.42012C8.4457 4.79189 9.20096 4.47778 10.0833 4.47778C10.9655 4.47778 11.7208 4.79189 12.349 5.42012C12.9774 6.04849 13.2916 6.80383 13.2916 7.68612C13.2916 8.56841 12.9774 9.32367 12.349 9.95189C11.7208 10.5801 10.9655 10.8942 10.0833 10.8942ZM16.87 13.0362C17.0946 13.0362 17.2859 12.9557 17.4439 12.7947C17.602 12.6337 17.681 12.4409 17.681 12.2165C17.681 11.9921 17.602 11.8008 17.4439 11.6427C17.2859 11.4846 17.0946 11.4055 16.87 11.4055C16.6456 11.4055 16.4529 11.4846 16.2918 11.6427C16.1308 11.8008 16.0503 11.9921 16.0503 12.2165C16.0503 12.4409 16.1308 12.6337 16.2918 12.7947C16.4529 12.9557 16.6456 13.0362 16.87 13.0362Z"
                fill="#323338"
              />
            </g>
          </svg>
        ),
        scope: 'employee_access',
        submenu: [
          { title: 'Employee Data', path: '/employee-data',scope: 'employee_access.employee_date' },
          { title: 'Manage Salary', path: '/manage-salary',scope: 'employee_access.manage_salary' },
          { title: 'View Project', path: '/view-project' ,scope: 'employee_access.view_project'},
          { title: 'Permission', path: '/permission' ,scope: 'employee_access.Permissions'},
        ],
      },
    // Add more items if needed
  ];
  
  const MenuComponent = () => {
    return (
      <ul>
        {menuItems.map((item, index) => (
          <li className={`${item.submenu.length > 0 ? 'dropdown' : ''}`} key={index}>
            <PermissionHOC scope={item.scope}>
                <Link to={`${item.submenu.length > 0 ?"javascript:void(0)" : item?.path}`}>  
                <span className="menu-icon">{item.icon}</span>
                <span>{item.title}</span>
                </Link>
            </PermissionHOC>
  
            {item.submenu.length > 0 && (
              <ul className="sidebar-submenu">
                {item.submenu.map((subitem, subindex) => (
                  <li key={subindex}>
                    <PermissionHOC scope={subitem.scope}>
                      <Link to={subitem.path}>
                        <i className="ri-circle-fill circle-icon text-primary-600 w-auto" />
                        {subitem.title}
                      </Link>
                    </PermissionHOC>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    );
  };
  
  export default MenuComponent;
  