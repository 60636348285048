import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const clientSearchAPI = function (data) {
    const url = `${config.baseUrl}client/get`;
    return postRequest({
        url,
        data,
    });
};
export const getclientAPI = function (data) {
    const url = `${config.baseUrl}client/getclient`;
    return postRequest({
        url,
        data,
    });
};

export const clientCreateAPI = function (data) {
    const url = `${config.baseUrl}client/save`;
    return postRequest({
        url,
        data,
    });
};

export const clientUpdateAPI = function ({
    clientId,
    data,
}) {
    const url = `${config.baseUrl}client/update/${clientId}`;
    return putRequest({
        url,
        data,
    });
};
