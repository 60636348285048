import React, { useRef, useEffect, useState } from 'react';
import flatpickr from 'flatpickr';
import SelectSingalDate from './SelectSingalDate';

const AddTimestamp = () => {  
    return (
        <div>
            <div className="modal fade cusmodl LeaveDetailsModal" id="AddTimestampModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between border-0">
                            <h6 className='modal-title text-lg'>Add Time Stamp</h6>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button>
                        </div>
                        <div className="modal-body"> 
                            <div className="row">
                            <div className="col-sm-4">
                                <div className="h-100 d-flex justify-content-between flex-column">
                                    <label htmlFor="date" className="form-label">
                                    <div className="">Handover Date  <span className="text-danger-600">*</span></div>
                                    <small>Sales to Operate </small>
                                    </label>
                                   <SelectSingalDate />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="h-100 d-flex justify-content-between flex-column">
                                    <label htmlFor="date" className="form-label">
                                    <div className="">Kick Off Date  <span className="text-danger-600">*</span></div>
                                    
                                    </label>
                                   <SelectSingalDate />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="h-100 d-flex justify-content-between flex-column">
                                    <label htmlFor="date" className="form-label">
                                    <div className="">Expected Project 
                                    Closer Date <span className="text-danger-600">*</span></div> 
                                    </label>
                                   <SelectSingalDate />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddTimestamp;
