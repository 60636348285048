import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { CLIENT_PRODUCTION_ACTIONS } from '../actions/ClientProductionActions';
import { ClientProductionDefaultProps } from '../ClientProductionPropTypes';

const initialState = () => ({
    currentRecord: { ...ClientProductionDefaultProps },
    assignedProjects: [],
    assignedTasks: [],
    assignedModules: [],
    selectedClient: {clientId:null,ClientProjectCode:null},
    selectedProject: {projectId:null,ProjectSubCode:null},
    selectCategory: {categoryId:null,categoryName:null},
    selectSubCategory: {subCategoryId:null,subCategoryName:null},
    selectedMonth: new Date().getMonth() + 1,
    selectedYear: new Date().getFullYear(),
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },
    groupCounter:{
        totalIDLE:{
            total: '0h:0m',
            outOf: '0h:00m'
        },
        totalWorkingHour:{
            total: '0h:0m',
            outOf: '0h:00m'
        },
        totalAccepted:{
         total: '0',
         outOf: '0'
        },
        totalRejected:{
         total: '0',
         outOf: '0'
        },
        totalPending:{
         total: '0',
         outOf: '0'
        }
    },
    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                groupCounter: {
                    ...state.groupCounter, 
                    ...action.data.groupCounter[0]?.groupCounter
                },
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_CURRENT_CLIENT_PRODUCTION:{
            return {
                ...state,
                currentRecord: action.data,
            };
        }

        case CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_ASSIGNED_PROJECTS_SEARCH: {
            return {
                ...state,
                assignedProjects: action.data,
            };
        }
        case CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_TASK_BY_PROJECT_SEARCH: {
            return {
                ...state,
                assignedTasks: action.data,
            };
        }
        case CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_MODULE_BY_TASK_SEARCH: {
            return {
                ...state,
                assignedModules: action.data,
            };
        }
        case CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        case CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_ASSIGN_CATEGORY: {
            return {
                ...state,
                selectCategory: action.data,
                selectSubCategory: {subCategoryId:null,subCategoryName:null},
            };
        }
        case CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_ASSIGN_SUB_CATEGORY: {
            return {
                ...state,
                selectSubCategory: action.data,
            };
        }        
        case CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_RESET: {
            return {
                ...state,
                currentRecord: { ...ClientProductionDefaultProps },
            };
        }

        default:
            return state;
    }
};
