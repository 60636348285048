import { useState } from "react";
import DateRangePickerWithAll from "src/components/DateRangePickerWithAll"
import Highcharts from 'highcharts';
import moment from 'moment';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';


Highcharts3D(Highcharts);
const ProductionStatus = () => {
    const [dateRangeStatus, setDateRangeStatus] = useState([moment().subtract(29, 'days'), moment()]);
    const options3 = {
        chart: {
            type: 'pie',
            options3d: {
                enabled: true,
                alpha: 45
            }
        },
        title: {
            text: 'Production Status',
            align: 'left'
        },
        credits: {
            enabled: false // Disable credits
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        plotOptions: {
            pie: {
                innerSize: 100,
                depth: 45,
                allowPointSelect: true, // Allows slices to be selected
                cursor: 'pointer', // Changes cursor on hover
                showInLegend: true, // Ensure the pie slices appear in the legend
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y}', // Show name and value on the slices
                }
            }
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b> ({point.percentage:.1f}%)' // Custom tooltip format
        },
        legend: {
            layout: 'horizontal',
            align: 'right', // Align legend to the right
            verticalAlign: 'top', // Position legend at the top
            floating: true, // Make the legend float above the chart
            x: -10, // Adjust horizontal position
            y: 10 // Adjust vertical position
        },
        series: [
            {
                name: 'Status', // Series name for the tooltip and legend
                data: [
                    {
                        name: 'Accepted', // Label for the slice
                        y: 16,
                        color: '#22C55E', // Custom color for 'Accepted'
                    },
                    {
                        name: 'Rejected', // Label for the slice
                        y: 12,
                        color: '#EF4444' // Custom color for 'Rejected'
                    },
                    {
                        name: 'Pending', // Label for the slice
                        y: 8,
                        color: '#FFAC1F' // Custom color for 'Pending'
                    }
                ]
            }
        ]
    };
    return (
        <>
            <div className="col-md-6">
                <div className="card h-100 p-0">
                    <div className="card-body p-24">
                        <div className="ms-auto d-flex justify-content-end inwth"> 
                            <DateRangePickerWithAll value={dateRangeStatus} onChange={setDateRangeStatus} />
                        </div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options3}
                        />
                    </div>
                </div>
            </div>
        </>

    )
}
export default ProductionStatus