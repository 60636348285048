import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const EmployeeProductionPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    employeeName: PropTypes.string,
});

export const EmployeeProductionDefaultProps = { 
    employeeName: '',
    _id: undefined
};

export const EmployeeProductionYupSchema = Yup.object().shape({ 
    employeeName: Yup.string().required('Required'),
});

export default {
    EmployeeProductionDefaultProps,
    EmployeeProductionPropTypes,
    EmployeeProductionYupSchema,
};
