import React from 'react';
import { Routes, Route } from "react-router-dom";
import ProjectSearchContainer from './ProjectSearchContainer';
import ModuleContainer from './Module/ModuleContainer';
import TaskContainer from './Task/TaskContainer';
import ViewContainer from './View/ViewContainer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { projectEditAction } from './actions/ProjectActions';



const ProjectContainer = () => {
  const dispatch = useDispatch();
  const {currentRecord} = useSelector((state) => state.ProjectReducer, shallowEqual);
  const {_id:userId} = useSelector(state=>state.auth?.user,shallowEqual);
  if(!currentRecord?._id){
    const projectId = localStorage.getItem("projectId");
    dispatch(projectEditAction({_id:projectId,userId: userId}));
  }
  return (
    <>
      <Routes>
        <Route index element={<ProjectSearchContainer />} />
        <Route path="task/:projectId" element={<TaskContainer />} />
        <Route path="module/:projectId" element={<ModuleContainer />} />
        <Route path="view/*" element={<ViewContainer />} />
      </Routes>
    </>
  )
}

export default ProjectContainer