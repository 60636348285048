import React from 'react';

// Utility function to get initials
const getInitials = (name) => {
  if (!name) return '';
  const words = name.trim().split(' ');
  const firstNameInitial = words[0]?.charAt(0).toUpperCase();
  const lastNameInitial = words[1]?.charAt(0).toUpperCase();
  return `${firstNameInitial}${lastNameInitial || ''}`;
};

// Utility function to generate color from a string (e.g., client name)
const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, '0')}${((hash >> 16) & 0xff).toString(16).padStart(2, '0')}${((hash >> 8) & 0xff).toString(16).padStart(2, '0')}`;
  return color.slice(0, 7); // Ensure it's a valid hex color
};

const PaymentDetailsSummary = ({ paymentData, totalAmount, month }) => {
  return (
    <div>
      <div className="modal fade cusmodl" id="PaymentDetailsSummary" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex flex-column justify-content-between pb-0 border-0 gap-3">
              <div className="d-flex align-items-center justify-content-between w-100">
                <h6 className="mb-2 fw-bold text-lg mb-0">Payment Details Summary</h6>
                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button>
              </div>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="text-md">Total Amount {month}</div>
                <div className="fw-bold text-xl">₹{totalAmount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
              </div>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table basic-border-table mb-0 table-hover">
                  <thead className="sticky-header">
                    <tr>
                      <th scope="col">Sr.</th>
                      <th scope="col">Project</th>
                      <th scope="col">Client Name 1</th>
                      <th scope="col">Client Name 2</th>
                      <th scope="col" className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentData.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{row.project}</td>
                        <td>
                            <div className="d-flex align-items-center gap-0">
                          {row.clientImage1 ? (
                            <img src={row.clientImage1} alt={row.clientName1} className="client-image" />
                          ) : (
                            <span className="client-initials img imgsSet" style={{ backgroundColor: stringToColor(row.clientName1), color: '#fff' }}>
                              {getInitials(row.clientName1)}
                            </span>
                          )}
                        
                          {row.clientName1}  </div>
                        </td>
                        <td>
                            <div className="d-flex align-items-center gap-0">
                          {row.clientImage2 ? (
                            <img src={row.clientImage2} alt={row.clientName2} className="client-image" />
                          ) : (
                            <span className="client-initials img imgsSet" style={{ backgroundColor: stringToColor(row.clientName2), color: '#fff' }}>
                              {getInitials(row.clientName2)}
                            </span>
                          )}
                          {row.clientName2} </div>
                        </td>
                        <td className={`text-end ${parseFloat(row.amount) < 0 ? 'text-red' : 'text-dblue'} fw-semibold`}>
                          ₹{parseFloat(row.amount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailsSummary;
