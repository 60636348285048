/* eslint-disable react/forbid-prop-types */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { hasPermission } from 'src/utils/CommonUtils';

const PermissionHOC = ({
    scope,
    children,
    fallback,
    component,
}) => {
    const {
        userScopes,
    } = useSelector((state) => state.auth.user, shallowEqual);

    const hasRight = useMemo(() => hasPermission({
        scope,
        scopeList: userScopes,
    }), [
        userScopes,
        scope,
    ]);

    if (!hasRight) {
        return fallback;
    }

    return component || children;
};

PermissionHOC.propTypes = {
    scope: PropTypes.string.isRequired,
    fallback: PropTypes.object,
    component: PropTypes.any,
    children: PropTypes.any,
};

PermissionHOC.defaultProps = {
    fallback: null,
    component: null,
    children: null,
};

export default PermissionHOC;
