import React, { useState } from 'react';
import InfoCard from './InfoCard';
import InvoiceTable from './InvoiceTable';
import MonthPicker from './MonthPicker';
import SearchInput from './SearchInput';
import InvoiceButtons from './InvoiceButtons';
import SummaryInvoiceTable from './SummaryInvoiceTable';

const CancelledInvoice = () => {
  const cardData = [
    {
        title: 'Total Cancelled Invoice ',
        totalAmount: -35737415.35, // Sum of the above two
        dueAmount: 27799237.85,   // Sum of the above two
        nonDueAmount: 7938177.50, // Sum of the above two
        dueTransactions: 103,     // Sum of the above two
        nonDueTransactions: 51,   // Sum of the above two
      },
    {
      title: 'Government',
      totalAmount: 20737415.35,
      dueAmount: 15799237.85,
      nonDueAmount: 4938177.50,
      totalTransactions: 87,
      dueTransactions: 58,
      nonDueTransactions: 29,
    },
    {
      title: 'Non-Governmental',
      totalAmount: 15000000.00,
      dueAmount: 12000000.00,
      nonDueAmount: 3000000.00,
      totalTransactions: 67,
      dueTransactions: 45,
      nonDueTransactions: 22,
    }, 
  ];

  const sampleData = [
    {
      invoiceNumber: 'Acx56724-25348',
      customerName: 'Natrajk (Baghu)',
      taxableAmount: '₹0',
      gstAmount: '₹0',
      totalAmount: '₹61740.00',
      invoiceDate: '16 Oct 2024',
      cancelledDate: '19 Oct 2024',
      raisedBy: 'Amrik Singh',
      dueDays: '2 Days',
      rollbacked: 'No',
      notes: 'PMDAKSH/Singrauli/OBC/11/2023 dated 08Th Dec 2023',
    },
    {
      invoiceNumber: 'Acx56724-25349',
      customerName: 'Natrajk (Baghu)',
      taxableAmount: '₹0',
      gstAmount: '₹0',
      totalAmount: '₹61740.00',
      invoiceDate: '16 Oct 2024',
      cancelledDate: '19 Oct 2024',
      raisedBy: 'Amrik Singh',
      dueDays: '2 Days',
      rollbacked: 'No',
      notes: 'PMDAKSH/Singrauli/OBC/11/2023 dated 08Th Dec 2023',
    },
    // Add more rows as needed...
  ];

  // Initialize activeTab with 0 to make the first tab active by default
  const [activeTab, setActiveTab] = useState(0);

  const handleCardClick = (index) => {
    setActiveTab(index === activeTab ? null : index); // Toggle the same tab or set a new one
  };


  const handleChangeDate = ()=>{
    
  }


  const initialColumns = [
    { id: 'srNo', label: 'Sr. No.', visible: true },
    { id: 'invoiceNumber', label: 'Invoice Number', visible: true },
    { id: 'customerName', label: 'Customer Name', visible: true },
    { id: 'taxableAmount', label: 'Taxable Amount', visible: true },
    { id: 'gstAmount', label: 'GST Amount', visible: true },
    { id: 'totalAmount', label: 'Total Amount', visible: true },
    { id: 'invoiceDate', label: 'Invoice Date', visible: true },
    { id: 'cancelledDate', label: 'Cancelled Date', visible: true },
    { id: 'raisedBy', label: 'Raised', visible: false },
    //{ id: 'dueDays', label: 'Payable Days', visible: true },
    //{ id: 'rollbacked', label: 'Rollbacked', visible: false },
    { id: 'action', label: 'Action', visible: false },
    { id: 'notes', label: 'Notes', visible: false }
];


const [showSummaryTable, setShowSummaryTable] = useState(false);

    const handleSummaryClick = () => {
        setShowSummaryTable(true);
    };

  return (
    <div className="card h-100">
      <div className="card-body p-24">
        <div className="border-bottom mb-24 pb-16">
        <div className="d-flex justify-content-between align-items-center">
        <div className="">
          <h2 className="fw-semibold mb-16 font-24">
            <button>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <g data-name="20-Arrow Left">
                  <path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0z" />
                  <path d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z" />
                </g>
              </svg>
            </button>{" "}
            Invoice
          </h2>
          <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="/"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Home
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Cancelled Invoice</li>
              </ul>
              </div>
              <SearchInput />
              </div>
              <div className="col-md-12 d-flex justify-content-between align-items-center pt-24"> 
                     <InvoiceButtons />
        <div className="d-flex flex-wrap align-items-center gap-4 justify-content-end my-0 py-0 pt100">
                
                <MonthPicker handleChangeDate={handleChangeDate} />
              </div>
              </div>
        </div>

        <div className="row"> 
          <div className="col-md-12 despset">
            {/* Rendering InfoCards with click behavior to toggle the accordion */}
            <div className="d-flex align-items-center justify-content-between gap-4 setbreaddrs paidInvoice">
              {cardData.map((card, index) => (
                <InfoCard
                  key={index}
                  title={card.title}
                  totalAmount={card.totalAmount}
                  dueAmount={card.dueAmount}
                  nonDueAmount={card.nonDueAmount}
                  totalTransactions={card.totalTransactions}
                  dueTransactions={card.dueTransactions}
                  nonDueTransactions={card.nonDueTransactions}
                  onClick={() => handleCardClick(index)}
                  isActive={activeTab === index} // Pass active state to style it differently
                />
              ))}
            </div>

            {/* Conditionally rendering the content (InvoiceTable) based on active tab */}
            {activeTab === 0 && (
              <div className="col-md-12 mt-24 Government"> 
                <InvoiceTable data={sampleData} tableTitle="Total Cancelled Invoice" initialColumns={initialColumns} />
              </div>
            )}

            {activeTab === 1 && (
              <div className="col-md-12 mt-24 Non-Governmental">
                
                <InvoiceTable data={sampleData} tableTitle="Government" initialColumns={initialColumns} />
              </div>
            )}

            {activeTab === 2 && (
              <div className="col-md-12 mt-24 total"> 
                <InvoiceTable data={sampleData} tableTitle="Non-Governmental" initialColumns={initialColumns} />
              </div>
            )} 
          </div>
           
          <div className="col-md-12 despset"></div>
          <div className="col-md-12 SummaryInvoiceTable d-none"> 
           <SummaryInvoiceTable />  
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelledInvoice
