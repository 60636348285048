import React, { useCallback, useEffect, useState } from "react"; 
import { useNavigate } from "react-router-dom";   
import { Helmet } from "react-helmet";
import config from "src/config";
import { useDispatch } from "react-redux";
import { useNotification } from "src/components/Notifination/ToastNotification";
import { LoginDefaultProps, LoginYupSchema } from "./LoginPropTypes";
import { Formik, Form } from 'formik';
import FormikInput from 'src/components/formik/FormikInput';
import { includeStamp } from 'src/utils/StampUtils';
import { handleLogin } from "src/api/AuthAPI";
import { fetchUserScopesAction, loginSuccess } from "../home-container/actions/authAction";
import { setDefaultHeaders } from "src/utils/RequestUtil";


const Render =(props)=>{
     return (
        <>
        <Form autoComplete="disabled">
                <div class="icon-field mb-16">
                    <span class="icon top-50 translate-middle-y">
                        <iconify-icon icon="mage:email"></iconify-icon>
                    </span>
                    {/* <input type="email" class="form-control h-56-px bg-neutral-50 radius-12" placeholder="Email"/> */}
                        <FormikInput
                        name="username"
                        placeholder="Employee ID"
                        class="form-control h-56-px bg-neutral-50 radius-12"
                        /> 
                </div>
                <div class="position-relative mb-20">
                    <div class="icon-field">
                        <span class="icon top-50 translate-middle-y">
                            <iconify-icon icon="solar:lock-password-outline"></iconify-icon>
                        </span> 
                        {/* <input type="password" class="form-control h-56-px bg-neutral-50 radius-12" id="your-password" placeholder="Password"/> */}
                        <FormikInput
                          id="your-password"
                          name="password"
                          placeholder="Password"
                          class="form-control h-56-px bg-neutral-50 radius-12"
                        />
                    </div>
                    <span class="toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light" data-toggle="#your-password"></span>
                </div>
                <div class="">
                    <div class="d-flex justify-content-between gap-2">
                        <div class="form-check style-check d-flex align-items-center">
                            {/* <input class="form-check-input border border-neutral-300" type="checkbox" value="" id="remeber"/> */}
                                <FormikInput
                                id="remeber"
                                type="checkbox" 
                                name="departmentName"
                                placeholder="Enter the department name here."
                                class="form-check-input border border-neutral-300"
                                /> 
                            <label class="form-check-label" for="remember">Remember me </label>
                        </div>
                        {/* <a href="javascript:void(0)" class="text-primary-600 fw-medium">Forgot Password?</a> */}
                    </div>
                </div> 
                <button type="submit" class="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"> Sign In</button>  
                </Form>
        </>
     )
}
let setSubmitting = () => { };
const Login = () => { 
  const { openSuccessNotification, openErrorNotification } = useNotification();
  const navigate = useNavigate();  
  const dispatch = useDispatch();
  const token  = localStorage.getItem("token");
  if(token) navigate('/', { replace: true });
  const onSubmit = useCallback(async(
    values,
    formikUtils,
) => {
    ({ setSubmitting } = formikUtils);     
    // includeStamp(values); 
    // console.log('values=>',values);
    // dispatch(departmentUpsertAction(values));
    const { data } = await handleLogin(values);
    const { token, user } = data;
    if (token) {
      localStorage.setItem("user", JSON.stringify({ ...user }));
      localStorage.setItem("token", JSON.stringify(token)); 
      localStorage.setItem("userData", JSON.stringify(values)); 
      dispatch(loginSuccess({ ...user, jwtToken: token }));
      dispatch(fetchUserScopesAction({ userId:user._id,roleId:user?.roleDetails?._id }));
      setDefaultHeaders({
        Authorization: token,
       });
      navigate("/", { replace: true });
      openSuccessNotification("Welcome! Logged In Successfully.");
    }
    else{
      navigate("/login");
      openErrorNotification("Login failed. Please check your credentials and try again.");
    }
}, [
    dispatch,
]);

  return (
    <>
    <Helmet>
    <section>
      <title>index - </title>
      <link href="assets/css/style-custom.css" rel="stylesheet" />
      <link href="assets/css/assets/css/style.css" rel="stylesheet" />
      <link href="assets/css/dragndrop.table.columns.css" rel="stylesheet" />
    </section>
  </Helmet> 
      <section class="auth bg-base d-flex flex-wrap">  
    <div class="auth-left d-lg-block d-none">
        <div class="d-flex align-items-center flex-column h-100 justify-content-center">
            <img src="assets/images/auth-img.png" alt=""/>
        </div>
    </div>
    <div class="auth-right py-32 px-24 d-flex flex-column justify-content-center">
        <div class="max-w-464-px mx-auto w-100">
            <div>
                <a href="/" class="mb-40 max-w-290-px">
                   <img src="assets/images/left-sidebar/logo.svg" alt="site logo" class="light-logo"/>
                </a>
                <h4 class="mb-12">Sign In to your Account</h4>
                <p class="mb-32 text-secondary-light text-lg">Welcome back! please enter your detail</p>
            </div>
            <Formik
            onSubmit={onSubmit} 
            enableReinitialize
            initialValues={LoginDefaultProps}
            validationSchema={LoginYupSchema}
            >
            {(formikProps) => (
                <Render 
                    {...formikProps}
                />
            )}
   </Formik>


        </div>
    </div>
</section>
      </>
 
  );
};


export default Login;
