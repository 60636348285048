import React, { useState } from 'react';
import SearchInput from 'src/components/SearchInput';
import DatePickerComponent from 'src/components/DatePickerComponent';
import Select2Component from 'src/components/Select2Component';
import FeedbackEmail from './FeedbackEmail';
import FeedbackMailchain from './FeedbackMailchain';
import FeedbackExcludedDomain from './FeedbackExcludedDomain';


const FeedbackContainer = () => {

  const tableDocumentation = [
    {
      line: 'Content Development|Data Visualisation	',
      recvto: 'Danish_& Faizal <ui@acadecraft.com>',
      assignproject: 'Project 001 -A',
      assignedby: 'Ahmad Monish',
    },
    {
      line: 'Content Development|Data Visualisation	',
      recvto: 'Danish_& Faizal <ui@acadecraft.com>',
      assignproject: 'Project 001 -A',
      assignedby: 'Ahmad Monish',
    },
    {
      line: 'Content Development|Data Visualisation	',
      recvto: 'Danish_& Faizal <ui@acadecraft.com>',
      assignproject: 'Project 001 -A',
      assignedby: 'Ahmad Monish',
    },
    {
      line: 'Content Development|Data Visualisation	',
      recvto: 'Danish_& Faizal <ui@acadecraft.com>',
      assignproject: 'Project 001 -A',
      assignedby: 'Ahmad Monish',
    }
  ];



  const handleDateRangeChange = (selectedDates) => {

  }



  const [selectedValue, setSelectedValue] = useState(null);

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    console.log('Selected Value:', value);
  };

  const options = [
    { value: '001', label: 'Project 001 -A' },
    { value: '002', label: 'Project 001 -B' },
    { value: '003', label: 'Project 001 -C' }
  ];




  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">

            <div class="card-body p-24 pt-10">
              <div className="d-flex justify-content-between align-items-center border-bottom  pb-16">
                <div className="">
                  <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                    Feedback LMS
                  </h2>
                  <ul className="d-flex align-items-center gap-2">
                    <li className="fw-medium">
                      <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                        <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                        Special Access
                      </a>
                    </li>
                    <li>-</li>
                    <li className="fw-medium">Feedback LMS</li>
                  </ul>
                </div>
              </div>

              <div className="d-flex gap-2 align-items-center py-16 mt-16">
                <SearchInput />
                <DatePickerComponent
                  onDateRangeChange={handleDateRangeChange}
                />
              </div>


              <ul class="nav focus-tab nav-pills mb-16 mt-24" id="pills-tab-two" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link fw-semibold text-primary-light radius-4 px-16 py-10 active" id="pills-focus-home-tab" data-bs-toggle="pill" data-bs-target="#pills-focus-home" type="button" role="tab" aria-controls="pills-focus-home" aria-selected="true">Mail Chain</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link fw-semibold text-primary-light radius-4 px-16 py-10" id="pills-focus-details-tab" data-bs-toggle="pill" data-bs-target="#pills-focus-details" type="button" role="tab" aria-controls="pills-focus-details" aria-selected="false" tabindex="-1">Email </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link fw-semibold text-primary-light radius-4 px-16 py-10" id="pills-focus-Domains-tab" data-bs-toggle="pill" data-bs-target="#pills-focus-Domains" type="button" role="tab" aria-controls="pills-focus-Domains" aria-selected="false" tabindex="-1">Excluded Domains </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tab-twoContent">
                <div class="tab-pane fade active show" id="pills-focus-home" role="tabpanel" aria-labelledby="pills-focus-home-tab" tabindex="0">
                  <div>
                    <div className="table-responsive table-container mt-24">
                      <table className="table basic-border-table mb-0 table-hover">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Mail Subject Line	</th>
                            <th>Mail rcvd to	</th>
                            <th className='text-center'>Assign to Project	</th>
                            <th className='text-center'>Assigned by	</th>
                            <th className='text-center'>View</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableDocumentation.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td><div className="tsknem">{item.line}</div></td>
                              <td>{item.recvto}</td>
                              {/* <td className='text-center'>{item.assignproject}</td> */}
                              <td className='text-center'><div className="sct2drpdn">
                                <Select2Component
                                  options={options}
                                  placeholder="Select an option"
                                  onChange={handleSelectChange}
                                />
                                {/* {selectedValue && <p>Selected: {selectedValue}</p>} */}
                              </div></td>
                              <td className='text-center'>{item.assignedby}</td>
                              <td className='text-center'><button className="btn btn-primary text-lg px-16 py-4" data-bs-toggle="modal" data-bs-target="#ViewdescriptionModual"><i class="mdi mdi-eye"></i></button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="">
                      {/* <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center mt-24">
                        <li className="page-item">
                          <a
                            className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px"
                            href="javascript:void(0)"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px w-48-px"
                            href="javascript:void(0)"
                          >
                            <iconify-icon icon="ep:d-arrow-left" className="text-xl" />
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px w-48-px"
                            href="javascript:void(0)"
                          >
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px w-48-px border-primary-400 text-primary-600"
                            href="javascript:void(0)"
                          >
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px w-48-px"
                            href="javascript:void(0)"
                          >
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px w-48-px"
                            href="javascript:void(0)"
                          >
                            {" "}
                            <iconify-icon icon="ep:d-arrow-right" className="text-xl" />{" "}
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px"
                            href="javascript:void(0)"
                          >
                            Next
                          </a>
                        </li>
                      </ul> */}

                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="pills-focus-details" role="tabpanel" aria-labelledby="pills-focus-details-tab" tabindex="0">
                  <FeedbackEmail />
                </div>
                <div class="tab-pane fade" id="pills-focus-Domains" role="tabpanel" aria-labelledby="pills-focus-Domains-tab" tabindex="0">
                  <FeedbackExcludedDomain />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeedbackMailchain />
    </>
  )
}

export default FeedbackContainer