import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { ProductionDefaultProps } from 'src/containers/production-container/ProductionPropTypes';
import { PRODUCTION_ACTIONS } from '../actions/ProductionActions';

const initialState = () => ({
    currentRecord: { ...ProductionDefaultProps },
    assignedProjects: [],
    assignedTasks: [],
    assignedModules: [],
    selectedClient: {clientId:null,ClientProjectCode:null},
    selectedProject: {projectId:null,ProjectSubCode:null},
    selectTask: {taskId:null,taskName:null,target:0,targetUnit:"min"},
    selectModule: {moduleId:null,moduleName:null},
    selectedMonth: new Date().getMonth() + 1,
    selectedYear: new Date().getFullYear(),
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },
    HubstaffResponse: [],
    groupCounter:{
        totalIDLE:{
            total: '0h:0m',
            outOf: '0h:00m'
        },
        totalWorkingHour:{
            total: '0h:0m',
            outOf: '0h:00m'
        },
        totalAccepted:{
         total: '0',
         outOf: '0'
        },
        totalRejected:{
         total: '0',
         outOf: '0'
        },
        totalPending:{
         total: '0',
         outOf: '0'
        }
    },
    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case PRODUCTION_ACTIONS.PRODUCTION_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case PRODUCTION_ACTIONS.PRODUCTION_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                HubstaffResponse: action.data.HubstaffResponse,
                groupCounter: {
                    ...state.groupCounter, 
                    ...action.data.groupCounter[0]?.groupCounter
                },
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case PRODUCTION_ACTIONS.PRODUCTION_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case PRODUCTION_ACTIONS.PRODUCTION_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case PRODUCTION_ACTIONS.PRODUCTION_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case PRODUCTION_ACTIONS.PRODUCTION_CURRENT_PRODUCTION:{
            return {
                ...state,
                currentRecord: action.data,
            };
        }

        case PRODUCTION_ACTIONS.PRODUCTION_ASSIGNED_PROJECTS_SEARCH: {
            return {
                ...state,
                assignedProjects: action.data,
            };
        }
        case PRODUCTION_ACTIONS.PRODUCTION_TASK_BY_PROJECT_SEARCH: {
            return {
                ...state,
                assignedTasks: action.data,
            };
        }
        case PRODUCTION_ACTIONS.PRODUCTION_MODULE_BY_TASK_SEARCH: {
            return {
                ...state,
                assignedModules: action.data,
            };
        }
        case PRODUCTION_ACTIONS.PRODUCTION_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        case PRODUCTION_ACTIONS.PRODUCTION_ASSIGN_CLIENT: {
            return {
                ...state,
                selectedClient: action.data,
                selectedProject: {projectId:null,ProjectSubCode:null},
                selectTask: {taskId:null,taskName:null,item:"open",target:0,targetUnit:"min"},
                selectModule: {moduleId:null,moduleName:null},
            };
        }
        case PRODUCTION_ACTIONS.PRODUCTION_ASSIGN_PROJECT: {
            return {
                ...state,
                selectedProject: action.data,
                selectTask: {taskId:null,taskName:null,item:"open",target:0,targetUnit:"min"},
                selectModule: {moduleId:null,moduleName:null},
            };
        }
        case PRODUCTION_ACTIONS.PRODUCTION_ASSIGN_TASK: {
            return {
                ...state,
                selectTask: action.data,
                selectModule: {moduleId:null,moduleName:null},
            };
        }
        case PRODUCTION_ACTIONS.PRODUCTION_ASSIGN_MODULE: {
            return {
                ...state,
                selectModule: action.data,
            };
        }

        
        case PRODUCTION_ACTIONS.PRODUCTION_RESET: {
            return {
                ...state,
                selectedClient: {clientId:null,ClientProjectCode:null},
                selectedProject: {projectId:null,ProjectSubCode:null},
                selectTask: {taskId:null,taskName:null,item:"open",target:0,targetUnit:"min"},
                selectModule: {moduleId:null,moduleName:null},
                currentRecord: { ...ProductionDefaultProps },
            };
        }
        case PRODUCTION_ACTIONS.CHANGE_DATE: {
            return {
                ...state,
                selectedMonth: action.data.selectedMonth,
                selectedYear: action.data.selectedYear
            };
        }

        default:
            return state;
    }
};
