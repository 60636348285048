import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { CLIENT_ACTIONS } from '../actions/ClientActions';
import { ClientDefaultProps } from '../ClientPropTypes';

const initialState = () => ({
    currentRecord: { ...ClientDefaultProps },
    currentProjectAssignee: [],
    usersForProjectAssign: [],
    currentSelectedProjectId: null,
    projectCode: '',
    projectSub: '',
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case CLIENT_ACTIONS.CLIENT_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case CLIENT_ACTIONS.CLIENT_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case CLIENT_ACTIONS.CLIENT_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        case CLIENT_ACTIONS.SET_PROJECT_ASSIGN: {
            return {
                ...state,
                currentProjectAssignee: action.data,
            };
        }
        // Project Assign Search 
        case CLIENT_ACTIONS.PROJECT_ASSIGN_LOAD: {
            return {
                ...state,
                currentSelectedProjectId: action.projectId,
                projectCode: action.projectCode,
                projectSub: action.projectSub,
                usersForProjectAssign: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }

        case CLIENT_ACTIONS.CLIENT_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case CLIENT_ACTIONS.CLIENT_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case CLIENT_ACTIONS.CLIENT_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case CLIENT_ACTIONS.CLIENT_RESET: {
            return {
                ...state,
                currentRecord: { ...ClientDefaultProps },
            };
        }

        default:
            return state;
    }
};
