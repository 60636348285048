import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const ModulePropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    ModuleName: PropTypes.string,
});

export const ModuleDefaultProps = { 
    ModuleName: '',
    _id: undefined
};

export const ModuleYupSchema = Yup.object().shape({ 
    ModuleName: Yup.string().required('Required'),
});

export default {
    ModuleDefaultProps,
    ModulePropTypes,
    ModuleYupSchema,
};
