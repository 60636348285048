import { put, call } from 'redux-saga/effects';
import { clientCreateAPI,clientSearchAPI,clientUpdateAPI } from 'src/api/ClientApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { CLIENT_ACTIONS } from '../actions/ClientActions';
import { toast } from 'react-toastify';
import { getProjectAssigneeAPI } from 'src/api/ProjectApi';


function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(clientSearchAPI, {
            where: buildSearchWithNoCase({
                where,
                fullTextSearch,
            }),
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: CLIENT_ACTIONS.CLIENT_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: CLIENT_ACTIONS.CLIENT_SEARCH_ERROR,
        });
        return null;
    }
}
function* projectAssigneeSearch({
    where, currentPage = 0, autopopulate = true} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(clientSearchAPI, {
            where,
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: CLIENT_ACTIONS.CLIENT_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: CLIENT_ACTIONS.CLIENT_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({
    _id,
}) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(clientSearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {
            
            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }

        
        yield put({
            type: CLIENT_ACTIONS.CLIENT_UPSERT_SUCCESS,
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(clientCreateAPI, payload);

        yield put(headerShowAction({
            data: 'Created',
        })); 
        toast("Client Created")
        
        yield put({
            type: CLIENT_ACTIONS.CLIENT_UPSERT_SUCCESS,
            data: {
                ...payload,
                _id: data._id,
            },
        });
        yield put({
            type: CLIENT_ACTIONS.CLIENT_SEARCH
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: CLIENT_ACTIONS.CLIENT_UPSERT_ERROR,
        });
    }
}

function* update(clientId, payload) {
    try {
        yield call(clientUpdateAPI, {
            clientId,
            data: payload,
        });

        yield put(headerShowAction({
            data: 'Updated',
        }));
        if(payload.deleted===true){
            toast("Client deleted")
        }
        else{
            toast("Client updated")
        } 
        
        yield put({
            type: CLIENT_ACTIONS.CLIENT_SEARCH
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: CLIENT_ACTIONS.CLIENT_UPSERT_ERROR,
        });
    }
}

function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        yield insert(payload);
    }
}

export default [
    takeFirstSagaUtil(CLIENT_ACTIONS.CLIENT_SEARCH, search),
    takeFirstSagaUtil(CLIENT_ACTIONS.CLIENT_UPSERT, upsert),
    takeFirstSagaUtil(CLIENT_ACTIONS.CLIENT_EDIT, edit),
    // takeFirstSagaUtil(CLIENT_ACTIONS.PROJECT_ASSIGN_SEARCH, projectAssigneeSearch),
];
