import PropTypes from 'prop-types';
import React from 'react';
import SelectSearch from 'react-select-search';
import { get, isEqual, throttle } from 'lodash-es';
import './FormikSearchDropdown.css';
import { Field, ErrorMessage } from 'formik';

class Render extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            options: [],
        };

        this.throttledGetOptions = throttle(this.getOptions, 1000);
        this.selectSearchRef = React.createRef(); // Create ref to target the input
    }

    componentDidMount() {
        this.getDefaultOption();
        this.addCustomClassToInput(); // Add the class after the component is mounted
    }

    componentDidUpdate(prevProps) {
        const { value, whereConditions, form, field } = this.props;

        if (prevProps.value !== value) {
            this.getDefaultOption();
        } else if (!isEqual(prevProps.whereConditions, whereConditions)) {
            form.setFieldValue(field.name, '');
            this.setState({ options: [] });
        }

        this.addCustomClassToInput(); // Ensure class is added when component updates
    }

    addCustomClassToInput = () => {
        // Use the ref to find the input and add the custom class
        if (this.selectSearchRef.current) {
            const input = this.selectSearchRef.current.querySelector('.select-search__input');
            if (input) {
                input.classList.add('form-control'); // Add your custom class here
            }
        }
    };

    getOptionsByWhereClause = async ({ where }) => {
        const { displayKeyPath, valueKeyPath, apiCallAddonData, api } = this.props;

        try {
            const select = displayKeyPath.reduce((total, current) => {
                total[current] = 1;
                return total;
            }, {});

            const { data: { data } } = await api({ where, select, ...apiCallAddonData });
            const optionsData = data.map(item => ({
                name: displayKeyPath.map(path => get(item, path)).filter(x => !!x).join(', '),
                value: get(item, valueKeyPath),
            }));

            this.setState({ options: optionsData });
        } catch (error) {
            console.error('LOG: getOptions -> error', error);
        }
    };

    getDefaultOption = async () => {
        const { defaultValueFetchByKeyPath, value } = this.props;
        const { options } = this.state;

        if (!value || !defaultValueFetchByKeyPath) return;

        const optionHasOptionForSelectedValue = !!options.find(option => !!get(option, defaultValueFetchByKeyPath, false));

        if (!optionHasOptionForSelectedValue) {
            try {
                await this.getOptionsByWhereClause({
                    where: { [defaultValueFetchByKeyPath]: value },
                });
            } catch (error) {
                console.error('LOG: getOptions -> error', error);
            }
        }
    };

    getOptions = async (query) => {
        const { whereClauseKeysPaths, whereConditions } = this.props;

        const whereWithOrCondition = { $or: [], ...whereConditions };

        whereClauseKeysPaths.forEach((keyPath) => {
            const condition = {};
            condition[keyPath] = { $regex: query, $options: 'i' };
            whereWithOrCondition.$or.push(condition);
        });

        try {
            await this.getOptionsByWhereClause({ where: whereWithOrCondition });
        } catch (error) {
            console.error('LOG: getOptions -> error', error);
        }
    };

    render() {
        const { value, placeholder, disabled, onChange, field } = this.props;
        const { options } = this.state;

        return (
            <div ref={this.selectSearchRef}> {/* Add the ref to the container */}
                <SelectSearch
                    className="select-search"
                    name={field.name}
                    disabled={disabled}
                    options={Array.isArray(options) ? options : []}
                    getOptions={this.throttledGetOptions}
                    onChange={selectedValue => {
                        onChange(selectedValue);
                    }}
                    value={value || ''}
                    placeholder={placeholder}
                    search
                    autoComplete="disabled"
                />
                <ErrorMessage component="div" className="text-danger" name={field.name} />
            </div>
        );
    }
}

Render.propTypes = {
    api: PropTypes.func.isRequired,
    apiCallAddonData: PropTypes.any,
    defaultValueFetchByKeyPath: PropTypes.string,
    disabled: PropTypes.bool,
    displayKeyPath: PropTypes.array.isRequired,
    field: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
    form: PropTypes.shape({
        setFieldValue: PropTypes.func,
    }).isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    valueKeyPath: PropTypes.string.isRequired,
    whereClauseKeysPaths: PropTypes.array,
    whereConditions: PropTypes.any,
    onChange: PropTypes.func,
};

Render.defaultProps = {
    apiCallAddonData: {},
    defaultValueFetchByKeyPath: '',
    disabled: false,
    placeholder: '',
    whereClauseKeysPaths: [],
    whereConditions: {},
    onChange: () => {},
    value: '',
};

const FormikSearchDropdown = (props) => (
    <Field
        {...props}
        component={Render}
    />
);

export default FormikSearchDropdown;
