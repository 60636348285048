import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import OrangeCategory from './OrangeCategory';

const UCLTopScorersChart = ({ week }) => {
  const options = {
    chart: {
      type: 'bar',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return `<strong>${this.series.name}</strong>: ${this.y}%`; // Show series name and value with percentage
      },
    },
    xAxis: {
      categories: [
        'Saurav Bhaduri',
        'Anubhav Saxena',
        'Amit Goyal',
        'Pushpender Kumar',
        'Sanjana Agrawal',
        'Danish Farhan',
        'Md Husnain',
        'Shubham Singh Tomar',
        'Harshita Gupta',
      ],
      labels: {
        useHTML: true, // Enable HTML for custom label content
        align: 'right', // Right align the labels
        formatter: function () {
          const images = {
            'Saurav Bhaduri': '/assets/images/users/user1.png',
            'Anubhav Saxena': '/assets/images/users/user1.png',
            'Amit Goyal': '/assets/images/users/user1.png',
            'Pushpender Kumar': '/assets/images/users/user1.png',
            'Sanjana Agrawal': '/assets/images/users/user1.png',
            'Danish Farhan': '/assets/images/users/user1.png',
            'Md Husnain': '/assets/images/users/user1.png',
            'Shubham Singh Tomar': '/assets/images/users/user1.png',
            'Harshita Gupta': '/assets/images/users/user1.png',
          };
          const label = this.value;
          const imgUrl = images[label];

          // Return HTML for the label with the image, right-aligned
          return `
            <div style="text-align: right;">
              ${label}
              <img src="${imgUrl}" style="width:20px;height:20px;vertical-align:middle;margin-left:5px;border-radius:50%;" alt="${label}" />
            </div>
          `;
        },
      },
    },
    yAxis: {
      min: 0,
      max: 100, // Set maximum for percentage scale
      title: {
        text: '',
      },
      opposite: true,
      labels: {
        formatter: function () {
          return this.value + '%'; // Append '%' to Y-axis labels
        },
      },
      tickPositions: [0, 25, 50, 75, 100], // Custom tick positions
    },
    legend: {
      enabled: false, // Disable the legend
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        borderRadius: 15,
        dataLabels: {
          enabled: false, // Disable data labels
        },
        point: {
          events: {
            click: function () {
              if (this.series.name === 'Orange') {
                handleOrangeClick();
              }
              if (this.series.name === 'Green') {
                handleOrangeClick();
              }
              if (this.series.name === 'Red') {
                handleOrangeClick();
              }
            },
          },
        },
      },
    },
    series: [
      {
        name: 'Green',
        data: [25, 30, 35, 40, 60, 65, 75, 80, 90],
        color: '#02BC7D',
      },
      {
        name: 'Orange',
        data: [15, 15, 15, 15, 15, 15, 15, 15, 5],
        color: '#FE6A35',
      },
      {
        name: 'Red',
        data: [5, 5, 5, 5, 5, 5, 5, 5, 5],
        color: '#EE0B0B',
      },
    ],
  };

  const handleOrangeClick = () => {
    // Show the modal when the Orange category is clicked
    const modal = new window.bootstrap.Modal(
      document.getElementById('categoryModual')
    );
    modal.show();
  };

  return (
    <>
      <figure className="highcharts-figure">
        <HighchartsReact highcharts={Highcharts} options={options} />
        <div className="text-center mt-16">
          <span className="rounded-pill bg-light-100 text-dark fw-bold radius-8 px-20 py-11">
            {week}
          </span>
        </div>
      </figure>

      <OrangeCategory />
    </>
  );
};

export default UCLTopScorersChart;
