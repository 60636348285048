import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { EmployeeProductionDefaultProps } from 'src/containers/employee-container/Production/EmployeeProductionPropTypes';
import { PERFOMANCE_ACTIONS } from '../actions/PerfomanceActions';


const initialState = () => ({
    currentRecord: { ...EmployeeProductionDefaultProps },

    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },
    projectWiseWhere:{},
    taskWiseWhere:{},

    selectedProject: null,
    selectedModule: null,

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case PERFOMANCE_ACTIONS.PERFOMANCE_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case PERFOMANCE_ACTIONS.PERFOMANCE_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case PERFOMANCE_ACTIONS.PERFOMANCE_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case PERFOMANCE_ACTIONS.PERFOMANCE_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case PERFOMANCE_ACTIONS.PERFOMANCE_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case PERFOMANCE_ACTIONS.PERFOMANCE_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case PERFOMANCE_ACTIONS.PERFOMANCE_RESET: {
            return {
                ...state,
                currentRecord: { ...EmployeeProductionDefaultProps },
            };
        }
        case PERFOMANCE_ACTIONS.PERFOMANCE_SET_PROJECT_WISE_WHERE: {
            return {
                ...state,
                projectWiseWhere: { ...action.where },
            };
        }
        case PERFOMANCE_ACTIONS.PERFOMANCE_SET_MODULE_WISE_WHERE: {
            return {
                ...state,
                taskWiseWhere: { ...action.where },
            };
        }
        case PERFOMANCE_ACTIONS.PERFOMANCE_SET_PROJECT_WISE_WHERE: {
            return {
                ...state,
                projectWiseWhere: { ...action.where },
            };
        }
        case PERFOMANCE_ACTIONS.PERFOMANCE_SET_MODULE_WISE_WHERE: {
            return {
                ...state,
                taskWiseWhere: { ...action.where },
            };
        }
        case PERFOMANCE_ACTIONS.PERFOMANCE_SET_SELECTED_PROJECT: {
            return {
                ...state,
                selectedProject: action.data,
            };
        }
        case PERFOMANCE_ACTIONS.PERFOMANCE_SET_SELECTED_MODULE: {
            return {
                ...state,
                selectedModule: action.data,
            };
        }

        default:
            return state;
    }
};
