import { shallowEqual, useSelector } from "react-redux";
import { Link,useNavigate } from "react-router-dom"
 

const BackProjectButton=()=>{
    const navigator = useNavigate();
    const {currentRecord} = useSelector((state) => state.ProjectReducer, shallowEqual);
    // if(!currentRecord?.RefClientId) navigator("/projects")
   return (
    <>
    <Link to="/projects" >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={30}
      height={30}
    >
      <g data-name="20-Arrow Left">
        <path
          fill="#0073ea"
          d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"
        />
        <path
          fill="#0073ea"
          d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"
        />
      </g>
    </svg>
  </Link>{" "}
  {currentRecord?.RefClientId?.ClientProjectCode} {currentRecord.ProjectSubCode}{" "}
  </>
   )
}
export default BackProjectButton