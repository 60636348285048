import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import $ from 'jquery';
import 'daterangepicker/daterangepicker.css';
import 'daterangepicker';

const DateRangePickerWithAll = ({ value, onChange }) => {
  const dateRangeRef = useRef(null);

  // Initialize state based on the value prop
  const [startDate, setStartDate] = useState(value[0] || moment().subtract(29, 'days'));
  const [endDate, setEndDate] = useState(value[1] || moment());

  useEffect(() => {
    function cb(start, end) {
      setStartDate(start);
      setEndDate(end);
      $(dateRangeRef.current).find('span').html(
        `${start.format('MMM D, YYYY')} - ${end.format('MMM D, YYYY')}` // Short month format
      );
      onChange([start, end]); // Call the onChange prop with new dates
    }

    // Initialize daterangepicker
    $(dateRangeRef.current).daterangepicker(
      {
        startDate: startDate,
        endDate: endDate,
        drops: 'auto', // Open direction
        opens: 'left', // Open on the left
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month')
          ]
        }
      },
      cb
    );

    cb(startDate, endDate); // Initialize the display

    // Cleanup daterangepicker on unmount
    return () => {
      if ($(dateRangeRef.current).data('daterangepicker')) {
        $(dateRangeRef.current).data('daterangepicker').remove();
      }
    };
  }, [startDate, endDate, onChange]); // Added onChange to dependency array

  // Update state if the value prop changes
  useEffect(() => {
    setStartDate(value[0]);
    setEndDate(value[1]);
  }, [value]);

  return (
    <div
      id="reportrange"
      ref={dateRangeRef}
      style={{ background: '#fff', cursor: 'pointer', padding: '5px 10px', border: '1px solid rgb(72 127 255)', width: '100%',borderRadius: '5px' }}
    >
      <span className="text-sm">{`${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`}</span>&nbsp; <i className="fa fa-calendar text-primary-500" />
    </div>
  );
};

export default DateRangePickerWithAll;
