import React, { useState, useRef, useEffect } from 'react';

const SearchInput = ({handleSearchInputChange,placeholder="Search"}) => {
  const [isActive, setIsActive] = useState(false);
  const searchRef = useRef(null);

  // Handle click on search button or input field to activate the search
  const handleActivateSearch = () => {
    setIsActive(true);
    searchRef.current.focus(); // Focus the input when clicked
  };

  // Handle input change to manage active state when typing
  const handleInputChange = (e) => {
    handleSearchInputChange(e)
    if (e.target.value) {
      setIsActive(true);
    }
  };

  // Handle click outside to remove active class
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.closest('.search').contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div className={`search ${isActive ? 'active' : ''}`}>
      <span
        className="searchBtn position-absolute end-0 top-50 translate-middle-y me-12 line-height-1"
        onClick={handleActivateSearch} // Clicking on the button will add active class
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512">
          <path
            fill="none"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeWidth="32"
            d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
          />
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="32"
            d="M338.29 338.29L448 448"
          />
        </svg>
      </span>
      <input
        type="text"
        className="form-control radius-8"
        id="search"
        placeholder={placeholder}
        onChange={handleInputChange}
        ref={searchRef}
        onClick={handleActivateSearch} // Clicking inside the input also adds the active class
      />
    </div>
  );
};

export default SearchInput;
