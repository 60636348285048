import { useCallback, useState } from "react"
import ModuleTree from "./ModuleTree"
import { useDispatch } from "react-redux"
import { getModuleByTaskFromAPI } from "src/api/UserApi"
import {productionAssignTaskIdAction } from "../actions/ProductionActions"
const TaskTree=({projectId,task})=>{
    const [modules,setModules] = useState([])
    const [searchTerm, setSearchTerm] = useState(''); // State for search input
    const dispatch = useDispatch();
    const handleLoadModuleByProject = useCallback(async(e,taskDetails)=>{
        e.stopPropagation(); // Prevent event propagation
        const module = await getModuleByTaskFromAPI(taskDetails._id)
        setModules(module.data.data)
        dispatch(productionAssignTaskIdAction({
          data:{
            taskId: taskDetails._id,
            taskName: taskDetails.taskName,
            item:taskDetails.item,
            target:taskDetails.target,
            targetUnit:taskDetails.unit
          }
        }));
        if(module.data.data.length===0){
          document.querySelectorAll('.accordion-collapse').forEach((element) => {
            element.classList.remove('show');
          });
          document.querySelectorAll('.accordion-button').forEach((element) => {
            element.classList.add('collapsed');
        });
        }
      },[task])
  // Filtered modules based on the searchTerm
  const filteredModules = modules.filter(module => 
    module.moduleName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <> 
                         
                 <div
                  id={`sub-collapse${projectId}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby="sub-heading6"
                  data-bs-parent="#sub-accordion2"
                >
                  <div className="accordion-body">
                    <div
                      className="accordion sub-accordion newSubCategory"
                      id="sub-accordion3"
                    >
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="sub-heading11"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#sub-collapseTask${task._id}`}
                            aria-expanded="true"
                            aria-controls="collapse11"
                            onClick={(e) => {handleLoadModuleByProject(e,task)}}
                          >
                            {task.taskName}
                          </button>

                        </h2>
                <div
                id={`sub-collapseTask${task._id}`}
                className="accordion-collapse collapse"
                aria-labelledby="sub-heading11"
                data-bs-parent="#sub-accordion3"
                >
              <div className="accordion-body">
              {/* <form className="navbar-search w-100">
                <input 
                    type="text" 
                    name="search" 
                    className="w-100 rounded-0" 
                    placeholder="Search" 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <iconify-icon icon="ion:search-outline" class="icon" />
              </form> */}
                <div className="accordion accordion-flush newMoreCategory" id="accordionFlushExample">
                        {
                         filteredModules.map((module,moduleIndex)=>{
                                return(
                                   <ModuleTree module={module} taskId={task._id} key={moduleIndex}/>
                                )
                            })
                        }
                        </div>
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>



    </>
  )
}
export default TaskTree