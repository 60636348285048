import { useSelector,shallowEqual } from "react-redux"
import ProjectTree from "./ProjectsTree"
import { useState } from "react"

const ProjectTreeContainer=(props)=>{
  const {assignedProjects} = useSelector(state=>state.ProductionReducer,shallowEqual)
    return (

      <div className="accordion acodne110" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed acoonm1"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Select Project
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div
                      className="accordion sub-accordion"
                      id="sub-accordionExample"
                    >
                      {
                        assignedProjects.map((item,index)=>{
                          return(              
                           <ProjectTree  key={index} item={item}/>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div> 
    )
}
export default ProjectTreeContainer