// LeaveDetails.js
import React, { useEffect } from 'react';
import { Field, ErrorMessage,useFormikContext } from 'formik';
import { costFormat } from 'src/utils/CommonUtils';

const LeaveDetails = ({ values,employeeDetails,leaveDetails, handleChange }) => {
    const { setFieldValue } = useFormikContext();
    const formGroups = [
        {
            id:"totalLeaves",
            label: "Total Leaves",
            name: "leaveDetails.totalLeaves",
            type: "number",
            placeholder: "0",
            value: leaveDetails?.totalAbsent
        },
        {
            id:"adjustedSL",
            label: "Adjusted Sick Leaves",
            name: "leaveDetails.adjustedSL",
            type: "select",
            options: Array.from({ length: values?.leaveBalance?.remainingSL + 1 }, (_, i) => i),
        },
        {
            id:"adjustedCL",
            label: "Adjusted Casual Leaves",
            name: "leaveDetails.adjustedCL",
            type: "select",
            options: Array.from({ length: values?.leaveBalance?.remainingCL + 1 }, (_, i) => i),
        },
    ];
    useEffect(()=>{ 
       const day_salary = leaveDetails?.daySalary || 0;
       const totalCL = leaveDetails?.adjustedSL || 0;
       const totalSL = leaveDetails?.adjustedCL || 0;
       const totalCLAmt = day_salary * totalCL;
       const totalSLAmt = day_salary * totalSL;
       const totalAdjustedLeaves = totalCL + totalSL;
       if (leaveDetails) {
       leaveDetails.adjustedSickLeavesAmount=totalCLAmt||0;
       leaveDetails.adjustedCasualLeavesAmount=totalSLAmt||0; 
       const effectiveLeaves = (leaveDetails?.totalLeaves || 0) - totalCL - totalSL;
       const totalLeaveDeductionAmount = (effectiveLeaves*day_salary)?.toFixed() || 0;
       leaveDetails.otherLeaveDeduction = effectiveLeaves||0
       leaveDetails.totalLeaveDeductionAmount = totalLeaveDeductionAmount||0
       leaveDetails.otherLeaveDeductionAmount = totalLeaveDeductionAmount||0
    //    Math.max(0, Math.ceil(leaveDetails.otherLeaveDeductionAmount - totalAdjustedLeaves));
       }
    //    const totalAbsent = totalLeaves - totalAdjustedLeaves;
    //    const otherLeaveDeduction = employeeDetails?.totalLeaveDeduction || 0;
    },[leaveDetails])
    return (
        <div className="col-xl-6 col-md-12 mb-24 gy-3">
            <div className="possettop">
                <h4 className="card-title">Leave Details</h4>
                <div className="radius-10 border p-16">
                    {formGroups.map(({id, label, name, type, placeholder, options }) => (
                        <div className="align-items-center d-flex justify-content-between py-8" key={name}>
                            <label className="form-label mb-0">{label}</label>
                            <div className="inpset001">
                                {type === "number" ? (
                                    <Field
                                        id={id}
                                        name={name}
                                        type="number"
                                        className="form-control w-160-px ms-auto"
                                        placeholder={placeholder}
                                        // onChange={handleChange}
                                    />
                                ) : (
                                    <Field as="select" name={name} className="form-select w-160-px" 
                                    onChange={(e) => {
                                        const selectedValue = Number(e.target.value);
                                        setFieldValue(name, selectedValue);
                                      }}
                                    // onChange={handleChange}
                                    >
                                        {options.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Field>
                                )}
                                <ErrorMessage name={name} component="div" className="text-danger small mt-1" />
                            </div>
                        </div>
                    ))}

                    <div className="align-items-center d-flex justify-content-between py-8 py-16 border-top mt-16">
                        <label className="form-label mb-0 text-neutral-500">Effective Leaves</label>
                        <span className="text-md fw-semibold text-neutral-800">{leaveDetails?.otherLeaveDeduction || 0}</span>
                    </div>
                    <div className="align-items-center d-flex justify-content-between py-8 py-16 border-top">
                        <label className="form-label mb-0 text-neutral-500">Adjusted Sick Leaves Amount</label>
                        <span className={`text-md fw-semibold text-neutral-800 ${leaveDetails?.adjustedSickLeavesAmount < 0 ? 'text-red' : ''}`}>
                            {costFormat(leaveDetails?.adjustedSickLeavesAmount)}
                        </span>
                    </div>
                    <div className="align-items-center d-flex justify-content-between py-8 py-16 border-top">
                        <label className="form-label mb-0 text-neutral-500">Adjusted Casual Leaves Amount</label>
                        <span className={`text-md fw-semibold text-neutral-800 ${leaveDetails?.adjustedCasualLeavesAmount < 0 ? 'text-red' : ''}`}>
                            {costFormat(leaveDetails?.adjustedCasualLeavesAmount)}
                        </span>
                    </div>
                    <div className={`align-items-center d-flex justify-content-between py-8 py-16 px-16 bg-primary-50 radius-10 ${employeeDetails.totalLeaveDeduction < 0 ? 'text-red bg-danger-100' : ''}`}>
                        <label className="form-label mb-0 text-dblue">Total Deduction</label>
                        <span className={`text-xl fw-bold ${leaveDetails?.totalLeaveDeductionAmount < 0 ? 'text-red' : ''}`}>
                            {costFormat(leaveDetails?.totalLeaveDeductionAmount)}
                        </span>
                    </div>
                    {/* <div className="bunSeeLeave mt-16">
                        <button type='button' className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2" data-bs-toggle="modal" data-bs-target="#LeaveDetailsModal">See Leave Details <i className="mdi mdi-eye mdi-18px"></i></button>
                    </div> */}
                </div>
            </div>
        </div>
    );
};
export default LeaveDetails;
