import { useCallback, useEffect, useMemo, useState } from "react"
import { Formik, Field, Form } from 'formik';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { includeStamp } from "src/utils/StampUtils";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import FormikTree from "src/components/formik/FormikTree";
import { getAllProjectPermissionsAPI, userProjectPermissionsCreateAPI } from "src/api/ProjectApi";
import { permissionsToTreeStructure } from "src/utils/CommonUtils";
import { ProjectPermissionsYupSchema } from "./ProjectPropTypes";

const Render=(props)=>{ 
    const {
        values, 
        scopeTree,
        projectCode,
        projectSub,
        selectedUser
    } = props;

    return(
     <>
        <Form autoComplete="disabled"> 
        <div className="card-body"> 
            {/* <div
  className="alert alert-primary bg-primary-50 text-primary-600 border-primary-50 px-24 py-11 mb-0 fw-semibold text-lg radius-8 d-flex align-items-center justify-content-between"
  role="alert"
>
  <div className="d-flex align-items-center gap-2 rounded-pill"> 
    {`${projectCode}(${projectSub}) `} Permission 
    To {selectedUser.firstName} {selectedUser.lastName} ({selectedUser.employeeID})
  </div> 
</div> */}
   
            <div className="d-flex justify-content-between align-items-center">
              <div className="assignFlexpro">
                <span className="img" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-className="tooltip-dark" data-bs-title={`${selectedUser.firstName} ${selectedUser?.lastName}`}>
                  <span className="initials d-flex justify-content-center align-items-center w-100 h-100">AS</span></span>
                  <div className="assiContentInn"><span>{selectedUser?.firstName} {selectedUser?.lastName} ({selectedUser?.employeeID})</span><span className="grayText">{selectedUser?.designationId?.designationName}</span><span className="pinktext">{selectedUser?.departmentId?.departmentName}</span></div>
                  </div>
              {/* <div className="form-switch switch-success d-flex align-items-center gap-3">
                <label
                  className="form-check-label line-height-1 fw-medium text-secondary-light"
                  htmlFor="switch1"
                >
                  <div className="d-flex align-items-center gap-2 setnev111">
                    <i class="mdi mdi-key mdi-18px text-neutral-800"></i>
                    <div className="swchclor">
                      All Accesses
                      <span className="d-block mt-8">Total 5 accesses </span>
                    </div>

                  </div>
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switch1"
                  defaultChecked=""
                />
              </div> */}
              </div> 


            <div className="form-group col mt-24">
            <FormikTree
                nodes={scopeTree}
                name="permissions"
                checked={values.permissions}
            />
            
        </div>
            <div className="form-group text-end gap-3 d-flex justify-content-end mt-24">
                <button type="submit"
                 aria-label="submit"
                 data-bs-dismiss="modal"
                 className="form-wizard-next-btn btn btn-primary-600 px-32 w-100"
                 >Assign Permissions</button> 
            </div>
        </div>
        </Form>
     </>
    ) 
  }
  
  Render.propTypes = {
    scopeTree: PropTypes.array.isRequired,
    values: PropTypes.shape({
        permissions: PropTypes.array,
        permissions: PropTypes.bool,
    }),
    resetForm: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    forceRefreshSearchContainer: PropTypes.object.isRequired,
    onClear: PropTypes.func.isRequired,
 };
  let setSubmitting = () => { };
const ProjectPermissionPopup=({selectedUser,userSelectedProjectPermission})=>{ 
     
    const dispatch = useDispatch();
    const [scopes,setScopes] = useState([]);
    // const currentRecord = {permissions:userSelectedProjectPermission};
    const [currentRecord,setCurrentRecord] = useState({permissions:userSelectedProjectPermission});
    const {currentSelectedProjectId,projectCode,projectSub}  = useSelector((state) => state.ClientReducer, shallowEqual); 
    const {_id:userId} = useSelector(state=>state.auth?.user,shallowEqual);

    const getPermissionMaster = async()=>{
        const response = await getAllProjectPermissionsAPI();
        if(response && response.data){
            setScopes(response.data.data.data[0].scopes);
        }
    }

    useEffect(() => {
        getPermissionMaster();
    },[])
    useEffect(() => {
      setCurrentRecord({permissions:userSelectedProjectPermission})
    },[userSelectedProjectPermission])

    const scopeTree = useMemo(() => permissionsToTreeStructure(scopes), [
        scopes,
    ]);
    const onSubmit = useCallback(async(
        values,
        { resetForm }
    ) => { 
         includeStamp(values);
         values.projectId = currentSelectedProjectId;
         values.userId = selectedUser._id;
         values.by = userId; 
        // dispatch(rolePermissionsUpsertAction(values)); 
        await userProjectPermissionsCreateAPI(values);
        toast.success('Project Permission Updated successfully!');
        resetForm();
        setCurrentRecord({permissions:[]});
    }, [
        dispatch,
        selectedUser._id,
        currentSelectedProjectId,
    ]);
   
     const onClear = useCallback(() => {
        // dispatch(rolePermissionsResetAction());
    }, [
        dispatch,
    ]);

   return (
    <>
    
      <div className="modal fade cusmodl" id="roulsXXModual" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content"> 
                <div className="modal-header d-flex justify-content-between pb-0 border-0">
              <div
  className="alert alert-primary bg-primary-50 text-primary-600 border-primary-50 px-24 py-11 mb-0 fw-semibold text-lg radius-8 d-flex align-items-center justify-content-between rounded-pill"
  role="alert"
>
  <div className="d-flex align-items-center gap-2 rounded-pill"> 
    {`${projectCode}(${projectSub}) `} Permission 
    {/* To {selectedUser.firstName} {selectedUser.lastName} ({selectedUser.employeeID}) */}
  </div> 
</div>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button>
            </div>
                    <div className="modal-body">  
                                <Formik
                                   onSubmit={onSubmit} 
                                   onClear={onClear}
                                   enableReinitialize
                                   initialValues={currentRecord}
                                   validationSchema={ProjectPermissionsYupSchema}
                                >
                                    {(formikProps) => (
                                        <Render 
                                            scopeTree={scopeTree}   
                                            onClear={onClear}
                                            selectedUser={selectedUser}
                                            projectCode={projectCode}
                                            projectSub={projectSub}
                                            {...formikProps}
                                        />
                                    )}
                        </Formik>
                    </div> 
                </div>
            </div>
        </div> 
    </>
   )
}

export default ProjectPermissionPopup