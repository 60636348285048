import React, { useState, useEffect, useRef } from 'react';
import TopTabs from "../TopTabs"
import ProjectProductionFilter from "./FIlter"
import ManageProductionColum from "./ManageColoum"
import { useParams } from 'react-router-dom';
import { productionApproveUpdate, productionProjectSearchAPI, productionRejectionUpdate } from 'src/api/ReportsAPI';
import moment from 'moment'
import BackProjectButton from 'src/components/BackProjectButton';
import SearchInput from 'src/components/SearchInput';
import DatePickerComponent from 'src/components/DatePickerComponent';
import { shallowEqual, useSelector } from 'react-redux';
import ApprovalModal from './ApprovalModal';
import { calculateAmount, generateColor, getContrastYIQ } from 'src/utils/utils';
import { useDispatch } from 'react-redux';
import CommentModal from 'src/containers/production-container/CommentModal';
import ProjectPermissionHOC from 'src/hoc/ProjectPermissionHOC';
import { useSticky } from 'src/hooks/useSticky';
import Pagination from 'src/components/Pagination';

const ProjectProductionContainer = () => {

  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [production, setProduction] = useState([])
  const [selectedProduction,setSelectedProduction]=useState([])
  const [totalcost, setTotalCost] = useState()
  const [totalDuration, setTotalDuration] = useState()
  const [search, setSearch] = useState(''); 
  const [where, setWhere] = useState({}); 
  const [productionStatus, setProductionStatus] = useState();
  const [selectedStatuses, setSelectedStatuses] = useState('');
  const [totalrejected, setTotalRejected] = useState(0)
  const [totalapproved, setTotalApproved] = useState(0)
  const [pageNo,setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLength, setPageLength] = useState(100);
  const [isFlatPikerReset, setIsFlatPikerReset] = useState(false);
  const {currentRecord} = useSelector((state) => state.ProjectReducer,shallowEqual)
  const projectCode = (currentRecord?.RefClientId?.ClientProjectCode)?`${currentRecord?.RefClientId?.ClientProjectCode} ${currentRecord.ProjectSubCode}`:''




  const getProjectProductionData = async () => {
    let body = {
      pageLength,
      currentPage:pageNo, 
      where
    };
    try {
      const resp = await productionProjectSearchAPI({ projectId, data:body });
      const skillsData = resp?.data?.data || [];
      setTotalPages(resp?.data?.total);
      // Filter skills based on departmentName
      const filterSkillsData = skillsData.filter(item =>
        item?.userId?.firstName.toLowerCase().includes(search.toLowerCase()) 
      );

      // Calculate total duration and total cost for filtered data
      const totals = filterSkillsData.reduce((acc, item) => {
        acc.totalDuration += item.totalDurations || 0;
        acc.totalApproved += item?.approvedDurations || 0;
        acc.totalRejected += item?.rejectedDurations || 0;
        acc.totalCost += (item.cost || 0)+calculateAmount(item);
        return acc;
      }, { totalDuration: 0, totalCost: 0, totalApproved: 0, totalRejected: 0 });
      // Set the filtered skills and totals in state
      setProduction(filterSkillsData);
      setTotalDuration(totals.totalDuration);
      setTotalCost(totals.totalCost);
      setTotalApproved(totals.totalApproved)
      setTotalRejected(totals.totalRejected)

    } catch (error) {
      console.log('error from project Task', error);
    }
  };

  useEffect(() => {
    getProjectProductionData();
  }, [search,where,pageNo]); 
  const handleChangeStatus=(status,production)=>{
    setSelectedStatuses(status); 
    setSelectedProduction(production);
  }

  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) { 
      setIsFlatPikerReset(false)
      const [startDate, endDate] = selectedDates.map(date => {
        // Ensure that the date is treated as UTC and not auto-adjusted
        const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000); 
        return utcDate.toISOString().split("T")[0]; // Get date part only (YYYY-MM-DD)
      });  
      setWhere({...where, ts: { $gte: startDate, $lte: endDate } })
    }
  }
  useEffect(() => {
    // Initialize Bootstrap tooltips for elements with data-bs-toggle="tooltip"
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    // Cleanup tooltips when the component unmounts
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);
  const handleSearchInputChange = (e) => {
    const searchKey = e.target.value;
    setSearch(searchKey.toLowerCase());     
  };
  const resetFilter = () => {
    document.getElementById('search').value = '';
    setIsFlatPikerReset(true)
    setSearch('');
    setWhere({});
    // setFilterDefaultProps(defaultClientFilterProps);
  }
  const handleComment = (production) => {
    dispatch({ type: "PRODUCTION_CURRENT_PRODUCTION", data: production });
 };
 const handleFileDownload = (production) => { 
  production?.files.forEach(file => {
    const a = document.createElement('a');
    a.href = file.url;
    a.target = '_blank'; // Open in a new tab
    a.click();
  });
};



// const { isSticky, elementRef } = useSticky(); // Use the custom hook

// Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code End
const handleStatusSelection=(status)=>{
  console.log('status',status);
 if(status=="pending"){
  setWhere({
    ...where,
    $expr: {
      $gt: [
        "$totalDurations",
        {
          $add: [
            { $ifNull: ["$approvedDurations", 0] },
            { $ifNull: ["$rejectedDurations", 0] }
          ]
        }
      ]
    }
  });
 }
 else if(status=="reviewed"){
  setWhere({
    ...where,
    $expr: {
      $eq: [
        "$totalDurations",
        {
          $add: [
            { $ifNull: ["$approvedDurations", 0] },
            { $ifNull: ["$rejectedDurations", 0] }
          ]
        }
      ]
    }
  });
 }
 else if(status=="rejected"){
  setWhere({
    ...where,
    $and: [
      {
        $expr: {
          $gt: [
            "$rejectedDurations",
            0
          ]
        }
      }
    ]
  });
 }
 else{ 
      // For "all" status, remove the `$expr` condition
      const { $expr, ...restWhere } = where;
      setWhere({
        ...restWhere
      });
 }
}
  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
              <BackProjectButton/>
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="/"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Production</li>
              </ul>
              <TopTabs />
              <div className="d-flex gap-2 align-items-center py-16 postionTop-0 bg-white">
                {/* Search start */}
                 <SearchInput 
                 handleSearchInputChange={handleSearchInputChange}
                 placeholder="Search by name"
                 />
                {/* Search End */}
                {/* Date Range piker start  */}
                <DatePickerComponent 
                isFlatPikerReset={isFlatPikerReset}
                onDateRangeChange={handleDateRangeChange} 
                />
                 <button type="reset"  
                onClick={resetFilter}
                className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                >
                  Reset
                </button>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-stack"
                    viewBox="0 0 16 16"
                  >
                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
                  </svg>{" "}
                  {/* Total Project: 458 */}
                  {projectCode}
                </h6>
              </div>
              <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
              <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                  <thead>
                    <tr className="dnd-moved">
                      {/* <th className="w30px dragablefalse" draggable="false">
                        <input className="form-check-input" type="checkbox" />{" "}
                      </th> */}
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>                      
                      <th>Date</th>
                      <th>Name </th>
                      {/* <th>Department </th> */}
                      <th>Designation</th>
                      <th>Task </th>
                      <th>Module </th> 
                      <th>File </th>                      
                      <th className="text-center">Total </th>
                      <th>Approved </th>
                      <th>Pending </th>
                      <th>Rejected </th>
                      <th>Description </th>
                      <ProjectPermissionHOC scope="view_report.production.cost">
                        <th className="text-center">Cost </th>
                      </ProjectPermissionHOC>
                      <th className="text-center end-0 borderSrt z-3"><div className="d-flex align-items-center justify-content-between">Status  <div className="dropdown">
                  <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-filter-variant mdi-24px"></i>
                  </button>
                  <ul className="dropdown-menu border  dropdown-menu-end mx-hight200px">
                     
                      <li className="dropdown-item">
                        <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                          <input
                            className="form-check-input"
                            onClick={()=>{handleStatusSelection("all")}} 
                            type="radio" name='status'  style={{width: "24px", height: "24px"}}
                          />
                          <label className="form-check-label">All</label>
                        </div>
                      </li>
                      <li className="dropdown-item">
                        <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                          <input
                            className="form-check-input"
                            onClick={()=>{handleStatusSelection("pending")}}
                            type="radio" name='status'  style={{width: "24px", height: "24px"}}
                          />
                          <label className="form-check-label">Pending</label>
                        </div>
                      </li>
                      <li className="dropdown-item">
                        <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                          <input
                            className="form-check-input"
                            onClick={()=>{handleStatusSelection("reviewed")}}
                            type="radio" name='status'  style={{width: "24px", height: "24px"}}
                          />
                          <label className="form-check-label">Approved</label>
                        </div>
                      </li>
                      <li className="dropdown-item">
                        <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                          <input
                            className="form-check-input"
                            onClick={()=>{handleStatusSelection("rejected")}}
                            type="radio" name='status'  style={{width: "24px", height: "24px"}}
                          />
                          <label className="form-check-label">Rejected</label>
                        </div>
                      </li>
                    
                  </ul>
                </div></div></th>
                    </tr>
                  </thead>
                  <tbody id="table-body">
                    {production?.map((item, index) => {
                     const approvedDurations = item?.approvedDurations || 0;
                     const rejectedDurations = item?.rejectedDurations || 0;
                     const totalDurations = item?.totalDurations || 0;
                      // Determine the status based on the conditions
                      let status;
                      
                    if (((item?.approvedDurations||0)+(item?.rejectedDurations||0)) >= item?.totalDurations) {
                      status = "Reviewed";  // Approved if approvedDurations is greater than or equal to totalDurations
                    } else {
                      status = "Pending";  // Otherwise, it's pending
                    }                     

                      // Calculate Pending based on the provided conditions
                      let pending;
                      if (approvedDurations === 0 && rejectedDurations === 0) {
                        pending = totalDurations;
                      } else if (approvedDurations > 0 && rejectedDurations > 0) {
                        pending = totalDurations - (approvedDurations + rejectedDurations);
                      } else if (approvedDurations > 0) {
                        pending = totalDurations - approvedDurations;
                      } else if (rejectedDurations > 0) {
                        pending = totalDurations - rejectedDurations;
                      }
                      // Ensure pending is not negative
                      pending = Math.max(pending, 0);
                      if(productionStatus=="all" && productionStatus!==status) return
                         const fullCost = (item?.cost||0+calculateAmount(item))
                          
                      return (
                        <tr key={index} className="dnd-moved">
                          {/* <td draggable="false">
                            <input className="form-check-input" type="checkbox" />
                          </td> */}
                          <td draggable="false">
                            <label className="form-check-label">{index + 1}</label>
                          </td>                          
                          <td>{moment(item?.date).format('DD-MM-YYYY')}</td>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                            <span
                                       className="img imgsSet me-0"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       data-bs-custom-class="tooltip-dark"
                                       data-bs-title={`${item?.userId?.firstName} ${item?.userId?.lastName}`}
                                     >
                                       {item?.userId?.profilePicture ? (
                                         <img
                                           src={item?.userId?.profilePicture}
                                           className="img-fluid"
                                           alt=""
                                         />
                                       ) : (
                                         <span
                                           className="initials d-flex justify-content-center align-items-center w-100 h-100"
                                           style={{
                                             backgroundColor: generateColor(`${item?.userId?.firstName}${item?.userId?.lastName}`),
                                             color: getContrastYIQ(generateColor(`${item?.userId?.firstName}${item?.userId?.lastName}`)),
                                           }}
                                         >
                                           {`${item?.userId?.firstName.charAt(0)}${item?.userId?.lastName.charAt(0)}`}
                                         </span>
                                       )}
                                     </span>

                              {/* <span className="userimg" /> */}
                              <div className="flex-grow-1">
                                <h6 className="text-md mb-0 fw-medium text-primary-600">
                                  {item?.userId?.firstName} {item?.userId?.lastName}
                                </h6>
                              </div>
                            </div>
                          </td>
                          {/* <td>{item?.userId?.departmentId?.departmentName}</td> */}
                          <td>{item?.userId?.designationId?.designationName}</td>
                          <td><div className="mxwset2hdr">{item?.taskId?.taskName}</div></td>
                          <td>{item?.moduleId?.moduleName}</td> 
                        <td className="text-center">
                        {
                            (item?.files?.length>0)?
                            <button className="d-flex align-items-center gap-2 text-primary-600"
                            onClick={()=>{handleFileDownload(item)}}
                            >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={22}
                                height={22}
                                fill="currentColor"
                                className="bi bi-file-earmark-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z" />
                            </svg>{" "}
                            View
                            </button>
                            :"NA"
                        }                            
                        </td>

                          <td className="text-center">
                            {item?.totalDurations}
                          </td>
                          <td className="text-center">

                            {item?.approvedDurations || 0}
                          </td>
                          <td className="text-center">
                            {pending}
                          </td>
                          <td className="text-center">

                            {item?.rejectedDurations || 0}
                          </td>
                           <td className="text-center">
                                <button
                                className="text-primary-900"
                                data-bs-toggle="modal"
                                data-bs-target="#CommentModal"
                                onClick={() => handleComment(item)}
                                >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={22}
                                    height={22}
                                    fill="currentColor"
                                    className="bi bi-eye-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                </svg>
                                </button>
                        </td>
                        <ProjectPermissionHOC scope="view_report.production.cost">
                          <td className="text-center">
                            <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                              ₹ {fullCost.toFixed(2)}
                            </span>
                          </td>
                          </ProjectPermissionHOC>
                          <td className="text-center end-0 borderSrt">                             
                                               <div class="btn-group w-100">
                                                      <div
                                                        class={`badge text-sm fw-semibold ${(status==="Reviewed")?"bg-success-600":"bg-warning-600"} px-20 py-9 radius-4 text-white d-flex align-items-center gap-2  w-100`}>
                                                        {status} 
                                                      </div>
                                                    <button type="button"
                                                        class={`btn ${(status==="Reviewed")?"btn-success-600":"btn-warning-600"} dropdown-toggle dropdown-toggle-split rounded-0 border-start px-16`}
                                                        data-bs-toggle="dropdown" aria-expanded="false"
                                                        data-bs-reference="parent">
                                                        <span class="visually-hidden">{status} </span>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li class="">
                                                            <a
                                                                class="dropdown-item d-flex align-items-center gap-2" 
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#statusModal"
                                                                onClick={()=>{handleChangeStatus("Approved",item)}}
                                                                href="#">
                                                                  <svg xmlns="http://www.w3.org/2000/svg"
                                                                width="22" height="22" fill="currentColor"
                                                                class="bi bi-check-circle-fill text-success-500"
                                                                
                                                                viewBox="0 0 16 16">
                                                              <path
                                                                 d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                              </svg> Approved 
                                                              </a>
                                                            </li>
                                                            <li class="">
                                                                <a
                                                                class="dropdown-item d-flex align-items-center gap-2" 
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#statusModal"
                                                                onClick={()=>{handleChangeStatus("Rejected",item)}}
                                                                href="#">
                                                                  <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="22" height="22" fill="currentColor"
                                                                    class="bi bi-x-circle-fill text-danger-500"
                                                                    viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                                                                </svg> Rejected</a>
                                                              </li>
                                                    </ul>
                                                </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr style={{ position: 'relative', zIndex: 20 }}>
                      <td colSpan={6} />
                      <td colSpan={1} className="fw-bold">
                        Total
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">{totalDuration?.toFixed(2)}</span>
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">{totalapproved?.toFixed(2)}</span>
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">{((totalDuration||0) - ((totalapproved||0) + (totalrejected||0)))?.toFixed(2)}</span>
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">{totalrejected?.toFixed(2)}</span>
                      </td>
                      <td className="text-center">                        
                      </td>
                      <ProjectPermissionHOC scope="view_report.production.cost">
                      <td className="text-center">
                        <span className="d-block fw-bold">₹{totalcost?.toFixed(2)}</span>
                      </td>
                      </ProjectPermissionHOC>
                      <td className=" end-0 borderSrt" colSpan={1} />
                    </tr>
                    {/* <tr>
                      <td colSpan={14}>
                      {production && production?.length>0 && <Pagination perPage={pageLength} setPageNo={setPageNo} pageNo={pageNo} total={totalPages}/>}
                      </td>
                    </tr> */}


                  </tfoot>
                </table> 
              </div>
              <div className=''>
              {production && production?.length>0 && <Pagination perPage={pageLength} setPageNo={setPageNo} pageNo={pageNo} total={totalPages}/>}
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <ApprovalModal 
      selectedStatuses={selectedStatuses}
      production={selectedProduction}
      getProjectProductionData={getProjectProductionData}
      />
      <ProjectProductionFilter />
      <ManageProductionColum />
      <CommentModal />
    </>
  )
}
export default ProjectProductionContainer