import React, { useState } from 'react';
import SearchInput from '../SearchInput';
import MonthPicker from '../MonthPicker';
import GroupCounter from 'src/containers/production-container/GroupCounter';
import AttendanceCalendar from 'src/containers/production-container/AttendanceCalendar';
import SalaryComponent from './SalaryCard';
import EmployeeDetails from './EmployeeDetails';
import LeaveDetails from './LeaveDetails'; // Import the LeaveDetails component
import LeaveTable from './LeaveTable';
import LagDeductionDetail from './LagDeductionDetail';
import EncashmentDetail from './EncashmentDetail';
import FinalSalary from './FinalSalary';
import ShowDetailsEmployee from './ShowDetailsEmployee';

const EmployeeSalarySlip2 = () => {
    const [employeeDetails, setEmployeeDetails] = useState({
        totalLeaves: 0,
        adjustedSickLeaves: '1L',
        adjustedCasualLeaves: '1L',
        effectiveLeaves: 0.5,
        sickLeavesAmount: 800.00,
        totalDeduction: -400.00,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployeeDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDownload = () => {
        console.log('Downloading salary slip...');
    };



    const [formData, setFormData] = useState({
        exactHours: "00",
        pfDeduction: "00",
        taxDeduction: "00",
        otherDeduction: "00",
        extraDeduction: "00",
        totalDeduction: "00",
    });

    const handleInputChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const handleRadioChange = (field, value) => {
        console.log(`${field} set to ${value}`);
    };


    const attendanceData = [
        {
          day: "Monday",
          entries: [
            { date: 1, status: "Present", hours: "09:19:48", activity: "57%", reason: "", comments: "sxxx" },
            { date: 8, status: "Sick Leave", hours: "10:44:50", activity: "72%", reason: "", comments: 0 },
            { date: 15, status: "Half Day", hours: "07:56:56", activity: "55%", reason: "", comments: 0 },
          ],
        },
        {
          day: "Tuesday",
          entries: [
            { date: 2, status: "Casual Leave", hours: "08:49:00", activity: "59%", reason: "", comments: 0 },
            { date: 9, status: "Present", hours: "09:12:47", activity: "62%", reason: "", comments: 0 },
            { date: 16, status: "Present", hours: "09:01:49", activity: "63%", reason: "", comments: 0 },
          ],
        },
        {
          day: "Wednesday ",
          entries: [
            { date: 3, status: "Present", hours: "08:49:00", activity: "59%", reason: "", comments: 0 },
            { date: 10, status: "Present", hours: "09:12:47", activity: "62%", reason: "", comments: 0 },
            { date: 17, status: "Present", hours: "09:01:49", activity: "63%", reason: "", comments: 0 },
          ],
        },
        {
          day: "Thursday ",
          entries: [
            { date: 4, status: "Present", hours: "08:49:00", activity: "59%", reason: "", comments: 0 },
            { date: 11, status: "Present", hours: "09:12:47", activity: "62%", reason: "", comments: 0 },
            { date: 18, status: "Present", hours: "09:01:49", activity: "63%", reason: "", comments: 0 },
          ],
        },
        {
          day: "Friday ",
          entries: [
            { date: 5, status: "Present", hours: "08:49:00", activity: "59%", reason: "", comments: 0 },
            { date: 12, status: "Holiday", hours: "09:12:47", activity: "62%", reason: "Independence Day", comments: 0 },
            { date: 19, status: "Present", hours: "09:01:49", activity: "63%", reason: "", comments: 0 },
          ],
        },
        {
          day: "Saturdy ",
          entries: [
            { date: 6, status: "Week Off", hours: "08:49:00", activity: "59%", reason: "", comments: 0 },
            { date: 13, status: "Week Off", hours: "09:12:47", activity: "62%", reason: "", comments: 0 },
            { date: 20, status: "Week Off", hours: "09:01:49", activity: "63%", reason: "", comments: 0 },
          ],
        },
        {
          day: "Sunday",
          entries: [
            { date: 7, status: "Week Off", hours: "08:49:00", activity: "59%", reason: "", comments: 0 },
            { date: 14, status: "Week Off", hours: "09:12:47", activity: "62%", reason: "", comments: 0 },
            { date: 21, status: "Week Off", hours: "09:01:49", activity: "63%", reason: "", comments: 0 },
          ],
        },
        // ...similar data for the other days of the week
      ];
      
  
       // State to track the visibility of the details and button label
  const [showDetails, setShowDetails] = useState(false);

  // Toggle function to switch between "Show" and "Hide" states
  const toggleDetails = () => {
    setShowDetails((prev) => !prev);
  };

    return (
        <>
            <div className="card h-100">
                <div className="card-body p-24">
                    <div className="d-flex justify-content-between align-items-center border-bottom pb-16">
                        <div>
                            <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                                <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width={30} height={30}>
                                        <g data-name="20-Arrow Left">
                                            <path fill="#0073ea" d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z" />
                                            <path fill="#0073ea" d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z" />
                                        </g>
                                    </svg>
                                </button>
                                Employee Salary Slip
                            </h2>
                            <ul className="d-flex align-items-center gap-2">
                                <li className="fw-medium">
                                    <a href="/" className="d-flex align-items-center gap-1 hover-text-primary">
                                        <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg" />
                                        Special Access
                                    </a>
                                </li>
                                <li>-</li>
                                <li className="fw-medium">
                                    <a href="/" className="d-flex align-items-center gap-1 hover-text-primary">
                                        HR
                                    </a>
                                </li>
                                <li>-</li>
                                <li className="fw-medium">Salary Slip</li>
                            </ul>
                        </div>
                        <a
                            href="#"
                            className="btn btn-primary text-sm btn-sm px-12 py-4 radius-8 d-flex align-items-center gap-2"
                            onClick={handleDownload}
                        >
                            Download Salary Slip <i className="mdi mdi-download mdi-18px"></i>
                        </a>
                    </div>

                    <EmployeeDetails employeeDetails={employeeDetails} />

                    <div className="mt-24">
                        <h4 className="card-title">Production Details</h4>
                        <GroupCounter />
                        <AttendanceCalendar />
                        <div className="d-flex flex-wrap align-items-center gap-4 py-16 border-bottom">
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-green-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Presents</span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-pink-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Holidays</span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-yellow-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Sick Leave</span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-blue-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Casual Leave</span>
                            </div>
                        </div>
                        <div className='text-end d-flex justify-content-end pt-16'> <button
          className="text-primary-600 hover-text-primary d-flex align-items-center gap-1 fw-semibold"
          onClick={toggleDetails}
        >
          {showDetails ? 'Hide Details' : 'Show Details'}
        </button></div>
                        {showDetails && (
        <div className="showdtalponew">
          <ShowDetailsEmployee data={attendanceData} />
        </div>
      )}
                    </div>

                    <div className='my-24'>
                    <SalaryComponent employeeDetails={employeeDetails} />
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-xl-6">
                            <div className="radius-10 border p-16">
                                 <div className="">
                                <h3 className="text-xxl">Leave Details</h3>
                                <ul className="py-16">
                                    <li className="d-flex justify-content-between align-items-center py-2">Total Leaves <span className="fw-semibold text-neutral-900">0.5 Day</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-2">Adjusted Sick Leaves <span className="fw-semibold text-neutral-900">0.5 Day</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-2">Adjusted Casual Leaves <span className="fw-semibold text-neutral-900">0.5 Day</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-2">Effective Leaves <span className="fw-semibold text-neutral-900">18.5 Day</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-10 border-top mt-16">Adjusted Casual Leaves Amount <span className="fw-semibold text-neutral-900">₹800.00</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-10 border-bottom">Adjusted Sick Leaves Amount <span className="fw-semibold text-neutral-900">₹800.00</span></li>
                                    <li className="d-flex justify-content-between align-items-center pt-4 pb-16 my-16 text-neutral-900 fw-medium border-bottom">Total Leave Deductions Amount <span className="fw-semibold text-neutral-900 text-xxl"> -₹400.00</span></li>

                                </ul>
                                </div>


                               <div className="">
                               <h3 className="text-xxl">Lag Deduction Detail</h3>
                               <ul className="py-16">
                                    <li className="d-flex justify-content-between align-items-center py-2">Total Working Hours   <span className="fw-semibold text-neutral-900">207 h</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-2">Required Hours   <span className="fw-semibold text-neutral-900">189 h</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-2">Exact Hours   <span className="fw-semibold text-neutral-900">189 h</span></li> 
                                    <li className="d-flex justify-content-between align-items-center py-10 border-top mt-16 gap-2"><span className="w-100">Lag Hours</span> <div className="w-100"><span className="fw-semibold text-neutral-900">2.5</span> hours</div>  <span className="fw-semibold text-neutral-900 w-100 text-end">-₹222.22</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-10 border-bottom gap-2"><span className="w-100">Production Lag</span> <div className="w-100"><span className="fw-semibold text-neutral-900">2.5</span> hours</div> <span className="fw-semibold text-neutral-900 w-100 text-end">₹800.00</span></li>
                                    <li className="d-flex justify-content-between align-items-center pt-4 pb-16 my-16 text-neutral-900 fw-medium border-bottom">Total Deductions <span className="fw-semibold text-neutral-900 text-xxl"> -₹400.00</span></li> 
                                </ul>
                               </div>

                               <div className="">
                               <h3 className="text-xxl">Encashment Details</h3>
                               <ul className="py-16">  
                                    <li className="d-flex justify-content-between align-items-center py-10 gap-2"><span className="w-100">Leave Encashment</span> <div className="w-100"><span className="fw-semibold text-neutral-900">2.5</span> hours</div>  <span className="fw-semibold text-neutral-900 w-100 text-end">-₹222.22</span></li> 
                                    <li className="d-flex justify-content-between align-items-center py-10 gap-2"><span className="w-100">Incentives</span> <div className="w-100"><span className="fw-semibold text-neutral-900">2.5</span> hours</div> <span className="fw-semibold text-neutral-900 w-100 text-end">₹800.00</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-10 gap-2"><span className="w-100">Previous Month</span> <div className="w-100"><span className="fw-semibold text-neutral-900">2.5</span> hours</div>  <span className="fw-semibold text-neutral-900 w-100 text-end">-₹222.22</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-10">Performance bonus   <span className="fw-semibold text-neutral-900">189 h</span></li> 

                                    <li className="d-flex justify-content-between align-items-center pt-4 pb-16 my-16 text-neutral-900 fw-medium border-bottom">Total Encashment Amount <span className="fw-semibold text-neutral-900 text-xxl"> +₹400.00</span></li> 
                                </ul>
                               </div>

                               <div className="">
                                <h3 className="text-xxl">Final Salary</h3>
                                <div className="text-xxl d-flex justify-content-between align-items-center text-neutral-500 fw-medium border-bottom py-20">Salary <span>₹24000.00</span></div>
                                <ul className="py-16">
                                    <li className="d-flex justify-content-between align-items-center py-2">Leave Deduction <span className="fw-semibold text-neutral-900">0.5 Day</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-2">Lag Deduction <span className="fw-semibold text-neutral-900">0.5 Day</span></li>  
                                    <li className="d-flex justify-content-between align-items-center py-2">PF Deduction <span className="fw-semibold text-neutral-900">0.5 Day</span></li>  
                                    <li className="d-flex justify-content-between align-items-center py-2">Tax Deduction <span className="fw-semibold text-neutral-900">0.5 Day</span></li>  
                                    <li className="d-flex justify-content-between align-items-center py-2">Other Deduction <span className="fw-semibold text-neutral-900">0.5 Day</span></li>  
                                    <li className="d-flex justify-content-between align-items-center py-2">Total Encashment <span className="fw-semibold text-neutral-900">0.5 Day</span></li> 
                                    <li className="d-flex justify-content-between align-items-center mt-16 text-neutral-900 fw-medium border-bottom bg-primary-100 p-16 radius-10">Total Payable Net Amount
                                     <span className="fw-semibold text-dblue text-xxl">₹1087140.00</span></li>  
                                </ul>
                                <div className="Remarks d-inline-flex gap-2"><span className="fw-medium">Remarks:</span> NA</div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-12 col-xl-6">
                            <LeaveTable className="pssitiky" />
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}

export default EmployeeSalarySlip2
