import React, { useEffect, useRef, useState } from 'react';
import SearchInput from './SearchInput';
import DatePickerComponent from './DatePickerComponent';  
import VendorListAdd from '../containers/vendor-container/VendorListAdd';

// Function to get initials from the full name
const getInitials = (name) => {
    const nameParts = name.split(' ');
    const firstInitial = nameParts[0]?.[0] || '';
    const lastInitial = nameParts[nameParts.length - 1]?.[0] || '';
    return `${firstInitial} ${lastInitial}`.toUpperCase();
};

const VendorList = () => {
  const handleSearchInputChange = (e) => {};
  const handleDateRangeChange = (selectedDates) => {};

  const [tableData, setTableData] = useState([
      {
        sno: '01',
        registeredDate: '28-Jun-2024',
        vendorName: 'Kiran Mazumdar',
        panCard: 'ABCTY1234D',
        serviceType: 'Translation',
        phoneNumber: '+91 9854458XXX',
        email: 'Kiranmazumdar@gmail.com',
        address: 'H No. 256, St. No. 1, Roshan Vihar, East Sadat Pur',
        city: 'East Delhi',
        state: 'Delhi',
        stateCode: '110096',
        msme: 'UDYAM-XX-00-000000000',
        document: 'Agreement',
        status: '',
        action: 'Edit',
      },
      {
        sno: '02',
        registeredDate: '28-Jun-2024',
        vendorName: 'Airan Mazumdar',
        panCard: 'DBCTY1234D',
        serviceType: 'Developer',
        phoneNumber: '+91 9854458XXX',
        email: 'Kiranmazumdar@gmail.com',
        address: 'H No. 256, St. No. 1, Roshan Vihar, East Sadat Pur',
        city: 'East Delhi',
        state: 'Delhi',
        stateCode: '110096',
        msme: 'UDYAM-XX-00-000000000',
        document: 'Agreement',
        status: '',
        action: 'Edit',
      },
      // More rows...
  ]);

  // State for filter values
  const [filterValues, setFilterValues] = useState({
    vendorNames: [],
    panCard: [],
    serviceTypes: [],
  });

  // Handle filter change for checkbox selections
  const handleFilterChange = (e, type) => {
      const { value, checked } = e.target;
      setFilterValues((prevValues) => {
          const newValues = { ...prevValues };
          if (checked) {
              newValues[type].push(value);
          } else {
              newValues[type] = newValues[type].filter((item) => item !== value);
          }
          return newValues;
      });
  };

  // Filter data based on selected values
  const filteredData = tableData.filter((row) => {
      const vendorNameMatch =
          filterValues.vendorNames.length === 0 ||
          filterValues.vendorNames.includes(row.vendorName);
      const serviceTypeMatch =
          filterValues.serviceTypes.length === 0 ||
          filterValues.serviceTypes.includes(row.serviceType);
      const panCardMatch =
          filterValues.panCard.length === 0 ||
          filterValues.panCard.includes(row.panCard);
      return vendorNameMatch && serviceTypeMatch && panCardMatch;
  });
  
  // Columns with visibility control
  const [columns, setColumns] = useState([
      { id: 'sno', label: 'S.No.', visible: true },
      { id: 'registeredDate', label: 'Registered Date', visible: true },
      { id: 'vendorName', label: 'Vendor Name', visible: true },
      { id: 'panCard', label: 'Pan Card Number', visible: true },
      { id: 'serviceType', label: 'Service Type', visible: true },
      { id: 'phoneNumber', label: 'Phone Number', visible: true },
      { id: 'email', label: 'Email ID', visible: true },
      { id: 'address', label: 'Address', visible: true },
      { id: 'city', label: 'City', visible: false },
      { id: 'state', label: 'State', visible: true },
      { id: 'stateCode', label: 'State Code', visible: false },
      { id: 'msme', label: 'MSME', visible: false },
      { id: 'document', label: 'Document', visible: false },
      { id: 'status', label: 'Status', visible: true },
      { id: 'action', label: 'Action', visible: true },
  ]);

  const [viewAllChecked, setViewAllChecked] = useState(false);

  // Handle column visibility change
  const handleColumnVisibilityChange = (id) => {
      setColumns((prevColumns) =>
          prevColumns.map((col) =>
              col.id === id ? { ...col, visible: !col.visible } : col
          )
      );
  };

  // Handle View All
  const handleViewAllChange = (event) => {
      const isChecked = event.target.checked;
      setViewAllChecked(isChecked);
      setColumns((prevColumns) =>
          prevColumns.map((col) => ({ ...col, visible: isChecked }))
      );
  };

  // Handle Apply button
  const handleApply = () => {
      setColumns((prevColumns) => [...prevColumns]); // Force a state update
      console.log('Column visibility applied:', columns);
  };
     

  // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code Start

    return (
        <>
            <div className="row">
                <div className="col-xxl-12 col-xl-12">
                    <div className="card h-100">
                        <div className="card-body p-24">
                            <div className="border-bottom pb-16 d-flex justify-content-between align-items-center">
                               <div className="">
                                <h2 className="fw-semibold mb-16 font-24">
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                            <g data-name="20-Arrow Left">
                                                <path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z" />
                                                <path d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z" />
                                            </g>
                                        </svg>
                                    </button>{" "}
                                    Vendor List
                                </h2>
                                <ul className="d-flex align-items-center gap-2">
                                    <li className="fw-medium">
                                        <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                                            <iconify-icon
                                                icon="solar:home-smile-angle-outline"
                                                className="icon text-lg"
                                            ></iconify-icon>
                                            Special Access
                                        </a>
                                    </li>
                                    <li>Vendor List</li>
                                </ul>
                            </div>
                            <div className="">
                                <button data-bs-toggle="offcanvas" data-bs-target="#vendorListadd" aria-controls="vendorListadd"
                                    className="btn btn-primary text-sm heightAllBtnSet44 btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2">
                                    <iconify-icon
                                        icon="ic:baseline-plus"
                                        className="icon text-xl line-height-1"
                                    ></iconify-icon>
                                   Add Vendor
                                </button>
                            </div>
                            </div>

                            <div className="d-flex gap-2 align-items-center py-24 positionTop-0">  
                                <SearchInput handleSearchInputChange={handleSearchInputChange} />
                                <DatePickerComponent onDateRangeChange={handleDateRangeChange} />
                                <div className="dropdown">
                                    <button
                                        className="btn bg-base px-18 py-9 dropdown-toggle toggle-icon border d-flex align-items-center gap-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                    >
                                        <img
                                            src="/assets/images/add-column.svg"
                                            className="img-fluid"
                                            alt="add-column"
                                        />
                                        Manage Columns
                                    </button>

                                    <div className="dropdown-menu border wsetColumn">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="viewAll"
                                                checked={viewAllChecked}
                                                onChange={handleViewAllChange}
                                            />
                                            <label className="form-check-label" htmlFor="viewAll">
                                                View All
                                            </label>
                                        </div>
                                        <div className="mx-hight200px">
                                            {columns.map((col) => (
                                                <div key={col.id} className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={col.id}
                                                        checked={col.visible}
                                                        onChange={() => handleColumnVisibilityChange(col.id)}
                                                    />
                                                    <label className="form-check-label" htmlFor={col.id}>
                                                        {col.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <button className="btn btn-primary mt-16 w-100" onClick={handleApply}>
                                            Apply
                                        </button>
                                    </div>  
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2" 
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="2em"
                                        height="1.5em"
                                        viewBox="0 0 24 24"
                                    >
                                        <path fill="currentColor" d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z" />
                                    </svg>{" "}
                                    Filter
                                </button>
                            </div>

                            <div className='col-md-12'>  
                            <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
        <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                                   
                                    <thead> 
                                    <tr>
        {columns.map((col) => (
            col.visible && (
                <th className='align-middle' key={col.id}>
                    {col.label}            
                    {col.id === 'vendorName' && (
                        <span className="dropdown">
                            <button
                                className="btn px-18 py-4 text-primary-light"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i className="mdi mdi-filter-variant mdi-24px"></i>
                            </button>
                            <ul className="dropdown-menu border dropdown-menu-end">
                                {Array.from(new Set(tableData.map((row) => row.vendorName))).map((vendor) => (
                                    <li key={vendor} className="form-check d-flex align-items-center gap-2 cttbcheck">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={vendor} // Unique ID for each checkbox
                                            value={vendor}
                                            checked={filterValues.vendorNames.includes(vendor)}
                                            onChange={(e) => handleFilterChange(e, 'vendorNames')}
                                        />
                                        <label className="form-check-label" htmlFor={vendor}>
                                            {vendor}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </span>
                    )}
                    {col.id === 'serviceType' && (
                        <span className="dropdown">
                            <button
                                className="btn px-18 py-4 text-primary-light"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i className="mdi mdi-filter-variant mdi-24px"></i>
                            </button>
                            <ul className="dropdown-menu border dropdown-menu-end">
                                {Array.from(new Set(tableData.map((row) => row.serviceType))).map((serviceType) => (
                                    <li key={serviceType} className="form-check d-flex align-items-center gap-2 cttbcheck">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={serviceType} // Unique ID for each checkbox
                                            value={serviceType}
                                            checked={filterValues.serviceTypes.includes(serviceType)}
                                            onChange={(e) => handleFilterChange(e, 'serviceTypes')}
                                        />
                                        <label className="form-check-label" htmlFor={serviceType}>
                                            {serviceType}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </span>
                    )}
                    
                </th>
            )
        ))}
    </tr>
</thead>

                                    <tbody>
                                        {filteredData.map((row, index) => (
                                            <tr key={index}>
                                                {columns.map(
                                                    (col) =>
                                                        col.visible && (
                                                            <td key={col.id}>
                                                                {col.id === 'vendorName' ? (
                                                                    <div className="user-cell">
                                                                        <span className="user-initials">{getInitials(row.vendorName)}</span> {row.vendorName}
                                                                    </div>
                                                                ): 
                                                                col.id === 'address' ? (
                                                                    <div className="d-flex align-items-center gap-2 mxwsetnotes">
                                                                        <i className='mdi mdi-map-marker text-neutral-400 me-2'></i> {/* Add your icon here */}
                                                                        <span>{row.address}</span> {/* Display the address */}
                                                                    </div>
                                                                ) :
                                                                col.id === 'document' ? (
                                                                    <div className="">
                                                                        <button className="d-flex align-items-center gap-2 px-8 py-8">
                                                                            <span>{row.document}</span> <i className='mdi mdi-file-document text-dblue'></i>
                                                                        </button>
                                                                    </div>
                                                                )
                                                                :
                                                                col.id === 'status' ? (
                                                                    <div className="form-switch switch-primary d-flex align-items-center gap-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id="yes"
                                                                            defaultChecked=""
                                                                        />
                                                                        <label
                                                                            className="form-check-label line-height-1 fw-medium text-secondary-light"
                                                                            htmlFor="yes"
                                                                        >
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                )
                                                                :
                                                                col.id === 'action' ? (
                                                                    <div className=''>
                                                                        <button className="d-flex align-items-center gap-2 text-dblue px-8 py-8" data-bs-toggle="offcanvas" data-bs-target="#vendorListadd" aria-controls="vendorListadd">
                                                                            <span>{row.action}</span>  <i className='mdi mdi-pencil'></i>
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    row[col.id]
                                                                )}
                                                            </td>
                                                        )
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <VendorListAdd />
        </>
    );
};

export default VendorList;
