import React, { useMemo, useState, useEffect, useRef } from 'react';
import { costFormat, calculateDeviation, calculatePercentage } from 'src/utils/CommonUtils';
import SummariesComponent from './SummariesComponent';
import { useSticky } from 'src/hooks/useSticky';

const PddstatsProject = (search) => {
  const [data, setData] = useState([]);

  // Effect to handle data loading
  useEffect(() => {
    setData(search?.search || []);
  }, [search]);

  // Function to determine text color based on the deviation percentage
  const getClassName = (value) => {
    return value.includes('-') ? 'text-red' : 'text-green';
  };

  // Calculate totals
  let totalOAD = 0;
  let totalCMD = 0;

  search?.search.forEach((item) => {
    totalOAD += item.totalOad || 0;
    totalCMD += item.totalcmd || 0;
  });

  const trimmedQuery = (typeof search.searchQuery === 'string' ? search.searchQuery : '').trim().toLowerCase();
  const filteredData = trimmedQuery
    ? data.filter((item) =>
      item.ClientProjectCode.toLowerCase().includes(trimmedQuery) ||
      item.ClientRegisteredName.toLowerCase().includes(trimmedQuery) ||
      item.vertical.toLowerCase().includes(trimmedQuery)
    )
    : data; // Return all data if the searchQuery is empty

  const [totalOAD2, totalCMD2, totalDelivery2] = filteredData.reduce(
    ([oadSum, cmdSum, deliverySum], item) => [
      oadSum + (item.totalOad || 0),
      cmdSum + (item.totalcmd || 0),
      deliverySum + (item.totalDeliveryCost || 0)
    ],
    [0, 0, 0]
  );

  // Summaries for the table
  const summaries3 = [
    { title: 'Total Project', value: filteredData.length },
    { title: 'Total OAD', value: costFormat(totalOAD2) },
    { title: 'Total CMD', value: costFormat(totalCMD2) },
    { title: 'Total Delivered', value: costFormat(totalDelivery2) },
  ];

  // const { isSticky, elementRef } = useSticky(); // Use the custom hook

  useEffect(() => {
    if (filteredData.length > 0) {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
  
      return () => {
        tooltipList.forEach(tooltip => tooltip.dispose());
      };
    }
  }, [filteredData]);  // Run tooltip initialization when `filteredData` changes


  // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code Start

  return (
    <>
    <div className="TotlUbgset pb-24">
      <SummariesComponent summaries={summaries3} /></div>
      <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
        <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Project Code</th>
              <th>Client Name</th>
              <th>Vertical</th>
              <th>OAD</th>
              <th>% OAD</th>
              <th>CMD</th>
              <th>% CMD</th>
              <th>Delivered</th>
              <th>% Deviation</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 ? (
              <tr>
                <td colSpan="10" className="text-center">No data available.</td>
              </tr>
            ) : (
              filteredData.map((item, index) => {
                const deviation = calculateDeviation(item.totalDeliveryCost, item.totalcmd);

                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.ClientProjectCode}</td>
                    <td>
                      <div
                        className="detaset"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-custom-class="tooltip-primary"
                        data-bs-title={`${item.ClientRegisteredName}`}  // Dynamic tooltip content
                      >
                        {item.ClientRegisteredName}
                      </div>
                    </td>
                    <td>{item.vertical}</td>
                    <td className='text-end'>{costFormat(item.totalOad)}</td>
                    <td className={`text-end ${getClassName(calculatePercentage(item.totalOad, totalOAD))}`}>
                      {calculatePercentage(item.totalOad, totalOAD)}%
                    </td>
                    <td className='text-end'>{costFormat(item.totalcmd)}</td>
                    <td className={`text-end ${getClassName(calculatePercentage(item.totalcmd, totalCMD))}`}>
                      {calculatePercentage(item.totalcmd, totalCMD)}%
                    </td>
                    <td className="text-end">{costFormat(item.totalDeliveryCost)}</td>
                    <td className={`text-end ${getClassName(deviation)}`}>{deviation}%</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PddstatsProject;
