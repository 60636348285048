import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter,Navigate , Routes, Route } from "react-router-dom";
import Login from "./containers/login-container/Login";
import PrivateRoute from "./hoc/PrivateRouteHOC";
import MainContainer from 'src/containers/main-container/MainContainer';
import { appInitAction } from "./containers/app-container/actions/AppActions";
import AuthCheck from "./containers/login-container/AuthCheck";
 

const Routings = () => {
  // const authToken = useSelector((state) => state.auth.user?.jwtToken);
  // const dispatch = useDispatch();
 

  // useEffect(() => {
  //   dispatch(appInitAction()); 
  // }, [authToken, dispatch]);

  return ( 
        <BrowserRouter>
          <Routes> 
            <Route path="authCheck" element={<AuthCheck />} />
            <Route path="login" element={<Login />} />
            <Route path="/*" element={<PrivateRoute Component={MainContainer} />} />
            {/* <Route path="home" element={<PrivateRoute Component={Home} />} /> */}
          </Routes>
        </BrowserRouter>
  );
};

export default Routings;
