import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const ProjectPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    ProjectName: PropTypes.string,
});

export const ProjectDefaultProps = { 
    ProjectName: '',
    categoryDetails: [],
    _id: undefined
};

export const ProjectYupSchema = Yup.object().shape({ 
    ProjectName: Yup.string().required('Required'),
});
export const ProjectPermissionsYupSchema = Yup.object().shape({
    permissions: Yup.array().required('Required').min(1, 'Give at least 1 Permissions'),
});
export default {
    ProjectDefaultProps,
    ProjectPropTypes,
    ProjectYupSchema,
};
