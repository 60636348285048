import { useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import CategoryTree from "./CategoryTree"


const ProjectTree=({showModal})=>{
  const {currentRecord}  = useSelector(state=>state.ProjectReducer,shallowEqual)
    // const [isOpen,setIsOpen] = useState(false)
    // const dispatch = useDispatch();
    // const handleSelectProject = (e,client)=>{
    //     e.stopPropagation(); // Prevent event propagation
    //     setIsOpen(!isOpen)
    //     dispatch(productionAssignClientIdAction({
    //         data:{
    //             clientId:client._id,
    //             ClientProjectCode:client.ClientProjectCode
    //         }
    //     }))
    // }
    const handleCreate=(e)=>{
      // e.stopPropagation(); // Prevent event propagation 
      showModal()
    }
    return (
      <>
         <div className="accordion-item">
                                  <h2 className="accordion-header" id="sub-heading6">
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#sub-collapse6"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      <div className="d-flex gap-3 align-items-center">
                                        {`${currentRecord?.RefClientId?.ClientProjectCode}(${currentRecord?.ProjectSubCode})`}
                                      </div>
                                      <div 
                                          className="btnwttip" 
                                          data-bs-toggle="tooltip" 
                                          data-bs-placement="top"
                                          data-bs-custom-class="tooltip-dark" 
                                          data-bs-title="Click to Create New Category" 
                                          aria-describedby="tooltip548014"
                                            >
                                              <button className="addBnme1 CategoryModual" 
                                                    type="button"
                                                    onClick={handleCreate}
                                              >
                                              <i className="mdi mdi-plus" />
                                            </button>
                                            </div>
                                    </button>
                                  </h2>
                                  <div
                                        id="sub-collapse6"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="sub-heading6"
                                        data-bs-parent="#sub-accordion2"
                                      >
                                            <div className="accordion-body">
                                              <div
                                                className="accordion sub-accordion newSubCategory"
                                                id="sub-accordion3"
                                              > 
                                                {
                                                  currentRecord?.categoryDetails?.map((category)=>{
                                                    return <CategoryTree key={category._id} category={category} showModal={showModal} />
                                                    
                                                  })
                                                }
                                             </div>
                                            </div>
                                    </div>

                                  {/* <div
                                    id="sub-collapseOne"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="sub-headingOne"
                                    data-bs-parent="#sub-accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <div
                                        className="accordion sub-accordion2 newCategory"
                                        id="sub-accordion2"
                                      >
                                        <div className="accordion-item">
                                          <h2 className="accordion-header" id="sub-heading6">
                                            <button
                                              className="accordion-button collapsed"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#sub-collapse6"
                                              aria-expanded="true"
                                              aria-controls="collapse6"
                                            >
                                              {currentRecord?.ProjectSubCode}
                                            </button>

                                            <div className="btnwttip" 
                                            data-bs-toggle="tooltip" 
                                            data-bs-placement="top" 
                                            data-bs-custom-class="tooltip-dark" 
                                            data-bs-title="Click to Create New Category"
                                            aria-describedby="tooltip548014">
                                            <button 
                                                className="addBnme1 CategoryModual" 
                                                type="button"
                                                onClick={handleCreate}
                                              >
                                              <i className="mdi mdi-plus" />
                                            </button>
                                            </div>

                                          </h2>
                                          <div
                                            id="sub-collapse6"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="sub-heading6"
                                            data-bs-parent="#sub-accordion2"
                                          >
                                            <div className="accordion-body">
                                              <div
                                                className="accordion sub-accordion newSubCategory"
                                                id="sub-accordion3"
                                              > 
                                                {
                                                  currentRecord.categoryDetails?.map((category)=>{
                                                    return <CategoryTree key={category._id} category={category} showModal={showModal} />
                                                    
                                                  })
                                                }
                                             </div>
                                            </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
      </>
    )
  }
  export default ProjectTree