import { useEffect, useRef, useState } from "react";

const VendorList=({
    columns,
    tableData,
    filterValues,
    handleFilterChange,
    filteredData,
    getInitials,

})=>{

    // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code End

   return (
    <>
    <div className='col-md-12'>
    <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
    <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                <thead>
                    <tr>
                        {columns.map((col) => (
                        col.visible && (
                        <th className='align-middle' key={col.id}>
                        {col.label}
                        {col.id === 'vendorName' && (
                        <span className="dropdown">
                            <button
                                className="btn px-18 py-4 text-primary-light"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                >
                            <i className="mdi mdi-filter-variant mdi-24px"></i>
                            </button>
                            <ul className="dropdown-menu border dropdown-menu-end">
                                {Array.from(new Set(tableData.map((row) => row.vendorName))).map((vendor) => (
                                <li key={vendor} className="form-check d-flex align-items-center gap-2 cttbcheck">
                                    <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={vendor} // Unique ID for each checkbox
                                    value={vendor}
                                    checked={filterValues.vendorNames.includes(vendor)}
                                    onChange={(e) => handleFilterChange(e, 'vendorNames')}
                                    />
                                    <label className="form-check-label" htmlFor={vendor}>
                                    {vendor}
                                    </label>
                                </li>
                                ))}
                            </ul>
                        </span>
                        )}
                        {col.id === 'serviceType' && (
                        <span className="dropdown">
                            <button
                                className="btn px-18 py-4 text-primary-light"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                >
                            <i className="mdi mdi-filter-variant mdi-24px"></i>
                            </button>
                            <ul className="dropdown-menu border dropdown-menu-end">
                                {Array.from(new Set(tableData.map((row) => row.serviceType))).map((serviceType) => (
                                <li key={serviceType} className="form-check d-flex align-items-center gap-2 cttbcheck">
                                    <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={serviceType} // Unique ID for each checkbox
                                    value={serviceType}
                                    checked={filterValues.serviceTypes.includes(serviceType)}
                                    onChange={(e) => handleFilterChange(e, 'serviceTypes')}
                                    />
                                    <label className="form-check-label" htmlFor={serviceType}>
                                    {serviceType}
                                    </label>
                                </li>
                                ))}
                            </ul>
                        </span>
                        )}
                        </th>
                        )
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((row, index) => (
                    <tr key={index}>
                        {columns.map(
                        (col) =>
                        col.visible && (
                        <td key={col.id}>
                        {col.id === 'vendorName' ? (
                        <div className="user-cell">
                            <span className="user-initials">{getInitials(row.vendorName)}</span> {row.vendorName}
                        </div>
                        ): 
                        col.id === 'address' ? (
                        <div className="d-flex align-items-center gap-2 mxwsetnotes">
                            <i className='mdi mdi-map-marker text-neutral-400 me-2'></i> {/* Add your icon here */}
                            <span>{row.address}</span> {/* Display the address */}
                        </div>
                        ) :
                        col.id === 'document' ? (
                        <div className="">
                            <button className="d-flex align-items-center gap-2 px-8 py-8">
                            <span>{row.document}</span> <i className='mdi mdi-file-document text-dblue'></i>
                            </button>
                        </div>
                        )
                        :
                        col.id === 'status' ? (
                        <div className="form-switch switch-primary d-flex align-items-center gap-3">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="yes"
                                defaultChecked=""
                                />
                            <label
                                className="form-check-label line-height-1 fw-medium text-secondary-light"
                                htmlFor="yes"
                                >
                            Yes
                            </label>
                        </div>
                        )
                        :
                        col.id === 'action' ? (
                        <div className=''>
                            <button className="d-flex align-items-center gap-2 text-dblue px-8 py-8" data-bs-toggle="offcanvas" data-bs-target="#vendorListadd" aria-controls="vendorListadd">
                            <span>{row.action}</span>  <i className='mdi mdi-pencil'></i>
                            </button>
                        </div>
                        ) : (
                        row[col.id]
                        )}
                        </td>
                        )
                        )}
                    </tr>
                    ))}
                </tbody>
            </table>
            </div>
            </div>
    </>
   )
}
export default VendorList