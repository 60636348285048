import { useEffect } from "react";
import { generateColor, getContrastYIQ } from "src/utils/utils"

const AssigneeCircle=({user})=>{
    useEffect(() => {
        // Initialize Bootstrap tooltips for elements with data-bs-toggle="tooltip"
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
        // Cleanup tooltips when the component unmounts
        return () => {
          tooltipList.forEach(tooltip => tooltip.dispose());
        };
      }, []);

  return(
    <span
    className="img imgsSet me-0"
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    data-bs-custom-class="tooltip-dark"
    data-bs-title={`${user.firstName} ${user.lastName}`}
  >
    {user.profilePicture ? (
      <img
        src={user.profilePicture}
        className="img-fluid"
        alt=""
      />
    ) : (
      <span
         data-bs-toggle="tooltip"
         data-bs-placement="top"
         data-bs-custom-class="tooltip-dark"
         data-bs-title={`${user.firstName} ${user.lastName}`}
        className="initials d-flex justify-content-center align-items-center w-100 h-100"
        style={{
          backgroundColor: generateColor(`${user.firstName}${user.lastName}`),
          color: getContrastYIQ(generateColor(`${user.firstName}${user.lastName}`)),
        }}
      >
        {`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
      </span>
    )}
  </span>
  )
}
export default AssigneeCircle