import React, { useState } from 'react';
import DateRangePickerWithAll from './DateRangePickerWithAll';
import moment from 'moment';

const TableComponent = ({ heading, tableData }) => {
  const [dateRangeTable, setDateRangeTable] = useState([moment().subtract(29, 'days'), moment()]); 

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-24 gap-3">
        <h5 className="card-title">{heading}</h5>
        <div className="ms-auto d-flex justify-content-end">
          <DateRangePickerWithAll value={dateRangeTable} onChange={setDateRangeTable} /> 
        </div>
      </div>
      <div className="table-responsive">
        <table className="table basic-border-table mb-0 table-hover">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Task</th>
              <th scope="col">Hours</th>
              <th scope="col">Percentage</th>
              <th scope="col">Cost</th>
              <th scope="col">Revenue</th>
              <th scope="col" className="text-center">Profit %</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.date}</td>
                <td>{row.task}</td>
                <td>{row.hours}</td>
                <td>{row.percentage}</td>
                <td>{row.cost}</td>
                <td>{row.revenue}</td>
                <td className="text-center">{row.profit}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
