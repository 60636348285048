const NewSubProjectAlter=({handleNewProject})=>{
    return (
<div className="modal fade cusmodl" id="taskModual" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content"> 
                    <div className="modal-body text-center"> 
                        <h6 className="fw-bold text-lg mb-24">Are you sure to create a <span className="d-block">new Sub-Project?</span></h6>  

                        <div className="d-flex gap-4 justify-content-between">
                            <button type="button" class="btn btn-primary radius-8 px-20 py-11 w-100" data-bs-dismiss="modal" aria-label="Close" onClick={handleNewProject}>Yes</button>
                            <button type="button" class="btn radius-8 px-20 py-11 w-100  btn-outline-neutral-900" data-bs-dismiss="modal" aria-label="Close">No</button>
                            </div>  
                    </div> 
                </div>
            </div>
        </div>

    )
}
export default NewSubProjectAlter