import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { assignUserByTask, taskCreateAPI } from 'src/api/TaskApi';
import UserMultiSelect from 'src/business-components/UserMultiSelect';

const UpsertTask = ({ onTaskSaved }) => {
  const { projectId } = useParams();
  const [userId, setUserId] = useState(null);
  const [taskname, setTaskName] = useState('');
  const [target, setTarget] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState([]);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUserId(storedUser?._id);
    }
  }, []);

  const saveTask = async () => {
    const assigneeIds=assignedUsers.map((user) => user.id);
    let body = {
      taskName: taskname,
      target: target,
      unit: selectedUnit,
      projectId: projectId,
      by: userId,
      item: selectedItem,
      assigneeIds: assigneeIds,
    };  
    try {
      const resp = await taskCreateAPI(body);
      if (onTaskSaved) {
         await assignUserByTask({ projectId: projectId,assigneeIds});
        onTaskSaved(); // Trigger the searchTask function from TaskContainer
      }
      setTaskName('');
      setTarget('');
      setSelectedUnit('');
      setSelectedItem('');
      setAssignedUsers([]);
      toast.success('Task created successfully!');
    } catch (err) {
      toast.error('Failed to create task. '+err.message);
    }
  };

  const handleItemChange = (item) => {
    setSelectedItem(item);
    if (item === 'Daily Target') {
      setSelectedUnit('Per Day');
    } else {
      setSelectedUnit('min');
    }
    setDropdownOpen(false); // Close the dropdown after selection
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Inline styles
  const styles = {
    customDropdown: {
      position: 'relative',
      border: '1px solid #ced4da',
      borderRadius: '4px',
      padding: '3px 12px',
      cursor: 'pointer',
    },
    selectedItem: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px',
      padding: '4px 8px',
      marginRight: '4px',
    },
    placeholder: {
      color: '#6c757d',
    },
    dropdownOptions: {
      position: 'absolute',
      top: '100%',
      left: '0',
      right: '0',
      zIndex: '1000',
      backgroundColor: '#fff',
      border: '1px solid #ced4da',
      maxHeight: '200px',
      overflowY: 'auto',
      borderRadius: '4px',
    },
    dropdownOption: {
      padding: '8px 12px',
      cursor: 'pointer',
    },
    dropdownOptionHover: {
      // backgroundColor: '#f8f9fa',
    }
  };

  return (
    <div
      className="offcanvas offcanvas-end filterSecPopup"
      id="AddNewTaskPopup"
      aria-labelledby="addNewTaskSec"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="addNewTaskSec">
          Add New Task
        </h5>
        <button
          type="button"
          className="btn-close d-none"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div className="offcanvas-body pt-0 pb-0">
        <div className="assignPopoHeight">
          <div className="selectSec pt-0">
            <label htmlFor="">Task</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Task Name"
              value={taskname}
              onChange={(e) => setTaskName(e.target.value)}
            />
           
          </div>
         <div className="d-flex align-items-center gap-2">
         <div className="selectSec pt-0  w-50">
            <label htmlFor="">Target</label>
            <div className="costSec w-100">
              <div className="costinputSec w-100">
                <input
                  type="number"
                  min={0}
                  defaultValue={0}
                  className="w-100"
                  value={target}
                  onChange={(e) => setTarget(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="selectSec filterStyleSec pt-2 w-50">
            <label htmlFor="">Item</label>
            <select
    className="form-select"
    value={selectedItem}
    onChange={(e) => handleItemChange(e.target.value)}
  >
    <option value="">Select Item</option>
    <option value="Daily Target">Daily Target</option>
    <option value="Bound">Bound</option>
    <option value="Open">Open</option>
  </select>
            {/* <div className="w-100 dropdown-toggle d-flex align-items-center justify-content-between" style={styles.customDropdown}>
              <div
                style={styles.selectedItem}
                onClick={toggleDropdown}
              >
                {selectedItem || 'Select Item'}
              </div>
              {dropdownOpen && (
                <div style={styles.dropdownOptions}>
                  <div
                    style={styles.dropdownOption}
                    onClick={() => handleItemChange('Daily Target')}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = styles.dropdownOptionHover.backgroundColor)}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                  >
                    Daily Target
                  </div>
                  <div
                    style={styles.dropdownOption}
                    onClick={() => handleItemChange('Bound')}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = styles.dropdownOptionHover.backgroundColor)}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                  >
                    Bound
                  </div>
                  <div
                    style={styles.dropdownOption}
                    onClick={() => handleItemChange('Open')}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = styles.dropdownOptionHover.backgroundColor)}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                  >
                    Open
                  </div>
                </div>
              )}
            </div> */}
          </div>
         </div>
          <div className="selectSec filterStyleSec pt-2">
            <label htmlFor="">Unit</label>
            <input
              type="text"
              className="form-control"
              value={selectedUnit}
              disabled
            />
          </div>
          <div className="selectSec filterStyleSec pt-2">
            <label htmlFor="">Assign to</label>
              <UserMultiSelect 
                 className="form-control p-0 border-0 heightsetAuto"
                 name="assignTo" 
                 value={assignedUsers}
                 onChange={(value) => setAssignedUsers(value)} 
              />
          </div>
        </div>
        <div className="psstyky">
          <button
            type="button"
            data-bs-dismiss="offcanvas"
             aria-label="Close"
            className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
            onClick={saveTask}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpsertTask;
