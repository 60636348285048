import { useCallback, useState } from "react"
import { useDispatch } from 'react-redux';
import SubCategoryTree from "./SubCategoryTree";
import { clientProductionAssignCategoryAction } from "../actions/ClientProductionActions";

const CategoryTree=({showModal,category})=>{
  const dispatch = useDispatch();
    const handleCategorySelect = (e,categoryDetails)=>{
        e.stopPropagation(); // Prevent event propagation
        // setIsOpen(!isOpen)
        dispatch(clientProductionAssignCategoryAction({
          data:{
            categoryId: categoryDetails._id,
            categoryName: categoryDetails.categoryName
          }
        }))
        if(category.subCategoryDetails.length===0){
                 document.querySelectorAll('.accordion-collapse').forEach((element) => {
                element.classList.remove('show');
              });
              document.querySelectorAll('.accordion-button').forEach((element) => {
                element.classList.add('collapsed');
              });
        }
    }
    return (
      <>
            <div className="accordion-item">
              <h2
                className="accordion-header"
                id={`sub-heading11${category._id}`}
              >
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#sub-collapse11${category._id}`}
                  aria-expanded="true"
                  aria-controls="collapse11"
                  onClick={(e) => {handleCategorySelect(e,category)}}
                >
                  {category.categoryName}
                </button>

                <div className="btnwttip" 
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    data-bs-custom-class="tooltip-dark"
                    data-bs-title="Click to Create New Sub-Category" 
                    aria-describedby="tooltip548014"
                >
                <button 
                    className="addBnme1 CategoryModual" 
                    onClick={()=>{showModal(category._id)}}
                >
                <i className="mdi mdi-plus" />
                </button>
                </div>
              </h2>
              <div
                id={`sub-collapse11${category._id}`}
                className="accordion-collapse collapse"
                aria-labelledby={`sub-heading11${category._id}`}
                data-bs-parent="#sub-accordion3"
              >
                <div className="accordion-body">
                  <div class="accordion accordion-flush newMoreCategory" id="accordionFlushExample">                   
                  {
                    category.subCategoryDetails?.map((subCategory)=>{
                      return <SubCategoryTree key={subCategory._id} subCategory={subCategory} showModal={showModal} />
                      
                    })
                  }
                  </div>
                </div>
              </div>
            </div>
          
      </>
    )
  }
  export default CategoryTree