import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const LoginPropTypes = PropTypes.shape({
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    rememberme: PropTypes.string.isRequired,
});

export const LoginDefaultProps = {
    username: '',
    password: '',
    rememberme: '', 
};

export const LoginYupSchema = Yup.object().shape({
    // username: Yup.string().required(),
    // password: Yup.string().required()
});

export default {
    LoginDefaultProps,
    LoginPropTypes,
    LoginYupSchema,
};
