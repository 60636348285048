import React, { useState } from "react";

const FinalSalary = () => {
    // State variables for each field
    const [salary, setSalary] = useState(24000.0);
    const [leaveDeduction, setLeaveDeduction] = useState(-400.0);
    const [lagDeduction, setLagDeduction] = useState(-222.22);

    const [taxDeduction, setTaxDeduction] = useState(-222.22);
    const [otherDeduction, setOtherDeduction] = useState(-222.22);
    const [pfDeduction, setPfDeduction] = useState(-222.22);


    const [totalEncashment, setTotalEncashment] = useState(0.0);
    const [finalSalary, setFinalSalary] = useState(salary + leaveDeduction + lagDeduction + + taxDeduction + otherDeduction +  pfDeduction + totalEncashment);
    const [remarks, setRemarks] = useState("");

    // Update final salary whenever deductions or encashment change
    React.useEffect(() => {
        setFinalSalary(salary + leaveDeduction + lagDeduction + taxDeduction + otherDeduction +  pfDeduction + totalEncashment);
    }, [salary, leaveDeduction, lagDeduction, totalEncashment, taxDeduction, otherDeduction,  pfDeduction,]);

    return (
        <div className="setbdn100 mt-24">
            <h4 className="card-title">Final Salary</h4>
            <div className="radius-10 border p-16">
                <div className="align-items-center d-flex justify-content-between py-8 mt-16 border-bottom">
                    <label className="form-label mb-0 text-xl fw-bold">Salary</label>
                    <div className="d-flex gap-2 align-items-center text-xl fw-bold">
                        ₹{salary.toFixed(2)}
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Leave Deduction</label>
                    <div className={`textAmunt ${leaveDeduction < 0 ? "text-red" : ""}`}>
                        {leaveDeduction < 0 ? `-₹${Math.abs(leaveDeduction).toFixed(2)}` : `₹${leaveDeduction.toFixed(2)}`}
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">PF Deduction</label>
                    <div className={`textAmunt ${pfDeduction < 0 ? "text-red" : ""}`}>
                        {pfDeduction < 0 ? `-₹${Math.abs(pfDeduction).toFixed(2)}` : `₹${pfDeduction.toFixed(2)}`}
                    </div>
                </div>
                    <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Tax Deduction</label>
                    <div className={`textAmunt ${taxDeduction < 0 ? "text-red" : ""}`}>
                        {taxDeduction < 0 ? `-₹${Math.abs(taxDeduction).toFixed(2)}` : `₹${taxDeduction.toFixed(2)}`}
                    </div>
                </div>
                    <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Other Deduction</label>
                    <div className={`textAmunt ${otherDeduction < 0 ? "text-red" : ""}`}>
                        {otherDeduction < 0 ? `-₹${Math.abs(otherDeduction).toFixed(2)}` : `₹${otherDeduction.toFixed(2)}`}
                    </div>
                </div>
                    


                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Lag Deduction</label>
                    <div className={`textAmunt ${lagDeduction < 0 ? "text-red" : ""}`}>
                        {lagDeduction < 0 ? `-₹${Math.abs(lagDeduction).toFixed(2)}` : `₹${lagDeduction.toFixed(2)}`}
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Total Encashment</label>
                    <div className="textAmunt">
                        ₹{totalEncashment.toFixed(2)}
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8 py-16 px-16 border-top border-bottom mt-16">
                    <label className="form-label mb-0 text-dblue text-xxl">Final Salary</label>
                    <span className="text-xxl fw-bold text-dblue">
                        ₹{finalSalary.toFixed(2)}
                    </span>
                </div>

                <div className="mt-16">
                    <label className="fw-bold mb-2">Remarks</label>
                    <textarea
                        className="form-control"
                        placeholder="Remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                    ></textarea>
                    <button
                        type="submit"
                        className="btn btn-primary-600 w-100 mt-16"
                        onClick={() => console.log("Form submitted with remarks:", remarks)}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FinalSalary;
