// Select2Component.js
import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2';

const Select2Component = ({ options, placeholder, onChange }) => {
  const selectRef = useRef(null);

  useEffect(() => {
    const $select = $(selectRef.current);
    
    // Initialize Select2
    $select.select2({
      placeholder: placeholder,
      allowClear: true
    });

    // Attach event listener to capture change events
    $select.on('change', (e) => {
      const selectedValue = e.target.value;
      onChange(selectedValue);
    });

    // Cleanup on component unmount
    return () => {
      $select.select2('destroy');
    };
  }, [placeholder, onChange]);

  return (
    <select ref={selectRef} style={{ width: '100%' }}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default Select2Component;
