import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const DepartmentPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    departmentName: PropTypes.string,
});

export const DepartmentDefaultProps = { 
    departmentName: '',
    _id: undefined
};

export const UserYupSchema = Yup.object().shape({ 
    firstName: Yup.string().required('Required'),    
    email: Yup.string().required('Required').email('Invalid email format'),
});

export default {
    DepartmentDefaultProps,
    DepartmentPropTypes,
    UserYupSchema,
};
