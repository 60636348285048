import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';


export const skillsSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectSkill/${projectId}`;
    return postRequest({
        url,
        data
    })
};
export const taskProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectTask/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const individualProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectUsers/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const vendorDeliveryProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectVendorDelivery/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const moduleProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectModule/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const productionProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectProduction/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const profitandlossProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectProfitAndLoss/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const productionApproveUpdate = function ({
    productionId,
    data
}) {
    const url = `${config.baseUrl}production/approval/${productionId}`;
    return putRequest({
        url,
        data
    })
};


export const productionRejectionUpdate = function ({
    productionId,
    data
}) {
    const url = `${config.baseUrl}production/rejection/${productionId}`;
    return putRequest({
        url,
        data
    })
};

