import React from 'react';

const sampleData = [
    { date: '09 Aug 2024', amount: '+300' },
    { date: '09 Aug 2024', amount: '+200' },
    { date: '09 Aug 2024', amount: '+1500' },
    { date: '09 Aug 2024', amount: '+900' },
    // Add more entries as needed
];

const PaymentView = ({ PaymentView }) => {
    // Calculate total from sampleData
    const totalAmount = sampleData.reduce((total, item) => {
        // Convert amount string to a number and sum it
        return total + parseFloat(item.amount.replace(/[^0-9.-]+/g, ""));
    }, 0);

    return (
        <div
            className="modal fade cusmodl"
            id="PaymentView"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-sm modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body p-0">
                        <table className="table basic-border-table mb-0 table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col" className="text-center">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sampleData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.date}</td>
                                        <td
                                            className={`text-center fw-semibold ${item.amount.startsWith('+') ? 'text-green' : 'text-red'
                                                }`}
                                        >
                                            {item.amount}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Net Amount</td>
                                    <th className="text-center">₹{totalAmount.toFixed(2)}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentView;
