import { useState } from "react"
import Attendance from "./Attendance"
import HubStaffHours from "./HubStaffHours"
import Information from "./Information"
import ProjectModuleTaskCost from "./ProjectModuleTaskCost"
import ProductionStatus from "./ProductionStatus"
import Productivity from "./Productivity"
import ProjectModuleWiseCost from "./ProjectModuleWiseCost"
import ProjectWiseCost from "./ProjectWiseCost"
import PerfomanceProductionList from "./PerfomanceProductionList"



const EmployeePerfomanceContainer=()=>{
    const [projectId, setProjectId] = useState(null);
    const [moduleId, setModuleId] = useState(null);
    return (
        <>
         <div> 
            <div className="row gy-4">
                <Information/>
                <Attendance/>
                <HubStaffHours/>
                <Productivity/>
                <ProjectWiseCost setProjectId={setProjectId}/>
                <ProjectModuleWiseCost projectId={projectId} setModuleId={setModuleId}/>
                <ProjectModuleTaskCost moduleId={moduleId}/>
                <PerfomanceProductionList/>
                 
            </div>
        </div>
        </>
    )
}
export default EmployeePerfomanceContainer