import { useState } from 'react';

const SummaryInvoiceFilter = () => {
    const [filters, setFilters] = useState({
        projectCode: '',
        clientName: '',
        invoiceNumber: '',
        invoiceComparison: 'Above',
        netAmount: '',
        netAmountComparison: 'Above'
    });

    const handleClear = () => {
        setFilters({
            projectCode: '',
            clientName: '',
            invoiceNumber: '',
            invoiceComparison: 'Above',
            netAmount: '',
            netAmountComparison: 'Above'
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevState => ({ ...prevState, [name]: value }));
    };

    return (
        <div
            className="offcanvas offcanvas-end"
            id="SummaryInvoiceFilter"
            aria-labelledby="filterRightLayout"
        >
            <div className="offcanvas-header border-bottom d-flex justify-content-between align-items-center">
                <h5 className="offcanvas-title" id="filterRightLayout">
                    Filter
                </h5>
                <button
                    type="button"
                    className="btn-close d-none"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                />
                <button
                    className="btn rounded-pill text-primary-600 radius-8 px-20 py-11"
                    type="button"
                    onClick={handleClear}
                >
                    Clear
                </button>
            </div>
            <div className="offcanvas-body">
                <div className="assignPopoHeight">
                    <div className="mb-8">
                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">
                            Project Code
                        </label>
                        <select
                            name="projectCode"
                            value={filters.projectCode}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value="">Select Project Code</option>
                            <option value="1">Awesome</option>
                            <option value="2">Beast</option>
                            <option value="3">Compatible</option>
                            <option value="4">Thomas Edison</option>
                            <option value="5">Nikola</option>
                        </select>
                    </div>
                    <div className="mb-8">
                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">
                            Client Name
                        </label>
                        <select
                            name="clientName"
                            value={filters.clientName}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value="">Select Client Name</option>
                            <option value="1">A</option>
                            <option value="2">B</option>
                            <option value="3">C</option>
                            <option value="4">Thomas Edison</option>
                            <option value="5">Nikola</option>
                        </select>
                    </div>

                    <div className="selectSec pt-0">
                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">
                            Number of invoices
                        </label>
                        <div className="costSec">
                            <div className="costinputSec">
                                <input
                                    type="number"
                                    min={0}
                                    name="invoiceNumber"
                                    value={filters.invoiceNumber}
                                    onChange={handleChange}
                                />
                            </div>
                            <select
                                name="invoiceComparison"
                                value={filters.invoiceComparison}
                                onChange={handleChange}
                                className="slectIconsShow"
                            >
                                <option value="Above">Above</option>
                                <option value="15">15</option>
                                <option value="75">75</option>
                                <option value="78">78</option>
                                <option value="85">85</option>
                                <option value="03">03</option>
                                <option value="10">10</option>
                            </select>
                        </div>
                    </div>
                    <div className="selectSec pt-0">
                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">
                            Net Amount
                        </label>
                        <div className="costSec">
                            <div className="costinputSec">
                                <label className="form-label fw-semibold text-primary-light text-md mb-0 me-4">
                                    ₹
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    name="netAmount"
                                    value={filters.netAmount}
                                    onChange={handleChange}
                                />
                            </div>
                            <select
                                name="netAmountComparison"
                                value={filters.netAmountComparison}
                                onChange={handleChange}
                                className="slectIconsShow"
                            >
                                <option value="Above">Above</option>
                                <option value="15">15</option>
                                <option value="75">75</option>
                                <option value="78">78</option>
                                <option value="85">85</option>
                                <option value="03">03</option>
                                <option value="10">10</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="offcanvas-footer px-16">
                <button
                    type="button"
                    className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
                >
                    Save Filter
                </button>
            </div>
        </div>
    );
};

export default SummaryInvoiceFilter;
