import React, { useState } from 'react';

const FeedbackLink = ({ link, date, updatedBy, onDelete }) => {
    return (
        <div className="feedback-link-item d-flex align-items-center justify-content-between py-8 border-bottom">
            <div className="d-flex align-items-center gap-2">
                <div className="badge-circle d-flex align-items-center justify-content-center">
                    <span className="badge text-sm fw-semibold w-28-px h-28-px d-flex justify-content-center align-items-center rounded-circle bg-primary-600 text-white">
                        {link.id}
                    </span>
                </div>
                <a href={link.url} target="_blank" rel="noopener noreferrer" className="link-text text-sm text-neutral-700">
                    {link.url}
                </a>
            </div>
            <span className="text-sm text-neutral-700">{date}</span>
            <span className="updated-by text-sm text-neutral-900">
                <span className="text-neutral-400 me-4">Updated by:</span> {updatedBy}
            </span>
            <div className="action-icons d-flex gap-2">
                <button className="icon btn py-0 px-10 text-neutral-500 editBtn">
                    <i className="mdi mdi-pencil-outline mdi-24px"></i>
                </button>
                <button className="icon btn py-0 px-10 text-neutral-500 deleteBtn" onClick={() => onDelete(link.id)}>
                    <i className="mdi mdi-delete-outline mdi-24px"></i>
                </button>
                <button className="btn badge text-sm fw-semibold border border-primary-600 text-primary-600 bg-transparent px-20 py-4 radius-4 text-white ms-10">
                    View
                </button>
            </div>
        </div>
    );
};

const FeedbackLinksList = ({ initialLinks = [] }) => {
    const [links, setLinks] = useState(initialLinks);

    const handleDelete = (id) => {
        const updatedLinks = links.filter((link) => link.id !== id);
        setLinks(updatedLinks); // Update the state with the filtered links
    };

    return (
        <div className="feedback-links-list">
            <div className="header d-flex align-items-center gap-2 mb-0">
                <i className="mdi mdi-link-variant mdi-24px"></i>
                <h6 className="mb-0 text-lg">Client Feedback Links</h6>
            </div>
            {links.map((link, index) => (
                <FeedbackLink
                    key={index}
                    link={{ id: link.id, url: link.url }}
                    date={link.date}
                    updatedBy={link.updatedBy}
                    onDelete={handleDelete} // Pass the handleDelete function to FeedbackLink
                />
            ))}
            <div className="progress-bar mt-3"></div>
        </div>
    );
};

export default FeedbackLinksList;
