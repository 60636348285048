export const CLIENT_PRODUCTION_ACTIONS = {
    CLIENT_PRODUCTION_SEARCH: 'CLIENT_PRODUCTION_SEARCH',
    CLIENT_PRODUCTION_SEARCH_SUCCESS: 'CLIENT_PRODUCTION_SEARCH_SUCCESS',
    CLIENT_PRODUCTION_SEARCH_ERROR: 'CLIENT_PRODUCTION_SEARCH_ERROR',

    CLIENT_PRODUCTION_CURRENT_CLIENT_PRODUCTION: 'CLIENT_PRODUCTION_CURRENT_CLIENT_PRODUCTION',
    
    CLIENT_PRODUCTION_ASSIGNED_PROJECTS_SEARCH: 'CLIENT_PRODUCTION_ASSIGNED_PROJECTS_SEARCH',
    CLIENT_PRODUCTION_TASK_BY_PROJECT_SEARCH: ' CLIENT_PRODUCTION_TASK_BY_PROJECT_SEARCH',
    CLIENT_PRODUCTION_MODULE_BY_TASK_SEARCH: ' CLIENT_PRODUCTION_MODULE_BY_TASK_SEARCH',

    CLIENT_PRODUCTION_ASSIGN_CLIENT: 'CLIENT_PRODUCTION_ASSIGN_CLIENT',
    CLIENT_PRODUCTION_ASSIGN_PROJECT: 'CLIENT_PRODUCTION_ASSIGN_PROJECT',
    CLIENT_PRODUCTION_ASSIGN_CATEGORY: 'CLIENT_PRODUCTION_ASSIGN_CATEGORY',
    CLIENT_PRODUCTION_ASSIGN_SUB_CATEGORY: 'CLIENT_PRODUCTION_ASSIGN_SUB_CATEGORY',

    CLIENT_PRODUCTION_UPSERT: 'CLIENT_PRODUCTION_UPSERT',
    CLIENT_PRODUCTION_UPSERT_SUCCESS: 'CLIENT_PRODUCTION_UPSERT_SUCCESS',
    CLIENT_PRODUCTION_UPSERT_ERROR: 'CLIENT_PRODUCTION_UPSERT_ERROR',

    CLIENT_PRODUCTION_EDIT: 'CLIENT_PRODUCTION_EDIT',

    CLIENT_PRODUCTION_RESET: 'CLIENT_PRODUCTION_RESET',
};

export const clientProductionResetAction = () => ({
    type: CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_RESET,
});

export const clientProductionSearchAction = (payload) => ({
    type: CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_SEARCH,
    ...payload,
});

export const clientProductionEditAction = (payload) => ({
    type: CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_EDIT,
    ...payload,
});

export const clientProductionUpsertAction = (payload) => ({
    type: CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_UPSERT,
    ...payload,
});
export const clientProductionProjectAssigneeSearchAction = (payload) => ({
    type: CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_ASSIGNED_PROJECTS_SEARCH,
    ...payload,
});
export const clientProductionTaskByProjectSearchAction = (payload) => ({
    type: CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_TASK_BY_PROJECT_SEARCH,
    ...payload,
});
export const clientProductionModuleByTaskSearchAction = (payload) => ({
    type: CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_MODULE_BY_TASK_SEARCH,
    ...payload,
});



export const clientProductionAssignProjectIdAction = (payload) => ({
    type: CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_ASSIGN_PROJECT,
    ...payload,
});
export const clientProductionAssignCategoryAction = (payload) => ({
    type: CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_ASSIGN_CATEGORY,
    ...payload,
});
export const clientProductionAssignSubCategoryAction = (payload) => ({
    type: CLIENT_PRODUCTION_ACTIONS.CLIENT_PRODUCTION_ASSIGN_SUB_CATEGORY,
    ...payload,
});
export default {
    CLIENT_PRODUCTION_ACTIONS,
    clientProductionSearchAction,
    clientProductionUpsertAction,
    clientProductionEditAction,
};
