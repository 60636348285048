import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { productionFIleRemoveAPI, userProductionFileAPI } from 'src/api/ProductionApi';

const FileUpload = ({files,setFiles}) => {
  const { _id: userId } = useSelector(state => state.auth?.user, shallowEqual);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState('Select Document');
    const [showAttachment, setShowAttachment] = useState(false);
    const documentOptions = [
      { label: 'Agreement', id: 'radiod1' },
      { label: 'Invoice', id: 'radiod2' },
      { label: 'Payment Receipt', id: 'radiod3' },
      { label: 'Purchase order', id: 'radiod4' },
      { label: 'Proforma invoice', id: 'radiod5' },
    ];
  
    const formatFileSize = (size) => {
      if (size < 1024) {
        return `${size} bytes`;
      } else if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(2)} KB`;
      } else if (size < 1024 * 1024 * 1024) {
        return `${(size / (1024 * 1024)).toFixed(2)} MB`;
      } else {
        return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
      }
    };
  
    const handleFileUpload = (event) => {
      handleUploadFile(event);
      const files = Array.from(event.target.files);
      const newFiles = files.map(file => ({
        name: file.name,
        size: formatFileSize(file.size), // Format the size dynamically
        type: selectedDocument, // Store the selected document type
        format: file.name.split('.').pop().toUpperCase() // Extract file format
      }));
      setUploadedFiles(prevFiles => [...prevFiles, ...newFiles]);
    };
  
    const handleFileDelete = (fileName) => {
      setUploadedFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
      handleRemoveFile(fileName);
    };
  
    const handleSelectDocument = (document) => {
      setSelectedDocument(document);
      setShowAttachment(true); // Show the attachment section when a document is selected
    };
  
    const handleUploadFile = async (event) => {
      const files = event.target.files;
      const payload = { file: files[0], by: userId };
      const fileResponse = await userProductionFileAPI(payload);
      setFiles(prevFiles => [...prevFiles, fileResponse.data.data]);
    };
    const handleRemoveFile = async (data) => {
      const remove = await productionFIleRemoveAPI({ ids: [data._id] })
      if (remove)
        setFiles(files.filter(item => item._id !== data._id))
    }

  return (
    <div>
      <div className="selectSec droupPosiSet">
        <label htmlFor="" className="">Documents</label>
        <button
          type="button"
          className="btn dropdown-toggle dropdown-toggle-split droupDownPopuSec"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-bs-reference="parent"
        >
          {selectedDocument || "Select Document"}
          <span className="visually-hidden">Active</span>
        </button>
        <ul className="dropdown-menu ulsecSetWidth">           
          <ul>
              {documentOptions.map((doc, index) => (
                <li key={doc.id} className={index === 0 ? 'mb-8' : 'mt-8'}>
                  <div
                    className="form-check checked-primary d-flex align-items-center gap-2 radio-check"
                    onClick={() => handleSelectDocument(doc.label)}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="radio"
                      id={doc.id}
                    />
                    <label
                      className="form-check-label line-height-1 fw-medium text-secondary-light"
                      htmlFor={doc.id}
                    >
                      {doc.label}
                    </label>
                  </div>
                </li>
              ))}
          </ul>

        </ul>
      </div>

      {/* Conditionally render the attachment component */}
      {showAttachment && (
        <div className="selectSec filterStyleSec">
          <label htmlFor="">Attach File ({selectedDocument})</label>
          <div className="producMideaFlex clientNewAddMedia">
            <div className="attchProMaine mt-0">
              <input type="file" name="file" id="file" multiple onChange={handleFileUpload} />
              <label htmlFor="file">
                <div className="attContent">
                  + Attachment{" "}
                  <span>
                    {/* Add SVG or icon here if needed */}
                  </span>
                </div>
              </label>
            </div>

            {files.length > 0 && (
              <div className="clientDocShow my-4">
                {files.map((file, index) => (
                  <div key={index} className="cleDocContent">
                    <div className="d-flex align-items-start gap-1">
                    <div className="fileFormat"><span className="bgFilesg"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-file-earmark-fill" viewBox="0 0 16 16">
  <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z"/>
</svg></span><span className="filefmttext">{file.format}</span></div>
                    <div>
                      <div className="FileName">{file.name}</div>
                      <div className="FileSize">{file.size}</div>
                      <div className="FileType">Type: {file.type}</div>
                    </div>
                    </div>
                    <button
                      className="deletFilebtn px-3 py-3 d-flex justify-content-center align-items-center"
                      onClick={() => handleFileDelete(file)}
                    >
                       <svg
          width={16}
          height={18}
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.30775 17.4997C2.81058 17.4997 2.385 17.3227 2.031 16.9687C1.677 16.6147 1.5 16.1892 1.5 15.692V2.99973H1.25C1.0375 2.99973 0.859417 2.92782 0.71575 2.78398C0.571917 2.64015 0.5 2.46198 0.5 2.24948C0.5 2.03682 0.571917 1.85873 0.71575 1.71523C0.859417 1.57157 1.0375 1.49973 1.25 1.49973H5C5 1.2549 5.08625 1.04624 5.25875 0.873735C5.43108 0.701402 5.63967 0.615234 5.8845 0.615234H10.1155C10.3603 0.615234 10.5689 0.701402 10.7413 0.873735C10.9138 1.04624 11 1.2549 11 1.49973H14.75C14.9625 1.49973 15.1406 1.57165 15.2843 1.71548C15.4281 1.85932 15.5 2.03748 15.5 2.24998C15.5 2.46265 15.4281 2.64073 15.2843 2.78423C15.1406 2.9279 14.9625 2.99973 14.75 2.99973H14.5V15.692C14.5 16.1892 14.323 16.6147 13.969 16.9687C13.615 17.3227 13.1894 17.4997 12.6923 17.4997H3.30775ZM13 2.99973H3V15.692C3 15.7818 3.02883 15.8556 3.0865 15.9132C3.14417 15.9709 3.21792 15.9997 3.30775 15.9997H12.6923C12.7821 15.9997 12.8558 15.9709 12.9135 15.9132C12.9712 15.8556 13 15.7818 13 15.692V2.99973ZM6.15425 13.9997C6.36675 13.9997 6.54483 13.9279 6.6885 13.7842C6.832 13.6404 6.90375 13.4622 6.90375 13.2497V5.74973C6.90375 5.53723 6.83183 5.35907 6.688 5.21523C6.54433 5.07157 6.36617 4.99973 6.1535 4.99973C5.941 4.99973 5.76292 5.07157 5.61925 5.21523C5.47575 5.35907 5.404 5.53723 5.404 5.74973V13.2497C5.404 13.4622 5.47583 13.6404 5.6195 13.7842C5.76333 13.9279 5.94158 13.9997 6.15425 13.9997ZM9.8465 13.9997C10.059 13.9997 10.2371 13.9279 10.3807 13.7842C10.5243 13.6404 10.596 13.4622 10.596 13.2497V5.74973C10.596 5.53723 10.5242 5.35907 10.3805 5.21523C10.2367 5.07157 10.0584 4.99973 9.84575 4.99973C9.63325 4.99973 9.45517 5.07157 9.3115 5.21523C9.168 5.35907 9.09625 5.53723 9.09625 5.74973V13.2497C9.09625 13.4622 9.16817 13.6404 9.312 13.7842C9.45567 13.9279 9.63383 13.9997 9.8465 13.9997Z"
            fill="#1C1B1F"
          />
        </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
