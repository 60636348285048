import React, {useCallback, useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import FilterPopup from './FilterPopup';
import ManageColumn from './ManageColumn';
import { toast } from 'react-toastify';
import { userSearchAPI, userUpdateAPI } from 'src/api/UserApi';
import DatePickerComponent from 'src/components/DatePickerComponent';
import SearchInput from 'src/components/SearchInput';
import UserInfo from 'src/containers/user-container/UserInfo';
import EmployeeListRow from './EmployeeListRow';
import { debounce } from 'lodash';
import { employeeDataEditAction, employeeDataSearchAction } from './actions/EmployeeDataActions';
import { useSticky } from 'src/hooks/useSticky';

const EmployeeDataContainer = () => {
  const dispatch = useDispatch();
  const {
    search,
} = useSelector((state) => state.EmployeeDataReducer, shallowEqual);
  const [isFlatPikerReset, setIsFlatPikerReset] = useState(false);
  const [where,setWhere] = useState({});
  const[pageNo,setPageNo] = useState(0);

  // const getUserEmployeeData = async () => {
  //   let body = {
  //     pageLength: 100,
  //     where
  //   };
  //   try {
  //     const resp = await userSearchAPI(body); 
  //     const filteredData = resp.data.data.filter(user => { 
  //       const matchesSearch = `${user.firstName} ${user.lastName}`.toLowerCase().includes(search.toLowerCase());
  //       const matchesDepartment = !selectedDepartment || user.departmentId?.departmentName === selectedDepartment;
  //       const matchesDesignation = !selectedDesignation || user.designationId?.designationName === selectedDesignation;
  //       return  matchesSearch && matchesDepartment && matchesDesignation;
  //     });
  //     setData(filteredData);
  //   } catch (error) {
  //     console.log('error from employee data', error);
  //   }
  // };
const searchUser=()=>{
  const payload = {
      where, 
      currentPage:pageNo, 
      autopopulate:true, 
      fullTextSearch:true,
  }
  dispatch(employeeDataSearchAction(payload));
}
  useEffect(()=>{ 
    searchUser();
},[where])

  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) { 
      setIsFlatPikerReset(false)
      const [startDate, endDate] = selectedDates.map(date => {
        // Ensure that the date is treated as UTC and not auto-adjusted
        const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000); 
        return utcDate.toISOString().split("T")[0]; // Get date part only (YYYY-MM-DD)
      });  
      setWhere({...where, ts: { $gte: startDate, $lte: endDate } })
    }
  } 
  const handleTextSearch = useCallback(
    debounce((searchKey, setWhere) => {
      const whereClauseKeysPaths = ['firstName', 'lastName', 'email','employeeID','phone'];
      const whereWithOrCondition = {
        $or: whereClauseKeysPaths.map((keyPath) => ({ 
          [keyPath]: {
            $regex: searchKey,
            $options: 'i',
          },
        })),
      }; 
      setWhere(whereWithOrCondition);
    }, 300),
    []
  );
  const handleSearchInputChange = (e) => {
    const searchKey = e.target.value;
    handleTextSearch(searchKey, setWhere);
  };
  const resetFilter = () => {
    document.getElementById('search').value = '';
    setIsFlatPikerReset(true)
    setWhere({});
    // setFilterDefaultProps(defaultClientFilterProps);
  }
  const handleEditProfile = useCallback((
    recoard,        
) => { 
    dispatch(employeeDataEditAction(recoard));
}, [
    dispatch,
]);
const handleApplyFilters=()=>{

}
const updateStatus = async(id) => {
  if(window.confirm('Are you sure you want to update status?')){
    const data ={status:false,active:false};
     await userUpdateAPI({userId:id,data});
      toast.success('Status Updated!');
      searchUser();
  } 
}
// const { isSticky, elementRef } = useSticky(); // Use the custom hook

// Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code End

  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24"> 
                Employee Data
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="javascript:void(0)"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Employee Data</li>
              </ul>
              <div className="d-flex gap-2 align-items-center py-16 postionTop-0 bg-white">
                {/* Search start */}
                 <SearchInput  handleSearchInputChange={handleSearchInputChange} />
                {/* Search End */}
                {/* Date Range Picker start */}
                <DatePickerComponent 
                isFlatPikerReset={isFlatPikerReset}
                onDateRangeChange={handleDateRangeChange} 
                />
                {/* Date Range Picker end */}
                {/* Filter button start */}
                <div className="FilterBtn d-flex gap-2">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#filterRightPopup"
                    aria-controls="filterRightPopup"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
                      />
                    </svg>{" "}
                    Filter
                  </button>
                  <button type="reset"
                  onClick={resetFilter}
                  className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2">Reset</button>
                </div>
                {/* Filter button end */}
              </div>
              <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
      <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                  <thead>
                    <tr className="dnd-moved"> 
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th>User Id </th>
                      <th>Name</th>
                      <th>Department </th>
                      <th>Designation </th>
                      <th className="text-center">View </th>
                    </tr>
                  </thead>
                  <tbody id="table-body">
                    {search?.data.map((user, index) => <EmployeeListRow key={index} index={index} user={user} handleEditProfile={handleEditProfile} updateStatus={updateStatus}/>)}
                  </tbody>
                </table>
              </div>
              {/* <div className="tableFooter d-flex align-items-center justify-content-end py-16">
                {/* Add pagination here if needed 
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Filter Popup */}
      <FilterPopup applyFilters={handleApplyFilters} />
      {/* Manage Column Popup */}
      <ManageColumn />
      <UserInfo />
    </>
  );
};

export default EmployeeDataContainer;
