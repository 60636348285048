import React, { useState } from 'react';

const CommentMessage = ({ title, onClose }) => {
  const [messages, setMessages] = useState([
    { sender: 'other', text: 'Hello!', time: '6:00 PM', avatar: 'assets/images/chat/11.png' },
    { sender: 'me', text: 'Hi there!', time: '6:01 PM', avatar: 'assets/images/chat/12.png' },
  ]);
  const [newMessage, setNewMessage] = useState('');

  const handleSendMessage = (event) => {
    event.preventDefault();
    
    if (newMessage.trim()) {
      const message = {
        sender: 'me',
        text: newMessage,
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        avatar: 'assets/images/chat/12.png',
      };
      
      setMessages((prevMessages) => [...prevMessages, message]);
      setNewMessage(''); // Clear input field
    }
  };

  return (
    <>
      <div
        className="modal fade cusmodl LeaveDetailsModal"
        id="CommentMessage"
        tabIndex="-1"
        aria-labelledby="CommentMessageLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between">
              <h6 className="card-title text-neutral-900">Message</h6>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                ✗
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="chat-main">
                <div className="chat-message-list">
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`chat-single-message ${msg.sender === 'me' ? 'right' : 'left'}`}
                    >
                      {msg.sender !== 'me' && (
                        <img
                          src={msg.avatar}
                          alt="image"
                          className="avatar-lg object-fit-cover rounded-circle"
                        />
                      )}
                      <div className="chat-message-content">
                        <p className="mb-3">{msg.text}</p>
                        <p className="chat-time mb-0">
                          <span>{msg.time}</span>
                        </p>
                      </div>
                      {/* No need for another rendering for "me" */}
                    </div>
                  ))}
                </div>
                <form className="chat-message-box" onSubmit={handleSendMessage}>
                  <input
                    type="text"
                    name="chatMessage"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Write message"
                  />
                  <div className="chat-message-box-action">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary-600 radius-8 d-inline-flex align-items-center gap-1"
                    >
                      Send
                      <iconify-icon icon="f7:paperplane" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentMessage;
