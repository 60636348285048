import React, { useEffect, useRef, useState } from 'react';

const PaymentInvoiceTable = () => {
  const [collapse, setCollapse] = useState({});
  
  const toggleCollapse = (id) => {
    setCollapse((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const data = [
    {
      id: 'demo1',
      projectCode: 'Project _415',
      customerName: 'Hasmukhbhai Parekh',
      delivered: '₹23591.01',
      invoiceRaised: '₹0',
      pending: '₹61740.00',
      paid: '16 Oct 2024',
      balance: '₹61740.00',
      subRows: [
        {
          id: 'demo10',
          projectCode: 'Project 407 - A - Dummy delivery',
          legalName: 'HCL',
          commercialName: 'Vineet kumar HCL',
          unitCost: '₹40.00',
          delivered: 8,
          invoiceDeliveries: 2,
          balQty: 0.5,
          totalCost: '₹61740.00',
          subItems: [
            { id: 'sub1', description: '1. Project 407 - A - Dummy Delivery - category', unitCost: '₹40.00', delivered: 1, balQty: 0 },
            { id: 'sub2', description: '2. Project 407 - A - Dummy Delivery - category', unitCost: '₹40.00', delivered: 1, balQty: 0 },
          ]
        },
      ]
    },
    {
      id: 'demo21',
      projectCode: 'Project _416',
      customerName: 'Aasmukhbhai Parekh',
      delivered: '₹24591.01',
      invoiceRaised: '₹100',
      pending: '₹62740.00',
      paid: '16 Oct 2024',
      balance: '₹61740.00',
      subRows: [
        {
          id: 'demo31',
          projectCode: 'Project 407 - A - Dummy delivery',
          legalName: 'HCL',
          commercialName: 'Vineet kumar HCL',
          unitCost: '₹40.00',
          delivered: 8,
          invoiceDeliveries: 2,
          balQty: 0.5,
          totalCost: '₹61740.00',
          subItems: [
            { id: 'sub1', description: '1. Project 407 - A - Dummy Delivery - category', unitCost: '₹40.00', delivered: 1, balQty: 0 },
            { id: 'sub2', description: '2. Project 407 - A - Dummy Delivery - category', unitCost: '₹40.00', delivered: 1, balQty: 0 },
          ]
        },
      ]
    },
  ];

   // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code End
  return (
    <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
        <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
      <thead className="z-3">
        <tr>
          <th><input className="form-check-input" type="checkbox" /></th>
          <th>S.No.</th>
          <th>Project Code</th>
          <th>Customer Name</th>
          <th>Delivered</th>
          <th>Invoice Raised</th>
          <th>Pending</th>
          <th>Paid</th>
          <th>Balance</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <React.Fragment key={row.id}>
            {/* Main row */}
            <tr>
              <td><input className="form-check-input" type="checkbox" /></td>
              <td>{index + 1}</td>
              <td className="fw-bold fw-bold d-flex align-items-center gap-2">
                <button
                  className={`text-dblue steactive accordion-toggle ${collapse[row.id] ? 'active' : ''}`}
                  onClick={() => toggleCollapse(row.id)}
                >
                  <i className="mdi mdi-play-circle-outline mdi-36px"></i>
                </button>
                {row.projectCode}
              </td>
              <td>{row.customerName}</td>
              <td>{row.delivered}</td>
              <td>{row.invoiceRaised}</td>
              <td>{row.pending}</td>
              <td>{row.paid}</td>
              <td>{row.balance}</td>
            </tr>
            {/* Sub-row */}
            {collapse[row.id] && row.subRows.map((subRow) => (
              <React.Fragment key={subRow.id}>
                <tr>
                  <td colSpan="9" className="hiddenRow">
                    <div className="accordian-body collapse show">
                      <table className="table basic-border-table mb-0 table-hover">
                        <thead>
                          <tr className="infobgblack">
                            <th><input type="checkbox" /></th>
                            <th>#</th>
                            <th>Project</th>
                            <th>Legal Name</th>
                            <th>Commercial Name</th>
                            <th>Unit Cost</th>
                            <th>Delivered</th>
                            <th>Invoice Deliveries</th>
                            <th>Bal. Qty.</th>
                            <th>Total Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><input type="checkbox" /></td>
                            <td>01</td>
                            <td className="fw-bold fw-bold d-flex align-items-center gap-2">
                              <button
                                className={`text-dblue steactive accordion-toggle ${collapse[subRow.id] ? 'active' : ''}`}
                                onClick={() => toggleCollapse(subRow.id)}
                              >
                                <i className="mdi mdi-play-circle-outline mdi-36px"></i>
                              </button>
                              {subRow.projectCode}
                            </td>
                            <td>{subRow.legalName}</td>
                            <td>{subRow.commercialName}</td>
                            <td>{subRow.unitCost}</td>
                            <td>{subRow.delivered}</td>
                            <td>{subRow.invoiceDeliveries}</td>
                            <td>{subRow.balQty}</td>
                            <td>{subRow.totalCost}</td>
                          </tr>
                          {collapse[subRow.id] && subRow.subItems.map((item) => (
                            <tr key={item.id} className="hiddenRow accordian-body collapse show">
                              <td></td>
                              <td></td>
                              <td className="text-dblue">{item.description}</td>
                              <td></td>
                              <td></td>
                              <td>{item.unitCost}</td>
                              <td>{item.delivered}</td>
                              <td>{item.delivered}</td>
                              <td>{item.balQty}</td>
                              <td>{subRow.totalCost}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
    </div>
  );
};

export default PaymentInvoiceTable;
