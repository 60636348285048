import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const moduleSearchAPI = function (data) {
    const url = `${config.baseUrl}module/search`;
    return postRequest({
        url,
        data,
    });
};
export const getmoduleAPI = function (data) {
    const url = `${config.baseUrl}module/getmodule`;
    return postRequest({
        url,
        data,
    });
};

export const moduleCreateAPI = function (data) {
    const url = `${config.baseUrl}module/save`;
    return postRequest({
        url,
        data,
    });
};
export const moduleUploadAPI = function (data) {
    const url = `${config.baseUrl}module/upload`;
    return postRequest({
        url,
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
     
}

export const moduleUpdateAPI = function ({
    moduleId,
    data,
}) {
    const url = `${config.baseUrl}module/update/${moduleId}`;
    return putRequest({
        url,
        data,
    });
};
