import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { projectCategorySaveAPI, projectSubCategorySaveAPI } from "src/api/ProjectApi";
import { projectEditAction } from "src/containers/project-container/actions/ProjectActions";

const AddCategory=({
    isModalVisible,
    categories,
    setCategories,
    addCategory,
    removeCategory,
    hideModal,
    categoryId
})=>{
    const {currentRecord}  = useSelector(state=>state.ProjectReducer,shallowEqual)
    const {_id:userId} = useSelector(state=>state.auth?.user,shallowEqual)
    const dispatch = useDispatch();
    const handleCloseModal = () => {
        setCategories([]);
        hideModal();
    }
    const handleSave = async() => {
      const isSubCategory = categoryId !== undefined && categoryId !== null;  
      // Build the payload conditionally
      const payload = isSubCategory 
          ? {
              by: userId,
              categoryId: categoryId, // For subcategory
              subCategories: categories
          }
          : {
              by: userId,
              projectId: currentRecord._id, // For category
              categories
          };  
      // Call the correct API based on the condition
      const response = isSubCategory 
          ? await projectSubCategorySaveAPI(payload) // Save subcategory
          : await projectCategorySaveAPI(payload);   // Save category
  
      if(response){
          setCategories([]);  // Clear categories after saving
          hideModal();
          dispatch(projectEditAction({_id: currentRecord._id ,userId: userId,}));
      }
  };
    return (
        <>
        {/* Add new popup  */}
        <div className={`newcretmodalNw ${isModalVisible ? '' : 'd-none'}`}>
              <div className="newcretmodalNwinner">
                <div className="modal-header d-flex justify-content-center pb-0 border-0">
                  <h6 className="mb-2 fw-bold text-lg mb-0">Add New {(categoryId)?"Sub Category":"Category"} </h6>
                </div>
                <div className="newcretmodal">
                  <div className="row gy-3">
                    <div className="col-12">
                      <label className="form-label d-flex justify-content-between align-items-center">
                        Category
                        {/* Only show counter if there is more than 1 category */}
                        {categories.length > 1 && (
                          <span className="countCname">{categories.length} Created</span>
                        )}
                      </label>

                      {/* Render the input fields dynamically */}
                      <div className="category-wrapper">
                        {categories.map((category, index) => (
                          <div key={index} className="mb-8">
                            <input
                              type="text"
                              name={`#${index}`}
                              className="form-control me-2"
                              placeholder="Enter Category"
                              value={category}
                              onChange={(e) => {
                                const newCategories = [...categories];
                                newCategories[index] = e.target.value;
                                setCategories(newCategories);
                              }}
                            />
                            {/* Remove button - only show if there are more than 1 categories */}
                            {categories.length > 1 && (
                              <button className="text-danger-700 fw-bold text-sm" onClick={() => removeCategory(index)}>
                                Remove
                              </button>
                            )}
                          </div>
                        ))}
                      </div>

                      {/* Add More button */}
                      <button className="text-info-700 fw-bold text-sm addcountNum" onClick={addCategory}>
                        <i className="mdi mdi-plus"></i> Add More
                      </button>
                    </div>
                    <div className="col-12 col-md-8 mx-auto">
                      <div className="d-flex gap-4 justify-content-between">
                        <button type="button" className="btn radius-8 px-20 py-11 w-100  btn-outline-neutral-900" onClick={handleCloseModal}>Cancel</button>
                        <button 
                            type="button" 
                            className="btn btn-primary radius-8 px-20 py-11 w-100"
                            onClick={handleSave}

                         >Save </button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </>
    )
}
export default AddCategory