import React, { useEffect, useRef, useState } from 'react';
import AddforInvoiceModal from './AddforInvoiceModal';
import PaymentView from './PaymentView';

const getStatusIcon = (status) => {
  switch (status) {
    case "Invoice Under Process":
      return <i className="mdi mdi-clock mdi-24px"></i>;
    case "Unpaid":
      return <i className="mdi mdi-close-circle mdi-24px"></i>;
    case "Fully Paid":
      return <i className="mdi mdi-check-circle mdi-24px"></i>;
    case "Partially Paid":
      return <i className="mdi mdi-check-circle mdi-24px"></i>;
    default:
      return null;
  }
};

const VendorDeliveryTable = ({ data }) => {
  const [generatingInvoice, setGeneratingInvoice] = useState(false); // State to track invoice generation
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  // States for multiple filters
  const [uploadedByFilter, setUploadedByFilter] = useState([]);
  const [vendorNameFilter, setVendorNameFilter] = useState([]);
  const [clientPathFilter, setClientPathFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);

  // Extract unique values for each filter dropdown
  const uniqueUploadedBy = [...new Set(data.map(item => item.uploadedBy.name))];
  const uniqueVendorName = [...new Set(data.map(item => item.vendorName.name))];
  const uniqueClientPath = [...new Set(data.map(item => item.mappedClientDeliveryPath))];
  const uniqueCategory = [...new Set(data.map(item => item.category))];
  const uniqueStatus = [...new Set(data.map(item => item.status))];

  const handleGenerateInvoice = () => {
    setGeneratingInvoice(true);
    setTimeout(() => {
      setGeneratingInvoice(false);
    }, 2000);
  };

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  const handleInvoiceModal = async () => { };

  const openModalWithMessage = (message) => {
    setModalMessage(message);
    const modalElement = new window.bootstrap.Modal(document.getElementById('AddforInvoiceModal'));
    modalElement.show();
  };

  // Generic handler for checkbox selection in filters
  const handleCheckboxChange = (filterState, setFilterState, value) => {
    if (filterState.includes(value)) {
      setFilterState(filterState.filter(item => item !== value));
    } else {
      setFilterState([...filterState, value]);
    }
  };

  // Filter data based on selected filters
  const filteredData = data.filter(row => {
    const matchUploadedBy = uploadedByFilter.length ? uploadedByFilter.includes(row.uploadedBy.name) : true;
    const matchVendorName = vendorNameFilter.length ? vendorNameFilter.includes(row.vendorName.name) : true;
    const matchClientPath = clientPathFilter.length ? clientPathFilter.includes(row.mappedClientDeliveryPath) : true;
    const matchCategory = categoryFilter.length ? categoryFilter.includes(row.category) : true;
    const matchStatus = statusFilter.length ? statusFilter.includes(row.status) : true;

    return matchUploadedBy && matchVendorName && matchClientPath && matchCategory && matchStatus;
  });


  // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code End
  return (
    <>
      <div className={`table-body-scrollable mt-24 ${isScrollable ? 'scrollable' : ''}`}>
      <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
          <thead>
            <tr>
              <th className="align-middle">S.No.</th>
              <th className="align-middle">Date</th>

              {/* Uploaded by Dropdown */}
              <th className="align-middle">
                <div className="d-flex justify-content-between align-items-center">
                  Uploaded by
                  <div className="filter-container mb-0">
                    <div className="dropdown">
                      <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="mdi mdi-filter-variant mdi-24px"></i>
                      </button>
                      <ul className="dropdown-menu border  dropdown-menu-end">
                        {uniqueUploadedBy.map((name, index) => (
                          <li key={index} className="dropdown-item">
                            <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={name}
                                id={`uploadedBy-${index}`}
                                checked={uploadedByFilter.includes(name)}
                                onChange={() => handleCheckboxChange(uploadedByFilter, setUploadedByFilter, name)}
                              />
                              <label className="form-check-label" htmlFor={`uploadedBy-${index}`}>
                                {name}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </th>

              {/* Vendor Name Dropdown */}
              <th className="align-middle">
                <div className="d-flex justify-content-between align-items-center">
                  Vendor Name
                  <div className="dropdown">
                    <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="mdi mdi-filter-variant mdi-24px"></i>
                    </button>
                    <ul className="dropdown-menu border dropdown-menu-end">
                      {uniqueVendorName.map((name, index) => (
                        <li key={index} className="dropdown-item">
                          <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={name}
                              id={`vendorName-${index}`}
                              checked={vendorNameFilter.includes(name)}
                              onChange={() => handleCheckboxChange(vendorNameFilter, setVendorNameFilter, name)}
                            />
                            <label className="form-check-label" htmlFor={`vendorName-${index}`}>
                              {name}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </th>

              {/* Mapped Client Delivery Path Dropdown */}
              <th className="align-middle">
                <div className="d-flex justify-content-between align-items-center">
                  Mapped Client Delivery Path
                  <div className="dropdown">
                    <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="mdi mdi-filter-variant mdi-24px"></i>
                    </button>
                    <ul className="dropdown-menu border  dropdown-menu-end">
                      {uniqueClientPath.map((path, index) => (
                        <li key={index} className="dropdown-item">
                          <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={path}
                              id={`clientPath-${index}`}
                              checked={clientPathFilter.includes(path)}
                              onChange={() => handleCheckboxChange(clientPathFilter, setClientPathFilter, path)}
                            />
                            <label className="form-check-label" htmlFor={`clientPath-${index}`}>
                              {path}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </th>

              {/* Category Dropdown */}
              <th className="align-middle">
                <div className="d-flex justify-content-between align-items-center">
                  Category
                  <div className="dropdown">
                    <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="mdi mdi-filter-variant mdi-24px"></i>
                    </button>
                    <ul className="dropdown-menu border  dropdown-menu-end">
                      {uniqueCategory.map((category, index) => (
                        <li key={index} className="dropdown-item">
                          <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={category}
                              id={`category-${index}`}
                              checked={categoryFilter.includes(category)}
                              onChange={() => handleCheckboxChange(categoryFilter, setCategoryFilter, category)}
                            />
                            <label className="form-check-label" htmlFor={`category-${index}`}>
                              {category}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </th>

              <th className="align-middle">Per Unit Cost</th>
              <th className="align-middle">Total Unit</th>
              <th className="align-middle">Paid Unit</th>
              <th className="align-middle">Unpaid Unit</th>
              {/* Status Dropdown */}
              <th className="align-middle">
                <div className="d-flex justify-content-between align-items-center">
                  Status
                  <div className="dropdown">
                    <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="mdi mdi-filter-variant mdi-24px"></i>
                    </button>
                    <ul className="dropdown-menu border  dropdown-menu-end">
                      {uniqueStatus.map((status, index) => (
                        <li key={index} className="dropdown-item">
                          <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={status}
                              id={`status-${index}`}
                              checked={statusFilter.includes(status)}
                              onChange={() => handleCheckboxChange(statusFilter, setStatusFilter, status)}
                            />
                            <label className="form-check-label" htmlFor={`status-${index}`}>
                              {status}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </th>
              <th className="align-middle">Add for Invoice</th>
              <th className="align-middle">Invoice List</th>
              <th className="align-middle">Payment</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr key={index}>
                <td>{row.sNo}</td>
                <td>{row.date}</td>
                <td>
                  <div className="user-cell">
                    <span className="user-initials">{row.uploadedBy.initials}</span>
                    <span>{row.uploadedBy.name}</span>
                  </div>
                </td>
                <td>
                  <div className="user-cell">
                    <span className="user-initials">{row.vendorName.initials}</span>
                    <span>{row.vendorName.name}</span>
                  </div>
                </td>
                <td>{row.mappedClientDeliveryPath}</td>
                <td>{row.category}</td>
                <td>{row.perUnitCost}</td>
                <td>{row.totalUnit}</td>
                <td>{row.paidUnit}</td>
                <td>{row.unpaidUnit}</td>
                <td className={`bg ${row.statusClass}`}>
                  <span className={`status-badge d-flex align-items-center gap-2 ${row.statusClass}`}>
                    {getStatusIcon(row.status)}  {row.status}
                  </span>
                </td>
                <td>
                  <div
                    className={`d-flex align-items-center ${row.addForInvoice === 'Download' ? 'additional-class' : ''}`}
                  >
                    {row.addForInvoice === "Generate Invoice" && generatingInvoice ? (
                      <span>Invoice generating... <i className="mdi mdi-spin mdi-loading"></i></span>
                    ) : (
                      <button
                        className={`badge text-sm fw-semibold px-20 py-9 radius-4 action-button d-flex align-items-center ${row.addForInvoice === 'Download' ? 'text-dblue pe-4' : 'bg-primary-600 text-white'}`}
                        onClick={row.addForInvoice === 'Generate Invoice' ? handleGenerateInvoice : undefined}
                        data-bs-toggle={row.addForInvoice === 'Download' ? "tooltip" : undefined}
                        data-bs-placement="top"
                        data-bs-custom-class="tooltip-primary"
                        data-bs-title={row.addForInvoice === 'Download' ? "Download Invoice" : undefined}
                      >
                        {row.addForInvoice}
                        {row.addForInvoice === 'Download' && (
                          <i className="mdi mdi-file-download-outline mdi-24px" style={{ marginLeft: '8px' }}></i>
                        )}
                      </button>
                    )}

                    {row.addForInvoice === 'Download' && (
                      <>
                        <div className="" onClick={() => {
                          setModalMessage('Are you sure to generate invoice?');
                          setModalVisible(true);
                        }}
                        >
                          <button
                            className="badge text-sm fw-semibold px-4 py-9 radius-4 text-black action-button"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-custom-class="tooltip-primary"
                            data-bs-title="Re-generate Invoice"
                          >
                            <i className="mdi mdi-replay mdi-24px"></i>
                          </button>
                        </div>

                        <div className="" onClick={() => {
                          setModalMessage('Are you sure to Cancel Invoice?');
                          setModalVisible(true);
                        }}
                        >
                          <button
                            className="badge text-sm fw-semibold px-4 py-9 radius-4 text-black action-button"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-custom-class="tooltip-primary"
                            data-bs-title="Cancel Invoice"
                          >
                            <i className="mdi mdi-close mdi-24px"></i>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </td>
                <td> 
                    <button className="view-button px-10 text-decoration-underline text-dblue"  data-bs-toggle="modal" data-bs-target="#viewInvoiceListModal">View Invoice</button> 
                </td>
                <td>
                  <div className="payment-cell">
                    <span>{row.payment}</span>
                    <button className="view-button px-10" data-bs-toggle="modal" data-bs-target="#PaymentView"><i className="mdi mdi-eye mdi-24px text-dblue"></i></button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <AddforInvoiceModal
        handleInvoiceModal={handleInvoiceModal}
        message={modalMessage}
        isVisible={modalVisible}
        setVisible={setModalVisible}
      />
      <PaymentView PaymentView={PaymentView} /> 

    </>
  );
};

export default VendorDeliveryTable;
