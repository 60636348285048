import React from 'react';
import { dateFormat, tenureFormat } from 'src/utils/utils';

const SalarySlip = ({ contentRef,user,initialValues }) => {
  console.log('user ===>',user)
    const  basic= initialValues.netSalary/2;
    const hra= basic/2;
    const medical=hra/5;
    const convence= hra/5;
    const special=(initialValues.netSalary)-(convence+medical+hra+basic);
    const panNo = initialValues?.documents?.find((doc) => doc.title === "pan");
 
    return (
        <div ref={contentRef} style={{width: "100%", maxWidth: 1080,minWidth: "560px", margin: 'auto', padding: '20px', border: '1px solid #eee', fontSize: '10px', lineHeight: 'auto', fontFamily: '"Inter", sans-serif', color: '#555', backgroundColor: '#fff'}}>
          <style
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');\n        body,p,h3,h2,h1,h4{\n  font-family: Inter, sans-serif;\n  font-optical-sizing: auto; \n  font-style: normal;\n}\n"
                    }}
                />
        <table style={{fontSize: '12px', lineHeight: '20px', width: '100%'}}>
          <thead>
            <tr>
              <td style={{padding: '0 0px 0px 0px'}}>
                <table style={{width: '100%', borderBottom: '5px solid #2E5EF7', paddingBottom: '10px'}}>
                  <tbody><tr>
                      <td style={{verticalAlign: 'middle' }}>
                        <div style={{fontSize: '12px', lineHeight: '14px', fontWeight: 'bold', color: '#323338',marginBottom: '0'}}>ACADECRAFT PVT. LTD.</div>
                        <div style={{fontSize: '8px',lineHeight: '18px', paddingTop: '4px', paddingBottom: '0px', fontWeight: 500, color: '#7B7E8B',marginBottom: '0px'}}>
                          Office (India): H-54, Sector-63 Noida, Uttar Pradesh - 201307, India</div>
                        <div style={{fontSize: '8px',lineHeight: '14px', fontWeight: 500, color: '#7B7E8B',marginBottom: '7px'}}>GST No: 09AANCA8296L2ZP</div>
                      </td>
                      <td style={{textAlign: 'right',verticalAlign: 'middle'}}>
                        <img src="/assets/images/Acadecraft-logo.svg" alt="Acadecraft Logo" className="img-fluid mb-8" />
                      </td>
                    </tr>
                  </tbody></table>
              </td>
            </tr>
            <tr>
              <td style={{borderBottom: '1px solid #ccc', padding: '0 0 10px 0'}}>
                <table style={{width: '100%'}}>
                  <tbody><tr>
                      <td style={{textAlign: 'start', fontSize: '12px', color: '#0073EA', fontWeight: 'bold',verticalAlign: 'middle'}}>
                        PAYSLIP 
                      </td>
                      <td>
                        <div style={{fontSize: '10px', textTransform: 'uppercase', textAlign: 'right', marginBottom: '0px', marginTop: 0}}>
                          Payslip for the month of
                        </div>
                        <div style={{fontSize: '10px', marginBottom: '0',lineHeight: '14px', textAlign: 'right', color: '#323338', textTransform: 'uppercase',fontWeight: 'bold'}}>October 2024</div>
                      </td>
                    </tr>
                  </tbody></table>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <table style={{width: '100%', borderCollapse: 'collapse'}}>
                  <tbody>
                    <tr>
                      <td colSpan={3} style={{fontSize: '12px', textTransform: 'uppercase', color: '#323338', fontWeight: 'bold', paddingTop: '10px', paddingBottom: '5px'}}>Employee Details</td>
                    </tr>
                    <tr>
                      <td style={{verticalAlign: 'top', paddingBottom: '15px', width: '45%', borderBottom: '1px solid #D0D4E4'}}>
                        <table style={{tableLayout: 'fixed', width: '-webkit-fill-available'}}>
                          <tbody><tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>Name</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 'bold',whiteSpace: "nowrap"}}>{`${initialValues.userId?.firstName} ${initialValues.userId?.lastName}`}</td>
                            </tr>
                            <tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>Employee Code</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>{initialValues.userId?.employeeID}</td>
                            </tr>
                            <tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>Designation</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500,whiteSpace: "nowrap"}}>{initialValues.userId?.designationId?.designationName}</td>
                            </tr>
                            <tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>Date Of Joining</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500,whiteSpace: "nowrap"}}>{dateFormat(user?.joindate,false)}</td>
                            </tr>
                            <tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>Gender</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>{user?.gender}</td>
                            </tr>
                             <tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>PAN</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>{panNo?.docno||"NA"}</td>
                            </tr> 
                            <tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>PF No.</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500,whiteSpace: "nowrap"}}>{(user?.pf_number)?user?.pf_number:"NA"}</td>
                            </tr>
                           {/* <tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>PF UAN.</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>102011597484</td>
                            </tr>
                            */}
                          </tbody></table>
                      </td>
                      <td style={{width: '10%', borderBottom: '1px solid #D0D4E4'}} />
                      <td style={{verticalAlign: 'top', paddingBottom: '15px', width: '45%', borderBottom: '1px solid #D0D4E4'}}>
                        <table style={{tableLayout: 'fixed', width: '-webkit-fill-available'}}>
                          <tbody><tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>Month Days</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>{initialValues?.daysDetails?.totalDays}</td>
                            </tr>
                            <tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>Eff. Working Days</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>{initialValues?.daysDetails?.workingDays}</td>
                            </tr>
                            <tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>LOP</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>NA</td>
                            </tr>
                            <tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>Paid Leave</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>{initialValues?.leaveDetails?.totalLeaveDeductionAmount}</td>
                            </tr>
                            <tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>Account Number</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>{initialValues?.bankDetails?.accountno}</td>
                            </tr>
                            <tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>Bank Name</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500,whiteSpace: "nowrap"}}>{initialValues?.bankDetails?.bankname}</td>
                            </tr>
                            <tr>
                              <th style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>Pay Mode</th>
                              <td style={{width: '10px', textAlign: 'center'}}>:</td>
                              <td style={{textAlign: 'left', color: '#323338', fontSize: '10px', fontFamily: 'Inter, sans-serif', fontWeight: 500}}>BANK TRANSFER</td>
                            </tr>
                          </tbody></table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{fontSize: '12px', textTransform: 'uppercase', color: '#323338', fontWeight: 'bold', paddingTop: '10px', paddingBottom: '5px'}}>Employee Pay Summary</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <table style={{width: '100%', borderCollapse: 'collapse', border: '1px solid #D0D4E4'}}>
                          <tbody><tr>
                              <th style={{width: '23%', paddingTop: '7px', paddingBottom: '12px', paddingLeft: '12px', paddingRight: '12px', background: '#F5F5F5', border: '1px solid #D0D4E4', fontSize: '12px', fontWeight: 'bold', color: '#323338', textAlign: 'left', verticalAlign: 'middle', lineHeight: '16px'}}>Earnings</th>
                              <th style={{width: '27%', paddingTop: '7px', paddingBottom: '12px', paddingLeft: '12px', paddingRight: '12px', background: '#F5F5F5', border: '1px solid #D0D4E4', fontSize: '12px', fontWeight: 'bold', color: '#323338', textAlign: 'right', verticalAlign: 'middle', lineHeight: '16px'}}>Amount Rs.</th>
                              <th style={{width: '23%', paddingTop: '7px', paddingBottom: '12px', paddingLeft: '12px', paddingRight: '12px', background: '#F5F5F5', border: '1px solid #D0D4E4', fontSize: '12px', fontWeight: 'bold', color: '#323338', textAlign: 'left', verticalAlign: 'middle', lineHeight: '16px'}}>Deductions</th>
                              <th style={{width: '27%', paddingTop: '7px', paddingBottom: '12px', paddingLeft: '12px', paddingRight: '12px', background: '#F5F5F5', border: '1px solid #D0D4E4', fontSize: '12px', fontWeight: 'bold', color: '#323338', textAlign: 'right', verticalAlign: 'middle', lineHeight: '16px'}}>Amount Rs.</th>
                            </tr>
                            <tr>
                              <td style={{width: '23%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '0px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'left', lineHeight: '14px'}}>Basic</td>
                              <td style={{width: '27%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', background: '#fff', fontSize: '10px', fontWeight: "bold", color: '#323338', textAlign: 'right', lineHeight: '14px'}}>{basic}</td>
                              <td style={{width: '23%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '0px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'left', borderLeft: '1px solid #D0D4E4', lineHeight: '14px'}}>Leave Deduction</td>
                              <td style={{width: '27%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'right', lineHeight: '14px'}}>{initialValues.leaveDetails?.otherLeaveDeductionAmount}</td>
                            </tr>
                            <tr>
                              <td style={{width: '23%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '0px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'left', lineHeight: '14px'}}>HRA</td>
                              <td style={{width: '27%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', background: '#fff', fontSize: '10px', fontWeight: "bold", color: '#323338', textAlign: 'right', lineHeight: '14px'}}>{hra}</td>
                              <td style={{width: '23%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '0px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'left', borderLeft: '1px solid #D0D4E4', lineHeight: '14px'}}>Lag Deduction</td>
                              <td style={{width: '27%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'right', lineHeight: '14px'}}>{Number(initialValues?.lagHourDetails?.productionLagDayAmount||0)+Number(initialValues?.lagHourDetails?.lagHoursAmount||0)}</td>
                            </tr>
                            <tr>
                              <td style={{width: '23%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '0px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'left', lineHeight: '14px'}}>Medical Allowance</td>
                              <td style={{width: '27%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', background: '#fff', fontSize: '10px', fontWeight: "bold", color: '#323338', textAlign: 'right', lineHeight: '14px'}}>{medical}</td>
                              <td style={{width: '23%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '0px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'left', borderLeft: '1px solid #D0D4E4', lineHeight: '14px'}}>PF Deduction</td>
                              <td style={{width: '27%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'right', lineHeight: '14px'}}>{initialValues?.lagHourDetails?.pfDeduction}</td>
                            </tr>
                            <tr>
                              <td style={{width: '23%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '0px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'left', lineHeight: '14px'}}>Transport Allowance </td>
                              <td style={{width: '27%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', background: '#fff', fontSize: '10px', fontWeight: "bold", color: '#323338', textAlign: 'right', lineHeight: '14px'}}>{convence}</td>
                              <td style={{width: '23%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '0px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'left', borderLeft: '1px solid #D0D4E4', lineHeight: '14px'}}>TAX Deduction</td>
                              <td style={{width: '27%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'right', lineHeight: '14px'}}>{initialValues?.lagHourDetails?.taxDeduction}</td>
                            </tr>
                            <tr>
                              <td style={{width: '23%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '0px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'left', lineHeight: '14px'}}>Special Allowances</td>
                              <td style={{width: '27%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', background: '#fff', fontSize: '10px', fontWeight: "bold", color: '#323338', textAlign: 'right', lineHeight: '14px'}}>{special?.toFixed(2)}</td>
                              <td style={{width: '23%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '0px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'left', borderLeft: '1px solid #D0D4E4', lineHeight: '14px'}}>Other Deduction</td>
                              <td style={{width: '27%', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'right', lineHeight: '14px'}}>{initialValues?.lagHourDetails?.otherDeduction}</td>
                            </tr>
                            <tr>
                              <td style={{width: '23%', paddingTop: '6px', paddingBottom: '10px', paddingLeft: '12px', paddingRight: '0px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'left', lineHeight: '14px'}}>Other Allowances</td>
                              <td style={{width: '27%', paddingTop: '6px', paddingBottom: '10px', paddingLeft: '12px', paddingRight: '12px', background: '#fff', fontSize: '10px', fontWeight: "bold", color: '#323338', textAlign: 'right', lineHeight: '14px'}}>0.00</td>
                              <td style={{width: '23%', paddingTop: '6px', paddingBottom: '10px', paddingLeft: '12px', paddingRight: '0px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'left', borderLeft: '1px solid #D0D4E4', lineHeight: '14px'}}>Extra Deduction</td>
                              <td style={{width: '27%', paddingTop: '6px', paddingBottom: '10px', paddingLeft: '12px', paddingRight: '12px', background: '#fff', fontSize: '10px', fontWeight: 500, color: '#323338', textAlign: 'right', lineHeight: '14px'}}>{initialValues?.lagHourDetails?.extraDeduction}</td>
                            </tr>
                          </tbody></table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{padding: '12px 0', borderTop: '1px solid #D7DAE0'}} />
                      <td style={{borderTop: '1px solid #D7DAE0'}} colSpan={3}>
                        <table style={{width: '100%', borderSpacing: 0}}>
                          <tbody>
                            <tr>
                              <th style={{paddingTop: '12px', fontSize: '12px', lineHeight: '14px', textAlign: 'end', color: '#7B7E8B', lineHeight: '14px',}}>
                                Total Earning (+)
                              </th>
                              <td style={{paddingTop: '12px', fontSize: '14px', textAlign: 'end', color: '#323338', fontWeight: 'bold', lineHeight: '14px'}}>
                                {initialValues?.monthlySalary}
                              </td>
                            </tr>
                            <tr>
                              <th style={{padding: '12px 0', fontSize: '10px', textAlign: 'end', color: '#7B7E8B', lineHeight: '14px'}}>
                                Total Deduction (-)
                              </th>
                              <td style={{padding: '12px 0', fontSize: '12px', textAlign: 'end', color: '#323338', fontWeight: 500, lineHeight: '14px'}}>
                                {initialValues?.lagHourDetails?.totalDeduction}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{padding: '10px 0', borderTop: '1px solid #A9ADBC', borderBottom: '1px solid #A9ADBC'}}>
                        <p style={{color: '#323338', fontSize: '12px', fontWeight: 'bold', paddingBottom: '7px',marginBottom:'0', lineHeight: '18px'}}>Total Payable Net Amount</p>
                        <p style={{color: '#0073EA', fontSize: '8px', fontWeight: 500,marginBottom:'0', lineHeight: '12px'}}>Total Payable Net Amount =Total Earnings-Total Deduction</p>
                      </td>
                      <td style={{textAlign: 'right', borderTop: '1px solid #A9ADBC', borderBottom: '1px solid #A9ADBC', fontSize: '18px', fontWeight: 'bold', color: '#0073EA', padding: '10px 0', lineHeight: '26px'}}>₹{initialValues?.netSalary}</td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{paddingTop: '15px'}}>
                        <p style={{display: 'flex', gap: '0 13px', color: '#7B7E8B',fontSize: '10px', lineHeight: '12px'}}><strong>Disclaimer:</strong> This is a system generated payslip, does not require any signature.</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
};

export default SalarySlip;
