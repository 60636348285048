export const SALARY_ACTIONS = {
    SALARY_SEARCH: 'SALARY_SEARCH',
    SALARY_SEARCH_SUCCESS: 'SALARY_SEARCH_SUCCESS',
    SALARY_SEARCH_ERROR: 'SALARY_SEARCH_ERROR',

    SALARY_UPSERT: 'SALARY_UPSERT',
    SALARY_UPSERT_SUCCESS: 'SALARY_UPSERT_SUCCESS',
    SALARY_UPSERT_ERROR: 'SALARY_UPSERT_ERROR',



    SALARY_UPLOAD: 'SALARY_UPLOAD',
    SALARY_UPLOAD_SUCCESS: 'SALARY_UPLOAD_SUCCESS',
    SALARY_UPLOAD_ERROR: 'SALARY_UPLOAD_ERROR',

    SALARY_EDIT: 'SALARY_EDIT',

    SALARY_RESET: 'SALARY_RESET',

    SALARY_INDIVIDUAL_UPDATE: 'SALARY_INDIVIDUAL_UPDATE',
};

export const salaryResetAction = () => ({
    type: SALARY_ACTIONS.SALARY_RESET,
});

export const salarySearchAction = (payload) => ({
    type: SALARY_ACTIONS.SALARY_SEARCH,
    ...payload,
});

export const salaryEditAction = (payload) => ({
    type: SALARY_ACTIONS.SALARY_EDIT,
    ...payload,
});

export const salaryUpsertAction = (payload) => ({
    type: SALARY_ACTIONS.SALARY_UPSERT,
    ...payload,
});
export const salaryUploadAction = (payload) => ({
    type: SALARY_ACTIONS.SALARY_UPLOAD,
    ...payload,
});
export const salaryIndividualUpdateAction = (payload) => ({
    type: SALARY_ACTIONS.SALARY_INDIVIDUAL_UPDATE,
    ...payload,
});

export default {
    SALARY_ACTIONS,
    salarySearchAction,
    salaryUpsertAction,
    salaryEditAction,
    salaryUploadAction,
    salaryIndividualUpdateAction,
};
