import React, { useEffect } from 'react';
import $ from 'jquery';
import { useSelector } from "react-redux";
import MenuComponent from './MenuComponent';
import { generateColor, getContrastYIQ } from 'src/utils/utils';

const LeftNavBar = () => { 
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    // Sidebar submenu collapsible
    $(".sidebar-menu .dropdown").on("click", function() {
      const item = $(this);
      item.siblings(".dropdown").children(".sidebar-submenu").slideUp();
      item.siblings(".dropdown").removeClass("dropdown-open");
      item.siblings(".dropdown").removeClass("open");
      item.children(".sidebar-submenu").slideToggle();
      item.toggleClass("dropdown-open");
    });

    $(".sidebar-toggle").on("click", function() {
      $(this).toggleClass("active");
      $(".sidebar").toggleClass("active");
      $(".dashboard-main").toggleClass("active");
    });

    $(".sidebar-mobile-toggle").on("click", function() {
      $(".sidebar").addClass("sidebar-open");
      $("body").addClass("overlay-active");
    });

    $(".sidebar-close-btn").on("click", function() {
      $(".sidebar").removeClass("sidebar-open");
      $("body").removeClass("overlay-active");
    });

    // Keep the current page active
    const currentPath = window.location.pathname;
    $("ul#sidebar-menu a").filter(function () {
      return this.pathname === currentPath;
    }).addClass("active-page").parent().addClass("active-page")
      .parentsUntil("ul#sidebar-menu", "li").addClass("open");

    // Table Header Checkbox checked all
    $('#selectAll').on('change', function () {
      $('.form-check .form-check-input').prop('checked', $(this).prop('checked')); 
    }); 

    // Remove Table Tr when click on remove btn
    $('.remove-btn').on('click', function () {
      $(this).closest('tr').remove(); 

      // Check if the table has no rows left
      if ($('.table tbody tr').length === 0) {
        $('.table').addClass('bg-danger');

        // Show notification
        $('.no-items-found').show();
      }
    });

    return () => {
      // Cleanup event listeners on unmount
      $(".sidebar-menu .dropdown").off("click");
      $(".sidebar-toggle").off("click");
      $(".sidebar-mobile-toggle").off("click");
      $(".sidebar-close-btn").off("click");
      $('#selectAll').off('change');
      $('.remove-btn').off('click');
    };
  }, []);

  // Utility functions for theme toggling
  useEffect(() => {
    const button = document.querySelector("[data-theme-toggle]");
    const localStorageTheme = localStorage.getItem("theme");

    const calculateSettingAsThemeString = ({ localStorageTheme }) => {
      return localStorageTheme !== null ? localStorageTheme : "light";
    };

    const updateButton = ({ buttonEl, isDark }) => {
      const newCta = isDark ? "dark" : "light";
      buttonEl.setAttribute("aria-label", newCta);
      buttonEl.innerText = newCta;
    };

    const updateThemeOnHtmlEl = ({ theme }) => {
      document.querySelector("html").setAttribute("data-theme", theme);
    };

    let currentThemeSetting = calculateSettingAsThemeString({ localStorageTheme });

    updateButton({ buttonEl: button, isDark: currentThemeSetting === "dark" });
    updateThemeOnHtmlEl({ theme: currentThemeSetting });

    const handleButtonClick = () => {
      const newTheme = currentThemeSetting === "dark" ? "light" : "dark";
      localStorage.setItem("theme", newTheme);
      updateButton({ buttonEl: button, isDark: newTheme === "dark" });
      updateThemeOnHtmlEl({ theme: newTheme });
      currentThemeSetting = newTheme;
    };

    button.addEventListener("click", handleButtonClick);

    return () => {
      button.removeEventListener("click", handleButtonClick);
    };
  }, []);
  // const auth = useSelector((state) => state.auth.user);
  // const { jwtToken: authToken, otherInformation: { profilePicture } = {} } =
  //   auth || {};
  // const navigate = useNavigate();
  // const dispatch = useDispatch();  
  useEffect(() => {
    // Initialize Bootstrap tooltips for elements with data-bs-toggle="tooltip"
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    // Cleanup tooltips when the component unmounts
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);
  return (
    <>
       <aside className="sidebar">
  <button type="button" className="sidebar-close-btn">
    <iconify-icon icon="radix-icons:cross-2" />
  </button>
  <div>
    <a href="/" className="sidebar-logo text-center">
      <img
        src="/assets/images/left-sidebar/logo.svg"
        alt="site logo"
        className="light-logo"
      />
      <img
        src="/assets/images/left-sidebar/logo.svg"
        alt="site logo"
        className="dark-logo"
      />
      <img
        src="/assets/images/left-sidebar/acadedigital-icon.svg"
        alt="site logo"
        className="logo-icon"
      />
    </a>
  </div>
  <div className="sidebar-menu-area">
    <ul className="sidebar-menu" id="sidebar-menu">
      <li className="userPList">
        <div className="text-center userNDEID">         
                                      <span
                                       className="img imgsSet w60px mx-auto"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       data-bs-custom-class="tooltip-dark"
                                       data-bs-title={`${user.firstName} ${user?.lastName}`}
                                     >
                                       {user.profilePicture ? (
                                         <img
                                           src={user.profilePicture}
                                           className="img-fluid"
                                           alt=""
                                         />
                                       ) : (
                                         <span
                                           className="initials d-flex justify-content-center align-items-center w-100 h-100"
                                           style={{
                                             backgroundColor: generateColor(`${user.firstName}${user?.lastName}`),
                                             color: getContrastYIQ(generateColor(`${user.firstName}${user?.lastName}`)),
                                           }}
                                         >
                                           {`${user.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`}
                                         </span>
                                       )}
                                     </span>
          <div className="">
            <div className="name">{`${user.firstName} ${user?.lastName}`}</div>
            <div className="designation">{user.designationDetails.designationName}</div>
            <div className="emplyId">{user.username}</div>
          </div>
        </div>
        <div
          className="accordion accordion-flush emplyDtalsMain"
          id="accordionuserFile"
        >
          <div className="accordion-item border-0 p-0">
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionuserFile"
            >
              <div className="accordion-body">
                <ul className="emplyDtalsList">
                  <li className='d-flex align-items-center gap-2'>
                    <span className="d-block">Department :</span>
                    {user.departmentDetails.departmentName}
                  </li>
                  <li  className='d-flex align-items-center gap-2'>
                    <span className="d-block">Designation :</span>
                    {user.designationDetails.designationName}
                  </li>
                  {
                    user.reportingManager?.length>0 && <li  className='d-flex align-items-center gap-2'> 
                    <span className="d-block">Reporting Manager :</span>
                      {user.reportingManager[0].firstName} {user.reportingManager[0].lastName}
                    </li>
                  } 
                </ul>
              </div>
            </div>
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed justify-content-center bg-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                <span id="emplyDtals">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                  >
                    <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z" />
                  </svg>
                </span>
              </button>
            </h2>
          </div>
        </div>
      </li>     
      <MenuComponent/>      
    </ul>
    <ul className="mt-auto pt-5">
      <li className="sidebar-menu-group-title emailLftBr">
        <span className="d-block d-flex align-items-center gap-1">
          <img
            src="/assets/images/left-sidebar/email-icon.svg"
            className="img-fluid"
          />
          info@acadecraft.net
        </span>{" "}
        All Rights Reserved by Acadecraft | Copyright © 2011-2024{" "}
      </li>
    </ul>
  </div>
</aside>

    </>
  );
};
export default LeftNavBar;
