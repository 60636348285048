import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import TreeComponent from '../tree/TreeComponent';

const component = ({
    field, form, ...rest
}) => (
    <>
        <TreeComponent
            onChange={(data) => {
                form.setFieldValue(field.name, data);
            }}
            {...rest}
        />

        <ErrorMessage
            component="div"
            className="text-danger"
            name={field.name}
        />
    </>
);

component.propTypes = {
    field: PropTypes.any.isRequired,
    form: PropTypes.any.isRequired,
};

const FormikTree = (props) => (
    <Field
        {...props}
        component={component}
    />
);

export default FormikTree;
