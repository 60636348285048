import React, { useCallback, useState, useEffect } from 'react';
import config from "src/config";
import { postRequest } from 'src/utils/RequestUtil';
import SearchInput from '../../../components/SearchInput';
import MonthPicker from '../../../components/MonthPicker';
// import PddStatsVertical from '../../../components/PddStatsVertical';
import PddstatsManager from './PddstatsManager';
// import PddstatsProject from '../../../components/PddstatsProject';
import SummariesComponent from './SummariesComponent';
import PddStatsVertical from './PddStatsVertical';
import PddstatsProject from './PddstatsProject';
import { costFormat } from 'src/utils/CommonUtils';
import { useSticky } from 'src/hooks/useSticky';
import { map } from 'lodash';

const PddStats = () => {

    const [searchQuery, setSearchTerm] = useState('');
    // Function to handle the search input change
    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const [search, setSearch] = useState([]);
    const fetchData = async (yearmonth) => {
        const url2 = `${config.baseUrl}project/stats`;
        try {
            const result2 = await postRequest({
                url: url2,
                data: { "yearmonth": yearmonth },  // Adjust this as per your data structure
            });
            setSearch(result2.data.data)
        } catch (error) {
            console.error("Error in fetching data", error);
        }
    }    

    useEffect(() => {
        const currentYearmonth = new Date().getFullYear() + "-" + String(new Date().getMonth() + 1).padStart(2, '0');
        fetchData(currentYearmonth)
    }, [])

    const handleChangeDate = (date) => {
        const year = date.getFullYear();  // year
        const month = String(date.getMonth() + 1).padStart(2, '0'); // date.getMonth() + 1;  // Since month is 0-indexed in JavaScript, add 1
        const yearmonth = year + "-" + month
        // Call fetchData with the dynamic yearmonth
        fetchData(yearmonth);
    }
    const [viewAll, setViewAll] = useState(false);




    const handleViewAllChange = (e) => {
        setViewAll(e.target.checked);
        // Expand or collapse all accordions based on checkbox state
        const accordions = document.querySelectorAll(".lmsn001");
        accordions.forEach((accordion) => {
            const collapse = new window.bootstrap.Collapse(accordion, {
                toggle: false,
            });
            viewAll ? collapse.hide() : collapse.show();
        });
    };

    const calculateTotals = (userIdTotals, detailType) => {
        const array = userIdTotals.reduce((acc, item) => {
            item[detailType].forEach(detail => {
                acc[detail._id] = detail; // Use detail._id as the key
            });
            return acc; // Return the accumulated object
        }, {});


        // Calculate total
        let total = 0;
        for (const detail of Object.values(array)) {
            total += detail.type === "add" ? detail.value : -detail.value; // Add or subtract based on type
        }

        return total;
    }    

    return (
        <>
            <div className="row">
                <div className="col-xxl-12 col-xl-12">
                    <div className="card h-100">
                        <div class="card-body px-24 pb-24 pt-0">
                            <div className="d-flex justify-content-between align-items-center border-bottom  pb-16 position-relative z-3 pt-24 bg-white">
                                <div className="">
                                    <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                                        PDD Stats
                                    </h2>
                                    <ul className="d-flex align-items-center gap-2">
                                        <li className="fw-medium">
                                            <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                                                <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                                                Special Access
                                            </a>
                                        </li>
                                        <li>-</li>
                                        <li className="fw-medium">PDD Stats</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center pt100 z-3">
                                <ul class="nav focus-tab nav-pills mb-16 mt-24" id="pills-tab-two" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link fw-semibold text-primary-light radius-4 px-16 py-10 active" id="pills-focus-Vertical-tab" data-bs-toggle="pill" data-bs-target="#pills-focus-Vertical" type="button" role="tab" aria-controls="pills-focus-Vertical" aria-selected="true">Vertical  </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link fw-semibold text-primary-light radius-4 px-16 py-10" id="pills-focus-Manager-tab" data-bs-toggle="pill" data-bs-target="#pills-focus-Manager" type="button" role="tab" aria-controls="pills-focus-Manager" aria-selected="false" tabindex="-1">Manager   </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link fw-semibold text-primary-light radius-4 px-16 py-10" id="pills-focus-Project-tab" data-bs-toggle="pill" data-bs-target="#pills-focus-Project" type="button" role="tab" aria-controls="pills-focus-Project" aria-selected="false" tabindex="-1">Project </button>
                                    </li>
                                </ul>
                                <div className="d-flex gap-2 align-items-center py-16 mt-16">
                                    {/* <SearchInput /> */}
                                    <SearchInput handleSearchInputChange={handleSearchInputChange} placeholder="Search..." />
                                    <MonthPicker handleChangeDate={handleChangeDate} />
                                </div>
                            </div>

                            <div class="tab-content" id="pills-tab-twoContent">
                                <div class="tab-pane fade active show" id="pills-focus-Vertical" role="tabpanel" aria-labelledby="pills-focus-Vertical-tab" tabindex="0">
                                    <PddStatsVertical search={search} searchQuery={searchQuery} />
                                </div>
                                <div class="tab-pane fade" id="pills-focus-Manager" role="tabpanel" aria-labelledby="pills-focus-Manager-tab" tabindex="0">
                                    <PddstatsManager search={search} searchQuery={searchQuery} />
                                </div>
                                <div class="tab-pane fade" id="pills-focus-Project" role="tabpanel" aria-labelledby="pills-focus-Project-tab" tabindex="0">
                                    <PddstatsProject search={search} searchQuery={searchQuery} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PddStats
