import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { AUTH_ACTIONS } from 'src/containers/auth-container/actions/AuthActions';
import { PERMISSION_MASTER_ACTIONS } from '../actions/PermissionsMasterActions';

const initialState = () => ({
    scopes: null,
    permissionMasterFetchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case PERMISSION_MASTER_ACTIONS.PERMISSION_MASTER_FETCH: {
            return {
                ...state,
                permissionMasterFetchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case PERMISSION_MASTER_ACTIONS.PERMISSION_MASTER_FETCH_SUCCESS: {
            return {
                ...state,
                scopes: action.data,
                permissionMasterFetchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case PERMISSION_MASTER_ACTIONS.PERMISSION_MASTER_FETCH_ERROR: {
            return {
                ...state,
                permissionMasterFetchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case AUTH_ACTIONS.LOGOUT:
            return {
                ...initialState(),
            };
        default:
            return state;
    }
};
