import config from "src/config";
import { postRequest } from "src/utils/RequestUtil";
import { toast } from 'react-toastify';

export const handleLogin = async ( values) => {
  try {
    const url = `${config.baseUrl}auth/sign-in`;
    return await postRequest({
      url,
      data: { ...values },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
};

export const handleLogout = async (userId) => {
  try {
    const url = `${config.baseUrl}auth/logout/all/devices`;
    return postRequest({
      url,
      data: {userId},
    });
  } catch (error) {
    throw error;
  }
};
