const ManageColum=()=>{
   return (
    <div
  className="offcanvas offcanvas-end filterSecPopup"
  id="manageColRightPopup"
  aria-labelledby="manageColRightLayout"
>
  <div className="offcanvas-header">
    <h5 className="offcanvas-title" id="manageColRightLayout">
      Manage Column
    </h5>
    <button
      type="button"
      className="btn-close d-none"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    />
  </div>
  <div className="offcanvas-body pt-0">
    <div className="assignPopoHeight">
      <div className="mangeSearchSec">
        <input
          type="text"
          className="managecolsSearch"
          placeholder="Find columns to show/hide"
        />
        <svg
          width={17}
          height={16}
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.3215 14.9883L12.0198 10.6866C12.9629 9.55576 13.5321 8.10274 13.5321 6.51846C13.5321 2.92412 10.608 0 7.01362 0C3.41926 0 0.495117 2.92412 0.495117 6.51846C0.495117 10.1128 3.41926 13.0369 7.01362 13.0369C8.59791 13.0369 10.0509 12.4677 11.1818 11.5246L15.4835 15.8264C15.5992 15.9421 15.7509 16 15.9025 16C16.054 16 16.2057 15.9421 16.3215 15.8264C16.553 15.5948 16.553 15.2197 16.3215 14.9883ZM1.6803 6.51846C1.6803 3.57774 4.07259 1.18517 7.01362 1.18517C9.95465 1.18517 12.3469 3.57774 12.3469 6.51846C12.3469 9.45917 9.95465 11.8517 7.01362 11.8517C4.07259 11.8517 1.6803 9.45917 1.6803 6.51846Z"
            fill="#323338"
          />
        </svg>
      </div>
      <div id="manageColMenu" className="selectSec managecolsmenuSec">
        <div className="form-group AllFilter_Select py-0 mangeColHoversec">
          {/* <div class="filterIconHover"><svg width="9" height="21" viewBox="0 0 9 21" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <circle cx="1.5" cy="1.5" r="1.5" fill="#94A3B8" />
                          <circle cx="7.5" cy="1.5" r="1.5" fill="#94A3B8" />
                          <circle cx="1.5" cy="7.5" r="1.5" fill="#94A3B8" />
                          <circle cx="7.5" cy="7.5" r="1.5" fill="#94A3B8" />
                          <circle cx="1.5" cy="13.5" r="1.5" fill="#94A3B8" />
                          <circle cx="7.5" cy="13.5" r="1.5" fill="#94A3B8" />
                          <circle cx="1.5" cy="19.5" r="1.5" fill="#94A3B8" />
                          <circle cx="7.5" cy="19.5" r="1.5" fill="#94A3B8" />
                      </svg>
                  </div> */}
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllmenu"
          />
          <label htmlFor="selectAllmenu">
            All columns <span>12 Selected</span>
          </label>
        </div>
        <div className="form-group AllFilter_Select py-0 mangeColHoversec">
          <div className="filterIconHover">
            <svg
              width={9}
              height={21}
              viewBox="0 0 9 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="1.5" cy="1.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="1.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="7.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="7.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="13.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="13.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="19.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="19.5" r="1.5" fill="#94A3B8" />
            </svg>
          </div>
          <input className="form-check-input" type="checkbox" id="deactive0" />
          <label htmlFor="deactive0">Actual Date</label>
        </div>
        <div className="form-group AllFilter_Select py-0 mangeColHoversec">
          <div className="filterIconHover">
            <svg
              width={9}
              height={21}
              viewBox="0 0 9 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="1.5" cy="1.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="1.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="7.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="7.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="13.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="13.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="19.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="19.5" r="1.5" fill="#94A3B8" />
            </svg>
          </div>
          <input className="form-check-input" type="checkbox" id="deactive1" />
          <label htmlFor="deactive1">Delivery Date</label>
        </div>
        <div className="form-group AllFilter_Select py-0 mangeColHoversec">
          <div className="filterIconHover">
            <svg
              width={9}
              height={21}
              viewBox="0 0 9 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="1.5" cy="1.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="1.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="7.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="7.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="13.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="13.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="19.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="19.5" r="1.5" fill="#94A3B8" />
            </svg>
          </div>
          <input className="form-check-input" type="checkbox" id="deactive2" />
          <label htmlFor="deactive2">Uploaded by</label>
        </div>
        <div className="form-group AllFilter_Select py-0 mangeColHoversec">
          <div className="filterIconHover">
            <svg
              width={9}
              height={21}
              viewBox="0 0 9 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="1.5" cy="1.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="1.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="7.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="7.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="13.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="13.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="19.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="19.5" r="1.5" fill="#94A3B8" />
            </svg>
          </div>
          <input className="form-check-input" type="checkbox" id="deactive3" />
          <label htmlFor="deactive3">Department</label>
        </div>
        <div className="form-group AllFilter_Select py-0 mangeColHoversec">
          <div className="filterIconHover">
            <svg
              width={9}
              height={21}
              viewBox="0 0 9 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="1.5" cy="1.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="1.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="7.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="7.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="13.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="13.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="19.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="19.5" r="1.5" fill="#94A3B8" />
            </svg>
          </div>
          <input className="form-check-input" type="checkbox" id="deactive4" />
          <label htmlFor="deactive4">Cadre</label>
        </div>
        <div className="form-group AllFilter_Select py-0 mangeColHoversec">
          <div className="filterIconHover">
            <svg
              width={9}
              height={21}
              viewBox="0 0 9 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="1.5" cy="1.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="1.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="7.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="7.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="13.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="13.5" r="1.5" fill="#94A3B8" />
              <circle cx="1.5" cy="19.5" r="1.5" fill="#94A3B8" />
              <circle cx="7.5" cy="19.5" r="1.5" fill="#94A3B8" />
            </svg>
          </div>
          <input className="form-check-input" type="checkbox" id="deactive5" />
          <label htmlFor="deactive5">Count</label>
        </div>
      </div>
    </div>
    <div className="">
      <button
        type="button"
        className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
      >
        Save as new view
      </button>
    </div>
  </div>
</div>

   )
}
export default ManageColum