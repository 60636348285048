import React from 'react';

const ProjectCardTimeStamp = ({ title, date, badgeText, badgeClass, description }) => {
    return (
        
            <div className="border-bottom">
                <div className="card-header pt-16 pb-0 px-0 bg-base d-flex align-items-center gap-1 justify-content-between border-0">
                    <div>
                        <h6 className="text-lg mb-0 text-dblue">{title}</h6>
                    </div>
                    <div className="d-flex align-items-center gap-4">
                        <span className="text-neutral-900 fw-bold">Date : {date}</span>
                        <span className={`badge text-md fw-semibold rounded-pill ${badgeClass} px-20 py-9 radius-4 text-white`}>
                            {badgeText}
                        </span>
                    </div>
                </div>
                <div className="card-body py-16 px-0">
                    <p className="card-text text-neutral-600 mb-16 text-sm"><span className="d-block text-neutral-900">Description</span>{description}</p>
                </div>
            </div> 
    );
};

export default ProjectCardTimeStamp
