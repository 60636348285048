import React, { useState } from 'react';

// Helper function to generate random color
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Function to calculate contrast color (black or white)
const getContrastColor = (hexColor) => {
  // Remove the # character
  hexColor = hexColor.substring(1);
  
  // Convert hex to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate brightness using the formula
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  
  // If brightness is less than 128, return white, otherwise return black
  return brightness < 128 ? '#FFFFFF' : '#000000';
};

const EmployeeDetails = () => {
  const [employee, setEmployee] = useState({
    name: 'Nandita Sharma',
    id: 4575,
    role: 'Content Writer',
    department: 'Production',
    // email: 'NanditaSharma125@gmail.com',
    // mobile: '7982590019',
    accountNo: '153110100058484',
    ifsc: 'UBIN0815314',
    bankName: 'UNION BANK OF INDIA',
    tenure: '1 Year 2 Months 5 Days',
    doj: '08 January 2024 11:24:18',
    imageUrl: null,  // null if no image is available
  });

  // Function to extract initials (e.g., N from Nandita and S from Sharma)
  const getInitials = (name) => {
    const nameParts = name.split(' ');
    const initials = nameParts.length > 1 ? nameParts[0][0] + nameParts[1][0] : nameParts[0][0];
    return initials.toUpperCase();
  };

  // Generate dynamic background color and contrast text color
  const backgroundColor = getRandomColor();
  const textColor = getContrastColor(backgroundColor);

  return (
    <div className="employee-details my-24 border-bottom">
      <h2 className="card-title">Employee Details</h2>
      <div className="employee-ftdtal d-flex justify-content-between border-bottom py-24">
      <div className="employee-header d-flex align-items-center gap-2">
        <span className="profile-pic">
          {employee.imageUrl ? (
            <img
              src={employee.imageUrl}
              alt={employee.name} 
            />
          ) : (
            <div
              className="initials-placeholder"
              style={{
                backgroundColor: backgroundColor,
                color: textColor,
              }}
            >
              {getInitials(employee.name)}
            </div>
          )}
        </span>
        <div>
          <h3 className="card-title">
            {employee.name} <span>({employee.id})</span>
          </h3>
          <p className="mb-0">{employee.role}</p>
          <p className="mb-0">Department: {employee.department}</p>
        </div>
      </div>
      <div className="contact-info text-end">
        <h3 className="text-xxl"><span className="fw-semibold d-block">September</span></h3>
      </div>
      </div>
      <div className="bank-details d-flex justify-content-between align-items-center"> 
        <div><span className="fw-bold d-block">Account No:</span> {employee.accountNo}</div>
        <div><span className="fw-bold d-block">IFSC:</span> {employee.ifsc}</div>
        <div><span className="fw-bold d-block">Bank Name:</span> {employee.bankName}</div>
        <div><span className="fw-bold d-block">Tenure:</span> {employee.tenure}</div>
        <div><span className="fw-bold d-block">Date of Joining:</span> {employee.doj}</div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
