import React from 'react';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';

const FormikMySelect = ({ name, options,onChange, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (selectedOptions) => {
    onChange(selectedOptions)
    setFieldValue(name, selectedOptions);
  };

  return (
    <div>
      <Select
        {...field}
        {...props}
        options={options}
        onChange={handleChange}
        value={field.value}
        isMulti
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default FormikMySelect;
