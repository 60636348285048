import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LeftNavBar from '../layout-container/LeftNavBar';
import HeaderNav from '../layout-container/HeaderNav';
import ProductionContainer from '../production-container/ProductionContainer';
import Login from '../login-container/Login';
import ClientContainer from '../client-container/ClientContainer';
import ProjectContainer from '../project-container/ProjectContainer';
import UserContainer from '../user-container/UserContainer';
import EmployeeDataContainer from '../employee-container/Data/EmployeeDataContainer';
import EmployeeProductionContainer from '../employee-container/Production/EmployeeProductionContainer';
import EmployeeProjectContainer from '../employee-container/Project/EmployeeProjectContainer';
import EmployeeSkillContainer from '../employee-container/Skill/EmployeeSkillContainer';
import ModuleContainer from '../project-container/Module/ModuleContainer';
import FeedBackLog from 'src/components/FeedBackLog';
import MTDContainer from '../MTD-container/MDTContainer';
import DeliveryDeviationContainer from '../delivery-daviation-container/DeliveryDeviationContainer';
import PermissionContainer from '../permission-container/PermissionContainer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchUserScopesAction } from '../home-container/actions/authAction';
import ViewPerformance from 'src/components/ViewPerformance';
import TaskPendingContainer from '../pending-container/TaskPendingContainer';
import ProductionPendingContainer from '../pending-container/ProductionPendingContainer';
import DateRangePickerWithAll from 'src/components/DateRangePickerWithAll';
import Documentation from 'src/components/Documentation';
import { setDefaultHeaders } from 'src/utils/RequestUtil';
import FeedbackLMS from 'src/components/FeedbackLMS';
import FeedbackLog2 from 'src/components/FeedbackLog2';
import FeedbackContainer from '../feedback-container/FeedbackContainer';
import MyAudit from 'src/components/MyAudit';
import UCLTopScorersChart from 'src/components/UCLTopScorersChart';
import ProductionListContainer from '../pending-container/ProductionContainer';
import AuditReport from 'src/components/AuditReport';
import EmployeePerfomanceContainer from '../employee-container/Perfomance/EmployeePerfomanceContainer';
import VendorDelivery from 'src/components/VendorDelivery';
import PddStats from 'src/containers/project-container/pdd/PddStats';
import AccountInvoice from 'src/components/AccountInvoice';
import InvoicePaid from 'src/components/InvoicePaid';
import CancelledInvoice from 'src/components/CancelledInvoice';
import SummaryInvoiceTable from 'src/components/SummaryInvoiceTable';
import VendorList from 'src/components/VendorList';
import PaymentDetails from 'src/components/PaymentDetails/PaymentDetails';
import CreateInvoice from 'src/components/PaymentDetails/CreateInvoice';
import VendorContainer from '../vendor-container/index';
import HrEmployeeManageSalary from '../salary-container/HrEmployeeManageSalary';
import AddEmployeeSalary from '../salary-container/AddEmployeeSalary';
import AddSalarySlip from '../salary-container/AddSalarySlip';
import EmployeeSalarySlip from 'src/components/Salary/EmployeeSalarySlip';
import EmployeeSalarySlip2 from 'src/components/Salary/EmployeeSalarySlip2';
import InvoiceTableModal from 'src/components/InvoiceTableModal'; 
import SalarySlip from '../salary-container/SalarySlip';
import TaxInvoiceDownload from 'src/components/InvoiceDownload/TaxInvoiceDownload';
import ClientInvoiceDownload from 'src/components/InvoiceDownload/ClientInvoiceDownload';
import FreelancerInvoice from 'src/components/VendorInvoicePdf/FreelancerInvoice';
import VendorInvoicePdf from 'src/components/VendorInvoicePdf/VendorInvoicePdf';
import SalaryContainer from '../employee-container/salary/Index';
import TimeStamp from 'src/components/TimeStamp/TimeStamp';
 

// import Permission from 'src/components/Permission';

// import Home from '../home-container/Home';
function MainContainer() {
  const dispatch = useDispatch();
  const userScopes = useSelector((state) => state.auth.user.userScopes, shallowEqual);
  if (userScopes && userScopes.length === 0) {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = JSON.parse(localStorage.getItem("token"));
    dispatch(fetchUserScopesAction({ userId: user?._id, roleId: user?.roleDetails?._id }));
    setDefaultHeaders({
      Authorization: token,
    });
  }
  return (
    <>
      <LeftNavBar />
      <main className="dashboard-main">
        <HeaderNav />
        <div className="dashboard-main-body pt-0">
          <Routes>

            <Route index element={<ProductionContainer />} />
            <Route path="productions" element={<ProductionContainer />} />
            <Route path="projects/*" element={<ProjectContainer />} />
            <Route path="clients" element={<ClientContainer />} />
            <Route path="employee-data" element={<EmployeeDataContainer />} />
            <Route path="manage-salary" element={<HrEmployeeManageSalary />} />
            <Route path="salary" element={<SalaryContainer />} />
            <Route path="view-production/:userId" element={<EmployeeProductionContainer />} />
            <Route path="view-perfomance/:employeeID/:userId" element={<EmployeePerfomanceContainer />} />
            <Route path="view-project" element={<EmployeeProjectContainer />} />
            <Route path="skill" element={<EmployeeSkillContainer />} />
            <Route path="module" element={<ModuleContainer />} />
            <Route path="feedback-log" element={<FeedBackLog />} />
            <Route path="mtd-delivery" element={<MTDContainer />} />
            <Route path="delivery-deviation" element={<DeliveryDeviationContainer />} />
            <Route path="permission" element={<PermissionContainer />} />
            <Route path="performance" element={<ViewPerformance />} />
            <Route path="pending-task-list" element={<TaskPendingContainer />} />
            <Route path="pending-production-list" element={<ProductionPendingContainer />} />
            <Route path="documentation" element={<Documentation />} />
            <Route path="feedback-lms" element={<FeedbackLMS />} />
            <Route path="feedbacklog" element={<FeedbackLog2 />} />
            <Route path="manage-project-feedback" element={<FeedbackContainer />} />
            <Route path="myaudit" element={<MyAudit />} />
            <Route path="scorerschart" element={<UCLTopScorersChart />} />
            <Route path="production-list" element={<ProductionListContainer />} />
            <Route path="documentation" element={<Documentation />} />
            <Route path="feedback-lms" element={<FeedbackLMS />} />
            <Route path="feedbacklog" element={<FeedbackLog2 />} />
            <Route path="myaudit" element={<MyAudit />} />
            <Route path="scorerschart" element={<UCLTopScorersChart />} />
            <Route path="auditreport" element={<AuditReport />} />
            <Route path="vendor-delivery" element={<VendorDelivery />} />
            <Route path="vendor-list" element={<VendorList />} />
            <Route path="pdd-stats" element={<PddStats />} />
            <Route path="account-invoice" element={<AccountInvoice />} />
            <Route path="invoice-paid" element={<InvoicePaid />} />
            <Route path="cancelled-invoice" element={<CancelledInvoice />} />
            <Route path="summary-invoice-table" element={<SummaryInvoiceTable />} />
            <Route path="payment-details" element={<PaymentDetails />} />
            <Route path="create-invoice" element={<CreateInvoice />} />
            <Route path="documentation" element={<Documentation />} />
            <Route path="feedback-lms" element={<FeedbackLMS />} />
            <Route path="feedbacklog" element={<FeedbackLog2 />} />
            <Route path="myaudit" element={<MyAudit />} />
            <Route path="scorerschart" element={<UCLTopScorersChart />} />
            <Route path="auditreport" element={<AuditReport />} />
            <Route path="vendor-list" element={<VendorContainer />} />
            <Route path="pdd-stats" element={<PddStats />} />
            <Route path="account-invoice" element={<AccountInvoice />} />
            <Route path="invoice-paid" element={<InvoicePaid />} />
            <Route path="cancelled-invoice" element={<CancelledInvoice />} />
            <Route path="summary-invoice-table" element={<SummaryInvoiceTable />} />
            
            <Route path="add-salary-slip/:userId" element={<AddSalarySlip />} />
            <Route path="pending-salary" element={<AddEmployeeSalary />} /> 

            
            <Route path="employee-salary-slip" element={<EmployeeSalarySlip />} />
            <Route path="employee-salary-slip2" element={<EmployeeSalarySlip2 />} />
            <Route path="invoice-table-modal" element={<InvoiceTableModal />} />    
            <Route path="SalarySlip" element={<SalarySlip />} />    
            <Route path="TaxInvoiceDownload" element={<TaxInvoiceDownload />} />    
            <Route path="ClientInvoiceDownload" element={<ClientInvoiceDownload />} />    
            <Route path="FreelancerInvoicedownload" element={<FreelancerInvoice />} />    
            <Route path="VendorInvoicedownload" element={<VendorInvoicePdf />} />    
       
            <Route path="timestamp" element={<TimeStamp />} />   

          </Routes>
        </div>
      </main>

      {/**/}
    </>
  );
}

export default MainContainer;
