import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const employeeDataSearchAPI = function (data) {
    const url = `${config.baseUrl}user/get`;
    return postRequest({
        url,
        data,
    });
}; 
export const employeeDataCreateAPI = function (data) {
    const url = `${config.baseUrl}user/save`;
    return postRequest({
        url,
        data,
    });
};

export const employeeDataUpdateAPI = function ({
    userId,
    data,
}) {
    const url = `${config.baseUrl}user/update/${userId}`;
    return putRequest({
        url,
        data,
    });
};

export const getReportingManager = function (data) {
    const url = `${config.baseUrl}user/getReportingManager`;
    return postRequest({
        url,
        data,
    });
};