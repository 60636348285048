import React, { useState, useEffect, useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css'; // You can choose any theme here

const DatePickerComponent = ({
  onDateRangeChange,
  isFlatPikerReset = false,
}) => {
  const [selectedRange, setSelectedRange] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const datePickerRef = useRef(null);

  useEffect(() => {
    // Detect clicks outside the datepicker to remove active class
    const handleDocumentClick = (event) => {
      if (
        !event.target.closest('.datepker') &&
        !event.target.closest('.flatpickr-input') &&
        !selectedRange
      ) {
        setIsActive(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [selectedRange]);

  const resetDatePicker = () => {
    setSelectedRange([]); // Reset state
    if (datePickerRef.current) {
      datePickerRef.current.flatpickr.clear(); // Clear the Flatpickr input
    }
  };
  useEffect(() => {
    if (isFlatPikerReset) {
      resetDatePicker();
    }
  }, [isFlatPikerReset]);

  return (
    <div className={`datepker position-relative ${isActive ? 'active' : ''}`}>
      <span
        className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1"
        onClick={() => {
          setIsActive(true); // Add active class when clicked
          datePickerRef.current.flatpickr.open(); // Open flatpickr
        }}
        style={{ cursor: 'pointer' }}
      >
        <iconify-icon icon="solar:calendar-linear" className="icon text-lg"></iconify-icon>
      </span>

      <Flatpickr
        className="form-control radius-8 bg-base flatpickr-input"
        placeholder="Date"
        id='datepker'
        options={{
          mode: 'range',
          showMonths: 2, // Display 2 months for the range picker
          dateFormat: 'Y-m-d',
        }}
        value={selectedRange}
        onChange={(dates) => {
          onDateRangeChange(dates);
          setSelectedRange(dates); // Handle the selected date range
          setIsActive(!!dates.length); // Set active based on selection
        }}
        ref={datePickerRef}
      />
    </div>
  );
};

export default DatePickerComponent;
