
// const API = "http://localhost:8000"
// export default {
//   baseUrl: `${API}/api/v1/`,
//   imageUrl: "https://stagging.acadedigital.com/uploads/",
// };


const API = "https://server.acadedigital.com/"
export default {
  baseUrl: `${API}/api/v1/`,
  imageUrl: "https://stagging.acadedigital.com/uploads/",
};
