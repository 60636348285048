import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import config from "src/config";
import { toast } from 'react-toastify';
// import { withFormikDevtools } from 'formik-devtools-extension';
// import SearchInput from 'src/components/SearchInput';
// import DatePickerComponent from 'src/components/DatePickerComponent';
// import Select2Component from 'src/components/Select2Component';
// import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { postRequest } from 'src/utils/RequestUtil';
// import { feedbackSearchAction } from '../project-container/actions/ProjectActions';
import { feedbackSearchAction } from './actions/FeedbackActions';


const FeedbackEmail = () => {
    const dispatch = useDispatch();
    const {
        search,
    } = useSelector((state) => state.FeedbackReducer, shallowEqual);
    console.log('search2', search);
    const [pageNo, setPageNo] = useState(0);
    const {_id:userId} = useSelector(state=>state.auth?.user,shallowEqual);  
  
    const feedbackSearch=()=>{
      const payload = {
        where,
        currentPage: pageNo,
        autopopulate: true,
        fullTextSearch: true,
      }
      dispatch(feedbackSearchAction(payload));
    }
    const [where, setWhere] = useState({
        participants: {
          $elemMatch: {
            userId: userId,
            status: "Active"
          }
        }
      });
    useEffect(() => {
      feedbackSearch()
    }, [where, pageNo])


    const [isModalOpen, setIsModalOpen] = useState(false);

    const [initialValues, setInitialValues] = useState({
        fullName: '',
        email: '',
    })
    // const handleOpenModal = () => {
    //     setIsModalOpen(true);
    // };
    const handleOpenModal = (newValues = { fullName: '', email: '' }) => {
        setInitialValues(newValues); // Set the initial values
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setInitialValues({
            fullName: '',
            email: '',
        })
    };
    // Validation schema
    const validationSchema = Yup.object({
        fullName: Yup.string()
            .required('Full Name is required')
            .min(2, 'Full Name must be at least 2 characters'),
        email: Yup.string()
            .email('Invalid email address')
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                'Email must have a valid domain, e.g., example@domain.com'
            )
            .required('Email is required'),
    });

    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        // console.log(values, 23);
        setSubmitting(false);
        try {
            
            const url = `${config.baseUrl}feedback/email/save`;
            const result = await postRequest({
                url,
                data: values,  // 'values' from the form or 'edit' depending on the data structure
            });            

            toast.success(result.data?.message);
            // Reset the form after successful submission
            resetForm();
            // Close the modal after submission
            handleCloseModal();
            feedbackSearch();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                // If error message exists in the response from the server
                toast.error(error.response.data.message);
            } else if (error.message) {
                // If it's a generic error (like a network issue)
                toast.error(error.message);
            } else {
                // Fallback for any other errors
                toast.error('Failed to save.');
            }
        }

    };
    useEffect(() => {
        if (isModalOpen) {
            setInitialValues({
                fullName: '',
                email: '',
            });
        }
    }, [isModalOpen]);

    return (
        <>
            <div>
                <div className="d-flex justify-content-end"><button onClick={handleOpenModal} class="btn btn-primary text-sm btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2"><i class="mdi mdi-plus"></i> Add Email ID</button></div>
                <div className="table-responsive table-container mt-24">
                    <table className="table basic-border-table mb-0 table-hover">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Email ID	</th>
                                <th>User Name	</th>
                                <th className='text-center'>No. of Subject Lines	</th>
                                <th className='text-center'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {search?.data.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1} </td>
                                    <td><div className="tsknem">{item.email}</div></td>
                                    <td>{item.fullName}</td>
                                    <td className='text-center'>0</td>
                                    <td className='text-center'><button type="button" class="btn btn-danger-100 text-danger-600 radius-8 px-14 py-6 text-sm">Remove</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>



            <div
                className={`modal fade cusmodl ${isModalOpen ? 'show' : ''}`}
                id="AddEmailIDModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden={!isModalOpen}
                style={{ display: isModalOpen ? 'block' : 'none' }} // Control display
            >
                <div className="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between pb-0 border-0"><h6 className="mb-2 fw-bold text-lg mb-0">Add Email ID</h6><button type="button" onClick={handleCloseModal} className="close" aria-label="Close">✗</button></div>
                        <div className="modal-body">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                enableReinitialize // Important to reinitialize on value change
                                onSubmit={onSubmit}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <div className="row gy-3">
                                            <div className="col-12">
                                                <label className="form-label">Full Name</label>
                                                <div className="icon-field">
                                                    <span className="icon">
                                                        <iconify-icon icon="f7:person" />
                                                    </span>
                                                    <Field
                                                        type="text"
                                                        name="fullName"
                                                        className="form-control"
                                                        placeholder="Enter Full Name"
                                                        autocomplete="off"
                                                    />
                                                    <ErrorMessage
                                                        name="fullName"
                                                        component="div"
                                                        className="text-danger"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <label className="form-label">Email</label>
                                                <div className="icon-field">
                                                    <span className="icon">
                                                        <iconify-icon icon="mage:email" />
                                                    </span>
                                                    <Field
                                                        type="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        autocomplete="off"
                                                    />
                                                    <ErrorMessage
                                                        name="email"
                                                        component="div"
                                                        className="text-danger"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary-600 w-100"
                                                    disabled={isSubmitting}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </div>
            </div >
            {isModalOpen && <div className="modal-backdrop fade show"></div>} {/* Backdrop */}
        </>
    )
}

export default FeedbackEmail