import { useEffect, useState } from "react";
import { generateColor, getContrastYIQ } from "src/utils/utils";


const AssignUser=({
    index,
    item,
    role,
    handleUserToggle,
    usersForProjectAssignState,
    handleLoadPermissions
})=>{
    const isUserAssigned = usersForProjectAssignState?.includes(item._id)||false;
    const [isUserAssignedState, setIsUserAssignedState] = useState(isUserAssigned);
    // Sync state when `usersForProjectAssignState` changes
    useEffect(() => {
        setIsUserAssignedState(isUserAssigned);
    }, [isUserAssigned]);
   return (
    <div key={index}>
    <div className="form-group AllFilter_Select p-0">
      <input
        className="form-check-input"
        // type={(role==="Manager")?"radio":"checkbox"}
        type="checkbox"
        id={`btn${index}`}
        checked={isUserAssignedState}
        onClick={() => {
              setIsUserAssignedState(!isUserAssignedState);
              handleUserToggle(item._id)
          }}
      />
      <label className="asiignProfile d-flex w-100" htmlFor={`btn${index}`}>
        <div className="assignFlexpro">                      
          <span
            className="img"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-custom-class="tooltip-dark"
            data-bs-title={`${item.firstName} ${item.lastName}`}
          >
            {item.profilePicture ? (
              <img
                src={item.profilePicture}
                className="img-fluid"
                alt=""
              />
            ) : (
              <span
                className="initials d-flex justify-content-center align-items-center w-100 h-100"
                style={{
                  backgroundColor: generateColor(`${item.firstName}${item.lastName}`),
                  color: getContrastYIQ(generateColor(`${item.firstName}${item.lastName}`)),
                }}
              >
                {`${item?.firstName?.charAt(0)}${item?.lastName?.charAt(0)}`}
              </span>
            )}
          </span>
          <div className="assiContentInn">
            <span>{`${item.firstName} ${item.lastName} (${item.employeeID})`}</span>
            <span className="grayText">{item.designationId.designationName}</span>
            <span className="pinktext">{item.departmentId.departmentName}</span>
          </div>
        </div>
      </label>
      {isUserAssignedState && (
          <button 
        type="button" 
        class="btn radius-8 px-20 py-12 text-sm d-flex gap-2 align-items-center border text-nowrap text-dblue mngPermsn" 
        data-bs-toggle="modal" 
        data-bs-target="#roulsXXModual"
        onClick={() => handleLoadPermissions(item)}
        >
        <i className="mdi mdi-key mdi-18px text-neutral-800"></i> Manage Permission
      </button> 
      )}
      
    </div>
  </div>
   )
}
export default AssignUser