import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const TaskPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    taskName: PropTypes.string,
});

export const TaskDefaultProps = { 
    taskName: '',
    _id: undefined
};

export const TaskYupSchema = Yup.object().shape({ 
    taskName: Yup.string().required('Required'),
});
export const OAD_CMD_YupSchema = Yup.object().shape({ 
    value: Yup.number().required('Required'),
});

export const Delivery_YupSchema = Yup.object().shape({ 
    value: Yup.number().required('Required'),
});

export default {
    TaskDefaultProps,
    TaskPropTypes,
    TaskYupSchema,
};
