import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import FormikInput from 'src/components/formik/FormikInput';
import DesignationDropDown from 'src/business-components/DesignationDropDown';
import DepartmentDropDown from 'src/business-components/DepartmentDropDown';
import { userResetAction, userUpsertAction } from './actions/UserActions';
import { UserYupSchema } from './ProjectPropTypes';
import RoleDropDown from 'src/business-components/RoleDropDown';
import FormikRadioBox from 'src/components/formik/FormikRadioBox';
const Render=(props)=>{
    const {
        values, 
        handleClick,
        isSubmitting,
        setFieldValue,
        uploadUsers,
        onClear,
        resetForm,
    } = props;
    return(
        <div
        className="offcanvas offcanvas-end"
        id="UserInfo"
        aria-labelledby="filterRightLayout"
    >
       
        <div className="offcanvas-header border-bottom">
            <h6 class="modal-title">Edit User</h6>
            <button
                type="button"
                className="btn-close d-none"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
            />
        </div>
        <div className="offcanvas-body pb-0">
        <Form autoComplete="disabled">
            <div className="row gy-3">
                <div className="col-12">
                    <label className="form-label">User Info</label>
                    <FormikInput
                    value={values.firstName}
                    name="firstName"
                    placeholder="First Name"
                    className="form-control"
                    /> 
                </div>
                <div className="col-12">
                    <FormikInput
                    value={values.lastName}
                    name="lastName"
                    placeholder="Last Name"
                    className="form-control"
                    /> 
                </div>
                <div className="col-12">
                    <FormikInput
                        value={values.email}
                        name="email"
                        placeholder="Email"
                        className="form-control"
                    />
                </div>
                <div className="col-12">
                        <label class="form-label">Under Revenue</label>
                        <div class="d-flex align-items-center flex-wrap gap-28">
                            <div class="form-check checked-primary d-flex align-items-center gap-2">
                                <FormikRadioBox
                                value={true}
                                checked={values.isRevenue}
                                name="isRevenue" 
                                label="Yes"
                                className="form-check-input"
                                />
                            </div>
                                <div class="form-check checked-secondary d-flex align-items-center gap-2">
                                <FormikRadioBox
                                value={false}
                                checked={(!values.isRevenue)}
                                name="isRevenue" 
                                label="No"
                                className="form-check-input"
                                />
                                </div>
                        </div>                         
                </div>
                <div className="col-12">                   
                    <DesignationDropDown
                    placeholder="Enter Designation"
                    name="designationId"
                    className="form-select form-select-md w-100 bg-base border text-secondary-light"
                    value={values.designationId}
                    onChange={(id)=>{
                      values.designationId=id
                }}
                />
                </div>
                <div className="col-12">
                    <DepartmentDropDown
                        placeholder="Enter department"
                        name="departmentId"
                        className="form-select form-select-md w-100 bg-base border text-secondary-light"
                        value={values.departmentId}
                        onChange={(id)=>{
                            values.departmentId=id
                    }}
                     />                     
                </div>                
                 
                <div className="col-12">
                <RoleDropDown
                        placeholder="Enter Role"
                        name="roleId"
                        className="form-select form-select-md w-100 bg-base border text-secondary-light"
                        value={values?.roleId}
                        onChange={(id)=>{
                            values.roleId=id
                       }}
                     />
                </div>

            </div>
            <div className="btnBsticky">
            <button
                type="submit"
                 data-bs-dismiss="offcanvas" aria-label="Close"
                className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
            >
                Save
            </button></div>
            </Form>
        </div>
        
       
    </div>
    )
}

const UserInfo = () => {
    const dispatch = useDispatch();
    const { currentRecord } = useSelector((state) => state.EmployeeDataReducer, shallowEqual);
    const onSubmit = (values, formikUtils) => {
        const { setSubmitting } = formikUtils; 
        dispatch(userUpsertAction(values));
        onClear(); 
        dispatch(userResetAction());
    };    
     const onClear = () => {
        dispatch(userResetAction());
    }
 

    return (
        <Formik
            onSubmit={onSubmit}
            onClear={onClear}
            enableReinitialize
            initialValues={currentRecord}
            validationSchema={UserYupSchema}
        >
            {(formikProps) => (
                <Render
                    onClear={onClear}                      
                    {...formikProps}
                />
            )}
        </Formik>

        
    )
}

export default UserInfo
