import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const FeedbackPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    FeedbackName: PropTypes.string,
});

export const FeedbackDefaultProps = { 
    FeedbackName: '',
    categoryDetails: [],
    _id: undefined
};

export const FeedbackYupSchema = Yup.object().shape({ 
    FeedbackName: Yup.string().required('Required'),
});

export default {
    FeedbackDefaultProps,
    FeedbackPropTypes,
    FeedbackYupSchema,
};
