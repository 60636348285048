import React, { useState } from 'react';

// Function to get initials from the full name
const getInitials = (name) => {
  const nameParts = name.split(' ');
  const firstInitial = nameParts[0]?.[0] || '';
  const lastInitial = nameParts[nameParts.length - 1]?.[0] || '';
  return `${firstInitial} ${lastInitial}`.toUpperCase();
};

const InvoiceTableModal = () => {
  const [sections, setSections] = useState([
    {
      sectionTitle: 'Storyboard Development',
      rows: [
        { date: '28-Jun-2024', uploadedBy: 'Kiran Mazumdar', vendorName: 'Kiran Mazumdar', perUnitCost: 98, totalUnit: 50, paidUnit: 10 },
        { date: '28-Jun-2024', uploadedBy: 'Kiran Mazumdar', vendorName: 'Kiran Mazumdar', perUnitCost: 98, totalUnit: 50, paidUnit: 20 },
      ],
    },
    {
      sectionTitle: 'Video Editing',
      rows: [
        { date: '28-Jun-2024', uploadedBy: 'Kiran Mazumdar', vendorName: 'Kiran Gazumdar', perUnitCost: 98, totalUnit: 50, paidUnit: 30 },
        { date: '28-Jun-2024', uploadedBy: 'Kiran Mazumdar', vendorName: 'Kiran Gazumdar', perUnitCost: 98, totalUnit: 50, paidUnit: 40 },
      ],
    },
  ]);

  const [showCombinedView, setShowCombinedView] = useState(true);

  const calculateTotals = () => {
    return sections.map((section) => {
      const totalPaidUnit = section.rows.reduce((total, row) => total + row.paidUnit, 0);
      const amount = section.rows.reduce((total, row) => total + row.paidUnit * row.perUnitCost, 0);
      return { category: section.sectionTitle, totalPaidUnit, amount };
    });
  };

  const grandTotals = calculateTotals().reduce(
    (totals, item) => {
      totals.totalPaidUnit += item.totalPaidUnit;
      totals.amount += item.amount;
      return totals;
    },
    { totalPaidUnit: 0, amount: 0 }
  );

  return (  
<div className="modal fade cusmodl LeaveDetailsModal" id="combinedinvoiveModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog modal-xl modal-dialog-scrollable">
    <div className="modal-content">
    {/* <div className="modal-header d-flex justify-content-between pb-0 border-0"><button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button></div> */}
        <div className="modal-body py-0"> 
        {showCombinedView ? (
          <div className="Combinedvset">
            {sections.map((section, sectionIndex) => (
              <div key={sectionIndex} className="section">
                <div className="d-flex align-items-center justify-content-between mb-6 mt-16">
                  <h3 className="text-md">{section.sectionTitle}</h3>
                  <button className="text-dblue fw-medium me-20" onClick={() => {
                    const newSections = sections.filter((_, index) => index !== sectionIndex);
                    setSections(newSections);
                  }}>Delete Section</button>
                </div>
                <table className="table basic-border-table mb-0 table-hover bdrnoneSet">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Date</th>
                      <th>Uploaded by</th>
                      <th>Vendor Name</th>
                      <th className="text-center">Per Unit Cost</th>
                      <th className="text-center">Total Unit</th>
                      <th className="text-center">Paid Unit</th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {section.rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td>{rowIndex + 1}</td>
                        <td>{row.date}</td>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <span className="user-initials">{getInitials(row.uploadedBy)}</span>{row.uploadedBy}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <span className="user-initials">{getInitials(row.vendorName)}</span>{row.vendorName}
                          </div>
                        </td>
                        <td className="text-center">{row.perUnitCost}</td>
                        <td className="text-center">{row.totalUnit}</td>
                        <td className="text-center">
                          <input
                            type="number"
                            className="form-control w-110-px text-center mx-auto"
                            value={row.paidUnit}
                            placeholder="0"
                            onChange={(e) => {
                              const newSections = [...sections];
                              newSections[sectionIndex].rows[rowIndex].paidUnit = Number(e.target.value);
                              setSections(newSections);
                            }}
                          />
                        </td>
                        <td className="text-center border-0">
                          <button onClick={() => {
                            const newSections = [...sections];
                            newSections[sectionIndex].rows.splice(rowIndex, 1);
                            setSections(newSections);
                          }}>
                            <i className="mdi mdi-delete mdi-24px"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
            <div className="text-center py-16">
              <button
                className="btn btn-primary text-sm heightAllBtnSet44 btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2 mx-auto"
                onClick={() => setShowCombinedView(false)}
              >
                <i className="mdi mdi-vector-combine"></i> Combine
              </button>
            </div>
          </div>
        ) : (
          <div className="GenerateCombineInvoice">
            <button
              className="d-flex align-items-center gap-2 btn btn-primary-100 text-primary-600 radius-8 px-20 py-6 mb-16 mt-16"
              onClick={() => setShowCombinedView(true)}
            >
              <i className="mdi mdi-keyboard-backspace mdi-18px"></i> Back
            </button>
            <div className="table-container1">
              <table className="table basic-border-table mb-0 table-hover">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th className="text-center">Total Paid Unit</th>
                    <th className="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {calculateTotals().map((item, index) => (
                    <tr key={index}>
                      <td><div className="d-inline-flex"><span>{index + 1}.</span> {item.category}</div></td>
                      <td className="text-center">{item.totalPaidUnit}</td>
                      <td className="text-end">₹ {item.amount.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th className="text-xl">Total</th>
                    <th className="text-center text-xl">{grandTotals.totalPaidUnit}</th>
                    <th className="text-end text-xl">₹ {grandTotals.amount.toFixed(2)}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="text-start py-16">
              <button className="btn btn-primary text-sm heightAllBtnSet44 btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2">
                <i className="mdi mdi-vector-combine"></i> Generate Combine Invoice
              </button>
            </div>
          </div>
        )}
      
        </div> 
    </div>
</div>
</div>
  );
};

export default InvoiceTableModal;
