import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useParams } from 'react-router-dom';
import { useSelector,shallowEqual } from 'react-redux';
import Highcharts3D from 'highcharts/highcharts-3d';
import DateRangePickerWithAll from 'src/components/DateRangePickerWithAll';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import MonthPicker from 'src/components/MonthPicker';
import { getHubStaffHourDateWiseAPI } from 'src/api/PerfomanceAPI';
Highcharts3D(Highcharts);


const HubStaffHours=()=>{
    const {employeeID,userId} = useParams();
    const [series, setSeries] = useState([]);
    const [dateRangeHubstaff, setDateRangeHubstaff] = useState([moment().subtract(29, 'days'), moment()]);
    const {selectedMonth,selectedYear} = useSelector(state=>state.ProductionReducer,shallowEqual);
    
    const [where,setWhere] = useState({
        userId,
        $expr: {
        $and: [
          { $eq: [{ $year: "$date" },selectedYear] },
          { $eq: [{ $month: "$date" }, selectedMonth] } 
        ]
      }});
      const [whereMySql,setWhereMySql] = useState({
              empid:employeeID,
              year: selectedYear,
              month: selectedMonth,
         });

    useEffect(() => {
         search(where);
       }, [         
         where,
         whereMySql
       ]);

       const search = useCallback(async()=>{
          const hubstaffData  = await getHubStaffHourDateWiseAPI({where,whereMySql});
          setSeries(hubstaffData.data.series)
       },[where,whereMySql])
    // const handleChangeDate = (date)=>{
    //     const year = date.getFullYear();  // Example year
    //     const month = date.getMonth()+1;  // Since month is 0-indexed in JavaScript, add 1
    //     setWhere({
    //       ...where,
    //       $expr: {
    //         $and: [
    //           { $eq: [{ $year: "$date" },year] },
    //           { $eq: [{ $month: "$date" }, month] } 
    //         ]
    //       }
    //     })
    //     setWhereMySql({
    //       ...whereMySql,
    //       year,
    //       month
    //     })
    //   }
      useEffect(()=>{
        setWhere({
            ...where,
            $expr: {
              $and: [
                { $eq: [{ $year: "$date" },selectedYear] },
                { $eq: [{ $month: "$date" }, selectedMonth] } 
              ]
            }
          })
          setWhereMySql({
            ...whereMySql,
            year:selectedYear,
            month:selectedMonth
          })
      },[
        selectedMonth
        ,selectedYear
    ])
      const formatTime = (value) => {
        if (value < 1) {
            // Convert to minutes
            return `${Math.round(value * 60)} min`; // Convert hours to minutes
        } else {
            // Show as hours
            return `${Math.floor(value)} hrs ${Math.round((value % 1) * 60)} min`; // Show as hours and minutes
        }
    };
      const options = {
        chart: {
            type: 'column',
            options3d: {
                enabled: true,
                alpha: 15,
                beta: 3,
                depth: 0,
                viewDistance: 40,
            },
        },
        title: {
            text: 'Hubstaff Hours',
            align: 'left',
            style: {
                float: 'left',
            },
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            type: 'category',
            title: {
                text: 'Date',
            },
        },
        yAxis: {
            title: {
                text: 'Hours',
            },
            min: 0,
        },
        series: series.map(s => ({
            ...s,
            data: s.data.map(([day, time]) => [day, time]), // Ensure data is correctly mapped
        })),
        colors: ['#22C55E', '#487fff', '#EF4444'],
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            floating: true,
            x: -10,
            y: 10,
        },
        tooltip: {
            shared: true,
            formatter: function () {
                let tooltipText = `<b></b><br/>`; // Display the day
                this.points.forEach(point => {
                    tooltipText += `${point.series.name}: <b>${formatTime(point.y)}</b><br/>`; // Use the formatTime function
                });
                return tooltipText;
            },
        },
    };

    return (
     <>
        <div className="col-md-12">
            <div className="card h-100 p-0">
                <div className="card-body p-24">
                    <div className="ms-auto d-flex justify-content-end inwth">
                        {/* <DatePickerComponent onDateRangeChange={handleDateRangeChange} /> */}
                        {/* <MonthPicker handleChangeDate={handleChangeDate} /> */}
                    </div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            </div>
        </div>
     </>
    )
 }
 export default HubStaffHours