import React, { useState } from 'react'

const DropDownAuditTable = () => {

    const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
    const [selectedCategory, setSelectedCategory] = useState("Category"); // Default selected category
    const [selectedClass, setSelectedClass] = useState(""); // Class for selected button
  
    // Handle option selection
    const handleSelect = (category, className) => {
      setSelectedCategory(category); // Update button text
      setSelectedClass(className); // Update button class
      setIsOpen(false);
    };


  return (
    <>
     <div className="dropdown">
      <button
        className={`btn btn-primary-600 px-12 py-6 text-sm dropdown-toggle toggle-icon rounded-pill ${selectedClass}`}
        type="button"
        onClick={() => setIsOpen((prev) => !prev)} // Toggle dropdown visibility
        aria-expanded={isOpen}
      >
        {selectedCategory}
      </button>
      <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`}> {/* Conditionally add show class */}
        <li>
          <button
            className="dropdown-item px-16 py-8 text-white bggreen bg-hover-neutral-200 text-hover-neutral-900"
            onClick={() => handleSelect("Green", "bggreen")}
          >
            Green
          </button>
        </li>
        <li>
          <button
            className="dropdown-item px-16 py-8 text-white bgred bg-hover-neutral-200 text-hover-neutral-900"
            onClick={() => handleSelect("Red", "bgred")}
          >
            Red
          </button>
        </li>
        <li>
          <button
            className="dropdown-item px-16 py-8 text-white bgorange bg-hover-neutral-200 text-hover-neutral-900"
            onClick={() => handleSelect("Orange", "bgorange")}
          >
            Orange
          </button>
        </li>
        <li>
          <button
            className="dropdown-item px-16 py-8 text-white bgblue bg-hover-neutral-200 text-hover-neutral-900"
            onClick={() => handleSelect("Blue", "bgblue")}
          >
            Blue
          </button>
        </li>
      </ul>
    </div> 
    </>
  )
}

export default DropDownAuditTable
