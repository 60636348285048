import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { DASHBOARD_ACTIONS } from '../actions/DashboardActions';
import { DashboardDefaultProps } from '../DashboardPropTypes';

const initialState = () => ({
    currentRecord: { ...DashboardDefaultProps },
    pendingList:{task:[],production:[],pendingType:"Task"},
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        case DASHBOARD_ACTIONS.DASHBOARD_PENDING_LIST: {
            return {
                ...state,
                pendingList: {
                    ...state.pendingList,
                    ...action.data,
                },
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }    

        
        case DASHBOARD_ACTIONS.DASHBOARD_RESET: {
            return {
                ...state,
                currentRecord: { ...DashboardDefaultProps },
            };
        }

        default:
            return state;
    }
};
