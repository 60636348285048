import { shallowEqual, useSelector } from "react-redux"

const BreadCrumbs=()=>{
  const { selectedClient, selectedProject, selectTask, selectModule } = useSelector(state => state.ProductionReducer, shallowEqual);
  return (
    <nav style={{ '--bs-breadcrumb-divider': '>' }} aria-label="breadcrumb">
      <ol className="breadcrumb my-16 align-items-center">
        {
          selectedClient?.ClientProjectCode && (
            <li className="breadcrumb-item">
              <a href="#" 
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne" 
              >{selectedClient.ClientProjectCode} <span className="xnbv1">&rsaquo;</span></a>
            </li>
          )
        }
        {
          selectedProject?.ProjectSubCode && (
            <li className="breadcrumb-item">
              <a href="#"
              data-bs-toggle="collapse"
              data-bs-target={`#sub-collapseOne${selectedClient.clientId}`}
              aria-expanded="true"
              aria-controls="collapseOne"              
              >{selectedProject.ProjectSubCode} <span className="xnbv1">&rsaquo;</span></a>
            </li>
          )
        }
        {
          selectTask?.taskName && (
            <li className="breadcrumb-item">
              <a href="#"
              data-bs-toggle="collapse"
              data-bs-target={`#sub-collapse${selectedProject.projectId}`}
              aria-expanded="true"
              aria-controls="collapse6"
              
              >{selectTask.taskName} <span className="xnbv1">&rsaquo;</span></a>
            </li>
          )
        }
        {
          selectModule?.moduleName && (
            <li className="breadcrumb-item active pt-4" aria-current="page"
                data-bs-toggle="collapse"
                data-bs-target={`#sub-collapseTask${selectTask.taskId}`}
                aria-expanded="true"
                aria-controls="collapse11"
            >
              {selectModule.moduleName}
            </li>
          )
        }
      </ol>
    </nav>
  );
}
export default BreadCrumbs