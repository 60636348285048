import React, { useEffect, useState } from 'react';

const DeliveriesDetails = ({ onInvoiceDeliveriesUpdate }) => {
    const [collapse, setCollapse] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [editableFields, setEditableFields] = useState({});
    const [deliveries, setDeliveries] = useState([
        {
            id: '1',
            projectCode: 'Project 407 - A - Dummy delivery',
            unitCost: '₹40.00',
            delivered: 4,
            invoiceDeliveries: 4,
            balQty: 6,
            totalCost: '₹61740.00',
            billedAmt: '₹61740.00',
            balAmt: '₹61740.00',
            subItems: [
                { id: 'sub1', projectCode: '1. Project 407 - A - Dummy Delivery - category', unitCost: '₹40.00', delivered: 4, invoiceDeliveries: 4, balQty: 6, totalCost: '₹61740.00', billedAmt: '₹61740.00', balAmt: '₹61740.00' },
                { id: 'sub2', projectCode: '2. Project 407 - A - Dummy Delivery - category', unitCost: '₹40.00', delivered: 4, invoiceDeliveries: 4, balQty: 6, totalCost: '₹61740.00', billedAmt: '₹61740.00', balAmt: '₹61740.00' },
            ],
        },
    ]);

    const toggleCollapse = (id) => {
        setCollapse((prevCollapse) => ({
            ...prevCollapse,
            [id]: !prevCollapse[id],
        }));
    };

    const handleSelectAll = () => {
        if (selectedRows.length === deliveries.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(deliveries.map((row) => row.id));
        }
    };

    const handleSelectRow = (rowId) => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.includes(rowId)
                ? prevSelectedRows.filter((id) => id !== rowId)
                : [...prevSelectedRows, rowId]
        );
    };

    // Toggle edit mode for a specific field
    const toggleEditField = (rowId, itemId = null) => {
        const fieldKey = itemId ? `${rowId}-${itemId}` : rowId;
        setEditableFields((prev) => ({
            ...prev,
            [fieldKey]: !prev[fieldKey],
        }));
    };

    // Update invoiceDeliveries value
    const handleInvoiceDeliveriesChange = (deliveryId, itemId, newValue) => {
        setDeliveries((prevDeliveries) =>
            prevDeliveries.map((delivery) =>
                delivery.id === deliveryId
                    ? {
                        ...delivery,
                        invoiceDeliveries: itemId === null ? newValue : delivery.invoiceDeliveries,
                        subItems: itemId
                            ? delivery.subItems.map((item) =>
                                item.id === itemId ? { ...item, invoiceDeliveries: newValue } : item
                            )
                            : delivery.subItems,
                    }
                    : delivery
            )
        );
    };

    // Calculate total of invoiceDeliveries
    const invoiceDeliveriesTotal = deliveries.reduce(
        (total, delivery) => 
            total + 
            delivery.invoiceDeliveries + 
            delivery.subItems.reduce((subTotal, subItem) => subTotal + Number(subItem.invoiceDeliveries), 0),
        0
    );

    // Trigger the parent component's callback on button click
    const handleAddAndContinue = () => {
        if (onInvoiceDeliveriesUpdate) {
            onInvoiceDeliveriesUpdate(invoiceDeliveriesTotal);
        }
    };

    // Save changes to invoiceDeliveries
    const handleSave = (rowId, itemId = null) => {
        // Here you can implement your logic for saving, such as updating a database or making an API call
        toggleEditField(rowId, itemId); // Toggle edit mode off after saving
    };

    return (
        <div>
            <div className="modal fade cusmodl LeaveDetailsModal" id="DeliveriesDetailsModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between border-0">
                            <h6 className="modal-title">Add Deliveries Details</h6>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button>
                        </div>
                        <div className="modal-body py-0">
                            <div className="table-container accordion-body collapse show">
                                <table className="table basic-border-table mb-0 table-hover">
                                    <thead>
                                        <tr className="infobgblack">
                                            <th>
                                                <input className="form-check-input" style={{ display: "inline-block", width: "24px", height: "24px" }}
                                                    type="checkbox"
                                                    onChange={handleSelectAll}
                                                    checked={selectedRows.length === deliveries.length}
                                                />
                                            </th>
                                            <th>#</th>
                                            <th>Project</th>
                                            <th className="text-center">Unit Cost</th>
                                            <th className="text-center">Delivered</th>
                                            <th className="text-center">Invoice Deliveries</th>
                                            <th className="text-center">Bal. Qty.</th>
                                            <th className="text-center">Total Cost</th>
                                            <th className="text-center">Billed Amt.</th>
                                            <th className="text-end">Bal. Amt.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {deliveries.map((row, index) => (
                                            <React.Fragment key={row.id}>
                                                <tr>
                                                    <td>
                                                        <input className="form-check-input" style={{ display: "inline-block", width: "24px", height: "24px" }}
                                                            type="checkbox"
                                                            checked={selectedRows.includes(row.id)}
                                                            onChange={() => handleSelectRow(row.id)}
                                                        />
                                                    </td>
                                                    <td>{index + 1}</td>
                                                    <td className="fw-bold d-flex align-items-center gap-2">
                                                        <button
                                                            className={`text-dblue steactive accordion-toggle ${collapse[row.id] ? 'active' : ''}`}
                                                            onClick={() => toggleCollapse(row.id)}
                                                        >
                                                            <i className="mdi mdi-play-circle-outline mdi-36px"></i>
                                                        </button>
                                                        {row.projectCode}
                                                    </td>
                                                    <td className="text-center">{row.unitCost}</td>
                                                    <td className="text-center">{row.delivered}</td>
                                                    <td className="text-center">
    {editableFields[row.id] ? (
        <div className="d-flex align-items-center">
            <input
                type="number"
                className="form-control w-80-px mx-auto px-4"
                value={row.invoiceDeliveries}
                onChange={(e) => handleInvoiceDeliveriesChange(row.id, null, Number(e.target.value) || 0)}
                onBlur={() => toggleEditField(row.id)} // Close edit mode on blur
                autoFocus
            />
            <button
                className="btn btn-primary btn-sm mx-2"
                onClick={() => {
                    handleSave(row.id); // Save on button click
                    toggleEditField(row.id); // Close edit mode
                }}
            >
                Save
            </button>
        </div>
    ) : (
        <span
            className="border px-10 radius-4"
            onClick={() => toggleEditField(row.id)} // Open edit mode
            style={{ cursor: 'pointer' }}
        >
            {row.invoiceDeliveries}
        </span>
    )}
</td>

                                                    <td className="text-center">{row.balQty}</td>
                                                    <td className="text-center">{row.totalCost}</td>
                                                    <td className="text-center">{row.billedAmt}</td>
                                                    <td className="text-end">{row.balAmt}</td>
                                                </tr>
                                                {collapse[row.id] && row.subItems.map((item) => (
                                                    <tr key={item.id} className="hiddenRow accordion-body collapse show">
                                                        <td>
                                                            <input className="form-check-input" style={{ display: "inline-block", width: "24px", height: "24px" }}
                                                                type="checkbox"
                                                                checked={selectedRows.includes(item.id)}
                                                                onChange={() => handleSelectRow(item.id)}
                                                            />
                                                        </td>
                                                        <td></td>
                                                        <td className="text-dblue">{item.projectCode}</td>
                                                        <td className="text-center">{item.unitCost}</td>
                                                        <td className="text-center">{item.delivered}</td>
                                                        <td className="text-center">
                                                            {editableFields[`${row.id}-${item.id}`] ? (
                                                                <div className="d-flex align-items-center">
                                                                    <input
                                                                        type="number" className="form-control w-80-px mx-auto px-4"
                                                                        value={item.invoiceDeliveries}
                                                                        onChange={(e) => handleInvoiceDeliveriesChange(row.id, item.id, e.target.value)}
                                                                        onBlur={() => toggleEditField(row.id, item.id)}
                                                                        autoFocus
                                                                    />
                                                                    <button className="btn btn-primary btn-sm text-sm mx-2" onClick={() => handleSave(row.id, item.id)}>Save</button>
                                                                </div>
                                                            ) : (
                                                                <span className="border px-10 radius-4" onClick={() => toggleEditField(row.id, item.id)} style={{ cursor: 'pointer' }}>
                                                                    {item.invoiceDeliveries}
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td className="text-center">{item.balQty}</td>
                                                        <td className="text-center">{item.totalCost}</td>
                                                        <td className="text-center">{item.billedAmt}</td>
                                                        <td className="text-end">{item.balAmt}</td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer border-0">
                            <div className="d-flex justify-content-between gap-4 align-items-center"> 
                                <div className="text-sm">
                                  <span className="InvoiceDeliveriesCount fw-bold">{invoiceDeliveriesTotal}</span> Invoice Deliveries added
                                </div>
                                <button className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={handleAddAndContinue}>Add & Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeliveriesDetails;
