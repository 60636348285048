import React, { useEffect, useRef, useState } from 'react';

// Utility function to get initials from name
const getInitials = (name) => {
  if (!name) return '';
  const words = name.trim().split(' ');
  const firstNameInitial = words[0]?.charAt(0).toUpperCase();
  const lastNameInitial = words[1]?.charAt(0).toUpperCase();
  return `${firstNameInitial}${lastNameInitial || ''}`;
};

// Utility function to generate color from a string (e.g., client name)
const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, '0')}${((hash >> 16) & 0xff).toString(16).padStart(2, '0')}${((hash >> 8) & 0xff).toString(16).padStart(2, '0')}`;
  return color.slice(0, 7); // Ensure it's a valid hex color
};

const ClientWise = ({ totalMonths }) => { // Accepting totalMonths as a prop
  // Sample data for the table
  const [data, setData] = useState([
    { sNo: '01', clientName1: 'HCL', clientName2: 'Hasmukh Parekh', month: 'February 2024, March 2024', amount: '₹23591.01' },
    { sNo: '02', clientName1: 'Acadecraft', clientName2: 'John Doe', month: 'February 2024, March 2024', amount: '₹33591.01' },
    { sNo: '03', clientName1: 'Microsoft', clientName2: 'Jane Smith', month: 'April 2024, May 2024', amount: '₹13591.01' },
    // Additional rows as needed
  ]);

  const [selectedRows, setSelectedRows] = useState([]);

  // Select or deselect a specific row by sNo
  const handleSelectRow = (sNo) => {
    setSelectedRows(selectedRows.includes(sNo) ? selectedRows.filter(row => row !== sNo) : [...selectedRows, sNo]);
  };

  // Select or deselect all rows
  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length === data.length ? [] : data.map(row => row.sNo));
  };

     // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code End

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center pt-16 pb-16">
        <h3 className="card-title">Payment Details Summary</h3>
      </div>
      <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
      <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
        <thead>
          <tr>
            <th>
              <input
                className="form-check-input"
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedRows.length === data.length}
                style={{ display: 'inline-block', width: '24px', height: '24px' }}
              />
            </th>
            <th>S.No.</th>
            <th>Client Name 1</th>
            <th>Client Name 2</th>
            <th>Month</th>
            <th className="text-center">View</th>
            <th className="text-end">Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.sNo}>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={selectedRows.includes(row.sNo)}
                  onChange={() => handleSelectRow(row.sNo)}
                  style={{ display: 'inline-block', width: '24px', height: '24px' }}
                />
              </td>
              <td>{row.sNo}</td>
              <td>
                <div className="d-flex align-items-center gap-2">
                  <span
                    className="client-initials img imgsSet"
                    style={{
                      backgroundColor: stringToColor(row.clientName1),
                      color: '#fff',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      width: '32px',
                      height: '32px',
                    }}
                  >
                    {getInitials(row.clientName1)}
                  </span>
                  {row.clientName1}
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center gap-2">
                  <span
                    className="client-initials img imgsSet"
                    style={{
                      backgroundColor: stringToColor(row.clientName2),
                      color: '#fff',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      width: '32px',
                      height: '32px',
                    }}
                  >
                    {getInitials(row.clientName2)}
                  </span>
                  {row.clientName2}
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center gap-0">
                  <span className="monthCount imgsSet bg-danger-200 text-danger-500">{row.month.split(',').length}</span>
                  {row.month}
                </div>
              </td>
              <td className="text-center">
                <button className="text-dblue" data-bs-toggle="modal" data-bs-target="#ClientWiseMonths">
                  <i className="mdi mdi-eye mdi-36px"></i>
                </button>
              </td>
              <td className={`text-end ${parseFloat(row.amount.replace(/[^\d.-]/g, '')) < 0 ? 'text-red' : 'text-green'}`}>
                {row.amount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default ClientWise;
