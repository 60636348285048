import React from 'react';
import { Field, ErrorMessage } from 'formik';


const RadioButton = ({
    field, form,checked, ...rest
}) => (
    <>
        <input
            {...field}
            {...rest}
            type="radio"
            checked={checked} 
            onChange={() => {
                form.setFieldValue(field.name, rest.value); 
            }}
        />
        <label>{rest.label}</label> {}
        <ErrorMessage
            component="div"
            className="text-danger"
            name={field.name}
        />
    </>
);


const FormikRadioBox = (props) => (
    <Field
        {...props}
        component={RadioButton}
    />
);

export default FormikRadioBox;
