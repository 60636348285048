export const EMPLOYEE_DATA_ACTIONS = {
    EMPLOYEE_DATA_SEARCH: 'EMPLOYEE_DATA_SEARCH',
    EMPLOYEE_DATA_SEARCH_SUCCESS: 'EMPLOYEE_DATA_SEARCH_SUCCESS',
    EMPLOYEE_DATA_SEARCH_ERROR: 'EMPLOYEE_DATA_SEARCH_ERROR',

    EMPLOYEE_DATA_UPSERT: 'EMPLOYEE_DATA_UPSERT',
    EMPLOYEE_DATA_UPSERT_SUCCESS: 'EMPLOYEE_DATA_UPSERT_SUCCESS',
    EMPLOYEE_DATA_UPSERT_ERROR: 'EMPLOYEE_DATA_UPSERT_ERROR',

    EMPLOYEE_DATA_EDIT: 'EMPLOYEE_DATA_EDIT',

    EMPLOYEE_DATA_RESET: 'EMPLOYEE_DATA_RESET',
};

export const employeeDataResetAction = () => ({
    type: EMPLOYEE_DATA_ACTIONS.EMPLOYEE_DATA_RESET,
});

export const employeeDataSearchAction = (payload) => ({
    type: EMPLOYEE_DATA_ACTIONS.EMPLOYEE_DATA_SEARCH,
    ...payload,
});

export const employeeDataEditAction = (payload) => ({
    type: EMPLOYEE_DATA_ACTIONS.EMPLOYEE_DATA_EDIT,
    ...payload,
});

export const employeeDataUpsertAction = (payload) => ({
    type: EMPLOYEE_DATA_ACTIONS.EMPLOYEE_DATA_UPSERT,
    ...payload,
});

export default {
    EMPLOYEE_DATA_ACTIONS,
    employeeDataSearchAction,
    employeeDataUpsertAction,
    employeeDataEditAction,
};
