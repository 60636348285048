import NotificationProvider from "./components/Notifination/ToastNotification";
import Routings from "./routes";
function App() {
  return (
     <>
     <NotificationProvider>
     <Routings />
     </NotificationProvider>
     </>
  );
}

export default App;