export function stampForCreate() {
    const { _id } = JSON.parse(localStorage.getItem('user'));

    return {
        by: _id,
        ts: new Date(),
    };
}

export function stampForUpdate() {
    const { _id } = JSON.parse(localStorage.getItem('user'));

    return {
        updatedBy: _id,
        updatedTs: new Date(),
    };
}

export function getLoggedInUserId() {
    const { _id } = JSON.parse(localStorage.getItem('user'));
    return _id;
}

export function includeStamp(data) {
    if (data._id) {
        Object.assign(data, stampForUpdate());
    }
    else {
        Object.assign(data, stampForCreate());
    }
    return data;
}

export default {
    stampForCreate,
    stampForUpdate,
    getLoggedInUserId,
    includeStamp,
};
