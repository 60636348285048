export const MODULE_ACTIONS = {
    MODULE_SEARCH: 'MODULE_SEARCH',
    MODULE_SEARCH_SUCCESS: 'MODULE_SEARCH_SUCCESS',
    MODULE_SEARCH_ERROR: 'MODULE_SEARCH_ERROR',

    MODULE_UPSERT: 'MODULE_UPSERT',
    MODULE_UPSERT_SUCCESS: 'MODULE_UPSERT_SUCCESS',
    MODULE_UPSERT_ERROR: 'MODULE_UPSERT_ERROR',

    MODULE_EDIT: 'MODULE_EDIT',

    MODULE_RESET: 'MODULE_RESET',
};

export const moduleResetAction = () => ({
    type: MODULE_ACTIONS.MODULE_RESET,
});

export const moduleSearchAction = (payload) => ({
    type: MODULE_ACTIONS.MODULE_SEARCH,
    ...payload,
});

export const moduleEditAction = (payload) => ({
    type: MODULE_ACTIONS.MODULE_EDIT,
    ...payload,
});

export const moduleUpsertAction = (payload) => ({
    type: MODULE_ACTIONS.MODULE_UPSERT,
    ...payload,
});

export default {
    MODULE_ACTIONS,
    moduleSearchAction,
    moduleUpsertAction,
    moduleEditAction,
};
