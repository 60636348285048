import React, { useRef, useEffect, useState } from 'react';
import flatpickr from 'flatpickr'
import ViewInvoiceListModal from '../ViewInvoiceListModal';
import DeliveriesDetails from './DeliveriesDetails';
import MeasurementDropdown from '../MeasurementDropdown';
import HSNSearchDropdown from './HSNSearchDropdown';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ClientInvoiceDownload from '../InvoiceDownload/ClientInvoiceDownload';
import TaxInvoiceDownload from '../InvoiceDownload/TaxInvoiceDownload';

const CreateInvoice = () => {

    const invoiceDateRef = useRef(null);
    const dueDateRef = useRef(null);
    const [invoiceDate, setInvoiceDate] = useState('');
    const [dueDate, setDueDate] = useState('');

    useEffect(() => {
        flatpickr(invoiceDateRef.current, {
            enableTime: false,
            dateFormat: "Y/m/d",
            onChange: (selectedDates) => {
                const dateStr = selectedDates[0].toISOString().split('T')[0];
                setInvoiceDate(dateStr);
            }
        });

        flatpickr(dueDateRef.current, {
            enableTime: false,
            dateFormat: "Y/m/d",
            onChange: (selectedDates) => {
                const dateStr = selectedDates[0].toISOString().split('T')[0];
                setDueDate(dateStr);
            }
        });
    }, []);

    const openCalendar = (ref) => {
        ref.current && ref.current._flatpickr.open();
    };



    // State for "Edit items" section
    const [editItems, setEditItems] = useState([{ id: Date.now(), description: '', unit: '', price: '', qty: '', totalAmount: '',  gSTRate: '',  gSTValue: '',  grandTotal: '' }]);

    // State for "Payment Details" section
    const [paymentItems, setPaymentItems] = useState([{ id: Date.now(), amount: '' }]);

    // Functions for "Edit items" section
    const addEditItem = () => {
        setEditItems([...editItems, { id: Date.now(), description: '', unit: '', price: '', qty: '', totalAmount: '', gSTRate: '',  gSTValue: '',  grandTotal: '' }]);
    };

    const removeEditItem = (id) => {
        setEditItems(editItems.filter((item) => item.id !== id));
    };

    const handleEditItemChange = (id, field, value) => {
        setEditItems(editItems.map(item => item.id === id ? { ...item, [field]: value } : item));
    };



    // State for managing payment items
    const [items, setItems] = useState([{ id: Date.now(), amount: '' }]);

    // Function to add a new payment item
    const addItem = () => {
        setItems([...items, { id: Date.now(), amount: '' }]);
    };

    // Function to remove a payment item by id
    const removeItem = (id) => {
        setItems(items.filter((item) => item.id !== id));
    };

    // Function to handle amount change in a specific item
    const handleAmountChange = (id, value) => {
        setItems(items.map(item => item.id === id ? { ...item, amount: value } : item));
    };


    const [addedInvoiceDeliveries, setAddedInvoiceDeliveries] = useState(0);

  const handleInvoiceDeliveriesUpdate = (total) => {
    setAddedInvoiceDeliveries(total);
  };



  const [selectedCurrency, setSelectedCurrency] = useState("₹ INR");

    const currencies = [
        { symbol: "$", name: "USD" },
        { symbol: "₹", name: "INR" },
        { symbol: "AU$", name: "AUD" },
        { symbol: "£", name: "GBP" },
        { symbol: "€", name: "EUR" },
        { symbol: "¥", name: "JPY" },
        { symbol: "₩", name: "KRW" },
        { symbol: "₽", name: "RUB" },
        { symbol: "Fr", name: "CHF" },
        { symbol: "C$", name: "CAD" },
        { symbol: "R", name: "ZAR" },
        { symbol: "R$", name: "BRL" },
        { symbol: "S$", name: "SGD" }
    ];


    const contentRef = useRef();
    const contentRef2 = useRef();

    // Function to handle the PDF generation and download for ClientInvoiceDownload
    const handleDownload = () => {
        const element = contentRef.current; // Reference to ClientInvoiceDownload content

        // Use html2canvas to capture the content as an image
        html2canvas(element, {
            scale: 5, // Increase the scale for better quality
            useCORS: true, // Enable CORS to handle external images (if any)
            logging: true, // Log the process to console for debugging
            backgroundColor: '#ffffff', // Make sure background is white (can adjust if you need)
        }).then((canvas) => {
            // Convert canvas to image data
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF('l', 'mm', 'a4'); // A4 paper size in mm (landscape mode)

            // Adjust the image size to fit the PDF page
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            const scaleX = pdfWidth / canvasWidth;
            const scaleY = pdfHeight / canvasHeight;
            const scale = Math.min(scaleX, scaleY); // Scale to fit within the page

            const xOffset = (pdfWidth - canvasWidth * scale) / 2; // Center horizontally
            const yOffset = 0; // Set yOffset to 0 to start from the top

            // Add image to PDF with calculated position and scaling
            pdf.addImage(imgData, 'JPEG', xOffset, yOffset, canvasWidth * scale, canvasHeight * scale);

            // Save the PDF with a name
            pdf.save('client_invoice.pdf');
        });
    };

    // Function to handle the PDF generation and download for TaxInvoiceDownload
    const handleDownload2 = () => {
        const element = contentRef2.current; // Reference to TaxInvoiceDownload content

        // Use html2canvas to capture the content as an image
        html2canvas(element, {
            scale: 5, // Increase the scale for better quality
            useCORS: true, // Enable CORS to handle external images (if any)
            logging: true, // Log the process to console for debugging
            backgroundColor: '#ffffff', // Make sure background is white (can adjust if you need)
        }).then((canvas) => {
            // Convert canvas to image data
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF('l', 'mm', 'a4'); // A4 paper size in mm (landscape mode)

            // Adjust the image size to fit the PDF page
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            const scaleX = pdfWidth / canvasWidth;
            const scaleY = pdfHeight / canvasHeight;
            const scale = Math.min(scaleX, scaleY); // Scale to fit within the page

            const xOffset = (pdfWidth - canvasWidth * scale) / 2; // Center horizontally
            const yOffset = 0; // Set yOffset to 0 to start from the top

            // Add image to PDF with calculated position and scaling
            pdf.addImage(imgData, 'JPEG', xOffset, yOffset, canvasWidth * scale, canvasHeight * scale);

            // Save the PDF with a name
            pdf.save('tax_invoice.pdf');
        });
    };


    return (
        <> 
            <div className="card h-100 p-0 radius-12">
                <div className="card-body">
                    <div class="d-flex justify-content-between align-items-center border-bottom  pb-16 mb-16">
                        <div class="">
                            <h2 class="fw-semibold mb-16 d-flex align-items-center gap-4 font-24"><button><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="30" height="30"><g data-name="20-Arrow Left"><path fill="#0073ea" d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"></path><path fill="#0073ea" d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"></path></g></svg></button> Create Invoice</h2>
                            <ul class="d-flex align-items-center gap-2">

                                <li class="fw-medium"><a href="/" class="d-flex align-items-center gap-1 hover-text-primary"><iconify-icon icon="solar:home-smile-angle-outline" classname="icon text-lg"></iconify-icon>Special Access</a></li>
                                <li>-</li>
                                <li class="fw-medium"><a href="/" class="d-flex align-items-center gap-1 hover-text-primary">Accounts</a></li>
                                <li>-</li>
                                <li class="fw-medium"><a href="/" class="d-flex align-items-center gap-1 hover-text-primary">Pending Invoice</a></li>
                                <li>-</li>
                                <li class="fw-medium">Create Invoice</li>

                            </ul>
                        </div>
                        <a href="/account-invoice" class="btn btn-outline-primary-600 radius-8 px-20 py-9 gap-2">View Pending</a></div>
                    <form action="#">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-20">
                                            <label for="country" className="form-label fw-semibold text-primary-light text-sm mb-8">Select Project <span className="text-danger-600">*</span> </label>
                                            <select className="form-control radius-8 form-select" id="project">
                                                <option selected="" disabled="">Select Project</option>
                                                <option>USA</option>
                                                <option>Bangladesh</option>
                                                <option>Pakistan</option>
                                                <option>India</option>
                                                <option>Canada</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-20">
                                            <label for="city" className="form-label fw-semibold text-primary-light text-sm mb-8">Organization Type <span className="text-danger-600">*</span> </label>
                                            <select className="form-control radius-8 form-select" id="city">
                                                <option selected="" disabled="">Select Organization Type</option>
                                                <option>Washington</option>
                                                <option>Dhaka</option>
                                                <option>Lahor</option>
                                                <option>Panjab</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="mb-20">
                                            <label for="text" className="form-label fw-semibold text-primary-light text-sm mb-8">Client Name <span className="text-danger-600">*</span></label>
                                            <input type="text" className="form-control radius-8" placeholder="Enter Client Name" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="mb-20">
                                            <label for="text" className="form-label fw-semibold text-primary-light text-sm mb-8">Invoice Title <span className="text-danger-600">*</span></label>
                                            <input type="text" className="form-control radius-8" placeholder="Enter Client Name" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-20">
                                            <label for="text" className="form-label fw-semibold text-primary-light text-sm mb-8">Serial No <span className="text-danger-600">*</span></label>
                                            <input type="text" className="form-control radius-8" placeholder="Enter Client Name" />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-20">
                                            <label for="text" className="form-label fw-semibold text-primary-light text-sm mb-8">Client Invoice No  <span className="text-danger-600">*</span></label>
                                            <input type="text" className="form-control radius-8" placeholder="Enter Client Name" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="mb-20">
                                            <label for="text" className="form-label fw-semibold text-primary-light text-sm mb-8">Client GST <span className="text-danger-600">*</span></label>
                                            <input type="text" className="form-control radius-8" placeholder="22AAAAA0000A1Z5" />
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="mb-20">
                                            <label htmlFor="invoiceDate" className="form-label">
                                                Invoice Date <span className="text-danger-600">*</span>
                                            </label>
                                            <div className="position-relative">
                                                <input
                                                    id="invoiceDate"
                                                    type="text"
                                                    placeholder="MM/DD/YYYY"
                                                    className="form-control radius-8 bg-base"
                                                    ref={invoiceDateRef}
                                                    value={invoiceDate}
                                                    onChange={(e) => setInvoiceDate(e.target.value)}
                                                />
                                                <span
                                                    className="position-absolute end-0 top-50 translate-middle-y me-12"
                                                    onClick={() => openCalendar(invoiceDateRef)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <iconify-icon icon="solar:calendar-linear" className="icon text-lg"></iconify-icon>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="mb-20">
                                            <label for="text" className="form-label fw-semibold text-primary-light text-sm mb-8">Select Days <span className="text-danger-600">*</span></label>
                                            <select className="form-control radius-8 form-select" id="state">
                                                <option selected="" disabled="">Select Days</option>
                                                <option>Non</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* Due Date */}
                                    <div className="col-sm-4">
                                        <div className="mb-20">
                                            <label htmlFor="dueDate" className="form-label">
                                                Due Date <span className="text-danger-600">*</span>
                                            </label>
                                            <div className="position-relative">
                                                <input
                                                    id="dueDate"
                                                    type="text"
                                                    placeholder="MM/DD/YYYY"
                                                    className="form-control radius-8 bg-base"
                                                    ref={dueDateRef}
                                                    value={dueDate}
                                                    onChange={(e) => setDueDate(e.target.value)}
                                                />
                                                <span
                                                    className="position-absolute end-0 top-50 translate-middle-y me-12"
                                                    onClick={() => openCalendar(dueDateRef)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <iconify-icon icon="solar:calendar-linear" className="icon text-lg"></iconify-icon>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="mb-20">
                                            <label for="address" className="form-label fw-semibold text-primary-light text-sm mb-8"> Client Address <span className="text-danger-600">*</span></label>
                                            <textarea type="text" className="form-control radius-8" id="address" placeholder="Client Address"></textarea>
                                        </div>
                                    </div>

                                 
                                    {/* Edit Items Section */}
                                    <div className="col-sm-12">
                                        <label className="form-label fw-semibold text-primary-light text-md mb-8">Edit Items</label>
                                        {editItems.map((item, index) => (
                                            <div key={item.id} className="addmoreItemsDescption">
                                                <div className="mb-10">
                                                    <label className="form-label fw-semibold text-primary-light text-sm mb-8 d-flex align-items-center gap-2">
                                                        <span className="countNumber badge text-sm fw-semibold w-28-px h-28-px d-flex justify-content-center align-items-center rounded-circle bg-primary-600 text-white">{index + 1}</span>
                                                        Item Description <span className="text-danger-600">*</span>
                                                    </label>
                                                    <input type="text" 
                                                                className="form-control radius-8"
                                                                placeholder="Enter Item Description" 
                                                                value={item.description} // Place value inside the tag
                                                                onChange={(e) => handleEditItemChange(item.id, 'description', e.target.value)}
                                                            />
                                                </div>
                                                <div className="d-flex gap-2">
                                                <div className="mb-10">
                                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">Unit of Measurement <span className="text-danger-600">*</span></label> 
                                                        <MeasurementDropdown />
                                                    </div>
                                                    <div className="mb-10 w-100">
                                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">HSN/SCN Code <span className="text-danger-600">*</span></label> 
                                                        <HSNSearchDropdown />
                                                    </div> 
                                                </div>
                                                <div className="d-flex gap-2">
                                                <div className="mb-10">
                                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">Price <span className="text-danger-600">*</span></label>
                                                        <div className="icon-field">
                                                            <span className="icon top-6">&#8377;</span>
                                                            <input
                                                                type="text"
                                                                className="form-control w-110-px text-end"
                                                                placeholder="000.0"
                                                                value={item.price}
                                                                onChange={(e) => handleEditItemChange(item.id, 'price', e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-10">
                                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">Qty. <span className="text-danger-600">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control w-64-px px-2 text-center"
                                                            placeholder="0"
                                                            value={item.qty}
                                                            onChange={(e) => handleEditItemChange(item.id, 'qty', e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex gap-2">
                                                <div className="mb-10">
                                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">Total Amount <span className="text-danger-600">*</span></label>
                                                        <div className="icon-field">
                                                            <span className="icon top-6">&#8377;</span>
                                                            <div 
                                                                className="form-control w-110-px text-end  text-sm disabledDv" >00</div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-10">
                                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">GST Rate % <span className="text-danger-600">*</span></label>
                                                        
                                                            <input
                                                                type="text"
                                                                className="form-control w-110-px text-end text-sm"
                                                                placeholder="0%" 
                                                            /> 
                                                    </div>
                                                    <div className="mb-10">
                                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">GST Value <span className="text-danger-600">*</span></label>
                                                        <div className="icon-field">
                                                            <span className="icon top-6">&#8377;</span>
                                                            <div className="form-control w-110-px text-end text-sm disabledDv"
                                                            >000.00</div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-10">
                                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">Grand Total <span className="text-danger-600">*</span></label>
                                                        <div className="icon-field">
                                                            <span className="icon top-6">&#8377;</span>
                                                            <div 
                                                                className="form-control w-110-px text-end text-sm disabledDv"
                                                            >000.00</div>
                                                        </div>
                                                    </div>
                                                    </div> 
                                                {index === editItems.length - 1 ? (
                                                    <button type="button" className="d-flex align-items-center gap-2 text-dblue ms-auto justify-content-end" onClick={addEditItem}>
                                                        <i className="mdi mdi-plus"></i> Add more
                                                    </button>
                                                ) : (
                                                    <button type="button" className="d-flex align-items-center gap-2 text-red ms-auto justify-content-end" onClick={() => removeEditItem(item.id)}>
                                                        Remove <i className="mdi mdi-delete mdi-18px"></i>
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="posistickyNpayment max-w-634-px ms-auto">
                                    <label className="form-label fw-semibold text-primary-light text-md mb-8">Payment Details</label>
                                    <div className="border radius-10 p-16">
                                        {items.map((item, index) => (
                                            <div key={item.id} className="addmoreItemsDescption">
                                                <div className="mb-10">
                                                    <div className="form-label fw-semibold text-primary-light text-sm mb-4 d-flex justify-content-between align-items-center gap-2">
                                                        <span className="countNumber badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle bg-neutral-900 text-white">
                                                            {index + 1}
                                                        </span>

                                                        <div className="ymdsenpdrl d-flex align-items-center gap-2">
                                                            <div className="year">
                                                                <select className="form-control w-100 radius-8 form-select" aria-label="Select Year">
                                                                    <option selected disabled>Year</option>
                                                                    {[2024, 2023, 2022, 2021, 2020, 2019].map(year => (
                                                                        <option key={year} value={year}>{year}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="month">
                                                                <select className="form-control w-100 radius-8 form-select" aria-label="Select Month">
                                                                    <option selected disabled>Month</option>
                                                                    {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((month, idx) => (
                                                                        <option key={idx} value={month}>{month}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="deliveriesDetails">
                                                                <button type="button" className="btn btn-outline-primary-600 radius-8 text-sm px-10 py-9 d-flex align-items-center gap-2" data-bs-toggle="modal" data-bs-target="#DeliveriesDetailsModal">
                                                                    Deliveries Details <i className="mdi mdi-chevron-down"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-end">
                                                    <span className="text-sm text-secondary-light fw-normal mb-16"> {addedInvoiceDeliveries} Invoice Deliveries added</span>
                                                    </div>
                                                    <div className="icon-field">
                                                        <span className="icon top-6">&#8377;</span>
                                                        <input
                                                            type="text"
                                                            className="form-control w-100 text-end"
                                                            placeholder="0"
                                                            value={item.amount}
                                                            onChange={(e) => handleAmountChange(item.id, e.target.value)}
                                                            aria-label="Enter payment amount"
                                                        />
                                                    </div>
                                                </div>

                                                {index === items.length - 1 ? (
                                                    <button
                                                        type="button"
                                                        className="d-flex align-items-center gap-2 text-dblue ms-auto justify-content-end"
                                                        onClick={addItem}
                                                    >
                                                        <i className="mdi mdi-plus"></i> Add more
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="d-flex align-items-center gap-2 text-red ms-auto justify-content-end mb-16"
                                                        onClick={() => removeItem(item.id)}
                                                    >
                                                        Remove <i className="mdi mdi-delete mdi-18px"></i>
                                                    </button>
                                                )}
                                            </div>
                                        ))}

                                        <div className="mt-0">
                                            <div className="d-flex justify-content-between align-items-center border-bottom py-10">
                                                <div className="Text text-neutral-900">Subtotal</div>
                                                <div className="Price text-neutral-900">₹0.00</div>
                                            </div>
                                            {/* <div className="d-flex justify-content-between align-items-center border-bottom py-10">
                                                <div className="Text text-neutral-500">GST Type</div>
                                                <div className="">
                                                    <div className="d-flex align-items-center flex-wrap gap-28">
                                                        <div className="form-check checked-primary d-flex align-items-center gap-2">
                                                            <input className="form-check-input" type="radio" name="horizontal" id="horizontal1" />
                                                            <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="horizontal1" > Non  </label>
                                                        </div>
                                                        <div className="form-check checked-primary d-flex align-items-center gap-2">
                                                            <input className="form-check-input" type="radio" name="horizontal" id="horizontal2" />
                                                            <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="horizontal2"> Partial</label>
                                                        </div>
                                                        <div className="form-check checked-primary d-flex align-items-center gap-2">
                                                            <input className="form-check-input" type="radio" name="horizontal" id="horizontal3" />
                                                            <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="horizontal3"> Full</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="d-flex justify-content-between align-items-center py-10">
                                                <div className="Text text-neutral-900">Total GST </div>
                                                <div className="Price">₹1,858.00</div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center bg-neutral-200 px-12 py-8 w-100 radius-8 text-secondary-light">
                                                <div className="Text fw-semibold text-neutral-900">Grand Total</div> 
                                                     <div className="dropdown">
                                                            <button className="dropdown-btn dropdown-toggle" data-bs-toggle="dropdown">
                                                                {selectedCurrency}
                                                            </button>
                                                            <div className="dropdown-menu mx-hight200px">
                                                                {currencies.map((currency, idx) => (
                                                                    <div 
                                                                        key={idx}
                                                                        className="dropdown-item text-sm cursor-pointer"
                                                                        onClick={() => setSelectedCurrency(`${currency.symbol} ${currency.name}`)}
                                                                    >
                                                                        <span className="currency-circle">{currency.symbol}</span> {currency.name}
                                                                    </div>
                                                                ))}
                                                            </div> 
                                                </div>
                                                <div className="Price fw-medium text-neutral-900 text-xl">₹1,858.00</div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center py-10">
                                                <div className="Text">Display Bank Details</div>
                                                <div className="">
                                                    <div className="d-flex align-items-center flex-wrap gap-28">
                                                        <div className="form-check checked-primary d-flex align-items-center gap-2">
                                                            <input className="form-check-input" type="radio" name="BankDetails" id="BankDetails1" />
                                                            <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="BankDetails1" > Yes  </label>
                                                        </div>
                                                        <div className="form-check checked-primary d-flex align-items-center gap-2">
                                                            <input className="form-check-input" type="radio" name="BankDetails" id="BankDetails2" />
                                                            <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="BankDetails2"> No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="savecontinu">
                                    <div style={{ position: 'absolute', top: '-10000px', left: '-10000px' }}> <ClientInvoiceDownload contentRef={contentRef} /> <TaxInvoiceDownload contentRef2={contentRef2} /></div>
                                        <button type="button" className="btn btn-info-600 radius-8 px-20 py-11 w-100" onClick={handleDownload}>Save and Continue</button>
                                        <button type="button" className="btn btn-info-600 radius-8 px-20 py-11 w-100" onClick={handleDownload2}>Save and Continue</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <DeliveriesDetails onInvoiceDeliveriesUpdate={handleInvoiceDeliveriesUpdate} />
        </>
    )
}

export default CreateInvoice
