const ProjectProductionFilter=()=>{
  return (
    <>
    <div
  className="offcanvas offcanvas-end filterSecPopup"
  id="filterRightPopup"
  aria-labelledby="filterRightLayout"
>
  <div className="offcanvas-header">
    <h5 className="offcanvas-title" id="filterRightLayout">
      Filter
    </h5>
    <button
      type="button"
      className="btn-close d-none"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    />
  </div>
  <div className="offcanvas-body pt-0">
    <div className="assignPopoHeight">
      <form className="AllFilter_Select" action="/action_page.php">
        <input className="form-check-input" type="checkbox" id="allFilter" />
        <label className="allFilter" htmlFor="allFilter">
          All Filter <span>3 Select</span>
        </label>
      </form>
      <div className="selectSec pt-2">
        <label htmlFor="">Status</label>
        <div className="form-group AllFilter_Select pt-0" action="">
          <input
            className="form-check-input radio"
            type="checkbox"
            defaultValue={1}
            name="fooby[1][]"
            id="stactive"
          />
          <label htmlFor="stactive">Active</label>
        </div>
        <div className="form-group AllFilter_Select pt-0" action="">
          <input
            className="form-check-input radio"
            type="checkbox"
            defaultValue={1}
            name="fooby[1][]"
            id="stdeactive"
          />
          <label htmlFor="stdeactive">Deactive</label>
        </div>
        <div
          className="form-group AllFilter_Select pt-0"
          action="/action_page.php"
        >
          <input
            className="form-check-input radio"
            type="checkbox"
            defaultValue={1}
            name="fooby[1][]"
            id="rejectSe"
          />
          <label htmlFor="rejectSe">Reject</label>
        </div>
      </div>
      <div className="selectSec filterStyleSec">
        <label htmlFor="">Department</label>
        <select id="departmentSec">
          <option value="">Select Department</option>
          <option value={1}>Awesome</option>
          <option value={2}>Beast</option>
          <option value={3}>Compatible</option>
          <option value={4}>Thomas Edison</option>
          <option value={5}>Nikola</option>
        </select>
      </div>
      <div className="selectSec filterStyleSec">
        <label htmlFor="">Cadre</label>
        <select id="cadreMenuSec">
          <option value="">Select Cadre</option>
          <option value={1}>Awesome</option>
          <option value={2}>Beast</option>
          <option value={3}>Compatible</option>
          <option value={4}>Thomas Edison</option>
          <option value={5}>Nikola</option>
        </select>
      </div>
      <div className="selectSec filterStyleSec">
        <label htmlFor="">Uploaded by</label>
        <select id="uploadedMenuSec">
          <option value="">Select User</option>
          <option value={1}>Awesome</option>
          <option value={2}>Beast</option>
          <option value={3}>Compatible</option>
          <option value={4}>Thomas Edison</option>
          <option value={5}>Nikola</option>
        </select>
      </div>
      <div className="selectSec pt-0">
        <label htmlFor="">Cost</label>
        <div className="costSec">
          <div className="costinputSec">
            <label htmlFor="">₹</label>
            <input type="number" min={0} defaultValue={0} />
          </div>
          <select className="slectIconsShow">
            <option value="Above">Above</option>
            <option value="">15</option>
            <option value="">75</option>
            <option value="">78</option>
            <option value="">85</option>
            <option value="">03</option>
            <option value="">10</option>
          </select>
        </div>
      </div>
      <div className="selectSec pt-0">
        <label htmlFor="">Hours</label>
        <div className="costSec">
          <div className="timepicker timepicker1" dir="ltr">
            <input
              type="text"
              className="hh N"
              min={0}
              max={23}
              placeholder="hh"
              maxLength={2}
            />
            :
            <input
              type="text"
              className="mm N"
              min={0}
              max={59}
              placeholder="mm"
              maxLength={2}
            />
          </div>
          <select className="slectIconsShow">
            <option value="Above">Above</option>
            <option value="">15</option>
            <option value="">75</option>
            <option value="">78</option>
            <option value="">85</option>
            <option value="">03</option>
            <option value="">10</option>
          </select>
        </div>
      </div>
    </div>
    
  </div>
  <div className="offcanvas-footer px-16">
      <button
        type="button"
        className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
      >
        Save Filter
      </button>
    </div>
</div>

    </>
  )
}
export default ProjectProductionFilter