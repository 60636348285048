import React, { useState } from 'react';
import StatusCard from './StatusCard'; // Import the StatusCard component
import Breadcrumb from './Breadcrumb'; // Import the Breadcrumb component (if needed)
import PaymentInvoiceTable from './PaymentInvoiceTable';
import SearchInput from '../SearchInput';
import DatePickerComponent from '../DatePickerComponent';
import SummaryInvoicePayment from './SummaryInvoicePayment';

const PaymentDetails = () => {
  const [cards, setCards] = useState([
    { amount: '₹31,583.36', status: 'Total Delivered', color: 'success' },
    { amount: '₹31,583.36', status: 'Total Invoice Raised', color: 'lilac' },
    { amount: '₹31,583.36', status: 'Total Pending', color: 'warning' },
    { amount: '₹31,583.36', status: 'Total Balance', color: 'info' },
  ]);


  const handleDateRangeChange = (selectedDates) => {

  }
  

  
const [showSummaryTable, setShowSummaryTable] = useState(false);

// Function to handle showing the summary table
const onClick = () => {
    setShowSummaryTable(true);
};

// Function to handle hiding the summary table
const handleSummaryClick2 = () => {
    setShowSummaryTable(false);
};

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="border-bottom mb-24 pb-16">
                <h2 className="fw-semibold mb-16 font-24">
                  <button>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                      <g data-name="20-Arrow Left">
                        <path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0z" />
                        <path d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z" />
                      </g>
                    </svg>
                  </button>{" "}
                  Payment Details
                </h2>
                <Breadcrumb />
              </div>
            </div>
            </div>
            <div className="row despset" style={{ display: showSummaryTable ? 'none' : 'flex' }}>
            {cards.map((card, index) => (
              <StatusCard key={index} amount={card.amount} status={card.status} color={card.color} className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 pe-2" />
            ))}
           
            <div className="col-md-12 pt-24 postionTop-0 bg-white">
              <div className="mb-3 d-flex align-items-center gap-3">
                <button type="button" className="btn btn-primary-600 radius-8 px-20 py-9">
                  <i className="mdi mdi-plus"></i> Add Comment
                </button>
                <button type="button" className="btn btn-primary-600 radius-8 px-20 py-9" onClick={onClick}>
                  <i className="mdi mdi-file-document-outline"></i> Summary
                </button>
                <SearchInput />
                <DatePickerComponent
                  onDateRangeChange={handleDateRangeChange}
                />
              </div>
            </div>

            <div className="col-md-12">
        <div className="panel panel-default">
          <div className="panel-heading fw-bold text-dblue mb-16">Payment Details List</div>
          <div className="panel-body">
            <PaymentInvoiceTable />
          </div>
        </div>
      </div> 
          </div>

          <div className={`row SummaryInvoiceTable ${showSummaryTable ? '' : 'd-none'}`}>
            <SummaryInvoicePayment onClick={handleSummaryClick2} />
          </div>
          
        </div>
      </div>

     
    </div>
  );
};

export default PaymentDetails;
