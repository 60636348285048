import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const EmployeeDataPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    firstName: PropTypes.string, 
    lastName: PropTypes.string, 
    email: PropTypes.string, 
    employeeID: PropTypes.string, 
    phone: PropTypes.string,  
    isAdmin: PropTypes.boolean,
    username: PropTypes.string,
 
});

export const EmployeeDataDefaultProps = {
    _id: undefined, 
    firstName: '', 
    lastName: '', 
    email: '', 
    employeeID: '',
    phone: '', 
    isAdmin: false,
    status: true,
    username: '',
 
};


export const EmployeeDataYupSchema = Yup.object().shape({ 
    firstName: Yup.string().required('Required'), 
    email: Yup.string().required('Required').email('Invalid email format'),
});

export default {
    EmployeeDataDefaultProps,
    EmployeeDataPropTypes,
    EmployeeDataYupSchema,
};
