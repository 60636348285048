import { put, call } from 'redux-saga/effects';
import { employeeDataSearchAPI } from 'src/api/EmployeeDataApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { DASHBOARD_ACTIONS } from '../actions/DashboardActions';
 

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(employeeDataSearchAPI, {
            where,
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: DASHBOARD_ACTIONS.DASHBOARD_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: DASHBOARD_ACTIONS.DASHBOARD_SEARCH_ERROR,
        });
        return null;
    }
}
 

export default [
    takeFirstSagaUtil(DASHBOARD_ACTIONS.DASHBOARD_SEARCH, search), 
];
