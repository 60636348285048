import React, { useEffect, useRef, useState } from "react";
import PaymentDetailsSummary from "./PaymentDetailsSummary";
import ClientWise from "./ClientWise";
import ClientWiseMonths from "./ClientWiseMonths";

const getInitials = (name) => {
    if (!name) return '';
    const cleanName = name.replace(/\(.*?\)/g, '').trim();
    const words = cleanName.split(' ');
    const firstNameInitial = words[0]?.charAt(0).toUpperCase();
    const lastNameInitial = words[1]?.charAt(0).toUpperCase();
    return `${firstNameInitial}${lastNameInitial || ''}`;
};


const SummaryInvoicePayment = ({ onClick }) => {
    const [data, setData] = useState([
        {
            sNo: "01",
            month: "February 2024",
            numberOfClient: "15",
            amount: "₹23591.01",
        },
        {
            sNo: "02",
            month: "March 2024",
            numberOfClient: "50",
            amount: "₹33591.01",
        },
        {
            sNo: "03",
            month: "April 2024",
            numberOfClient: "20",
            amount: "₹13591.01",
        },
    ]);

    const [selectedRows, setSelectedRows] = useState([]);
    const [activeTab, setActiveTab] = useState("MonthWise"); // State to track active tab

    const handleSelectRow = (sNo) => {
        setSelectedRows(selectedRows.includes(sNo)
            ? selectedRows.filter((row) => row !== sNo)
            : [...selectedRows, sNo]);
    };

    const handleSelectAll = () => {
        setSelectedRows(selectedRows.length === data.length ? [] : data.map((row) => row.sNo));
    };

    // Calculate total number of clients and total amount
    const totalClients = data.reduce((sum, row) => sum + parseInt(row.numberOfClient, 10), 0);
    const totalAmount = data.reduce((sum, row) => sum + parseFloat(row.amount.replace(/[^\d.-]/g, '')), 0);

    const datasummary = [
        { project: 'P001-A', clientName1: 'HCL', clientName2: 'Hasmukhbhai Parekh', amount: '23591.01' },
        { project: 'P001-B', clientName1: 'Acadecraft', clientName2: 'Hasmukhbhai Parekh', amount: '23591.01' },
        { project: 'P001-C', clientName1: 'Microsoft', clientName2: 'Hasmukhbhai Parekh', amount: '23591.01' },
    ];

    const totalAmountsummary = datasummary.reduce((sum, row) => sum + parseFloat(row.amount), 0);

    // Calculate total months across all rows
    const totalMonths = data.reduce((total, row) => {
        const monthCount = row.month.split(',').length;
        return total + monthCount;
    }, 0);

       // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code End
    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <button className="d-flex align-items-center gap-2 btn rounded-pill text-primary-light radius-8 pe-20 text-xl py-11" onClick={onClick}>
                    <i className="mdi mdi-arrow-left mdi-24px"></i> Summary
                </button>
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <ul className="nav focus-tab nav-pills mb-16" id="pills-tab-two" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link fw-semibold text-primary-light radius-4 px-16 py-10 active"
                            id="pills-focus-MonthWise-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-focus-MonthWise"
                            type="button"
                            role="tab"
                            aria-controls="pills-focus-MonthWise"
                            aria-selected="true"
                            onClick={() => setActiveTab("MonthWise")} // Update active tab
                        >
                            Month Wise 
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link fw-semibold text-primary-light radius-4 px-16 py-10"
                            id="pills-focus-ClientWise-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-focus-ClientWise"
                            type="button"
                            role="tab"
                            aria-controls="pills-focus-ClientWise"
                            aria-selected="false"
                            tabIndex={-1}
                            onClick={() => setActiveTab("ClientWise")} // Update active tab
                        >
                            Client Wise
                        </button>
                    </li>
                </ul>

                <div className="d-flex gap-4">
                    {/* Show Total Months only when ClientWise tab is active */}
                    {activeTab === "ClientWise" && (
                        <div className="card-body p-20 bg-base border h-100 d-flex flex-column justify-content-center radius-10 text-center">
                            <h6 className="fw-semibold text-primary-light mb-1">{totalMonths}</h6>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-8-px h-8-px bg-info-600 rounded-circle"></span>
                                <span className="text-neutral-800 fw-medium">Total Months</span>
                            </div>
                        </div>
                    )}
                    <div className="card-body p-20 bg-base border h-100 d-flex flex-column justify-content-center radius-10 text-center">
                        <h6 className="fw-semibold text-primary-light mb-1">{totalClients}</h6>
                        <div className="d-flex align-items-center gap-2">
                            <span className="w-8-px h-8-px bg-success-600 rounded-circle"></span>
                            <span className="text-neutral-800 fw-medium">Total Clients</span>
                        </div>
                    </div>

                    <div className="card-body p-20 bg-base border h-100 d-flex flex-column justify-content-center radius-10 text-center">
                        <h6 className="fw-semibold text-primary-light mb-1">₹{totalAmount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h6>
                        <div className="d-flex align-items-center gap-2">
                            <span className="w-8-px h-8-px bg-lilac-600 rounded-circle"></span>
                            <span className="text-neutral-800 fw-medium">Total Amount</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tab-content" id="pills-tab-twoContent">
                <div
                    className="tab-pane fade active show"
                    id="pills-focus-MonthWise"
                    role="tabpanel"
                    aria-labelledby="pills-focus-MonthWise-tab"
                    tabIndex={0}
                >
                    <div className="d-flex justify-content-between align-items-center pt-16 pb-16">
                        <h3 className="card-title">Payment Details Summary</h3>
                    </div>

                    <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
                    <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                        <thead>
                            <tr>
                                <th>
                                    <input className="form-check-input"
                                        type="checkbox"
                                        onChange={handleSelectAll}
                                        checked={selectedRows.length === data.length}
                                        style={{ display: "inline-block", width: "24px", height: "24px" }}
                                    />
                                </th>
                                <th>S.No.</th>
                                <th>Month</th>
                                <th>Number of Clients</th>
                                <th className="text-end">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row) => (
                                <tr key={row.sNo}>
                                    <td>
                                        <input className="form-check-input"
                                            type="checkbox"
                                            checked={selectedRows.includes(row.sNo)}
                                            onChange={() => handleSelectRow(row.sNo)}
                                            style={{ display: "inline-block", width: "24px", height: "24px" }}
                                        />
                                    </td>
                                    <td>{row.sNo}</td>
                                    <td>{row.month}</td>
                                    <td className="d-flex align-items-center gap-3">
                                        <button className="text-dblue" data-bs-toggle="modal" data-bs-target="#PaymentDetailsSummary">
                                            <i className="mdi mdi-plus-circle-outline mdi-36px"></i>
                                        </button>
                                        {row.numberOfClient}
                                    </td>
                                    <td className={`text-end ${parseFloat(row.amount.replace(/[^\d.-]/g, '')) < 0 ? 'text-red' : 'text-green'}`}>
                                        {row.amount}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                </div>

                <div
                    className="tab-pane fade"
                    id="pills-focus-ClientWise"
                    role="tabpanel"
                    aria-labelledby="pills-focus-ClientWise-tab"
                    tabIndex={0}
                >
                    {/* Pass the totalMonths to the ClientWise component */}
                    <ClientWise totalMonths={totalMonths} />
                </div>
            </div> 

            <PaymentDetailsSummary
                paymentData={datasummary}
                totalAmount={totalAmountsummary}
                month="February 2024"
            />
            <ClientWiseMonths />
        </>
    );
};

export default SummaryInvoicePayment;
