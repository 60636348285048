import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const SalaryPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    SalaryName: PropTypes.string,
});

export const SalaryDefaultProps = { 
        "_id": undefined,
        "userId": {
            "profilePicture": null, 
            "firstName": "NA",
            "lastName": "NA",
            "phone": "NA",
            "username": "NA",
            "employeeID": "NA",
            "mobile": "NA",
            "email": "NA", 
            "role": "user",
            "isAuthnticated": false,
            "isAdmin": true,
            "isOrange": false,
            "status": true,
            "active": true,
            "organization": " Acadecraft Pvt. Ltd.",
            "departmentId": {
                "departmentName": null,
                
            },
            "designationId": {
                "designationName": null, 
            },
            "reportingManagerId": null,
            "monthlySalary": 0,
        },
        "salaryYear": 2024,
        "salaryMonth": 1,
        "daysDetails": {
            "totalDays": 0,
            "OffSaturday": 0,
            "totalSunday": 0,
            "totalHoliday": 0,
            "totalAbsent": 0,
            "effWorkingDays": 0,
            "workingDays": 0, 
        },
        "leaveDetails": {
            "totalLeaves": 0,
            "adjustedSL": 0,
            "adjustedCL": 0,
            "adjustedCasualLeavesAmount": 0,
            "adjustedSickLeavesAmount": 0,
            "halfDaysLeave": 0,
            "halfDaysLeaveAmount": 0,
            "otherLeaveDeduction": 0,
            "otherLeaveDeductionAmount": 0,
            "totalLeaveDeductionAmount": 0,
            "daySalary": 0,
        },
        "lagHourDetails": {
            "totalWorkingHours": 0,
            "requiredHours": 0,
            "exactHours": 0,
            "hubstaffTime": 0,
            "lagHours": 0,
            "considerDeduction": "No",
            "pfDeduction": 0,
            "taxDeduction": 0,
            "otherDeduction": 0,
            "extraDeduction": 0,
            "extraDeductionAmount":0,
            "totalLagHourDeduction": 0, 
        },
        "monthlySalary": 0,
        "netSalary": 0, 
        "personal": {
            "id": null,
            "joindate":null,
            "accountno": "NA",
            "accountname": "NA",
            "ifsc": "NA",
            "bankname": "NA",
            "branchname": "NA"
        },
        "hubstaff": []
};

export const SalaryYupSchema = Yup.object().shape({ 
    SalaryName: Yup.string().required('Required'),
});

export default {
    SalaryDefaultProps,
    SalaryPropTypes,
    SalaryYupSchema,
};
