import React, { useState } from 'react';
import Highcharts from 'highcharts';
import { useDispatch } from 'react-redux';
import HighchartsReact from 'highcharts-react-official';
import { perfomanceSetSelectedModuleAction } from 'src/containers/employee-container/Perfomance/actions/PerfomanceActions';

const ModuleSummaryPieChart = ({projectTasks,setModuleId}) => {
  // Sample project data
  const dispatch = useDispatch(); 
  const totalCost = projectTasks.reduce((acc, project) => acc + project.amount, 0);
  const totalProjects = projectTasks.length;
  const chartOptions = {
    chart: {
      type: 'pie'
    },
    title: {
      text: '',
      align: 'left'
    },
    credits: {
      enabled: false // Disable credits
    },
    subtitle: {
      text: '',
      align: 'left'
    },
    tooltip: {
      headerFormat: '',
      pointFormat:
        '<span style="opacity: 0.7; color:#20A271; font-size: 1.2em; font-weight:normal;">{point.percentage:.1f}%</span><br>' +
        '<span style="color:#323338; font-weight:normal;"><b>{point.name}</b></span><br>' +
        '<span style="opacity: 0.8; color:#7B7E8B; font-weight:normal;">&#8377;{point.amount:,.2f}</span>' // Include the formatted amount
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        borderWidth: 2,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format:
            '<span style="font-size: 1.2em; color:#323338; font-weight:normal;"><b>{point.name}</b></span> ' +
            '<span style="opacity: 0.7; color:#20A271; font-size: 1.2em; font-weight:normal;"> {point.percentage:.1f}%</span><br>' +
            '<span style="opacity: 0.8; color:#7B7E8B; font-weight:normal;">&#8377;{point.amount:,.2f}</span>', // Include the formatted amount
          distance: 20
        },
        innerSize: '40%', // Set the inner size here
        borderRadius: 0, // Apply border radius here
        events: {
          click: function (event) {
            const clickedPoint = event.point;
            setModuleId(clickedPoint.moduleId); // Ensure this function is defined in your component
            dispatch(perfomanceSetSelectedModuleAction({ data: clickedPoint.name })); // Ensure dispatch is correctly set up
          },
        },
      },
    },
    series: [
      {
        enableMouseTracking: true, // Set to true to enable tooltips
        animation: {
          duration: 2000
        },
        colorByPoint: true,
        data: projectTasks.map((project) => ({
          name: project.name,
          y: project.percentage,
          color: project.color,
          amount: project.amount,
          moduleId: project.moduleId
        }))
      }
    ]
  };

  return (
    <div className="d-flex justify-content-between chartsetnewbg border-top border-bottom mt-24 rounded-12">
      <div className="chartsetnew1">
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>

      <div className="chartsetvalue1 tcostprojt border-bottom border-start">
        <div className="w-100 border-bottom px-24 pb-10 pt-16">
          <div className="d-flex justify-content-between align-items-center tCost">
            Total Cost <span>₹{totalCost.toFixed(2)}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center tProject">
            Total Module <span>{totalProjects}</span>
          </div>
        </div>

        <div className="max-h-350-px overflow-auto px-24 pt-24">
          {projectTasks
            .sort((a, b) => b.amount - a.amount)
            .map((project, index) => (
              <button
                key={index}
                className="d-flex align-items-center justify-content-between gap-3 mb-24 w-100 btnchrtpie"
                style={{
                  borderLeft: `4px solid ${project.color}`, // Add dynamic left-side border
                  paddingLeft: '10px' // Add left padding for spacing
                }}
              >
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <h6 className="text-md mb-0 fw-medium">{project.name}</h6>
                    <span className="text-sm text-secondary-light fw-medium">
                      ₹{project.amount.toFixed(2)}
                    </span>
                  </div>
                </div>
                <span className="text-success-500 text-md fw-medium">{project.percentage}%</span>
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ModuleSummaryPieChart;
