import { useCallback, useEffect, useState } from "react";
import { useSelector,shallowEqual,useDispatch } from 'react-redux';
import VehicleFuelChart from "src/components/VehicleFuelChart";
import { getIndividualProjectTaskWiseCostAPI } from "src/api/PerfomanceAPI";

const ModuleTaskCost=({moduleId})=>{
const [projectModule, setProjectModule] = useState([]);
const {taskWiseWhere,selectedModule} = useSelector(state=>state.PerfomanceReducer,shallowEqual);
useEffect(()=>{
  search();
},[taskWiseWhere,moduleId])

const search = useCallback(async()=>{
    const where = moduleId ? {...taskWiseWhere,moduleId,requestSrc:"task"} : {...taskWiseWhere,requestSrc:"task"};
    const projectCost  = await getIndividualProjectTaskWiseCostAPI({where});
    setProjectModule(projectCost.data.data||[])
    },[taskWiseWhere,moduleId])
 return (
    
    <>
      <div className="col-md-12">
                <div className="card h-100 p-0">
                    <div className="card-body p-24">
                        <div className="ms-auto d-flex justify-content-between pb-12 align-items-center inwth">
                            <h4 className="card-title">Task Summary  <span className="text-neutral-500">({(selectedModule)?selectedModule:"All"})</span></h4>
                        </div>
                            <VehicleFuelChart projects={projectModule} totalProjectsLabel="Total Task"/> {/* Pass chart data */}
                            {/* <div className="text-center"><button className="btn text-primary-600 border-0 radius-8 pt-20 py-24 pb-8">Hide</button></div> */}
                        </div>
                </div>
       </div>
    </>
 )
}
export default ModuleTaskCost