import { shallowEqual, useSelector } from "react-redux"

const ClientDeliveryBreadCrumbs=()=>{
  const {selectCategory,selectSubCategory} = useSelector(state => state.ClientProductionReducer,shallowEqual)
  const {currentRecord} = useSelector((state) => state.ProjectReducer,shallowEqual)
  return (
    <nav style={{ '--bs-breadcrumb-divider': '>' }} aria-label="breadcrumb">
      <ol className="breadcrumb my-16 align-items-center">
        {
          currentRecord?.RefClientId?.ClientProjectCode && (
            <li className="breadcrumb-item">
              <a href="#" 
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne" 
              >{currentRecord?.RefClientId?.ClientProjectCode} <span className="xnbv1">&rsaquo;</span></a>
            </li>
          )
        }
        {
          currentRecord?.ProjectSubCode && (
            <li className="breadcrumb-item">
              <a href="#"
              data-bs-toggle="collapse"
              data-bs-target={`#sub-collapseOne${currentRecord?.RefClientId?._id}`}
              aria-expanded="true"
              aria-controls="collapseOne"              
              >{currentRecord.ProjectSubCode} <span className="xnbv1">&rsaquo;</span></a>
            </li>
          )
        }
        {
          selectCategory?.categoryName && (
            <li className="breadcrumb-item">
              <a href="#"
              data-bs-toggle="collapse"
              data-bs-target={`#sub-collapse${currentRecord._id}`}
              aria-expanded="true"
              aria-controls="collapse6"
              
              >{selectCategory?.categoryName} <span className="xnbv1">&rsaquo;</span></a>
            </li>
          )
        }
        {
          selectSubCategory?.subCategoryName && (
            <li className="breadcrumb-item active pt-4" aria-current="page"
                data-bs-toggle="collapse"
                data-bs-target={`#sub-collapseTask${selectCategory.categoryId}`}
                aria-expanded="true"
                aria-controls="collapse11"
            >
              {selectSubCategory.subCategoryName}
            </li>
          )
        }
      </ol>
    </nav>
  );
}
export default ClientDeliveryBreadCrumbs