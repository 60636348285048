const FilterPopup=()=>{
    return(
        <div
        className="offcanvas offcanvas-end filterSecPopup"
        id="filterAddClientpop"
        aria-labelledby="filteraddClientSec"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="filteraddClientSec">
            Filter
          </h5>
          <button
            type="button"
            className="btn-close d-none"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body pt-0">
          <div className="assignPopoHeight">
            <form className="AllFilter_Select" action="/action_page.php">
              <input className="form-check-input" type="checkbox" id="allFilter" />
              <label className="allFilter" htmlFor="allFilter">
                All Filter <span>3 Select</span>
              </label>
            </form>
            <div className="selectSec">
              <label htmlFor="">Status</label>
              <div
                className="form-group AllFilter_Select pt-0"
                action="/action_page.php"
              >
                <input
                  className="form-check-input radio"
                  type="checkbox"
                  defaultValue={1}
                  name="fooby[1][]"
                  id="stactive"
                />
                <label htmlFor="stactive">Active</label>
              </div>
              <div
                className="form-group AllFilter_Select pt-0"
                action="/action_page.php"
              >
                <input
                  className="form-check-input radio"
                  type="checkbox"
                  defaultValue={1}
                  name="fooby[1][]"
                  id="stdeactive"
                  defaultChecked=""
                />
                <label htmlFor="stdeactive">Deactive</label>
              </div>
            </div>
            <div className="selectSec">
              <label htmlFor="">Sub Project Approval Status</label>
              <div
                className="form-group AllFilter_Select pt-0"
                action="/action_page.php"
              >
                <input
                  className="form-check-input radio"
                  type="checkbox"
                  defaultValue={1}
                  name="fooby[2][]"
                  id="pendingCl"
                />
                <label htmlFor="pendingCl">Pending</label>
              </div>
              <div
                className="form-group AllFilter_Select pt-0"
                action="/action_page.php"
              >
                <input
                  className="form-check-input radio"
                  type="checkbox"
                  defaultValue={1}
                  name="fooby[2][]"
                  id="approvedCl"
                />
                <label htmlFor="approvedCl">Approved</label>
              </div>
              <div
                className="form-group AllFilter_Select pt-0"
                action="/action_page.php"
              >
                <input
                  className="form-check-input radio"
                  type="checkbox"
                  defaultValue={1}
                  name="fooby[2][]"
                  id="rejectedCl"
                  defaultChecked=""
                />
                <label htmlFor="rejectedCl">Rejected</label>
              </div>
            </div>
            <div className="selectSec pt-0">
              <label htmlFor="">P&amp;L</label>
              <div className="costSec">
                <div className="costinputSec">
                  {/* <label for="">₹</label> */}
                  <input type="number" min={0} defaultValue='00' />
                </div>
                <select className="slectIconsShow">
                  <option value="Above">Above</option>
                  <option value="">15</option>
                  <option value="">75</option>
                  <option value="">78</option>
                  <option value="">85</option>
                  <option value="">03</option>
                  <option value="">10</option>
                </select>
              </div>
            </div>
            <div className="selectSec pt-0">
              <label htmlFor="">Expected</label>
              <div className="costSec">
                <div className="costinputSec">
                  {/* <label for="">₹</label> */}
                  <input type="number" min={0} defaultValue='00' />
                </div>
                <select className="slectIconsShow">
                  <option value="Above">Above</option>
                  <option value="">15</option>
                  <option value="">75</option>
                  <option value="">78</option>
                  <option value="">85</option>
                  <option value="">03</option>
                  <option value="">10</option>
                </select>
              </div>
            </div>
            <div className="selectSec filterStyleSec">
              <label htmlFor="">Assign to</label>
              <select id="departmentSec">
                <option value="">Select user</option>
                <option value={1}>Awesome</option>
                <option value={2}>Beast</option>
                <option value={3}>Compatible</option>
                <option value={4}>Thomas Edison</option>
                <option value={5}>Nikola</option>
              </select>
            </div>
          </div>
          <div className="">
            <button
              type="button"
              className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
            >
              Save Filter
            </button>
          </div>
        </div>
      </div>
    )
}
export default FilterPopup