import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import { getPendingProduction, taskStatusUpdateAPI } from "src/api/TaskApi";
import { actionSetPendingList } from "../dashboard-container/actions/DashboardActions";
import { useNotification } from "src/components/Notifination/ToastNotification";
import { generateColor, getContrastYIQ } from "src/utils/utils";
import { useMemo, useState } from "react";
import ApprovalModal from "../project-container/View/Production/ApprovalModal";
import CommentModal from "../production-container/CommentModal";
import { ExportReactCSV } from "src/components/ExportReactCSV";

const ProductionPendingContainer=()=>{
    const dispatch = useDispatch();
    const { openSuccessNotification, openErrorNotification } = useNotification();
    const {production} = useSelector((state) => state.DashboardReducer.pendingList,shallowEqual);
    const user = useSelector((state) => state.auth.user);

    const [selectedProduction,setSelectedProduction]=useState([])
    const [selectedStatuses, setSelectedStatuses] = useState('');

    const getProjectProductionData= async()=>{
        try {
            const pendingProduction = await getPendingProduction({userId: user?._id});
            dispatch(actionSetPendingList({data:{   
                production: pendingProduction?.data?.data,
                pendingType:'Production'
              }}));
         openSuccessNotification("Production Updated Successfully");
    } catch (error) {     
        openErrorNotification(
            error.response?.data?.message ?? "Error During Update task status"
        );
        }
    }
    const handleChangeStatus=(status,production)=>{
        setSelectedStatuses(status); 
        setSelectedProduction(production);
      }
      const handleFileDownload = (production) => { 
        production?.files.forEach(file => {
          const a = document.createElement('a');
          a.href = file.url;
          a.target = '_blank'; // Open in a new tab
          a.click();
        });
      };
      const handleComment = (production) => {
        dispatch({ type: "PRODUCTION_CURRENT_PRODUCTION", data: production });
     };

     const ExportInExcel = useMemo(() => { 
      if (production?.length===0) {
          return [];
      }
      const results = [];
      production?.forEach((item) => { 
        let status;
                      if (( item?.approvedDurations <= item?.rejectedDurations)) {
                      status = "Pending";  // Rejected if approved is less than or equal to rejected
                    } else if (((item?.approvedDurations||0)+(item?.rejectedDurations||0)) >= item?.totalDurations) {
                      status = "Reviewed";  // Approved if approvedDurations is greater than or equal to totalDurations
                    } else {
                      status = "Pending";  // Otherwise, it's pending
                    }


                      const approvedDurations = item?.approvedDurations || 0;
                      const rejectedDurations = item?.rejectedDurations || 0;
                      const totalDurations = item?.totalDurations || 0;

                      // Calculate Pending based on the provided conditions
                      let pending;
                      if (approvedDurations === 0 && rejectedDurations === 0) {
                        pending = totalDurations;
                      } else if (approvedDurations > 0 && rejectedDurations > 0) {
                        pending = totalDurations - (approvedDurations + rejectedDurations);
                      } else if (approvedDurations > 0) {
                        pending = totalDurations - approvedDurations;
                      } else if (rejectedDurations > 0) {
                        pending = totalDurations - rejectedDurations;
                      }

                      // Ensure pending is not negative
                      pending = Math.max(pending, 0);
                      // Ensure pending is not negative
                      pending = Math.max(pending, 0);
                  results.push({
                    Date: moment(item?.date).format('DD-MM-YYYY'),
                    Name: `${item.userId.firstName} ${item.userId.lastName}`,
                    Project: item?.clientsDetails?.ClientProjectCode,
                    Task: item?.taskDetails.taskName,                  
                    Module: item?.moduleDetails.moduleName,
                    Description: item?.comment,
                    Total: item?.totalDurations,
                    Approved: item?.approvedDurations || 0,
                    Pending: pending,
                    Rejected: item?.rejectedDurations || 0,
                    Status: status, 
                  });
      });
      return results;
  }, [
    production
  ]);

    return (
        <>
          <div className="row">
            <div className="col-xxl-12 col-xl-12">
              <div className="card h-100">
                <div className="card-body p-24">
                 <div className="d-flex align-items-center justify-content-between mb-16">
                 <div className="">
                 <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24"> 
                    Pending Production List For Approval
                  </h2>
                  <ul className="d-flex align-items-center gap-2">
                    <li className="fw-medium">
                      <a
                        href="javascript:void(0)"
                        className="d-flex align-items-center gap-1 hover-text-primary"
                      >
                        <iconify-icon
                          icon="solar:home-smile-angle-outline"
                          className="icon text-lg"
                        ></iconify-icon>
                        Special Access
                      </a>
                    </li>
                    <li>-</li>
                    <li className="fw-medium">Pending Production List </li>
                  </ul>  
                  </div> 
                  <ExportReactCSV csvData={ExportInExcel} fileName={"PendingProductionList-" + new Date().toLocaleDateString()} />  
                  </div>             
                  <div className="table-responsive table-container" id="table-container">
                    <table className="table basic-border-table mb-0 table-hover">
                      <thead>
                      <tr className="dnd-moved">
                            {/* <th className="w30px dragablefalse" draggable="false">
                                <input className="form-check-input" type="checkbox" />{" "}
                            </th> */}
                            <th className="w30px dragablefalse" draggable="false">
                                <label className="form-check-label">S.No.</label>
                            </th>
                            <th>Date</th>
                            <th>Name </th>
                            {/* <th>Production Path </th> */}
                            {/* <th>Department </th>
                            <th>Designation </th> */}
                            <th>Project </th>
                            <th>Task </th>
                            <th>Module </th>
                            <th>Description </th>
                            <th>File </th>
                            <th className="text-center">Total </th>
                            <th>Approved </th>
                            <th>Pending </th>
                            <th>Rejected </th>
                            {/* <th className="text-center">Cost </th> */}
                            <th className="text-center">Status </th>
                    </tr>
                      </thead>
                      <tbody id="table-body">
                      {production?.map((item, index) => {  
                      let status;
                      if (( item?.approvedDurations <= item?.rejectedDurations)) {
                      status = "Pending";  // Rejected if approved is less than or equal to rejected
                    } else if (((item?.approvedDurations||0)+(item?.rejectedDurations||0)) >= item?.totalDurations) {
                      status = "Reviewed";  // Approved if approvedDurations is greater than or equal to totalDurations
                    } else {
                      status = "Pending";  // Otherwise, it's pending
                    }


                      const approvedDurations = item?.approvedDurations || 0;
                      const rejectedDurations = item?.rejectedDurations || 0;
                      const totalDurations = item?.totalDurations || 0;

                      // Calculate Pending based on the provided conditions
                      let pending;
                      if (approvedDurations === 0 && rejectedDurations === 0) {
                        pending = totalDurations;
                      } else if (approvedDurations > 0 && rejectedDurations > 0) {
                        pending = totalDurations - (approvedDurations + rejectedDurations);
                      } else if (approvedDurations > 0) {
                        pending = totalDurations - approvedDurations;
                      } else if (rejectedDurations > 0) {
                        pending = totalDurations - rejectedDurations;
                      }

                      // Ensure pending is not negative
                      pending = Math.max(pending, 0);


                      // Ensure pending is not negative
                      pending = Math.max(pending, 0);

                      return (
                        <tr key={index} className="dnd-moved">
                          {/* <td draggable="false">
                            <input className="form-check-input" type="checkbox" />
                          </td> */}
                          <td draggable="false">
                            <label className="form-check-label">{index + 1}</label>
                          </td>
                          <td>{moment(item?.date).format('DD-MM-YYYY')}</td>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                            <span
                                       className="img imgsSet me-0"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       data-bs-custom-class="tooltip-dark"
                                       data-bs-title={`${item?.userId?.firstName} ${item?.userId?.lastName}`}
                                     >
                                       {item?.userId?.profilePicture ? (
                                         <img
                                           src={item?.userId?.profilePicture}
                                           className="img-fluid"
                                           alt=""
                                         />
                                       ) : (
                                         <span
                                           className="initials d-flex justify-content-center align-items-center w-100 h-100"
                                           style={{
                                             backgroundColor: generateColor(`${item?.userId?.firstName}${item?.userId?.lastName}`),
                                             color: getContrastYIQ(generateColor(`${item?.userId?.firstName}${item?.userId?.lastName}`)),
                                           }}
                                         >
                                           {`${item?.userId?.firstName.charAt(0)}${item?.userId?.lastName.charAt(0)}`}
                                         </span>
                                       )}
                                     </span>

                              {/* <span className="userimg" /> */}
                              <div className="flex-grow-1">
                                <h6 className="text-md mb-0 fw-medium text-primary-600">
                                  {item?.userId?.firstName} {item?.userId?.lastName}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td className="text-center">{`${item?.clientsDetails?.ClientProjectCode}(${item?.projectDetails?.ProjectSubCode})`}</td>
                          {/* <td>{item?.userId?.departmentId?.departmentName}</td>
                          <td>{item?.userId?.designationId?.designationName}</td> */}
                          
                          <td className="text-center">
                            {item?.taskDetails.taskName}
                          </td>
                          <td className="text-center">
                          {item?.moduleDetails.moduleName}
                          </td>
                          <td className="text-center">
                            {/* {item?.comment} */}
                            <button
                                className="text-primary-900"
                                data-bs-toggle="modal"
                                data-bs-target="#CommentModal"
                                onClick={() => handleComment(item)}
                                >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={22}
                                    height={22}
                                    fill="currentColor"
                                    className="bi bi-eye-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                </svg>
                                </button>
                          </td>
                          <td className="text-center">
                            {
                                (item?.files?.length>0)?
                                <button className="d-flex align-items-center gap-2 text-primary-600"
                                onClick={()=>{handleFileDownload(item)}}
                                >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={22}
                                    height={22}
                                    fill="currentColor"
                                    className="bi bi-file-earmark-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z" />
                                </svg>{" "}
                                View
                                </button>
                                :"NA"
                            }                            
                        </td>
                        <td className="text-center">
                            {item?.totalDurations}
                          </td>
                          <td className="text-center">

                            {item?.approvedDurations || 0}
                          </td>
                          <td className="text-center">
                            {pending}
                          </td>
                          <td className="text-center">

                            {item?.rejectedDurations || 0}
                          </td>
                          {/* <td className="text-center">
                            <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                              ₹ {item?.cost || 0}
                            </span>
                          </td> */}
                          <td className="text-center">                             
                                               <div class="btn-group w-100">
                                                      <div
                                                        class={`badge text-sm fw-semibold ${(status==="Approved")?"bg-success-600":"bg-warning-600"} px-20 py-9 radius-4 text-white d-flex align-items-center gap-2  w-100`}>
                                                        {status} 
                                                      </div>
                                                    <button type="button"
                                                        class={`btn ${(status==="Approved")?"btn-success-600":"btn-warning-600"} dropdown-toggle dropdown-toggle-split rounded-0 border-start px-16`}
                                                        data-bs-toggle="dropdown" aria-expanded="false"
                                                        data-bs-reference="parent">
                                                        <span class="visually-hidden">{status} </span>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li class="">
                                                            <a
                                                                class="dropdown-item d-flex align-items-center gap-2" 
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#statusModal"
                                                                onClick={()=>{handleChangeStatus("Approved",item)}}
                                                                href="#">
                                                                  <svg xmlns="http://www.w3.org/2000/svg"
                                                                width="22" height="22" fill="currentColor"
                                                                class="bi bi-check-circle-fill text-success-500"
                                                                
                                                                viewBox="0 0 16 16">
                                                              <path
                                                                 d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                              </svg> Approved 
                                                              </a>
                                                            </li>
                                                            <li class="">
                                                                <a
                                                                class="dropdown-item d-flex align-items-center gap-2" 
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#statusModal"
                                                                onClick={()=>{handleChangeStatus("Rejected",item)}}
                                                                href="#">
                                                                  <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="22" height="22" fill="currentColor"
                                                                    class="bi bi-x-circle-fill text-danger-500"
                                                                    viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                                                                </svg> Rejected</a>
                                                              </li>
                                                    </ul>
                                                </div>
                          </td>
                        </tr>
                      );
                    })}
                      </tbody>
                    </table>
                  </div> 
                </div>
              </div>
            </div>
          </div>
            <CommentModal />
            <ApprovalModal
            selectedStatuses={selectedStatuses}
            production={selectedProduction}
            getProjectProductionData={getProjectProductionData}
            />
        </>
      );
}
export default ProductionPendingContainer