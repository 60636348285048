export const DASHBOARD_ACTIONS = {
    DASHBOARD_SEARCH: 'DASHBOARD_SEARCH',
    DASHBOARD_SEARCH_SUCCESS: 'DASHBOARD_SEARCH_SUCCESS',
    DASHBOARD_SEARCH_ERROR: 'DASHBOARD_SEARCH_ERROR',
 
    DASHBOARD_PENDING_LIST: 'DASHBOARD_PENDING_LIST',

    DASHBOARD_RESET: 'DASHBOARD_RESET',
};

export const dashboardResetAction = () => ({
    type: DASHBOARD_ACTIONS.DASHBOARD_RESET,
});

export const actionSetPendingList = (payload) => ({
    type: DASHBOARD_ACTIONS.DASHBOARD_PENDING_LIST,
    ...payload,
}); 
export default {
    DASHBOARD_ACTIONS,
   actionSetPendingList,
};
