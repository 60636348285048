import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";

// Function to get the initials from the name
const getInitials = (name) => {
  const nameParts = name.split(' ');
  const initials = nameParts[0][0] + (nameParts[1] ? nameParts[1][0] : '');
  return initials.toUpperCase();
};

// Function to generate a dynamic background color based on the user's name
const getBackgroundColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 60%, 70%)`; // Adjust saturation and lightness as needed
  return color;
};

const TaskParticipantsPopup = ({assignees}) => {
  

  useEffect(() => {
    // Initialize Bootstrap tooltips for elements with data-bs-toggle="tooltip"
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    // Cleanup tooltips when the component unmounts
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  return (
    <div className="modal fade cusmodl" id="taskModual" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header d-flex justify-content-between pb-0 border-0">
            <h6 className="mb-2 fw-bold text-lg mb-0">{assignees.length} Members</h6>
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button>
          </div>
          <div className="modal-body">
            {assignees && assignees.map((item, index) => {
              const fullName = `${item.firstName} ${item.lastName}`;
              const initials = getInitials(fullName);
              const backgroundColor = getBackgroundColor(fullName);

              return (
                <div className="d-flex align-items-start gap-2 mb-20 linset" key={index}>
                  <span className="UsIMgName d-flex align-items-center justify-content-center" style={{ backgroundColor, width: '40px', height: '40px' }}>
                    {initials}
                  </span>
                  <div className="flex-grow-1 text-start">
                    <h6 className="text-md mb-1 fw-semibold">{fullName}</h6>
                    <p className="text-sm text-secondary-light fw-medium mb-0">
                      {item.departmentId.departmentName}
                    </p>
                    <span className="text-sm text-secondary-light fw-normal">
                      {item.designationId.designationName}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskParticipantsPopup;
