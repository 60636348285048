import { useState } from "react"
import TaskTree from "./TaskTree"
import { useDispatch } from "react-redux";
import {productionAssignModuleIdAction } from "../actions/ProductionActions";

const ModuleTree=({taskId,module})=>{
    // const [isOpen,setIsOpen] = useState(false)
    const dispatch = useDispatch();
    const handleModuleSelect = (e,moduleDetails)=>{
        e.stopPropagation(); // Prevent event propagation
        // setIsOpen(!isOpen)
        dispatch(productionAssignModuleIdAction({
          data:{
            moduleId: moduleDetails._id,
            moduleName: moduleDetails.moduleName
          }
        }))
        document.querySelectorAll('.accordion-collapse').forEach((element) => {
          element.classList.remove('show');
      });
      document.querySelectorAll('.accordion-button').forEach((element) => {
        element.classList.add('collapsed');
    });
    }
    return (
      <> 
      {/* <li   onClick={(e) => handleModuleSelect(e,module)}>
            <div className="acnav__label acnav__label--level8">
                {module.moduleName}
            </div> 
            </li> */}
            
                  
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-heading001">
                      <button onClick={(e) => handleModuleSelect(e,module)} className="accordion-button collapsed removePlsMin" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse001" aria-expanded="false" aria-controls="flush-collapse001">
                      {module.moduleName}
                      </button> 
                    </h2>
                    <div id="flush-collapse001" className="accordion-collapse collapse" aria-labelledby="flush-heading001" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">  
                      </div>
                    </div>
                  </div>


                
      </>
    )
  }
  export default ModuleTree