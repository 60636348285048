export const PERFOMANCE_ACTIONS = {
    PERFOMANCE_SEARCH: 'PERFOMANCE_SEARCH',
    PERFOMANCE_SEARCH_SUCCESS: 'PERFOMANCE_SEARCH_SUCCESS',
    PERFOMANCE_SEARCH_ERROR: 'PERFOMANCE_SEARCH_ERROR',

    PERFOMANCE_UPSERT: 'PERFOMANCE_UPSERT',
    PERFOMANCE_UPSERT_SUCCESS: 'PERFOMANCE_UPSERT_SUCCESS',
    PERFOMANCE_UPSERT_ERROR: 'PERFOMANCE_UPSERT_ERROR',

    PERFOMANCE_EDIT: 'PERFOMANCE_EDIT',

    PERFOMANCE_RESET: 'PERFOMANCE_RESET',

    PERFOMANCE_SET_PROJECT_WISE_WHERE: 'PERFOMANCE_SET_PROJECT_WISE_WHERE',
    PERFOMANCE_SET_MODULE_WISE_WHERE: 'PERFOMANCE_SET_MODULE_WISE_WHERE',
    PERFOMANCE_SET_SELECTED_PROJECT: 'PERFOMANCE_SET_SELECTED_PROJECT',
    PERFOMANCE_SET_SELECTED_MODULE: 'PERFOMANCE_SET_SELECTED_MODULE',
};

export const perfomanceResetAction = () => ({
    type: PERFOMANCE_ACTIONS.PERFOMANCE_RESET,
});

export const perfomanceSearchAction = (payload) => ({
    type: PERFOMANCE_ACTIONS.PERFOMANCE_SEARCH,
    ...payload,
});

export const perfomanceEditAction = (payload) => ({
    type: PERFOMANCE_ACTIONS.PERFOMANCE_EDIT,
    ...payload,
});

export const perfomanceUpsertAction = (payload) => ({
    type: PERFOMANCE_ACTIONS.PERFOMANCE_UPSERT,
    ...payload,
});

export const perfomanceSetProjectWiseWhereAction = (payload) => ({
    type: PERFOMANCE_ACTIONS.PERFOMANCE_SET_PROJECT_WISE_WHERE,
    ...payload,
});

export const perfomanceSetModuleWiseWhereAction = (payload) => ({
    type: PERFOMANCE_ACTIONS.PERFOMANCE_SET_MODULE_WISE_WHERE,
    ...payload,
});

export const perfomanceSetSelectedProjectAction = (payload) => ({
    type: PERFOMANCE_ACTIONS.PERFOMANCE_SET_SELECTED_PROJECT,
    ...payload,
});

export const perfomanceSetSelectedModuleAction = (payload) => ({
    type: PERFOMANCE_ACTIONS.PERFOMANCE_SET_SELECTED_MODULE,
    ...payload,
});

export default {
    PERFOMANCE_ACTIONS,
    perfomanceSearchAction,
    perfomanceUpsertAction,
    perfomanceEditAction,
    perfomanceSetProjectWiseWhereAction,
    perfomanceSetModuleWiseWhereAction,
    perfomanceSetSelectedProjectAction,
    perfomanceSetSelectedModuleAction,
};
