import { put, call } from 'redux-saga/effects';
import { getsalaryAPI, salaryCreateAPI, salarySearchAPI, salaryUpdateAPI, updateIndividualSalary } from 'src/api/SalaryApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { SALARY_ACTIONS, salarySearchAction } from '../actions/SalaryActions';
import { toast } from 'react-toastify';
import {  handleChangeDateAction, productionSearchAction } from 'src/containers/production-container/actions/ProductionActions';

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(getsalaryAPI, {
            where,
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: SALARY_ACTIONS.SALARY_SEARCH_SUCCESS,
            data,
        });
      const userId=data.userId._id
      const employeeID=data.userId.employeeID
      const selectedYear=data.salaryYear
      const selectedMonth=data.salaryMonth
        const mongoWhere = {
            userId,
            $expr: {
            $and: [
              { $eq: [{ $year: "$date" },selectedYear] },
              { $eq: [{ $month: "$date" }, selectedMonth] } 
            ]
          }}
        const whereMySql = {
        empid:employeeID,
        year: selectedYear,
        month: selectedMonth,
        }
        yield put(productionSearchAction({
            where: mongoWhere,
            whereMySql: whereMySql
        }));
        yield put(handleChangeDateAction({
            data:{selectedMonth,selectedYear}
        }))
        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: SALARY_ACTIONS.SALARY_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({
    _id,
}) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(salarySearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {

            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }
        delete data[0].password;

        yield put({
            type: SALARY_ACTIONS.SALARY_UPSERT_SUCCESS,
            currentRecord: data[0],
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(salaryCreateAPI, payload);
        // if (data?._id && payload.profilePicture) {
            // yield call(salaryProfilePicAPI, {
            //     salaryId: data?._id,
            //     data: payload,
            // });
        // }
        toast.success("Salary created.");

        yield put(headerShowAction({
            data: 'Created',
        }));
        toast("salary Created")

        yield put({
            type: SALARY_ACTIONS.SALARY_UPSERT_SUCCESS,
            data: {
                ...payload,
                _id: data?._id,
            },
        });
        yield put({
            type: SALARY_ACTIONS.SALARY_RESET
        });
        yield put({
            type: SALARY_ACTIONS.SALARY_SEARCH
        });
    }
    catch (error) {
        toast.error(error?.message);
        toast.error(error?.response?.data?.message);
        yield put({
            type: SALARY_ACTIONS.SALARY_UPSERT_ERROR,
        });
    }
}

function* bulkUpload(payload) {
    try {






        // const { finalData } = yield call(salaryUploadAPI, payload);

        // toast(`${finalData.length} salary uploaded successfully`)

        yield put({
            type: SALARY_ACTIONS.SALARY_UPLOAD_SUCCESS,
            data: {
                ...payload,

            },
        });
        yield put({
            type: SALARY_ACTIONS.SALARY_SEARCH
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: SALARY_ACTIONS.SALARY_UPLOAD_ERROR,
        });
    }
}

function* update(salaryId, payload) {
    try {
        yield call(salaryUpdateAPI, {
            salaryId,
            data: payload,
        });

        yield put(headerShowAction({
            data: 'Updated',
        }));
        if (payload.deleted === true) {
            toast("salary deleted")
        }
        else {
            toast("salary account updated")
        }

        yield put({
            type: SALARY_ACTIONS.SALARY_SEARCH
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: SALARY_ACTIONS.SALARY_UPSERT_ERROR,
        });
    }
}
function * salaryIndividualUpdateAction({userId}){
    try {
        yield call(updateIndividualSalary, {userId});  
        const payload = {
            where:{ _id: userId, active: true },
        }
        yield put(salarySearchAction(payload))
         toast.success("Salary Updated.");
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        yield insert(payload);
    }
}

export default [
    takeFirstSagaUtil(SALARY_ACTIONS.SALARY_SEARCH, search),
    takeFirstSagaUtil(SALARY_ACTIONS.SALARY_UPSERT, upsert),
    takeFirstSagaUtil(SALARY_ACTIONS.SALARY_UPLOAD, bulkUpload),
    takeFirstSagaUtil(SALARY_ACTIONS.SALARY_EDIT, edit),
    takeFirstSagaUtil(SALARY_ACTIONS.SALARY_INDIVIDUAL_UPDATE, salaryIndividualUpdateAction),
];
